import React, { useState, useContext } from 'react';

import Login from '../auth/Login';
import { Modal } from 'react-bootstrap';
import { AuthContext } from '../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import './home.css';
import Sidenav from './Sidenav';
// import RequestTutor from './RequestTutor';

function Header(props) {
	const auth = useContext(AuthContext);
	const history = useHistory();

	const [showPopup, setShowPopup] = useState(false);

	const handleClose = () => setShowPopup(false);
	const handleShow = () => setShowPopup(true);

	const handleDashboard = () => {
		if (auth.role === 'tutor') {
			history.push('/tutor-dashboard');
		} else if (auth.role === 'student') {
			history.push('/student-dashboard');
		} else if (auth.role === 'admin') {
			history.push('/admin');
		}
	};

	return (
		<div className="header-custom">
			<Sidenav />
			<nav className="navbar navbar-custom navbar-expand-lg" id="mainNav">
				<div className="container">
					<a className="navbar-brand" href="/">
						<img
							className="header-image"
							src="/images/Peerlinc.png"
							alt="home"
						/>
					</a>

					<ul className="navbar-nav ml-auto">
						<a href="/pricing" className="menu-item">
							Pricing
						</a>
						{/* <a href="http://help.Peerlinc.com" className="header-item">
							Help
						</a> */}
						<li className="nav-item">
							<div>
								{auth.currentUser !== null ? (
									<button
										onClick={handleDashboard}
										type="button"
										className="godash-button login-button"
									>
										<span className="login-text">
											Dashboard <i className="fad fa-angle-double-right"></i>{' '}
										</span>
									</button>
								) : (
									<button
										type="button"
										className="login-button"
										onClick={handleShow}
									>
										<span className="login-text">Login</span>
									</button>
								)}
							</div>
						</li>
					</ul>
				</div>
				{/* {!props.request ? null: <RequestTutor /> } */}
			</nav>

			{/* LOGIN POPUP */}
			<Modal show={showPopup} onHide={handleClose}>
				<Modal.Header className="modal-header" closeButton></Modal.Header>
				<Modal.Body className="modal-body">
					<Login newRegister={true} notFromURL />
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default Header;
