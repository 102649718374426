import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import axios from 'axios';
//import LoadingPage from '../../components/loading/LoadingPage';
import { Failure } from '../../components/alerts/Alerts';
import Payment from './Payment';
import LoadingButton from '../loading/LoadingButton';

function Payments() {
	const [payments, setPayments] = useState([]);
	const [alert, setAlert] = useState();
	const [isDataLoading, setDataLoading] = useState(true);
	const [showState, setShowState] = useState([]);
	const auth = useContext(AuthContext);

	const getSubscriptionLogs = () => {
		return new Promise((resolve, reject) => {
			axios
				.post(
					`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/list/subscription`,
					{}
				)
				.then((res) => {
					// get payment data
					var response = res.data;
					if (response.status) {
						var unfilteredPayments = response.data;
						var filteredPayments = [];
						if (auth.role === 'tutor') {
							for (var i = 0; i < unfilteredPayments.length; i++) {
								if (unfilteredPayments[i].tutorId === auth.userHash) {
									filteredPayments.push(unfilteredPayments[i]);
								}
							}
						} else {
							for (var j = 0; j < unfilteredPayments.length; j++) {
								if (unfilteredPayments[j].studentId === auth.userHash) {
									filteredPayments.push(unfilteredPayments[j]);
								}
							}
						}

						// Sort by date
						var allLogsItems = payments.concat(filteredPayments);
						var sortedPayments = allLogsItems.reverse();
						//console.log("logItems>>>", sortedPayments);
						setPayments(sortedPayments);
						setDataLoading(false);
					}
					resolve();
				})
				.catch((err) => {
					resolve();
				});
		});
	};

	const getPaymentLogs = () => {
		return new Promise((resolve, reject) => {
			axios
				.get(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/logs/`,
					{ params: { logType: 'PAYMENTS' } }
				)
				.then((res) => {
					// get payment data
					var unfilteredPayments = res.data.Items;

					// filter based on student/tutor ID
					var filteredPayments = [];
					if (auth.role === 'tutor') {
						for (var i = 0; i < unfilteredPayments.length; i++) {
							if (unfilteredPayments[i].tutorId === auth.userHash) {
								filteredPayments.push(unfilteredPayments[i]);
							}
						}
					} else {
						for (var j = 0; j < unfilteredPayments.length; j++) {
							if (unfilteredPayments[j].studentId === auth.userHash) {
								filteredPayments.push(unfilteredPayments[j]);
							}
						}
					}

					// Sort by date
					var allLogsItems = payments.concat(filteredPayments);
					var sortedPayments = allLogsItems.reverse();
					//console.log("logItems>>>", sortedPayments);
					setPayments(sortedPayments);
					//setDataLoading(false);
					resolve();
				})
				.catch((err) => {
					setAlert(<Failure title="Oops!" message={err.message} />);
					resolve();
				});
		});
	};

	const getLogs = async () => {
		await getPaymentLogs();
		setTimeout(() => {
			getSubscriptionLogs();
		}, 1000);
	};

	useEffect(() => {
		getLogs();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (payments.length > 0) {
			var sortedPayments = payments.sort(function (a, b) {
				return new Date(b.timestamp) - new Date(a.timestamp);
			});
			setShowState(
				sortedPayments.map((payment) => <Payment payment={payment} />)
			);
		} else {
			setShowState(<p>No transaction found</p>);
		}
	}, [payments]);

	return (
		<div>
			<div class="admin-container transaction-history-page">
				{alert}
				<h2>Transactions</h2>
				<div class="all-container">
					<div class="all-labels">
						<div class="row">
							<div class="col-8 text-left">
								<i class="far fa-clock"></i> Date & Type
							</div>
							<div class="col-4">
								<i class="far fa-money-bill-wave-alt"></i> Amount
							</div>
							{/* <div class="col-4">
								<i class="fas fa-typewriter"></i> Type
							</div> */}
						</div>
					</div>
					{isDataLoading ? <LoadingButton /> : showState}
				</div>
			</div>
		</div>
	);
}

export default Payments;
