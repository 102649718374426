import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';
import { Failure } from '../alerts/Alerts';
import LoadingButton from '../loading/LoadingButton';
import './auth.css';

function Confirm(props) {
	const [code, setCode] = useState('');
	const [username, setUsername] = useState('');
	const [formError, setFormError] = useState(null);
	const [loadState, setLoadState] = useState(<div>Confirm</div>);

	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		// Get the user's username (email) that was passed from register page
		if (props.setModalState) {
			setUsername(props.registeredUsername);
		} else {
			if (location.state) {
				setUsername(location.state.username);
				if (location.state.resendConfirm)
					Auth.resendSignUp(location.state.username);
			} else {
				history.push('/register');
			}
		}
	}, [props, location.state, history]);

	const handleConfirmation = async (e) => {
		e.preventDefault();
		setLoadState(<LoadingButton />);

		try {
			// Confirm sign up with confirmation code
			// eslint-disable-next-line
			const confirm = await Auth.confirmSignUp(username, code);

			setCode('');
			// Redirect to login page
			if (props.setModalState) {
				props.setModalState(3);
			} else {
				history.push('/login', { isRegister: location.state.isRegister });
			}
		} catch (error) {
			setLoadState(<div>Confirm</div>);
			setFormError({
				title: 'Invalid Verification Code.',
				message: 'Please enter the code sent to your email',
			});
		}
	};

	return (
		<div>
			{formError ? (
				<Failure title={formError.title} message={formError.message} />
			) : null}

			<div class="confirm-container">
				<h5>Confirmation Code</h5>
				<p class="explain-text">
					Enter the code sent to your email. Check spam and wait at least 3 minutes
				</p>
				<div>
					<input
						class="confirm"
						value={code}
						onChange={(e) => setCode(e.target.value)}
					></input>
				</div>

				<button class="confirm-button" onClick={handleConfirmation}>
					{loadState}
				</button>
				<p>
					Didn't receive a code?
					<a href="manually-confirm"> Request a manual confirmation</a>
				</p>
			</div>
		</div>
	);
}

export default Confirm;
