import React from 'react';
import Footer from '../../components/home/Footer';
import './index.scss';
import ModalJoinWaitlist from '../../components/home/Modals/ModalJoinWaitlist';

function HomeParents() {
	const [showModal, setShowModal] = React.useState(false);

	return (
		<div className="home-container page-highschool page-tutors">
			<div className="header-custom">
				<nav className="navbar navbar-custom navbar-expand-lg" id="mainNav">
					<div className="container">
						<a className="navbar-brand" href="/">
							<img
								className="header-image"
								src="/images/Peerlinc.png"
								alt="home"
							/>
						</a>

						{/* <ul className="navbar-nav ml-auto">
							<li>
								<button
									className="btn-custom"
									onClick={() => setShowModal(true)}
								>
									Apply
								</button>
							</li>
						</ul> */}
					</div>
				</nav>
			</div>

			<div className="container home-banner">
				<div className="row hero-banner">
					<div className="main-writing col-sm-12 col-md-12 col-lg-6">
						<p className="text-description heading-20">
							Don't let your child <br />
							<u>Fall Behind</u> in Math this year.
						</p>
						<p className="text-description heading-20">
							Give your learner <br />
							<u>#TeamAnya</u> & Let's Do this!
						</p>
						<button className="btn-custom" onClick={() => setShowModal(true)}>
							Subscribe
						</button>
					</div>

					<div className="col-sm-12 col-md-12 col-lg-6">
						<div className="main">
							<div className="orange-box"></div>
							<div className="orange-box bg-black"></div>
							<div className="main-video-div">
								<iframe
									src="https://www.loom.com/embed/0078acaa7de64e6b97ad108cc1bf7e82?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
									title="home video"
									className="main-video"
								></iframe>
							</div>
						</div>
					</div>
				</div>
				<div className="row section-branding">
					<div className="direction-arrow"></div>
					<div className="col-sm-12 col-md-10">
						<div className="box-branding">
							<div>
								<img
									src="/images/Peerlinc.png"
									alt="branding"
									className="img-logo"
								/>
							</div>
							<div>
								<img
									src="/images/icons/icon-equal.svg"
									alt="branding"
									className="img-icon"
								/>
							</div>
							<div>
								<div className="row-2">
									<span>
										Your Math Class Co-Pilot
										<br />
										for the 2023-24 School Year
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<section className="section-feature mt-10 mb-10">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<img
								src="/images/features/Office-Hours.png"
								alt="feature"
								className="img-feature"
							/>
						</div>
						<div className="col-md-6 description-box">
							<h4>Weekly Office Hours</h4>
							<ul>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<u>Every week</u> throughout the school year, Anya hosts
										live virtual "Office Hours". Ask questions and get answers
										to your most pressing math homework questions!
									</span>
								</li>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										Learn from Anya and other cohort members in this fun and
										educational setting.
									</span>
								</li>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										For sessions which you can't attend, all Office Hours are
										recorded and available for viewing.
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<section className="section-feature mb-10">
				<div className="container">
					<div className="row">
						<div className="col-md-6 xs-visible">
							<img
								src="/images/features/Top-College-Tutors.gif"
								alt="feature"
								className="img-feature"
							/>
						</div>
						<div className="col-md-6 description-box">
							<h4>STEM Videos</h4>
							<ul>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<strong>Homework Videos</strong>: Anya answers questions
										directly from her students' homework and posts videos that
										help explain solutions in detail.
									</span>
								</li>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<strong>Math Foundations</strong>: Anya's "Math Foundations"
										series helps students understand core concepts that drive
										success.
									</span>
								</li>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<strong>Study Strategies & Tutor Tips</strong>: Anya shares
										her best practices for studying, retaining information and
										taking tests.
									</span>
								</li>
							</ul>
						</div>
						<div className="col-md-6 xs-hidden">
							<img
								src="/images/features/Top-College-Tutors.gif"
								alt="feature"
								className="img-feature"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="section-feature mt-10 mb-10">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<img
								src="/images/features/Math-Chat.gif"
								alt="feature"
								className="img-feature"
							/>
						</div>
						<div className="col-md-6 description-box">
							<h4>Math-Chat</h4>
							<ul>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										Don't waste hours struggling with tough math homework
										questions. #TeamAnya is here to help you get through tough
										questions quickly.
									</span>
								</li>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										Chat your math homework questions to us, #TeamAnya answers!
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<div className="cover-footer">
				<section className="cta-section">
					<div className="container text-center">
						<h5 className="heading-5">
							JOIN #TeamAnya for the 2023 - 2024 School Year
						</h5>

						<div className="row">
							<div className="col-sm-12 col-md-12 col-lg-12 cta-div">
								<div className="cta-img-div row">
									<img
										className="cta-img"
										src="/images/online-teacher.png"
										alt="cta"
									/>
									<div className="col-lg-6 col-md-6 text-align-left">
										<h1 className="meet-tutors-title">Ready to get started?</h1>
										<button
											className="btn-custom"
											onClick={() => setShowModal(true)}
										>
											<span className="login-text">Subscribe</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<Footer />

			{showModal && (
				<ModalJoinWaitlist
					show={true}
					onClose={() => setShowModal(false)}
					title={'Subscribe to Parent Newsletter'}
					bodyTitle={'Peerlinc: Parent Newsletter'}
					subject={'New request for Join Parent Newsletter'}
				/>
			)}
		</div>
	);
}

export default HomeParents;
