import React, { useState, useContext, useEffect } from 'react';
import Payments from './Payments';
import Nav from './Nav';
import { AuthContext } from '../../contexts/AuthContext';
import { Button, Card } from 'react-bootstrap';
import { utcTimeFormat } from '../../tools/utils';
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';
import LoadingButton from '../loading/LoadingButton';
import { Failure } from '../alerts/Alerts';
import '../settings/settings.css';
import './css/custom.css';
import NavLogo from '../NavLogo';

function StudentPayment() {
	const [alert, setAlert] = useState();
	const [isDataLoading, setDataLoading] = useState(true);
	const [cancelBtn, setCancelBtn] = useState('Cancel Subscription');
	const auth = useContext(AuthContext);
	const { currentStudent } = auth;

	useEffect(() => {
		if (currentStudent && currentStudent.id) {
			setDataLoading(false);
		}
	}, [currentStudent]);
	//console.log('currentStudent>>', currentStudent);

	const handleCancelSubscription = () => {
		confirmAlert({
			title: 'Confirmation',
			message: 'Are you sure cancel this Subscription?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => {
						setCancelBtn(<LoadingButton />);
						axios
							.post(
								`${process.env.REACT_APP_NEW_API_ENDPOINT}/checkout/cancel_subscription`,
								{
									student_id: auth.userHash,
									subscription_id: currentStudent.subscriptionTxnId,
								}
							)
							.then((result) => {
								var response = result.data;
								if (response && response.status) {
									window.location.reload();
								} else {
									setAlert(
										<Failure title="Error!" message={response.message} />
									);
								}
							})
							.catch((err) => {
								setAlert(
									<Failure
										title="oops!"
										message={'Something went wrong. Contact us.'}
									/>
								);
							})
							.finally(() => {
								setCancelBtn('Cancel Subscription');
							});
					},
				},
				{
					label: 'No',
				},
			],
		});
	};

	return (
		<div className="general-container settings-container transaction-page">
			<Nav logo={<NavLogo />} />

			<div class="container-fluid">
				<div class="row">
					<div class="col-sm-12 col-md-4 col-lg-4">
						{isDataLoading ? (
							<LoadingButton />
						) : currentStudent && currentStudent.subscriptionTxnId ? (
							<Card>
								<Card.Body>
									<Card.Title>Current Subscription</Card.Title>
									<p className="txt-title">Subscription type:</p>
									<p className="txt-value">
										{currentStudent.subscriptionFrequency}ly
									</p>

									<>
										<p className="txt-title">Subscription start date:</p>
										<p className="txt-value">
											{utcTimeFormat(currentStudent.subscriptionStartDate)}
										</p>
										{currentStudent.subscriptionCancelStartDate ? (
											<>
												<p className="txt-title">Subscription End date:</p>
												<p className="txt-value">
													{utcTimeFormat(currentStudent.subscriptionEndDate)}
												</p>
											</>
										) : (
											<>
												<p className="txt-title">
													Subscription next renew date:
												</p>
												<p className="txt-value">
													{utcTimeFormat(currentStudent.subscriptionEndDate)}
												</p>
											</>
										)}
									</>

									{currentStudent.subscriptionCancelStartDate ? (
										<p className="txt-title">Cancelled Successfully</p>
									) : (
										<Button
											variant="danger"
											className="btn-cancel"
											onClick={handleCancelSubscription}
										>
											{cancelBtn}
										</Button>
									)}
								</Card.Body>
								{alert}
							</Card>
						) : currentStudent &&
						  currentStudent.subscriptionMode &&
						  currentStudent.subscriptionMode === 'manual' ? (
							<Card>
								<Card.Body>
									<Card.Title>Current Subscription</Card.Title>
									<h4>Manually Active</h4>
								</Card.Body>
							</Card>
						) : (
							<Card>
								<Card.Body>
									<h4>No active subscription</h4>
								</Card.Body>
							</Card>
						)}

						{isDataLoading ? (
							<></>
						) : (
							currentStudent &&
							currentStudent.balance > 1 && (
								<Card>
									<Card.Body>
										<Card.Title>Remaining balance</Card.Title>
										<p className="txt-value">{currentStudent.balance} hours</p>
									</Card.Body>
									{alert}
								</Card>
							)
						)}
					</div>
					<div class="col-sm-12 col-md-8 col-lg-6">
						<div class="transactions-container">
							<Payments />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default StudentPayment;
