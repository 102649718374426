import React from 'react';
import './settings.css';

function Input(props) {
	return (
		<div className="form-group">
			<label className="settings-label">{props.label}</label>
			<input
				className="form-control"
				value={props.value}
				type={props.type}
				onChange={props.onChange}
				{...props}
			/>
		</div>
	);
}

export default Input;
