import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { utcStringToLocalDate } from '../../../tools/timeConverter';
import Header from '../../../components/admin/home/Header';
import LoadingPage from '../../../components/loading/LoadingPage';
import { Failure } from '../../../components/alerts/Alerts';
import { NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import LoadingButton from '../../../components/loading/LoadingButton';

import './index.scss';

function AdminListHighschool() {
	const [alert, setAlert] = useState();
	const [highschools, setHighschools] = useState([]);
	const [filteredHighschools, setFilteredHighschools] = useState([]);
	const [loading, setLoading] = useState(true);
	const [activeFilter, setActiveFilter] = useState('all');

	useEffect(() => {
		getHighSchools();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		var filterItems = [];
		if (activeFilter === 'public') {
			filterItems = highschools.filter((item) => item.type === 'public');
		} else if (activeFilter === 'private') {
			filterItems = highschools.filter((item) => item.type === 'private');
		} else {
			filterItems = highschools.filter((item) => item);
		}
		setFilteredHighschools(filterItems);
		// eslint-disable-next-line
	}, [activeFilter]);

	const getHighSchools = async () => {
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_highschool`,
				{
					action: 'list_highschool',
				}
			)

			.then((res) => {
				var list = res?.data?.data;
				var sortedList = list.sort(
					(aStudent, bStudent) =>
						utcStringToLocalDate(bStudent.date_created) -
						utcStringToLocalDate(aStudent.date_created)
				);
				setHighschools(sortedList);
				setFilteredHighschools(sortedList);
			})
			.catch(() =>
				setAlert(
					<Failure
						title="Oops!"
						message="Something happened. Try again or contact us."
					/>
				)
			)
			.finally(() => setLoading(false));
	};

	const getHighSchoolUrl = (slug) => {
		const url = `${window?.location?.origin}/hs/${slug}`;
		return (
			<a
				href={url}
				target="_blank"
				rel="noopener noreferrer"
				className="copy-clipboard"
			>
				/{slug}
			</a>
		);
	};

	const DeleteButton = ({ item }) => {
		const { id, date_created } = item;
		const [loading, setLoading] = useState(false);

		const handleDelete = async () => {
			try {
				const confirm = window.confirm(
					'Are you sure you want to delete this highschool?'
				);
				if (!confirm) return;

				setLoading(true);
				await axios.post(
					`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_highschool`,
					{
						action: 'delete_highschool',
						id: id,
						date_created: date_created,
					}
				);
				getHighSchools();
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		};

		return (
			<Button disabled={loading} className="btn-delete" onClick={handleDelete}>
				{loading ? <LoadingButton /> : <i className="fas fa-trash-alt"></i>}
			</Button>
		);
	};

	const columns = [
		{
			name: 'Name',
			sortable: true,
			selector: 'name',
			cell: (item) => (
				<>
					<img src={item.logo_image} alt="" className="logo-img" />
					<span>{item?.name}</span>
				</>
			),
		},
		{
			name: 'Address',
			sortable: false,
			cell: (item) => (
				<>
					{item?.street_address}, {item?.city}, {item?.state}
				</>
			),
		},
		{
			name: 'Total Students',
			sortable: true,
			width: '140px',
			selector: 'total_student',
		},
		{
			name: 'HS Page Url',
			sortable: false,
			cell: (item) => <>{getHighSchoolUrl(item?.slug)}</>,
		},
		{
			name: 'Groups',
			sortable: false,
			width: '80px',
			cell: (item) => (
				<NavLink to={`/admin-highschool-groups/${item.id}?name=${item?.name}`}>
					View
				</NavLink>
			),
		},
		{
			name: 'Student',
			sortable: false,
			width: '80px',
			cell: (item) => (
				<NavLink
					to={`/admin-highschool-students/${item.id}?name=${item?.name}`}
				>
					View
				</NavLink>
			),
		},
		{
			name: 'Teachers',
			sortable: false,
			width: '90px',
			cell: (item) => (
				<NavLink
					to={`/admin-highschool-teachers/${item.id}?name=${item?.name}`}
				>
					View
				</NavLink>
			),
		},
		{
			name: 'Action',
			width: '130px',
			cell: (item) => (
				<>
					<NavLink to={`/admin-highschool/edit/${item.id}`}>
						<Button className="btn-edit">
							<i className="fas fa-pencil"></i>
						</Button>
					</NavLink>
					<DeleteButton item={item} />
				</>
			),
		},
	];

	const handleSearch = (e) => {
		const value = e.target.value;
		const searchKeyword = value.toLowerCase();
		const filteredItems = highschools.filter((item) => {
			return (
				item?.name?.toLowerCase()?.includes(searchKeyword) ||
				item?.street_address?.toLowerCase()?.includes(searchKeyword) ||
				item?.slug?.toLowerCase()?.includes(searchKeyword) ||
				item?.city?.toLowerCase()?.includes(searchKeyword) ||
				item?.state?.toLowerCase()?.includes(searchKeyword)
			);
		});
		setFilteredHighschools(filteredItems);

		if (value === '') {
			setFilteredHighschools(highschools);
		}
	};

	if (loading) {
		return <LoadingPage />;
	}

	return (
		<div className="page-admin-highschool">
			<Header />
			{alert}
			<div className="container-fluid">
				<div className="row page-header mt-5 mb-4">
					<h4 className="admin-title">High Schools</h4>

					<div className="action-buttons">
						<a href="/admin-highschool/add" className="btn-custom-primary">
							Add High school
						</a>
						<input
							type="text"
							className="form-control"
							placeholder="Search"
							onChange={handleSearch}
						/>
					</div>
					<div className="filter-bar">
						<span
							className={activeFilter === 'all' ? 'active' : ''}
							onClick={() => setActiveFilter('all')}
						>
							All
						</span>
						<span
							className={activeFilter === 'public' ? 'active' : ''}
							onClick={() => setActiveFilter('public')}
						>
							Public
						</span>
						<span
							className={activeFilter === 'private' ? 'active' : ''}
							onClick={() => setActiveFilter('private')}
						>
							Private
						</span>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						{filteredHighschools.length > 0 ? (
							<DataTable
								columns={columns}
								data={filteredHighschools}
								pagination
								striped
							/>
						) : (
							<p className="text-center">No high school found</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdminListHighschool;
