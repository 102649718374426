/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import Nav from '../../components/dashboard/Nav';
import { toast } from 'react-toastify';
import LoadingButton from '../../components/loading/LoadingButton';
import { useHistory } from 'react-router-dom';
import './index.scss';
import NavLogo from '../../components/NavLogo';

function TutorTnC() {
	const auth = useContext(AuthContext);
	const { userHash: tutorId } = auth;
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [isTnCAgreed, setIsTnCAgreed] = useState(false);

	useEffect(() => {
		// Check to make sure user has been authenticated and is a tutor
		const { userHash: tutorId } = auth;
		if (tutorId) {
			setIsDataLoaded(false);
			axios
				.get(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/tutors`,
					{
						params: {
							tutorParam: tutorId,
						},
					}
				)
				.then((res) => {
					const tutorData = res.data;
					const { tncAgreed } = tutorData;
					setIsTnCAgreed(tncAgreed);
				})
				.finally(() => {
					setIsDataLoaded(true);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const isTnCAgree = e.target.agree.checked;
			if (!isTnCAgree) {
				toast.error('Please agree to the terms and conditions');
				return;
			}
			setLoading(true);
			const results = await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/teacher/profile`,
				{
					action: 'agree_tnc',
					tutorId: tutorId,
				}
			);
			const { data } = results || {};
			const { status, message } = data || {};
			if (status) {
				toast.success(message);
				history.push('/tutor-dashboard');
			} else {
				toast.error(message);
			}
		} catch (error) {
			const errMessage = error?.response?.data?.message || error.message;
			toast.error(errMessage);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Nav logo={<NavLogo />} settings="/tutor-settings" />

			<div className="dashboard page-tnc">
				<div className="section-content">
					<div className="section-header">
						<h3>Terms & Conditions - For Tutors</h3>
					</div>

					<div className="tnc-text">
						<h5>Independent Contractor</h5>
						<p>
							All tutors on the PeerLinc platform are Independent Contractors
							and not employees of PeerLinc, LLC. Independent contractors are
							responsible for their own tax withholdings. Neither federal, nor
							state, nor local income tax, nor payroll tax of any kind shall be
							withheld by Peerlinc on behalf of Independent Contractors.
							Independent contractors are also responsible for any expenses
							incurred related to rendering of their tutoring services.
						</p>

						<h5>Pay Rate for Tutoring on PeerLinc</h5>
						<p>
							All tutors on the PeerLinc platform will be paid the following
							rate: $20 hour for 1-on-1 tutoring and a minimum of $40 hour for
							group tutoring. Tutors will only be paid for completed sessions.
							Tutors can access funds via the “Cash Out” button in their tutor
							dashboard.
						</p>

						<h5>Web Marketing</h5>
						<p>
							A tutor's picture, bio, and videos may be used to market PeerLinc
							tutoring services. The tutor agrees to allow Peerlinc, LLC to use
							all those above for marketing purposes. The objective of these
							marketing efforts will be to drive more business to PeerLinc and
							their active tutors.
						</p>

						<h5>School Assignment</h5>
						<p>
							Tutors on the PeerLinc platform are assigned to high schools and
							their associated students. From the student’s perspective you will
							show up in their student dashboard. The nature of this assignment
							is so that PeerLinc tutors can be a known resource for the
							students of these schools. PeerLinc tutors will make reasonable
							efforts to get to know these schools, all in the spirit of helping
							generate more business for themselves and PeerLinc.
						</p>

						<h5>Tutor Responsibilities</h5>
						<p>
							Each tutor is required to use the technology platform provided by
							PeerLinc, to perform each tutoring season. Each tutor is required
							to send a brief summary of the tutoring session, promptly after it
							ends. Creating and submitting this summary report takes place in
							the tutor dashboard.
						</p>

						<h5>Proactive Communication</h5>
						<p>
							Each tutor is required to let PeerLinc know when they will be
							taking a break from the platform for more than two weeks. This can
							be relayed to PeerLinc via email, text or phone call to your main
							point of contact. Whether it be a holiday break, a summer break, a
							semester off, the best tutors actively communicate these
							scheduling breaks to PeerLinc whenever they need to occur.
						</p>

						<h5>Tutor Code of Conduct</h5>
						<p>
							All tutors on the PeerLinc platform agree to the following Code of
							Conduct. At all times the Tutor and Student relationship is framed
							by this Code of Conduct. This code refers to tutoring sessions,
							tutor videos and any communication that occurs between Tutors and
							Students:
						</p>
						<ul>
							<li>
								Tutors at all times will conduct themselves in a manner that is
								respectful and professional.
							</li>
							<li>Tutors at all times will use professional language.</li>
							<li>
								Tutors at all times will interact with students only in the
								context of the tutoring subject material.
							</li>
						</ul>

						<p>
							Conducting oneself in a manner that is deemed to violate this
							Tutor Code of Conduct will result in immediate dismissal from the
							PeerLinc platform. Additionally, PeerLinc does not tolerate
							discrimination of any kind, whether based on race, sex, gender,
							sexual orientation, color, age, disability, national origin, or
							any other federally protected group.
						</p>
					</div>

					{isDataLoaded && (
						<>
							{!isTnCAgreed && (
								<div className="action-footer">
									<form onSubmit={handleSubmit}>
										<div className="form-group">
											<input
												type="checkbox"
												id="agree"
												name="agree"
												value="agree"
												required
											/>
											<label htmlFor="agree">
												I Agree to these Terms & Conditions
											</label>
										</div>

										<div className="form-action">
											<button
												className="btn btn-primary"
												type="submit"
												disabled={loading}
											>
												{loading ? <LoadingButton /> : 'Submit'}
											</button>
										</div>
									</form>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
}

export default TutorTnC;
