import React from 'react';
import ParentContact from '../../components/contact/ParentContact';

function ParentContactPage() {
	return (
		<ParentContact
			type = "parent"
			title="Free session for your child"
			text="An Advisor will reach out within minutes"
			input="What Math is your child in?"
		/>
	);
}

export default ParentContactPage;
