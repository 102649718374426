import React from 'react';
import Footer from '../../components/home/Footer';
import HowItWorks from '../../components/home/HowItWorks';
import './index.scss';

function HomeTutors() {
	return (
		<div className="home-container page-highschool page-tutors">
			<div className="header-custom">
				<nav className="navbar navbar-custom navbar-expand-lg" id="mainNav">
					<div className="container">
						<a className="navbar-brand" href="/">
							<img
								className="header-image"
								src="/images/Peerlinc.png"
								alt="home"
							/>
						</a>

						<ul className="navbar-nav ml-auto">
							<li>
								<a type="button" className="btn-custom" href={`/apply`}>
									Apply
								</a>
							</li>
						</ul>
					</div>
				</nav>
			</div>

			<div className="container home-banner">
				<div className="row">
					<div className="main-writing col-sm-12 col-md-12 col-lg-5">
						<h1 className="text-title">
							<span>Tutor with PeerLinc!</span>
						</h1>
						<p className="text-description">
							PeerLinc is an education platform that matches{' '}
							<strong>High School Students</strong> with exceptional{' '}
							<strong>College Students</strong>, for <strong>1-on-1</strong> and{' '}
							<strong>Group Tutoring</strong> sessions.
						</p>
						<a type="button" className="btn-custom" href={`/apply`}>
							Apply
						</a>
					</div>
					<div className="col-lg-1"></div>

					<div className="col-sm-12 col-md-12 col-lg-6">
						<div className="main">
							<div className="orange-box"></div>
							<div className="orange-box bg-black"></div>
							<div className="main-video-div">
								<iframe
									src="https://www.loom.com/embed/3857b2e89693495a93b0bb91eec7120d?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
									title="home video"
									className="main-video"
								></iframe>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="how-it-works-container">
				<p className="how-title">2 Ways to Earn</p>

				<div className="home-blub container">
					<div className="row justify-center">
						<HowItWorks
							step="1"
							img="/images/highschool/highschool-way-1.svg"
							text="1-on-1 Tutoring"
							paragraph=""
						/>
						<HowItWorks
							step="2"
							img="/images/highschool/highschool-way-2.svg"
							text="Group Tutoring"
							paragraph=""
						/>
					</div>
				</div>
			</div>

			<section className="section-feature mb-10">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<iframe
								src="https://www.loom.com/embed/7bd921a5211f463d9169b016cc5c4bf5?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
								className="video-feature"
								title="home video"
							></iframe>
						</div>
						<div className="col-md-6 description-box">
							<h4>
								<u>1-on-1 Tutoring</u>
							</h4>
							<ul>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<strong>Earn</strong> $20 hr. for each session.
									</span>
								</li>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<strong>Work</strong> with talented and motivated High
										School students.
									</span>
								</li>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<strong>Build</strong> a book of business, as students use
										you on a regular basis.
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<section className="section-feature">
				<div className="container">
					<div className="row">
						<div className="col-md-6 xs-visible">
							<img
								src="/images/features/Intern_Landing_Page_Group_Tutoring.gif"
								alt="feature"
								className="img-feature"
							/>
						</div>
						<div className="col-md-6 description-box">
							<h4>
								<u>Group Tutoring</u>
							</h4>
							<ul>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<strong>Earn</strong> $40+ hr. for each session.
									</span>
								</li>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<strong>Choose</strong> your own hours and perform all
										tutoring in a virtual environment.
									</span>
								</li>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<strong>Work</strong> with study groups who have similar
										abilities and subject based needs.
									</span>
								</li>
							</ul>
						</div>
						<div className="col-md-6 xs-hidden">
							<img
								src="/images/features/Intern_Landing_Page_Group_Tutoring.gif"
								alt="feature"
								className="img-feature"
							/>
						</div>
					</div>
				</div>
			</section>

			<div className="cover-footer">
				<section className="cta-section">
					<div className="container text-center">
						<div className="row">
							<div className="col-sm-12 col-md-12 col-lg-12 cta-div">
								<div className="cta-img-div row">
									<img
										className="cta-img"
										src="/images/online-teacher.png"
										alt="cta"
									/>
									<div className="col-lg-6 col-md-6 text-align-left">
										<h1 className="meet-tutors-title">Ready to get started?</h1>
										<a type="button" className="btn-custom" href={`/apply`}>
											<span className="login-text">Apply</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</div>
	);
}

export default HomeTutors;
