import React from 'react';
import Header from '../../components/home/Header';
import Footer from '../../components/home/Footer';

function PrivacyPolicy() {
	return (
		<div>
			<Header />

			<div className="terms-text">
				<div className="terms-title">
					<h1>Privacy Policy</h1>
				</div>
				<div className="terms-content">
					<p>
						We respect your privacy and are committed to protecting it through
						our compliance with this privacy policy ("Policy"). This Policy
						describes the types of information we may collect from you or that
						you may provide ("Personal Information") on the peerlinc.com website
						("Website" or "Service") and any of its related products and
						services (collectively, "Services"), and our practices for
						collecting, using, maintaining, protecting, and disclosing that
						Personal Information. It also describes the choices available to you
						regarding our use of your Personal Information and how you can
						access and update it.
					</p>

					<p>
						This Policy is a legally binding agreement between you ("User",
						"you" or "your") and PeerLinc, LLC ("PeerLinc, LLC", "we", "us" or
						"our"). If you are entering into this agreement on behalf of a
						business or other legal entity, you represent that you have the
						authority to bind such entity to this agreement, in which case the
						terms "User", "you" or "your" shall refer to such entity. If you do
						not have such authority, or if you do not agree with the terms of
						this agreement, you must not accept this agreement and may not
						access and use the Website and Services. By accessing and using the
						Website and Services, you acknowledge that you have read,
						understood, and agree to be bound by the terms of this Policy. This
						Policy does not apply to the practices of companies that we do not
						own or control, or to individuals that we do not employ or manage.
					</p>

					<h4>Collection of Personal Information</h4>

					<p>
						You can access and use the Website and Services without telling us
						who you are or revealing any information by which someone could
						identify you as a specific, identifiable individual. If, however,
						you wish to use some of the features offered on the Website, you may
						be asked to provide certain Personal Information (for example, your
						name and e-mail address).
					</p>

					<p>
						We receive and store any information you knowingly provide to us
						when you create an account, make a purchase, &nbsp;or fill any forms
						on the Website. When required, this information may include the
						following:
					</p>

					<p>
						- Account details (such as user name, unique user ID, password, etc)
					</p>
					<p>
						- Contact information (such as email address, phone number, etc)
					</p>

					<p>
						You can choose not to provide us with your Personal Information, but
						then you may not be able to take advantage of some of the features
						on the Website. Users who are uncertain about what information is
						mandatory are welcome to contact us.
					</p>

					<h4>Privacy of Children</h4>

					<p>
						We do not knowingly collect any Personal Information from children
						under the age of 13. If you are under the age of 13, please do not
						submit any Personal Information through the Website and Services. If
						you have reason to believe that a child under the age of 13 has
						provided Personal Information to us through the Website and
						Services, please contact us to request that we delete that
						child&rsquo;s Personal Information from our Services.
					</p>

					<p>
						We encourage parents and legal guardians to monitor their
						children&rsquo;s Internet usage and to help enforce this Policy by
						instructing their children never to provide Personal Information
						through the Website and Services without their permission. We also
						ask that all parents and legal guardians overseeing the care of
						children take the necessary precautions to ensure that their
						children are instructed to never give out Personal Information when
						online without their permission.
					</p>

					<h4>Use and Processing of Collected Information</h4>

					<p>
						We act as a data controller and a data processor when handling
						Personal Information, unless we have entered into a data processing
						agreement with you in which case you would be the data controller
						and we would be the data processor.
					</p>

					<p>
						&nbsp;Our role may also differ depending on the specific situation
						involving Personal Information. We act in the capacity of a data
						controller when we ask you to submit your Personal Information that
						is necessary to ensure your access and use of the Website and
						Services. In such instances, we are a data controller because we
						determine the purposes and means of the processing of Personal
						Information.
					</p>

					<p>
						We act in the capacity of a data processor in situations when you
						submit Personal Information through the Website and Services. We do
						not own, control, or make decisions about the submitted Personal
						Information, and such Personal Information is processed only in
						accordance with your instructions. In such instances, the User
						providing Personal Information acts as a data controller.
					</p>

					<p>
						In order to make the Website and Services available to you, or to
						meet a legal obligation, we may need to collect and use certain
						Personal Information. If you do not provide the information that we
						request, we may not be able to provide you with the requested
						products or services. Any of the information we collect from you may
						be used for the following purposes:
					</p>

					<p>- Create and manage user accounts</p>
					<p>- Run and operate the Website and Services</p>

					<p>
						Processing your Personal Information depends on how you interact
						with the Website and Services, where you are located in the world
						and if one of the following applies: (i) you have given your consent
						for one or more specific purposes; this, however, does not apply,
						whenever the processing of Personal Information is subject to
						California Consumer Privacy Act; (ii) provision of information is
						necessary for the performance of an agreement with you and/or for
						any pre-contractual obligations thereof; (iii) processing is
						necessary for compliance with a legal obligation to which you are
						subject; (iv) processing is related to a task that is carried out in
						the public interest or in the exercise of official authority vested
						in us; (v) processing is necessary for the purposes of the
						legitimate interests pursued by us or by a third party.
					</p>

					<p>
						&nbsp;Note that under some legislations we may be allowed to process
						information until you object to such processing by opting out,
						without having to rely on consent or any other of the legal bases.
						In any case, we will be happy to clarify the specific legal basis
						that applies to the processing, and in particular whether the
						provision of Personal Information is a statutory or contractual
						requirement, or a requirement necessary to enter into a contract.
					</p>

					<h4>Payment Processing</h4>

					<p>
						In case of Services requiring payment, you may need to provide your
						credit card details or other payment account information, which will
						be used solely for processing payments. We use third-party payment
						processors ("Payment Processors") to assist us in processing your
						payment information securely.
					</p>

					<p>
						Payment Processors adhere to the latest security standards as
						managed by the PCI Security Standards Council, which is a joint
						effort of brands like Visa, MasterCard, American Express and
						Discover. &nbsp;Sensitive and private data exchange happens over a
						SSL secured communication channel and is encrypted and protected
						with digital signatures, and the Website and Services are also in
						compliance with strict vulnerability standards in order to create as
						secure of an environment as possible for Users. We will share
						payment data with the Payment Processors only to the extent
						necessary for the purposes of processing your payments, refunding
						such payments, and dealing with complaints and queries related to
						such payments and refunds.
					</p>

					<p>
						&nbsp;Please note that the Payment Processors may collect some
						Personal Information from you, which allows them to process your
						payments (e.g., your email address, address, credit card details,
						and bank account number) and handle all the steps in the payment
						process through their systems, including data collection and data
						processing. The Payment Processors&rsquo; use of your Personal
						Information is governed by their respective privacy policies which
						may or may not contain privacy protections as protective as this
						Policy. We suggest that you review their respective privacy
						policies.
					</p>

					<p>Managing Information</p>

					<p>
						You are able to delete certain Personal Information we have about
						you. The Personal Information you can delete may change as the
						Website and Services change. When you delete Personal Information,
						however, we may maintain a copy of the unrevised Personal
						Information in our records for the duration necessary to comply with
						our obligations to our affiliates and partners, and for the purposes
						described below. If you would like to delete your Personal
						Information or permanently delete your account, you can do so by
						contacting us.
					</p>

					<h4>Disclosure of Information</h4>

					<p>
						To maintain the highest level of privacy and to protect your
						Personal Information to the full extent, we do not share your
						Personal Information with any third parties.
					</p>

					<p>
						However, we may also disclose any Personal Information we collect,
						use or receive if required or permitted by law, such as to comply
						with a subpoena or similar legal process, and when we believe in
						good faith that disclosure is necessary to protect our rights,
						protect your safety or the safety of others, investigate fraud, or
						respond to a government request.
					</p>

					<p>
						In the event we go through a business transition, such as a merger
						or acquisition by another company, or sale of all or a portion of
						its assets, your user account, and your Personal Information will
						likely be among the assets transferred.
					</p>

					<h4>Retention of Information</h4>

					<p>
						We will retain and use your Personal Information for the period
						necessary to comply with our legal obligations, to enforce our
						agreements, resolve disputes, and unless a longer retention period
						is required or permitted by law.
					</p>

					<p>
						We may use any aggregated data derived from or incorporating your
						Personal Information after you update or delete it, but not in a
						manner that would identify you personally. Once the retention period
						expires, Personal Information shall be deleted. Therefore, the right
						to access, the right to erasure, the right to rectification, and the
						right to data portability cannot be enforced after the expiration of
						the retention period.
					</p>

					<h4>California Privacy Rights</h4>

					<p>
						Consumers residing in California are afforded certain additional
						rights with respect to their Personal Information under the
						California Consumer Privacy Act ("CCPA"). If you are a California
						resident, this section applies to you.
					</p>

					<p>
						In addition to the rights as explained in this Policy, California
						residents who provide Personal Information as defined in the statute
						to obtain Services for personal, family, or household use are
						entitled to request and obtain from us, once a calendar year,
						information about the categories and specific pieces of Personal
						Information we have collected and disclosed.
					</p>

					<p>
						Furthermore, California residents have the right to request deletion
						of their Personal Information or opt-out of the sale of their
						Personal Information which may include selling, disclosing, or
						transferring Personal Information to another business or a third
						party for monetary or other valuable consideration. To do so, simply
						contact us. We will not discriminate against you if you exercise
						your rights under the CCPA.
					</p>

					<h4>How to Exercise Your Rights</h4>

					<p>
						Any requests to exercise your rights can be directed to us through
						the contact details provided in this document. Please note that we
						may ask you to verify your identity before responding to such
						requests. Your request must provide sufficient information that
						allows us to verify that you are the person you are claiming to be
						or that you are the authorized representative of such person. If we
						receive your request from an authorized representative, we may
						request evidence that you have provided such an authorized
						representative with power of attorney or that the authorized
						representative otherwise has valid written authority to submit
						requests on your behalf.
					</p>

					<p>
						You must include sufficient details to allow us to properly
						understand the request and respond to it. We cannot respond to your
						request or provide you with Personal Information unless we first
						verify your identity or authority to make such a request and confirm
						that the Personal Information relates to you.
					</p>

					<h4>Cookies</h4>

					<p>
						Our Website and Services use "cookies" to help personalize your
						online experience. A cookie is a text file that is placed on your
						hard disk by a web page server. Cookies cannot be used to run
						programs or deliver viruses to your computer. Cookies are uniquely
						assigned to you, and can only be read by a web server in the domain
						that issued the cookie to you. If you choose to decline cookies, you
						may not be able to fully experience the features of the Website and
						Services.
					</p>

					<p>
						We may use cookies to collect, store, and track information for
						security and personalization, to operate the Website and Services,
						and for statistical purposes. Please note that you have the ability
						to accept or decline cookies. Most web browsers automatically accept
						cookies by default, but you can modify your browser settings to
						decline cookies if you prefer.
					</p>

					<h4>Do Not Track Signals</h4>

					<p>
						Some browsers incorporate a Do Not Track feature that signals to
						websites you visit that you do not want to have your online activity
						tracked. Tracking is not the same as using or collecting information
						in connection with a website. For these purposes, tracking refers to
						collecting personally identifiable information from consumers who
						use or visit a website or online service as they move across
						different websites over time. The Website and Services do not track
						its visitors over time and across third-party websites. However,
						some third-party websites may keep track of your browsing activities
						when they serve you content, which enables them to tailor what they
						present to you. For a description of Do Not Track protocols for
						browsers and mobile devices or to learn more about the choices
						available to you, visit internetcookies.com
					</p>

					<h4>Push Notifications</h4>

					<p>
						We offer push notifications to which you may voluntarily subscribe
						at any time. To make sure push notifications reach the correct
						devices, we rely on a device token unique to your device which is
						issued by the operating system of your device. While it is possible
						to access a list of device tokens, they will not reveal your
						identity, your unique device ID, or your contact information to us.
						We will maintain the information sent via e-mail in accordance with
						applicable laws and regulations. If, at any time, you wish to stop
						receiving push notifications, simply adjust your device settings
						accordingly.
					</p>

					<h4>Links to Other Resources</h4>

					<p>
						The Website and Services contain links to other resources that are
						not owned or controlled by us. Please be aware that we are not
						responsible for the privacy practices of such other resources or
						third parties. We encourage you to be aware when you leave the
						Website and Services and to read the privacy statements of each and
						every resource that may collect Personal Information.
					</p>

					<h4>Information Security</h4>

					<p>
						We secure information you provide on computer servers in a
						controlled, secure environment, protected from unauthorized access,
						use, or disclosure. We maintain reasonable administrative,
						technical, and physical safeguards in an effort to protect against
						unauthorized access, use, modification, and disclosure of Personal
						Information in our control and custody. However, no data
						transmission over the Internet or wireless network can be
						guaranteed.
					</p>

					<p>
						Therefore, while we strive to protect your Personal Information, you
						acknowledge that (i) there are security and privacy limitations of
						the Internet which are beyond our control; (ii) the security,
						integrity, and privacy of any and all information and data exchanged
						between you and the Website and Services cannot be guaranteed; and
						(iii) any such information and data may be viewed or tampered with
						in transit by a third party, despite best efforts.
					</p>

					<p>
						As the security of Personal Information depends in part on the
						security of the device you use to communicate with us and the
						security you use to protect your credentials, please take
						appropriate measures to protect this information.
					</p>

					<h4>Data Breach</h4>

					<p>
						In the event we become aware that the security of the Website and
						Services has been compromised or Users&rsquo; Personal Information
						has been disclosed to unrelated third parties as a result of
						external activity, including, but not limited to, security attacks
						or fraud, we reserve the right to take reasonably appropriate
						measures, including, but not limited to, investigation and
						reporting, as well as notification to and cooperation with law
						enforcement authorities. In the event of a data breach, we will make
						reasonable efforts to notify affected individuals if we believe that
						there is a reasonable risk of harm to the User as a result of the
						breach or if notice is otherwise required by law. When we do, we
						will send you an email.
					</p>

					<h4>Changes and Amendments</h4>

					<p>
						We reserve the right to modify this Policy or its terms related to
						the Website and Services at any time at our discretion. When we do,
						we will revise the updated date at the bottom of this page. We may
						also provide notice to you in other ways at our discretion, such as
						through the contact information you have provided.
					</p>

					<p>
						An updated version of this Policy will be effective immediately upon
						the posting of the revised Policy unless otherwise specified. Your
						continued use of the Website and Services after the effective date
						of the revised Policy (or such other act specified at that time)
						will constitute your consent to those changes. However, we will not,
						without your consent, use your Personal Information in a manner
						materially different than what was stated at the time your Personal
						Information was collected.
					</p>

					<h4>Acceptance of This Policy</h4>

					<p>
						You acknowledge that you have read this Policy and agree to all its
						terms and conditions. By accessing and using the Website and
						Services and submitting your information you agree to be bound by
						this Policy. If you do not agree to abide by the terms of this
						Policy, you are not authorized to access or use the Website and
						Services.
					</p>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default PrivacyPolicy;
