import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingButton from '../loading/LoadingButton';
import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';
import './index.scss';

function TutorAssign(props) {
	const { id: tutorId } = props;
	const [highschools, setHighschools] = useState([]);
	const [selectedHighschools, setSelectedHighschools] = useState([]);
	const [loading, setLoading] = useState(false);
	const [submissionLoading, setSubmissionLoading] = useState(false);

	useEffect(() => {
		getHighSchools();
		// eslint-disable-next-line
	}, [tutorId]);

	const getHighSchools = async () => {
		try {
			setLoading(true);
			const promise1 = axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_highschool`,
				{ action: 'list_highschool' }
			);
			const promise2 = axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/update/teacher`,
				{ action: 'list_teacher_highschool', tutorId }
			);

			const [resultHighschools, resultTeacherHighschools] = await Promise.all([
				promise1,
				promise2,
			]);
			const { data: rowHighschool } = resultHighschools?.data || {};
			// sort by name
			const sortedList = rowHighschool?.sort((a, b) => {
				if (a.name < b.name) return -1;
				if (a.name > b.name) return 1;
				return 0;
			});
			const filteredHighschools = sortedList?.map((highschool) => {
				return {
					label: highschool.name,
					value: highschool.id,
				};
			});
			setHighschools(filteredHighschools);

			const { data: teacherHighschools } = resultTeacherHighschools?.data || {};
			const currentHighschools = filteredHighschools?.filter((highschool) => {
				return teacherHighschools?.includes(highschool?.value);
			});
			setSelectedHighschools(currentHighschools);
		} catch (e) {
			console.log('e', e);
		} finally {
			setLoading(false);
		}
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		try {
			setSubmissionLoading(true);
			const highschoolIds = selectedHighschools.map(
				(highschool) => highschool.value
			);
			const results = await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/update/teacher`,
				{ action: 'assign_highschool', tutorId, highschoolIds }
			);
			const { status, message } = results?.data || {};
			if (status) {
				toast.success(message);
			} else {
				toast.error(message);
			}
		} catch (e) {
		} finally {
			setSubmissionLoading(false);
		}
	};

	return (
		<div className="modal-tutor-assign">
			<div className="row">
				<div className="col-md-12">
					<form className="form-horizontal" onSubmit={handleFormSubmit}>
						<div className="form-group">
							<label className="control-label col-sm-4">
								Select Highschool:
							</label>
							<div className="col-sm-8">
								{loading ? (
									<LoadingButton />
								) : (
									<MultiSelect
										options={highschools}
										value={selectedHighschools}
										onChange={setSelectedHighschools}
										labelledBy="Select Highschool"
									/>
								)}
							</div>
						</div>

						<div className="form-group">
							<label className="control-label col-sm-4"></label>
							<div className="col-sm-8">
								<div className="selected-highschools">
									{selectedHighschools?.length > 0 && (
										<ul>
											{selectedHighschools.map((highschool, index) => (
												<li key={index}>{highschool.label}</li>
											))}
										</ul>
									)}
								</div>
							</div>
						</div>

						<div className="form-group">
							<label className="control-label col-sm-4"></label>
							<div className="col-sm-8">
								{!loading && (
									<button
										type="submit"
										className="btn btn-primary"
										disabled={submissionLoading}
									>
										{submissionLoading ? <LoadingButton /> : 'Assign'}
									</button>
								)}
							</div>
						</div>
					</form>
				</div>
			</div>

			<p className="center-it explain-text">{tutorId}</p>
		</div>
	);
}

export default TutorAssign;
