import React, { useState, useEffect, useContext } from 'react';
import Mark from './Mark';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import {
	utcStringToLocalDate,
	formattedSessionDay,
	formattedSessionTime,
} from '../../tools/timeConverter';
import Cancel from '../book/Cancel';
import { Failure } from '../alerts/Alerts';
import LoadingButton from '../loading/LoadingButton';
// import check from '../../Icons/check.svg';
// import deny from '../../Icons/deny.svg';

function Sessions(props) {
	const [alert, setAlert] = useState();
	const auth = useContext(AuthContext);
	const history = useHistory();
	const [unsortedSessions, setUnsortedSessions] = useState([]);
	const [sessions, setSessions] = useState([]);
	const [loadedSessions, setLoadedSessions] = useState(false);
	const [isDataLoading, setDataLoading] = useState(true);

	useEffect(() => {
		getSessionsData();
		// eslint-disable-next-line
	}, [props.sessionHashes]);

	// Sort sessions by date
	useEffect(() => {
		if (props.isPast) {
			let sortedSessions = [...unsortedSessions];
			sortedSessions = sortedSessions.filter(
				(session) => session.cancelled !== true
			);
			sortedSessions.sort(
				(aSession, bSession) =>
					utcStringToLocalDate(bSession.when) -
					utcStringToLocalDate(aSession.when)
			);
			setSessions(sortedSessions);
		} else {
			let sortedSessions = [...unsortedSessions];
			sortedSessions = sortedSessions.filter(
				(session) => session.cancelled !== true
			);
			sortedSessions.sort(
				(aSession, bSession) =>
					utcStringToLocalDate(aSession.when) -
					utcStringToLocalDate(bSession.when)
			);
			setSessions(sortedSessions);
		}
		if (loadedSessions) {
			if (sessions.length === 0) {
				setLoadState(null);
			}
			setDataLoading(false);
		}
		// eslint-disable-next-line
	}, [unsortedSessions, props.title]);

	const sessionPromise = (sessionHash) => {
		return new Promise(async (resolve, reject) => {
			let sessionData = {};

			try {
				const session = await axios.get(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/sessions`,
					{
						params: {
							sessionParam: sessionHash,
						},
					}
				);
				sessionData = session.data;
			} catch (err) {
				console.log('one session info was deleted');
				resolve(null);
			}
			if (sessionData.cancelled !== true) {
				try {
					// Get session's tutor name and image
					const tutor = await axios.get(
						`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/tutors`,
						{
							params: {
								tutorParam: sessionData.tutor,
							},
						}
					);
					const tutorData = tutor.data;

					const student = await axios.get(
						`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/students`,
						{
							params: {
								studentParam: sessionData.student,
							},
						}
					);
					const studentData = student.data;
					// Store necessary information
					const sessionItems = {
						id: sessionHash,
						when: sessionData.when,
						student: sessionData.student,
						tutor: sessionData.tutor,
						link:
							auth.role === 'student'
								? sessionData.studentUrl
								: sessionData.tutorUrl,
						completed: sessionData.completed ? true : false,
						cancelled: sessionData.cancelled ? true : false,
						studentMiss: sessionData.studentMiss ? true : false,
						tutorMiss: sessionData.tutorMiss ? true : false,
						tutorName: tutorData.name,
						tutorImage: tutorData.image,
						studentName: studentData.name,
						studentImage: studentData.image,
						tutorApproved: sessionData.approved,
					};

					resolve(sessionItems);
				} catch (err) {
					console.log('one session info was deleted');
					resolve(null);
				}
			} else {
				resolve(null);
			}
		});
	};

	const getSessionsData = async () => {
		let sessionPromises = [];

		// Get all session data
		for (let s = 0; s < props.sessionHashes.length; s++) {
			// Check to make sure hash exists (not an empty string)
			if (props.sessionHashes[s].length) {
				sessionPromises.push(sessionPromise(props.sessionHashes[s]));
			}
		}

		Promise.all(sessionPromises).then((data) => {
			data = data.filter((items) => items !== null);
			for (const [i, session] of data.entries()) {
				if (
					utcStringToLocalDate(session.when) < new Date() &&
					session.tutorApproved === false &&
					session.cancelled === false
				) {
					console.log('denied');
					cancelSession(session.id, session.student, false);
					data.splice(i, 1);
				}
			}
			setUnsortedSessions(data);
			setLoadedSessions(true);
		});
	};

	const markSession = async (sessionId, status, report) => {
		var sessionItem = sessions.filter((session) => session.id === sessionId)[0];
		try {
			if (status === 'completed') {
				await axios.post(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/bookings`,
					{
						sessionParam: sessionId,
						tutorParam: sessionItem.tutor,
						tagType: 'completed',
						reportParam: report,
					}
				);
			} else if (status === 'student-missed') {
				await axios.post(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/bookings`,
					{
						sessionParam: sessionId,
						tutorParam: sessionItem.tutor,
						tagType: 'student_miss',
					}
				);
			} else if (status === 'tutor-missed') {
				await axios.post(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/bookings`,
					{
						sessionParam: sessionId,
						tutorParam: sessionItem.tutor,
						tagType: 'tutor_miss',
					}
				);
			}
			history.go(0);
		} catch (err) {
			setAlert(
				<Failure title="Oops!" message="Something went wrong. Contact us." />
			);
		}
	};

	const cancelSession = async (sessionId, studentId, reload = true) => {
		try {
			await axios
				.delete(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/bookings`,
					{
						data: {
							sessionParam: sessionId,
							studentParam: studentId,
						},
					}
				)
				.then((data) => {
					//console.log(data.data);
				});

			let dashboard = '/student-dashboard';
			if (auth.role === 'tutor') dashboard = '/tutor-dashboard';
			if (reload) {
				history.go(0);
				history.push(dashboard, {
					success: {
						title: 'Successful Cancel!',
						message: 'Successfully cancelled session',
					},
				});
			}
		} catch (err) {
			setAlert(
				<Failure title="oops!" message="Something went wrong. Contact us." />
			);
		}
	};

	const resolveRequests = async (sessionId, tutorAccept, reload = true) => {
		try {
			await axios.put(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/bookings`,
				{
					sessionParam: sessionId,
					tutorAccept: tutorAccept,
				}
			);

			let dashboard = '/student-dashboard';
			if (auth.role === 'tutor') dashboard = '/tutor-dashboard';

			if (reload) {
				history.go(0);
				history.push(dashboard, {
					success: {
						title: tutorAccept
							? 'Successfully Accepted!'
							: 'Successfully Denied',
						message: tutorAccept
							? 'Successfully accepted session'
							: 'Successfully denied session',
					},
				});
			}
		} catch (err) {
			setAlert(
				<Failure title="oops!" message="Something went wrong. Contact us." />
			);
		}
	};

	function NoSession() {
		return (
			<div className="no-tutors align-middle">
				<img
					className="no-tutors-image-session "
					src="../images/no-past-session.svg"
					alt="sad face"
				/>
				<p className="no-tutors-text">No Sessions found!</p>
			</div>
		);
	}

	// loading effect
	const [loadState, setLoadState] = useState(
		<div className="loading-sessions">
			<LoadingButton />
		</div>
	);
	useEffect(() => {
		if (sessions.length > 0) {
			setLoadState(
				<div>
					{sessions.map((session, index) => {
						const date = utcStringToLocalDate(session.when);
						//console.log(date);
						const day = formattedSessionDay(date);
						const time = formattedSessionTime(date);

						var userImage =
							auth.role === 'student'
								? session.tutorImage
								: session.studentImage;
						if (userImage?.includes('flaticon')) {
							userImage = `https://i.ibb.co/xJyCzrB/user.png`;
						}

						return (
							<div className="a-session" key={index}>
								<div className="row">
									<div className="col-2">
										<img className="tutor-pic " src={userImage} alt="tutor" />
									</div>

									{props.isPast ? (
										<div className="col-lg-7 col-md-12 col-sm-12 session-details">
											<p className="upcoming-session-when session-tutor-name">
												{auth.role === 'student'
													? session.tutorName
													: session.studentName}{' '}
											</p>
											<p className="session-date">
												{day}, {time}
											</p>
										</div>
									) : (
										<>
											<div className="col-lg-5 col-md-12 col-sm-12 upcoming-session-when-container">
												<div className="upcoming-session-when">
													<div className="session-details session-details-upcoming">
														<p className="upcoming-session-when session-tutor-name">
															{auth.role === 'student'
																? session.tutorName
																: session.studentName}{' '}
														</p>
														<p className="session-date">
															{day}, {time}
														</p>
													</div>
												</div>
											</div>
											{session.tutorApproved !== false ? (
												<div className="col-md-2 col-lg-2 x-button-dashboard">
													<Cancel
														sessionData={session}
														cancelSession={cancelSession}
														role={auth.role}
													/>
												</div>
											) : null}
										</>
									)}

									{auth.role === 'tutor' && props.isPast ? (
										<div>
											<Mark
												sessionId={session.id}
												isMarked={
													session.completed ||
													session.studentMiss ||
													session.tutorMiss
												}
												markSession={markSession}
											/>
										</div>
									) : (
										<div className="col-sm-12 col-lg-3 dashboard-button-mobile">
											{' '}
											{session.tutorApproved === false &&
											auth.role === 'tutor' ? (
												<div className="row">
													<div className="col-6">
														<button
															className="request-deny"
															onClick={() =>
																cancelSession(session.id, session.student)
															}
														>
															Deny
														</button>
													</div>
													<div className="col-6">
														<button
															className="request-accept"
															onClick={() => resolveRequests(session.id, true)}
														>
															Accept
														</button>
													</div>
												</div>
											) : (
												<a
													href={
														session.tutorApproved === false
															? null
															: session.link
													}
													target="_blank"
													rel="noopener noreferrer"
												>
													{session.tutorApproved === false &&
													auth.role === 'student' &&
													!props.isPast ? (
														<button className="button-disabled join-button">
															Pending
														</button>
													) : (
														<button className="join-button">
															{props.action}
														</button>
													)}
												</a>
											)}
										</div>
									)}
								</div>
							</div>
						);
					})}
				</div>
			);
		}
		// eslint-disable-next-line
	}, [sessions]);

	if (isDataLoading) {
		return (
			<div className="col-sm-12 col-md-12 col-lg-12">
				<div className={props.class}>
					<h2 className="dash-header">{props.title}</h2>
					<div className="loading-sessions">
						<LoadingButton />
					</div>
				</div>
			</div>
		);
	} else if (sessions.length > 0) {
		return (
			<div className="col-sm-12 col-md-12 col-lg-12">
				<div className={props.class}>
					{alert}
					<h2 className="dash-header">{props.title}</h2>
					<div className="join"></div>
					<div>{loadState}</div>
				</div>
			</div>
		);
	} else if (!isDataLoading && sessions.length === 0) {
		return (
			<div className="col-sm-12 col-md-12 col-lg-12">
				<div className={props.class}>
					<h2 className="dash-header">{props.title}</h2>
					<NoSession />
				</div>
			</div>
		);
	} else return null;
}

export default Sessions;
