import React from 'react';
import { ValidationError } from '@statickit/react';
import Header from '../home/Header';
import Footer from '../home/Footer';
import './contact.css';

function Aid() {

	return (
		<div>
			<Header />

			<div className="center-it">
				<div className="contact-bug">
					<h1 className="contact-title">Free Tutoring Application</h1>

					<h4 className="contact-paragraph">
						As long as you qualify, there are no strings attached.
					</h4>

					<form
						action="https://formspree.io/p/1579158750088920430/f/contactForm"
						method="POST"
					>
						<div>
							{/* TYPE */}
							<input
								type="text"
								name="type"
								class="contact-type"
								id="type"
								value="aid"
								required
							/>

							{/* NAME */}

							<div>
								<input
									type="name"
									name="name"
									class="contact-input"
									id="name"
									placeholder="Your Name*"
									required
								/>
							</div>

							<ValidationError prefix="Name" field="name" />

							{/* EMAIL */}
							<div>
								<input
									id="email"
									type="email"
									name="email"
									className="contact-input"
									placeholder="Your Email*"
									required
								/>
							</div>

							<ValidationError prefix="Email" field="email" />

							{/* PHONE */}
							<div>
								<input
									id="number"
									type="phone"
									name="number"
									className="contact-input"
									placeholder="Your Phone*"
									required
								/>
							</div>

							<ValidationError prefix="Number" field="number" />

							{/* INCOME */}
							<div>
								<input
									id="income"
									type="text"
									name="income"
									className="contact-input"
									placeholder="2019 Net Income*"
									required
								/>
							</div>

							{/* TEXT */}

							<textarea
								type="text"
								name="text"
								className="contact-textarea"
								placeholder="Tell us in 4-5 sentences why you think you qualifiy and how we can help*"
								required
							/>

							<ValidationError prefix="Text" field="text" />

							<ValidationError prefix="Number" field="number" />
						</div>

						{/* BUTTON */}
						<button type="submit">Apply</button>
					</form>
				</div>
			</div>

			<br />

			<Footer />
		</div>
	);
}

export default Aid;
