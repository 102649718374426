import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../../components/admin/home/Header';
import LoadingButton from '../../../components/loading/LoadingButton';
import { useHistory } from 'react-router-dom';

import './index.scss';

function AdminAddHighschoolTeacher() {
	const history = useHistory();
	const [highschools, setHighschools] = useState([]);
	const [alert, setAlert] = useState(null);
	const [loading, setLoading] = useState(false);
	const [state, setState] = useState({
		highschool_id: '',
		teacher_name: '',
		teacher_type: '',
	});

	useEffect(() => {
		fetchHighSchools();
		// eslint-disable-next-line
	}, []);

	const fetchHighSchools = () => {
		axios
			.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_highschool`,
				{
					action: 'list_highschool',
				}
			)

			.then((res) => {
				var list = res?.data?.data;
				setHighschools(list);
			});
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setAlert(null);

		try {
			const postData = {
				action: 'add_teacher',
				highschool_id: state?.highschool_id || null,
				teacher_name: state?.teacher_name || '',
				teacher_type: state?.teacher_type || '',
			};

			const saveResponse = await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_highschool_teachers`,
				postData
			);
			const { data: saveData } = saveResponse;
			if (saveData.status) {
				setAlert({
					type: 'success',
					message: 'Highschool Teacher added successfully',
				});
				setTimeout(() => {
					history.push('/admin-highschools');
				}, 3000);
			} else {
				setAlert({
					type: 'danger',
					message: saveData.message,
				});
			}

			setLoading(false);
		} catch (err) {
			console.log(err);
			setAlert({
				type: 'danger',
				message: 'Something went wrong! Please try again later.',
			});
		}
	};

	return (
		<div className="page-admin-add-highschool">
			<Header />
			<div className="container-fluid">
				<div className="row mt-5">
					<div className="col-md-4"></div>
					<div className="col-md-4">
						<div className="card">
							<h4>Add high school teacher</h4>
							<form onSubmit={handleSubmit}>
								<div className="form-group">
									<label>High School Name</label>
									<select
										className="form-control"
										name="highschool_id"
										value={state?.highschool_id || ''}
										onChange={handleInputChange}
										required
									>
										<option value="" disabled selected>
											-- Select Highschool --
										</option>
										{highschools.map((highschool) => {
											return (
												<option value={highschool.id}>{highschool.name}</option>
											);
										})}
									</select>
								</div>
								<div className="form-group">
									<label>Teacher Name</label>
									<input
										type="text"
										className="form-control"
										name="teacher_name"
										placeholder="Enter Teacher name"
										value={state?.teacher_name || ''}
										onChange={handleInputChange}
										required
									/>
								</div>
								<div className="form-group">
									<label>Teacher Type</label>
									<select
										className="form-control"
										name="teacher_type"
										value={state?.teacher_type || ''}
										onChange={handleInputChange}
										required
									>
										<option value="" disabled selected>
											-- Select Type --
										</option>
										<option value="math">Math</option>
										<option value="science">Science</option>
										<option value="math,science">Both</option>
									</select>
								</div>
								<div className="form-group text-right">
									<button
										type="submit"
										className="btn-custom-primary"
										disabled={loading}
									>
										{loading ? <LoadingButton /> : 'Submit'}
									</button>
								</div>

								{alert && (
									<div className="form-group">
										<div className={`alert alert-${alert?.type}`} role="alert">
											{alert?.message}
										</div>
									</div>
								)}
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdminAddHighschoolTeacher;
