import React, { useState, useEffect } from 'react';
import {
	utcStringToLocalDate,
	ultimateConverter,
	formattedSessionTime,
} from '../../tools/timeConverter';
import { Modal } from 'react-bootstrap';
import './book.css';

function Cancel(props) {
	const [day, setDay] = useState('');
	const [time, setTime] = useState('');
	const [canCancel, setCanCancel] = useState(false);
	const [cancelLoading, setCancelLoading] = useState(false);
	// eslint-disable-next-line
	const [show, setShow] = useState(false);

	useEffect(() => {
		if (props.sessionData) {
			const sessionDate = utcStringToLocalDate(props.sessionData.when);
			const timeData = ultimateConverter(props.sessionData.when).split(',');

			const day = timeData[0];
			const time = formattedSessionTime(sessionDate);

			setDay(day);
			setTime(time);

			// Check if session is in less than 12 hours
			if (new Date(new Date().getTime() + 12 * 3600000) < sessionDate)
				setCanCancel(true);
			else setCanCancel(false);
		}
	}, [props.sessionData]);

	const handleConfirm = () => {
		if (canCancel) {
			setCancelLoading(true);
			props.cancelSession(
				props.sessionData.id,
				props.sessionData.student,
				props.sessionData.tutor
			);
		}
	};

	const handleShow = () => {
		setShow(true);
	};

	const handleClose = () => {
		setShow(false);
	};

	return (
		<div>
			<button
				type="button"
				className="cancel-button"
				onClick={() => handleShow()}
			>
				<i className="fal fa-times"></i>
			</button>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					Who:{' '}
					{props.sessionData &&
						(props.role === 'tutor'
							? props.sessionData.studentName
							: props.sessionData.tutorName)}
				</Modal.Header>
				<Modal.Body className="modal-body">
					<h4 className="modal-header-custom">
						Are you sure you want to cancel this session?
					</h4>

					<div className="cancel-content">
						<div className="book-content-summary-row row">
							<div className="book-content-summary-header col-4">Date</div>
							<div className="book-content-summary-header col-4">Time</div>
							{/* <div className="book-content-summary-header col-4">Who</div> */}
						</div>
						<div className=" book-content-summary-row row">
							<div className="book-content-summary-value col-4">{day}</div>
							<div className="book-content-summary-value col-4">{time}</div>
							{/* <div className="book-content-summary-value col-4">
								{props.sessionData &&
									(props.role === 'tutor'
										? props.sessionData.studentName
										: props.sessionData.tutorName)}
							</div> */}
						</div>
					</div>
					<div className="center-it">
						<button
							onClick={handleConfirm}
							className={`cancel-confirm ${
								!canCancel || cancelLoading ? 'button-disabled' : ''
							}`}
						>
							Cancel
						</button>

						{!canCancel ? (
							<p className="cant-cancel explain-text">
								Can't cancel within 12 hours of the session. Do you need to
								cancel? <a href="/contact">Request a late cancel</a>
							</p>
						) : null}
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default Cancel;
