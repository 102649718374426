import React from 'react';
import Calendly from './Calendly';
import Header from '../../funnels/consult/Header';
import Footer from '../../home/Footer';

function BookConsult() {
	return (
		<div>
			<Header />

			<Calendly />
			<Footer />
		</div>
	);
}

export default BookConsult;
