import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

const NavLogo = () => {
	const auth = useContext(AuthContext);
	const { role } = auth || {};

	const dashboardLink =
		role === 'tutor'
			? 'tutor-dashboard'
			: role === 'student'
			? 'student-dashboard'
			: role === 'admin'
			? 'admin'
			: '';

	return (
		<a href={`/${dashboardLink}`}>
			<img className="nav-img" src="/images/Peerlinc.png" alt="AA" />
		</a>
	);
};

export default NavLogo;
