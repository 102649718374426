import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../components/admin/home/Header';
import Session from '../../components/admin/Session';
import LoadingPage from '../../components/loading/LoadingPage';
import { Failure } from '../../components/alerts/Alerts';

function AdminSessions() {
	const [alert, setAlert] = useState();

	// Pages
	// const [page, setPage] = useState('')

	// Which Picked
	const [whichSessions, setWhichSessions] = useState('future');
	const [cancelledParam, setCancelledParam] = useState(false);
	const [upcomingPicked, setUpcomingPicked] = useState('admin-picked');
	const [pastPicked, setPastPicked] = useState('admin-pick');
	const [cancelledPicked, setCancelledPicked] = useState('admin-pick');

	const [sessions, setSessions] = useState([]);

	// Call API to GET Upcoming or Past Session info
	useEffect(() => {
		if (whichSessions === 'future') {
			axios
				.get(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/sessions/`,
					{
						params: {
							timeframeParam: 'future',
						},
					}
				)
				.then((res) => {
					setSessions(res.data.Items);
				})
				.catch((err) =>
					setAlert(<Failure title="Oops!" message={err.message} />)
				);
		}

		// Otherwise it's Past sessions
		else {
			axios
				.get(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/sessions/`,
					{
						params: {
							timeframeParam: 'past',
						},
					}
				)
				.then((res) => {
					setSessions(res.data.Items.reverse());
				})
				.catch(() =>
					setAlert(
						<Failure
							title="Oops!"
							message="Something happened. Try again or contact us."
						/>
					)
				);
		}
	}, [whichSessions]);

	// Call API to GET Cancelled Sessions

	useEffect(() => {
		if (cancelledParam) {
			axios
				.get(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/sessions/`,
					{
						params: {
							cancelledParam: true,
						},
					}
				)
				.then((res) => {
					setSessions(res.data.Items.reverse());
				})
				.catch(() =>
					setAlert(
						<Failure
							title="Oops!"
							message="Something happened. Try again or contact us."
						/>
					)
				);
		}
	}, [cancelledParam]);

	// Show sessions on the page
	const [showState, setShowState] = useState(<LoadingPage />);

	useEffect(() => {
		if (sessions.length > 0) {
			setShowState(sessions.map((session) => <Session session={session} />));
		}
	}, [sessions]);

	// Which Sessions Button Pick
	function upcoming() {
		setShowState(<LoadingPage />);
		setCancelledParam(false);
		setWhichSessions('future');
		setUpcomingPicked('admin-picked');
		setPastPicked('admin-pick');
		setCancelledPicked('admin-pick');
	}
	function past() {
		setShowState(<LoadingPage />);
		setCancelledParam(false);
		setWhichSessions('past');
		setUpcomingPicked('admin-pick');
		setPastPicked('admin-picked');
		setCancelledPicked('admin-pick');
	}
	function cancelled() {
		setShowState(<LoadingPage />);
		setCancelledParam(true);
		setUpcomingPicked('admin-pick');
		setPastPicked('admin-pick');
		setCancelledPicked('admin-picked');
	}

	return (
		<div>
			<Header />

			<div className="admin-container">
				{alert}
				<div className="sessions-when">
					<div className="row">
						<div className="col-4">
							<button onClick={upcoming} className={upcomingPicked}>
								Upcoming
							</button>
						</div>
						<div className="col-3">
							<button onClick={past} className={pastPicked}>
								Past
							</button>
						</div>
						<div className="col-4">
							<button onClick={cancelled} className={cancelledPicked}>
								Cancelled
							</button>
						</div>
					</div>
				</div>

				<div className="all-container">
					<div className="all-labels">
						<div className="row">
							<div className="col-3">
								<i className="fal fa-user"></i> Date
							</div>
							<div className="col-3">
								<i className="fal fa-user"></i> Student
							</div>
							<div className="col-3">
								<i className="far fa-clock"></i> Tutor
							</div>
							<div className="col-3">
								<i className="far fa-comments"></i> Status
							</div>
						</div>
					</div>

					{showState}
				</div>
			</div>
		</div>
	);
}

export default AdminSessions;
