import React from 'react';
import ATutor from './ATutor';
import PerfectTutor from './Modals/PerfectTutor';
import '../master.css';
import '../home/home.css';

function MeetTutors(props) {
	var link = 'http://ansarimath.com/search';
	return (
		<div className="third-container">
			<h1 class="meet-tutors-title">
				{props.title}
				{/* <i class="green fas fa-badge-check"></i> */}
			</h1>
			<br />
			<div className="home-tutors">
				<div className="container-fluid">
					<div
						id="carouselControls"
						data-interval="3000"
						className="carousel slide"
						data-ride="carousel"
					>
						<div className="carousel-inner">
							<div className="carousel-item active">
								<div className="tutor-row">
									<div className="row">
										<ATutor
											image="/images/jason.png"
											universitytext="UCLA"
											university="/images/ucla.png"
											name="Jason Inaba"
											hours="243"
											profile={link}
											subject1={'Algebra'}
											subject2={'Biology'}
											subject3={'Logic'}
										/>

										<ATutor
											image="/images/dina.png"
											universitytext="UCLA"
											university="/images/ucla.png"
											name="Dina M."
											hours="69"
											profile={link}
											subject1={'Math'}
											subject2={'Chemistry'}
											subject3={'Biology'}
										/>

										<ATutor
											image="/images/jessica.png"
											universitytext="UC Berkeley"
											university="/images/ucb.png"
											name="Jessica M."
											hours="903"
											profile={link}
											subject1={'Logic'}
											subject2={'Physics'}
											subject3={'Math'}
										/>
									</div>
								</div>
							</div>
							<div className="carousel-item">
								<div className="tutor-row">
									<div className="row">
										<ATutor
											image="/images/ryan.png"
											universitytext="UC Davis"
											university="/images/ucdavis.png"
											name="Ryan Davis"
											hours="37"
											profile={link}
											subject1={'Math'}
											subject2={'Physics'}
											subject3={'Chemistry'}
										/>

										<ATutor
											image="https://ansari-user-photos.s3-us-west-1.amazonaws.com/2195ca5549c5d1835e.png"
											universitytext="UCLA"
											university="/images/ucla.png"
											name="Angel A."
											hours="69"
											profile={link}
											subject1={'Math'}
											subject2={'SAT/ACT'}
											subject3={'Logic'}
										/>

										<ATutor
											image="/images/aj.png"
											universitytext="UC Berkeley"
											university="/images/ucb.png"
											name="AJ Grover"
											hours="239"
											profile={link}
											subject1={'Physics'}
											subject2={'SAT/ACT'}
											subject3={'Math'}
										/>
									</div>
								</div>
							</div>
						</div>

						<a
							className="carousel-control-prev"
							href="#carouselControls"
							role="button"
							data-slide="prev"
						>
							<span
								className="carousel-control-prev-icon"
								aria-hidden="true"
							></span>
							<span className="sr-only">Previous</span>
						</a>
						<a
							className="carousel-control-next"
							href="#carouselControls"
							role="button"
							data-slide="next"
						>
							<span
								className="carousel-control-next-icon"
								aria-hidden="true"
							></span>
							<span className="sr-only">Next</span>
						</a>
					</div>
				</div>
			</div>
			<PerfectTutor />
		</div>
	);
}

export default MeetTutors;
