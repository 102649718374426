import React, { useState, useEffect, useContext } from 'react';
import Nav from '../../components/dashboard/Nav';
import { Success, Failure } from '../../components/alerts/Alerts';
import { AuthContext } from '../../contexts/AuthContext';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Sessions from '../../components/dashboard/Sessions';
import NavLogo from '../../components/NavLogo';

function StudentSessions() {
	const auth = useContext(AuthContext);
	const location = useLocation();

	const [alert, setAlert] = useState();
	const [loading] = useState(<NavLogo />);

	function whichID() {
		if (auth.role === 'student') {
			return auth.userHash;
		} else if (auth.role === 'admin') {
			return location.state.id;
		} else {
			return null;
		}
	}

	const [upcomingSessions, setUpcomingSessions] = useState([]);
	const [pastSessions, setPastSessions] = useState([]);

	const [successTitle, setSuccessTitle] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [errorTitle, setErrorTitle] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	useEffect(() => {
		// Check to make sure user has been authenticated and is a student or admin
		if (
			auth.currentUser !== null &&
			(auth.role === 'student' || auth.role === 'admin')
		) {
			axios
				.get(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/students`,
					{
						params: {
							studentParam: whichID(),
						},
					}
				)
				.then((res) => {
					const studentData = res.data;
					let newUpcomingSessions = [...studentData.upcomingSessions];
					let newPastSessions = [...studentData.pastSessions];

					setUpcomingSessions(newUpcomingSessions);
					setPastSessions(newPastSessions);
					auth.setStudentBalance(studentData.balance);
					// if (studentData.balance === 0) {
					// 	setError(
					// 		'You have 0 hours!',
					// 		"To book a session, click on 'Buy Hours' and purchase a package."
					// 	);
					// }

					if (location.state) {
						if (location.state.success) {
							setSuccess(
								location.state.success.title,
								location.state.success.message
							);
						}
						if (location.state.error) {
							setError(
								location.state.error.title,
								location.state.error.message
							);
						}
					}
				})
				.catch(() =>
					setAlert(
						<Failure
							title="Oops!"
							message="Something happened. Try again or contact us."
						/>
					)
				);
		}
		// eslint-disable-next-line
	}, [auth, location.state]);

	const setError = (title, message) => {
		setErrorTitle(title);
		setErrorMessage(message);
	};

	const setSuccess = (title, message) => {
		setSuccessTitle(title);
		setSuccessMessage(message);
	};

	return (
		<div>
			<Nav logo={loading} settings="/student-settings" />
			<br />
			{alert}
			<div className="dashboard">
				<Success title={successTitle} message={successMessage} />
				<Failure title={errorTitle} message={errorMessage} />
				<h4 className="text-student-name">Tutoring sessions</h4>

				<Row>
					<Col lg={6} className={'ipad-dashboard'}>
						<Sessions
							title="Upcoming Sessions"
							action="Join"
							className="general-container student-session-box sessions-container"
							sessionHashes={upcomingSessions}
							isPast={false}
						/>
					</Col>

					<Col lg={6} className={'ipad-sessions-container'}>
						<Sessions
							title="Past Sessions"
							action="View"
							className="general-container student-session-box past-sessions-container"
							sessionHashes={pastSessions}
							isPast={true}
						/>
					</Col>
				</Row>
			</div>
		</div>
	);
}

export default StudentSessions;
