import React from 'react';
import PerfectTutor from './Modals/PerfectTutor';

function Cta(props) {
	return (
		<div class="cover-footer">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 240">
				<path
					fill="#fafafa"
					fill-opacity="1"
					d="M0,96L60,128C120,160,240,224,360,234.7C480,245,600,203,720,176C840,149,960,139,1080,133.3C1200,128,1320,128,1380,128L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
				></path>
			</svg>
			<section class="cta-section" id="">
				<div class=" container text-center">
					<div class="row">
						<div class="col-sm-12 col-md-12 col-lg-12 cta-div">
							<div class="cta-img-div row">
								{' '}
								<img class="cta-img" src="../images/online-teacher.png" alt="cta" />
								<div class='col-lg-6 col-md-6 text-align-left'>
									<h1 class="meet-tutors-title">Ready to get started?</h1>
									<PerfectTutor />
								</div>
							</div>

							<row>
								<div className="col-lg-12 md col-sm-12 col-md-12">
									<br />
								</div>
							</row>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Cta;
