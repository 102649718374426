import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import axios from 'axios';
import '../settings/settings.css';
import { Failure } from '../alerts/Alerts';
import Payments from './Payments';
import Nav from './Nav';
import NavLogo from '../NavLogo';

function TutorPayment() {
	const [alert, setAlert] = useState();

	const auth = useContext(AuthContext);
	const [stripeUrl, setStripeUrl] = useState('');
	const [isConnected, setIsConnected] = useState(false);

	useEffect(() => {
		if (auth.role === 'tutor') {
			axios
				.get(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/tutors`,
					{
						params: {
							tutorParam: auth.userHash,
						},
					}
				)
				.then((res) => {
					setIsConnected(res.data.stripeConfirmed);
					setStripeUrl(res.data.stripeUrl);
				})
				.catch((err) =>
					setAlert(<Failure title="oops!" message={err.message} />)
				);
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div className="general-container settings-container">
			<Nav logo={<NavLogo />} />
			{alert}
			<div class="row">
				<div class="col-sm-12 col-md-6 col-lg-6">
					<div class="stripe-container">
						<div class="tutors-payment-how">
							<img
								class="payments-pic"
								src="../images/debit-card.png"
								alt="payments-pic"
							/>
						</div>
						<a href={stripeUrl} target="_blank" rel="noopener noreferrer">
							{isConnected ? (
								<button>Go to Stripe</button>
							) : (
								<button>
									{stripeUrl.length ? 'Connect Stripe' : 'Loading...'}
								</button>
							)}
						</a>

						<p class="powered-by-stripe">
							Secured by{' '}
							<img class="stripe-img" src="../images/stripe.png" alt="stripe" />
						</p>
					</div>
				</div>

				<br />

				<div class="col-sm-12 col-md-6 col-lg-6">
					<div class="transactions-container">
						<Payments />
					</div>
				</div>
			</div>
		</div>
	);
}

export default TutorPayment;
