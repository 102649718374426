import React from 'react';
import './tutor-profile.css';

function Search(props) {
	return (
		<div className="search-container">
			<form onSubmit={props.searchOnClick}>
				<input
					className="search-input-box"
					type="text"
					id="search"
					placeholder="Search for a user..."
					value={props.inputValue}
					onChange={props.searchOnChange}
				/>

				<button type="submit" className="btn-submit" name="search">
					<i className="search-icon fas fa-search"></i>
				</button>
			</form>
		</div>
	);
}

export default Search;
