/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:7da73811-1f9b-457a-b955-db1e419dcfe2",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_z63FARHym",
    "aws_user_pools_web_client_id": "6tu72hdsuump3tstgiqr3er3p2",
    "oauth": {}
};


export default awsmobile;
