import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import axios from 'axios';
import LoadingPage from '../../components/loading/LoadingPage';
import { Book } from '../../components/book/Book';
import { toast } from 'react-toastify';
const queryString = require('query-string');

function BookStudentSessions(props) {
	const auth = useContext(AuthContext);
	const parsed = queryString.parse(props.location.search, {
		decoder: (c) => c,
	});
	const { sid, user } = parsed || {};
	const phoneNumber = user?.replace(/[^0-9]/g, '');

	const [loading, setLoading] = useState(false);
	const [studentInfo, setStudentInfo] = useState(null);
	const [isInvalid, setIsInvalid] = useState(false);
	const [isBooked, setIsBooked] = useState(false);

	const fetchUserSessions = async () => {
		try {
			setLoading(true);
			const result = await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/auth/check/session`,
				{
					phone: `+${phoneNumber}`,
					sid: sid,
				}
			);
			const { data, status, message } = result?.data || {};
			if (!status) {
				throw new Error(message);
			}
			const { student_profile, student_id } = data || {};
			setStudentInfo(data);

			auth.setUserHash(student_id);
			auth.setRole('student');
			auth.setCurrentStudent(student_profile);
		} catch (err) {
			console.log(err);
			setIsInvalid(true);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchUserSessions();
		// eslint-disable-next-line
	}, [sid]);

	const handleSuccessfulBook = async (sessionTime, tutorHash, helpText) => {
		try {
			setLoading(true);
			const { student_id: studentId, tutor_id: tutorId } = studentInfo || {};
			const booking = await axios.put(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/bookings`,
				{
					studentParam: studentId,
					tutorParam: tutorId,
					requestedTime: sessionTime,
					helpText: 'I need help on math homework',
				}
			);

			if (booking.data.statusCode === 200) {
				setIsBooked(true);
				if (booking.data.message === 'Requested') {
					toast.success('Successfully requested booking a tutoring session');
				} else {
					toast.success('You successfully booked a new tutoring session');
				}
			} else {
				const errMessage =
					booking?.data?.message ||
					'Unable to book, please try again or contact us';
				toast.error(errMessage);
			}
		} catch (err) {
			toast.error('Unable to book, please try again or contact us');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="page-book-session">
			<div className="navbar">
				<img className="nav-logo" src="/images/Peerlinc.png" alt="AA" />
			</div>
			<br />
			<div className="dashboard">
				{loading && <LoadingPage />}
				{!loading && isInvalid && (
					<p className="text-center">
						Invalid session link. Please contact support for help.
					</p>
				)}
				{!loading && !isInvalid && studentInfo && (
					<div className="book-container">
						<h1 className="text-center">Book a Tutoring Session</h1>
						{isBooked ? (
							<p className="text-center">
								Thank you for booking a tutoring session. Please check your
								email for more details.
							</p>
						) : (
							<Book
								tutor={studentInfo?.tutor_profile}
								open={true}
								handleSuccessfulBook={handleSuccessfulBook}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

export default BookStudentSessions;
