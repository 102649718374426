import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import axios from 'axios';
import LoadingPage from '../loading/LoadingPage';
import TextMessage from './TextMessage';
import './../dashboard/index.scss';
import './conversations.scss';

const AIChatConversations = (props) => {
	const auth = useContext(AuthContext);
	const studentProfile = auth?.currentStudent;
	const { id: studentId } = studentProfile || {};

	const [loading, setLoading] = useState(true);
	const [messages, setMessages] = useState([]);
	const [tutorName, setTutorName] = useState('');

	const fetchData = async () => {
		try {
			setLoading(true);

			const result = await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/chat/history`,
				{
					user_id: studentId,
				}
			);
			const { data, tutor_name } = result?.data || {};
			const conversation = data || [];
			setMessages(conversation);
			setTutorName(tutor_name || '');
		} catch (err) {
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (studentId) {
			fetchData();
		}
		// eslint-disable-next-line
	}, [studentId]);

	return (
		<div className="section-box-container section-math-gpt overflow-hidden">
			<div className="section-header info-header">
				<span>
					{tutorName || ''} {tutorName && '|'} Math Chat
				</span>
			</div>

			<div className="section-content">
				{loading ? (
					<LoadingPage />
				) : messages?.length === 0 ? (
					<div className="chat-preview-box">
						<h4>No messages yet</h4>
					</div>
				) : (
					<main className="chatview__chatarea">
						{messages.map((message, index) => (
							<TextMessage
								tutor={tutorName}
								key={index}
								message={{ ...message }}
							/>
						))}
					</main>
				)}
			</div>
		</div>
	);
};

export default AIChatConversations;
