import React from 'react';
import AnimatedText from '../../components/home/AnimatedText';
import Footer from '../../components/home/Footer';
import Header from '../../components/home/Header';
import { useEffect } from 'react';
import { getTutors } from '../../services/api';
import { Carousel } from 'react-bootstrap';
import Register from '../../components/auth/Register';
import './index.scss';
import AskTutorQuestion from '../../components/home/AskTutorQuestion';

function Home() {
	const [slideIndex, setSlideIndex] = React.useState(0);
	const [tutors, setTutors] = React.useState([]);
	const [showRegister, setShowRegister] = React.useState(false);
	const [userQuestion, setUserQuestion] = React.useState('');

	const fetchData = async () => {
		const response = await getTutors();
		setTutors(response);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleAskQuestion = () => {
		if (!userQuestion) return;
		setShowRegister(true);
	};

	return (
		<>
			<div className="home-container page-highschool">
				<Header />

				<div className="container home-banner">
					<div className="row hero-banner">
						<div className="main-writing col-sm-12 col-md-12 col-lg-6">
							<div className="home-description">
								<span>Need Math</span>
								<AnimatedText />
								<span>Help?</span>
							</div>

							<div className="xs-hidden w-full">
								<AskTutorQuestion
									tutorName={
										tutors?.length > 0 ? tutors[slideIndex]?.name : null
									}
									userQuestion={userQuestion}
									setUserQuestion={setUserQuestion}
									handleAskQuestion={handleAskQuestion}
								/>
							</div>
						</div>

						<div className="col-sm-12 col-md-12 col-lg-6">
							<div className="home-tutor-card">
								<div className="blank-tutor-card dir1"></div>
								<div className="blank-tutor-card dir2"></div>
								{tutors?.length > 0 && (
									<>
										<img
											src="/images/icons/top-badge.svg"
											alt="tutor"
											className="top-badge"
										/>
										<Carousel
											interval={null}
											activeIndex={slideIndex}
											onSelect={(index) => setSlideIndex(index)}
											onSlide={(index) => setSlideIndex(index)}
											autoPlay={false}
											indicators={false}
											// controls={false}
										>
											{tutors?.map((tutor, index) => {
												return (
													<Carousel.Item key={index}>
														<div className="tutor-card">
															<div
																className="tutor-profile-image"
																style={{
																	backgroundImage: `url(${tutor?.image || ''})`,
																}}
															></div>

															<div className="tutor-info-description">
																<div className="tutor-name">
																	{tutor?.name || ''}
																</div>
																<div className="tutor-school">
																	<img
																		src={tutor?.universityImageLink}
																		alt="school"
																	/>
																	<span>{tutor?.university || ''}</span>
																</div>
															</div>
														</div>
													</Carousel.Item>
												);
											})}
										</Carousel>
									</>
								)}
							</div>

							<div className="xs-visible mt-5">
								<AskTutorQuestion
									tutorName={
										tutors?.length > 0 ? tutors[slideIndex]?.name : null
									}
									userQuestion={userQuestion}
									setUserQuestion={setUserQuestion}
									handleAskQuestion={handleAskQuestion}
								/>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="home-peerlinc-branding">
							<div className="box-border-top"></div>
							<div className="branding-box">
								<div className="branding-card">
									<img
										src="/images/Peerlinc.png"
										alt="branding"
										className="img-logo"
									/>
									<span>math copilot</span>
								</div>
								<img
									src="/images/icons/icon-equal.svg"
									alt="branding"
									className="img-icon"
								/>
								<div className="branding-card">
									<span className="brand-text">Top 1% Tutors + AI</span>
									<span>
										Your{' '}
										<u>
											<strong>Best</strong>
										</u>{' '}
										Math Grade Yet!
									</span>
								</div>
							</div>

							<div className="box-border-top"></div>
						</div>
					</div>
				</div>

				<div className="footer-top">
					<Footer />
				</div>
			</div>
			{showRegister && (
				<Register
					fromHome={true}
					showModal={showRegister}
					onClose={() => setShowRegister(false)}
					userQuestion={userQuestion}
					selectedTutor={tutors[slideIndex]}
				/>
			)}
		</>
	);
}

export default Home;
