import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { utcStringToLocalDate } from '../../../tools/timeConverter';
import Header from '../../../components/admin/home/Header';
import LoadingPage from '../../../components/loading/LoadingPage';
import { Failure } from '../../../components/alerts/Alerts';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/esm/Modal';

import './index.scss';
import { MATHS_COURSES, SCIENCE_COURSES } from '../../../config/general';
import DetailedStudent from '../../../components/admin/DetailedStudent';

const queryString = require('query-string');

function AdminListHighschoolStudents(props) {
	const { id: highschoolId } = props.match.params;
	const parsed = queryString.parse(props?.location?.search);
	const [alert, setAlert] = useState();
	const [mathTeachers, setMathTeachers] = useState([]);
	const [ScienceTeachers, setScienceTeachers] = useState([]);
	const [students, setStudents] = useState([]);
	const [filteredStudents, setFilteredStudents] = useState([]);
	const [loading, setLoading] = useState(true);
	const [activeFilter, setActiveFilter] = useState('all');
	const [selectedTopic, setSelectedTopic] = useState('all');

	const [showInfo, setShowInfo] = useState(false);
	const [selectedStudent, setSelectedStudent] = useState(null);

	useEffect(() => {
		fetchStudents();
		fetchTeachers();
		// eslint-disable-next-line
	}, []);

	const fetchStudents = async () => {
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_highschool_students`,
				{
					action: 'list_highschool_students',
					id: highschoolId,
				}
			)
			.then((res) => {
				var list = res?.data?.data;
				var sortedList = list.sort(
					(aStudent, bStudent) =>
						utcStringToLocalDate(bStudent.created) -
						utcStringToLocalDate(aStudent.created)
				);
				setStudents(sortedList);
				setFilteredStudents(sortedList);
			})
			.catch(() =>
				setAlert(
					<Failure
						title="Oops!"
						message="Something happened. Try again or contact us."
					/>
				)
			)
			.finally(() => setLoading(false));
	};

	const fetchTeachers = async () => {
		axios
			.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_highschool_teachers`,
				{
					action: 'list_teacher',
					highschoolId: highschoolId,
				}
			)
			.then((res) => {
				var teachers = res?.data?.data || [];
				var mathTeacherFiltered = teachers.filter((teacher) =>
					teacher.teacher_type.includes('math')
				);
				var scienceTeacherFiltered = teachers.filter((teacher) =>
					teacher.teacher_type.includes('science')
				);
				setMathTeachers(mathTeacherFiltered);
				setScienceTeachers(scienceTeacherFiltered);
			});
	};

	const assignTeacher = async (studentId, teacherType, teacherId) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_highschool_teachers`,
				{
					action: 'assign_teacher',
					studentId: studentId,
					teacherId: teacherId,
					teacherType: teacherType,
				}
			);

			if (response?.data?.status) {
				toast.success('Teacher assigned successfully');
			} else {
				const errorMessage = response?.data?.message || 'Something went wrong';
				toast.error(errorMessage);
			}
		} catch (err) {
			const errorMessage = err?.message || 'Something went wrong';
			toast.error(errorMessage);
		}
	};

	// eslint-disable-next-line
	const SelectTeacher = (props) => {
		const { student, type } = props;

		const teachers = type === 'math' ? mathTeachers : ScienceTeachers;
		const currentTeacherId = student[type + 'TeacherId'] || '';
		const [selectedTeacherId, setSelectedTeacherId] =
			useState(currentTeacherId);

		const handleChange = (e) => {
			const teacherId = e.target.value;
			setSelectedTeacherId(teacherId);
			assignTeacher(student.id, type, teacherId);
		};

		return (
			<select
				className="form-control"
				value={selectedTeacherId}
				onChange={(e) => handleChange(e)}
			>
				<option value="" disabled>
					Select Teacher
				</option>
				{teachers.map((teacher) => (
					<option key={teacher.teacher_id} value={teacher.teacher_id}>
						{teacher.teacher_name}
					</option>
				))}
			</select>
		);
	};

	useEffect(() => {
		var filterItems = [];
		if (activeFilter === 'client') {
			filterItems = students.filter((item) => item?.firstPackagePurchased);
		} else if (activeFilter === 'registered') {
			filterItems = students.filter((item) => !item?.firstPackagePurchased);
		} else if (activeFilter === 'booked') {
			filterItems = students.filter((item) => item?.firstSessionBooked);
		} else {
			filterItems = students.filter((item) => item);
		}
		setFilteredStudents(filterItems);
		// eslint-disable-next-line
	}, [activeFilter]);

	useEffect(() => {
		var filterItems = [];
		if (selectedTopic === 'all') {
			filterItems = students.filter((item) => item);
		} else {
			filterItems = students.filter((item) => {
				const mathTopics = item?.mathTopics || '';
				const scienceTopics = item?.scienceTopics || '';
				return (
					mathTopics.includes(selectedTopic) ||
					scienceTopics.includes(selectedTopic)
				);
			});
		}
		setFilteredStudents(filterItems);
		// eslint-disable-next-line
	}, [selectedTopic]);

	const deleteStudent = async (studentId) => {
		try {
			// ask for confirmation
			const confirmation = window.confirm(
				"Are you sure you want to delete this student? This action can't be undone."
			);
			if (!confirmation) return;

			await axios.delete(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/students/`,
				{
					data: {
						studentParam: studentId,
					},
				}
			);
			fetchStudents();
			toast.success('Student deleted successfully');
		} catch (err) {
			const errorMessage = err?.message || 'Something went wrong';
			toast.error(errorMessage);
		}
	};

	const getStatus = (item) => {
		if (item?.firstPackagePurchased) {
			return <span className="badge badge-client">Client</span>;
		} else if (item?.firstSessionBooked) {
			return <span className="badge badge-booked">Booked</span>;
		} else {
			return <span className="badge badge-registered">Registered</span>;
		}
	};

	const getStudentType = (item) => {
		const { studentType } = item;
		if (studentType === 'intern') {
			return <span>Intern</span>;
		}
		return <span>Standard</span>;
	};

	if (loading) {
		return <LoadingPage />;
	}

	const ALL_TOPICS = [...MATHS_COURSES, ...SCIENCE_COURSES];

	const handleModal = (student = null) => {
		if (student) {
			setSelectedStudent(student);
		}
		setShowInfo(!showInfo);
	};

	return (
		<div className="page-admin-highschool">
			<Header />
			{alert}
			<div className="container-fluid">
				<div className="row page-header mt-5 mb-4">
					<h4 className="admin-title">
						{parsed?.name} Students ({students?.length})
					</h4>
					<div className="filter-dropdown">
						<label>Filter by:</label>
						<select
							className="form-control"
							value={selectedTopic}
							onChange={(e) => setSelectedTopic(e?.target?.value)}
						>
							<option value="all">All</option>
							{ALL_TOPICS.map((item, index) => {
								return (
									<option key={index} value={item}>
										{item}
									</option>
								);
							})}
						</select>
					</div>
					<div className="filter-bar">
						<span
							className={activeFilter === 'all' ? 'active' : ''}
							onClick={() => setActiveFilter('all')}
						>
							All
						</span>
						<span
							className={activeFilter === 'client' ? 'active' : ''}
							onClick={() => setActiveFilter('client')}
						>
							Client
						</span>
						<span
							className={activeFilter === 'booked' ? 'active' : ''}
							onClick={() => setActiveFilter('booked')}
						>
							Booked
						</span>
						<span
							className={activeFilter === 'registered' ? 'active' : ''}
							onClick={() => setActiveFilter('registered')}
						>
							Registered
						</span>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						{filteredStudents.length > 0 ? (
							<table className="table table-hover">
								<thead>
									<tr>
										<th scope="col">Date</th>
										<th scope="col">Student Name</th>
										<th scope="col">Grade</th>
										<th scope="col">Math</th>
										<th scope="col">Math Teacher</th>
										<th scope="col">Science</th>
										<th scope="col">Science Teacher</th>
										<th scope="col">Type</th>
										<th scope="col">Status</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>
									{filteredStudents.map((item, index) => (
										<tr key={index} onClick={() => handleModal(item?.id)}>
											<td>{moment(item.created).format('DD MMM, YYYY')}</td>
											<td>{item?.name}</td>
											<td>{item?.gradeNumber}</td>
											<td>{item?.mathTopics}</td>
											<td>{item?.mathTeacherName}</td>
											<td>{item?.scienceTopics}</td>
											<td>{item?.scienceTeacherName}</td>
											<td>{getStudentType(item)}</td>
											<td>{getStatus(item)}</td>
											<td>
												<Button
													size="sm"
													variant="danger"
													onClick={() => deleteStudent(item.id)}
												>
													<i className="fas fa-trash-alt"></i>
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							<p className="text-center">No student found</p>
						)}
					</div>
				</div>
			</div>
			<Modal size="lg" show={showInfo} onHide={handleModal}>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<DetailedStudent id={selectedStudent} />
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default AdminListHighschoolStudents;
