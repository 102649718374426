import React from 'react';
//import Packages from '../../components/pricing/Packages';
import SubscriptionPackages from '../../components/pricing/SubscriptionPackages';
import Header from '../../components/home/Header';
import Footer from '../../components/home/Footer';

function Pricing() {
	return (
		<>
			<Header request={true} />
			<SubscriptionPackages />
			<Footer bgColor={'#FCFCFC'} />
		</>
	);
}

export default Pricing;
