import React from 'react';
import ReactDOM from 'react-dom';
import App from "./components/App"
import { BrowserRouter } from 'react-router-dom';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

ReactDOM.render(
  <BrowserRouter>
  <App />
  </BrowserRouter>,
  document.getElementById('root')
);
