import React from 'react';
import Footer from '../../components/home/Footer';
import './index.scss';
import ModalJoinWaitlist from '../../components/home/Modals/ModalJoinWaitlist';

function HomeTeamAnya() {
	const [showModal, setShowModal] = React.useState(false);

	return (
		<div className="home-container page-highschool page-tutors">
			<div className="header-custom">
				<nav className="navbar navbar-custom navbar-expand-lg" id="mainNav">
					<div className="container">
						<a className="navbar-brand" href="/">
							<img
								className="header-image"
								src="/images/Peerlinc.png"
								alt="home"
							/>
						</a>

						{/* <ul className="navbar-nav ml-auto">
							<li>
								<button
									className="btn-custom"
									onClick={() => setShowModal(true)}
								>
									Apply
								</button>
							</li>
						</ul> */}
					</div>
				</nav>
			</div>

			<div className="container home-banner">
				<div className="row hero-banner">
					<div className="main-writing col-sm-12 col-md-12 col-lg-6">
						<p className="text-description heading-20">
							Don't <u>Fall Behind</u> in
							<br />
							Math this year.
						</p>
						<p className="text-description heading-20">
							Join <u>#TeamAnya</u> &<br /> Let's Do this!
						</p>
						<button className="btn-custom" onClick={() => setShowModal(true)}>
							Join waitlist
						</button>
					</div>

					<div className="col-sm-12 col-md-12 col-lg-6">
						<div className="main">
							<div className="orange-box"></div>
							<div className="orange-box bg-black"></div>
							<div className="main-video-div">
								<iframe
									src="https://www.loom.com/embed/a2b136459b5f428c8cf64e2199cabbb5?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
									title="home video"
									className="main-video"
								></iframe>
							</div>
						</div>
					</div>
				</div>
				<div className="row section-branding">
					<div className="direction-arrow"></div>
					<div className="col-sm-12 col-md-10">
						<div className="box-branding">
							<div>
								<span>#TeamAnya</span>
							</div>
							<div>
								<img
									src="/images/icons/icon-equal.svg"
									alt="branding"
									className="img-icon"
								/>
							</div>
							<div>
								<div className="row-2">
									<span>24/7 Homework Help</span>
									<img
										src="/images/icons/icon-plus.svg"
										alt="branding"
										className="img-icon"
									/>
									<span>Homework Videos</span>
								</div>
								<div className="row-2">
									<span>Math Chat</span>
									<img
										src="/images/icons/icon-plus.svg"
										alt="branding"
										className="img-icon"
									/>
									<span>Test Prep Support</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<section className="section-information">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h3>#TeamAnya + You = Math Class Success!</h3>
							<img
								src="/images/features/teamanya.png"
								alt="feature"
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="section-feature">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center section-header">
							<h3>JOIN #TeamAnya for 2023 - 2024 School Year</h3>
							<p>
								<strong>Note:</strong> Signup window is August 1st - September
								1st
							</p>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6 xs-visible text-center">
							<img
								src="/images/features/Top-College-Tutors.gif"
								alt="feature"
								className="img-feature"
							/>
							<div className="btn-join-waitlist">
								<button
									className="btn-custom"
									onClick={() => setShowModal(true)}
								>
									Join waitlist
								</button>
							</div>
						</div>
						<div className="col-md-6 description-box">
							<ul>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<strong>24/7 Math Homework Help:</strong> Ask math homework
										questions and #TeamAnya answers!
									</span>
								</li>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<strong>Homework Videos:</strong> Anya posts Homework Videos
										to help support key learning concepts and to answer tough
										questions.
									</span>
								</li>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<strong>Math Chat:</strong> Our Al Powered Math Chat feature
										helps you get real time math support.
									</span>
								</li>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<strong>Test Prep Support:</strong> Quizzes, tests,
										mid-terms, finals... whatever the scenario #TeamAnya has got
										your back!
									</span>
								</li>
							</ul>
						</div>
						<div className="col-md-6 xs-hidden text-center">
							<img
								src="/images/features/Top-College-Tutors.gif"
								alt="feature"
								className="img-feature"
							/>
							<div className="btn-join-waitlist">
								<button
									className="btn-custom"
									onClick={() => setShowModal(true)}
								>
									Join waitlist
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>

			<div className="cover-footer">
				<section className="cta-section">
					<div className="container text-center">
						<div className="row">
							<div className="col-sm-12 col-md-12 col-lg-12 cta-div">
								<div className="cta-img-div row">
									<img
										className="cta-img"
										src="/images/online-teacher.png"
										alt="cta"
									/>
									<div className="col-lg-6 col-md-6 text-align-left">
										<h1 className="meet-tutors-title">Ready to get started?</h1>
										<button
											className="btn-custom"
											onClick={() => setShowModal(true)}
										>
											<span className="login-text">Join waitlist</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<Footer />

			{showModal && (
				<ModalJoinWaitlist
					show={true}
					onClose={() => setShowModal(false)}
					title={'Join waitlist'}
					bodyTitle={'TeamAnya - Join Waitlist'}
					subject={'New request for join waitlist'}
				/>
			)}
		</div>
	);
}

export default HomeTeamAnya;
