import React from 'react';
import Modal from 'react-bootstrap/Modal';
import LoadingButton from '../../loading/LoadingButton';
import axios from 'axios';
import './index.scss';

function ModalJoinWaitlist(props) {
	const { show, onClose, title, bodyTitle, subject } = props;

	const [loading, setLoading] = React.useState(false);

	const [state, setState] = React.useState({
		name: '',
		email: '',
		phone: '',
	});

	const handleSubmit = async () => {
		try {
			setLoading(true);
			const { name, email, phone } = state;

			const htmlEmailBody = `
                <h3>${bodyTitle}</h3>
                <p>Name: ${name}</p>
                <p>Email: ${email}</p>
                <p>Phone: ${phone}</p>
            `;
			const formData = {
				// TO_EMAIL: 'umang+peerlinc@micro1.ai',
				TO_EMAIL: 'john@peerlinc.com',
				FROM_EMAIL: 'john@peerlinc.com',
				SUBJECT: `Peerlinc: ${subject}`,
				EMAIL_BODY: htmlEmailBody,
			};

			await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/general/send_email`,
				formData
			);
			alert('Your request has been submitted successfully!');
			onClose();
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setState({
			...state,
			[name]: value,
		});
	};

	return (
		<>
			<Modal
				show={show}
				onHide={onClose}
				className="custom-modal-box modal-apply-intership"
			>
				<button className="btn btn-close" onClick={onClose}>
					<img src="/images/highschool/icon-close.svg" alt="close" />
				</button>
				<Modal.Body>
					<div className="apply-form">
						<h5>{title}</h5>
						<hr />
						<div className="form">
							<div className="form-group">
								<label>Name</label>
								<input
									type="text"
									className="form-control"
									id="name"
									placeholder="Enter your name"
									name="name"
									value={state?.name}
									onChange={handleChange}
									required
								/>
							</div>
							<div className="form-group">
								<label>Email</label>
								<input
									type="email"
									className="form-control"
									id="email"
									placeholder="Enter your email"
									name="email"
									value={state?.email}
									onChange={handleChange}
									required
								/>
							</div>
							<div className="form-group">
								<label>Phone</label>
								<input
									type="text"
									className="form-control"
									id="phone"
									placeholder="Enter your phone"
									name="phone"
									value={state?.phone}
									onChange={handleChange}
									required
								/>
							</div>

							<button
								disabled={loading}
								onClick={handleSubmit}
								className="btn btn-primary"
							>
								{loading ? <LoadingButton /> : 'Submit'}
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default ModalJoinWaitlist;
