import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingPage from '../../components/loading/LoadingPage';
import { Failure } from '../../components/alerts/Alerts';
import Subscriptions from '../../components/admin/Subscriptions';

function AdminSubscriptions() {
	const [alert, setAlert] = useState();
	const [payments, setPayments] = useState([]);
	const [students, setStudents] = useState([]);

	// Retrieve payments
	useEffect(() => {
		axios
			.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/list/subscription`,
				{}
			)
			.then((res) => {
				let sortedPayments = res.data.data.reverse();
				setPayments(sortedPayments);
			})
			.catch((err) =>
				setAlert(<Failure title="Oops!" message={err.message} />)
			);

		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/students`
			)
			.then((res) => {
				// Filter out deleted students
				var filteredStudents = res.data.Items;
				setStudents(filteredStudents);
			});
	}, []);

	// Loading
	const [showState, setShowState] = useState(<LoadingPage />);

	useEffect(() => {
		if (payments.length > 0) {
			var sortedPayments = payments.sort(function (a, b) {
				return new Date(b.timestamp) - new Date(a.timestamp);
			});
			setShowState(
				sortedPayments.map((payment, index) => {
					var studentId = payment.studentId;
					var student = students.find((student) => student.id === studentId);
					var studentName = student?.name || ` - `;
					return (
						<Subscriptions
							key={index}
							studentName={studentName}
							payment={payment}
						/>
					);
				})
			);
		}
	}, [payments, students]);

	return (
		<div className="all-container">
			{alert}
			<div className="all-labels">
				<div className="row">
					<div className="col-4 txt-heading">User</div>
					<div className="col-3 txt-heading">Date & Time</div>
					<div className="col-3 txt-heading">Amount</div>
					<div className="col-2 txt-heading">Subscription type</div>
				</div>
			</div>
			{showState}
		</div>
	);
}

export default AdminSubscriptions;
