import React, { useState, useEffect } from 'react';
import './availability.scss';

function DayOfWeek(props) {
	const { day, dayIndex, newAvailableSessions, setNewAvailableSessions } =
		props;

	const [slots, setSlots] = useState([]);

	// Initialize slots with previously set times
	useEffect(() => {
		if (slots.length === 0) {
			const dayOfWeek = newAvailableSessions[dayIndex];

			if (dayOfWeek) {
				for (let i = 0; i < dayOfWeek.length; i++) {
					if (dayOfWeek[i]) {
						const newSlot = {
							start: dayOfWeek[i].start,
							end: dayOfWeek[i].end,
						};

						setSlots((prevSlots) => [...prevSlots, newSlot]);
					}
				}
			}
		}
	}, [newAvailableSessions, dayIndex, slots]);

	const changeTime = (e, slotIndex) => {
		// Create copy of slots
		const newSlots = [...slots];

		// Get slot that was changed
		const slot = newSlots[slotIndex];

		let [hour, minute] = e.target.value.split(':');
		if (parseInt(minute) > 0) hour += ':30';
		else hour += ':00';

		if (e.target.name === 'start') {
			slot.start = hour;
		} else if (e.target.name === 'end') {
			slot.end = hour;
		}

		setSlots(newSlots);

		// Update available sessions to match change in slot
		let newAvailableSessionTime = [...newAvailableSessions];
		if (newAvailableSessionTime[dayIndex]) {
			newAvailableSessionTime[dayIndex][slotIndex] = {
				start: slot.start,
				end: slot.end,
			};
		} else {
			newAvailableSessionTime[dayIndex] = [];
			newAvailableSessionTime[dayIndex][slotIndex] = {
				start: slot.start,
				end: slot.end,
			};
		}
		setNewAvailableSessions(newAvailableSessionTime);
	};

	const addNewSlot = (e) => {
		if (slots.length < 2) {
			const newSlot = {
				start: '--',
				end: '--',
			};

			setSlots([...slots, newSlot]);
		}
	};

	const removeSlot = (slotIndex) => {
		let newSlots = [...slots];
		newSlots.splice(slotIndex, 1);
		setSlots(newSlots);

		let newAvailableSessionTime = [...newAvailableSessions];
		if (newAvailableSessionTime[dayIndex]) {
			newAvailableSessionTime[dayIndex][slotIndex] = null;
			if (slotIndex === 0 && newAvailableSessionTime[dayIndex][1]) {
				newAvailableSessionTime[dayIndex][0] =
					newAvailableSessionTime[dayIndex][1];
				newAvailableSessionTime[dayIndex][1] = null;
			}
		}

		setNewAvailableSessions(newAvailableSessionTime);
	};

	return (
		<div className="calendar-box">
			<div className="text-day-name">{day}</div>

			{slots.map((slot, slotIndex) => {
				return (
					<div className="time-slot-box" key={slotIndex}>
						<input
							type="time"
							className="time-input"
							name="start"
							min="00:00"
							max="23:00"
							step="1800"
							value={`${slot.start}`}
							onChange={(e) => changeTime(e, slotIndex)}
							required
						/>
						<input
							type="time"
							className="time-input"
							name="end"
							min="00:00"
							max="23:00"
							step="1800"
							value={`${slot.end}`}
							onChange={(e) => changeTime(e, slotIndex)}
							required
						/>
						<button
							className="delete-slot"
							type="button"
							onClick={() => removeSlot(slotIndex)}
						>
							<i className="fal fa-times"></i>
						</button>
					</div>
				);
			})}

			{slots.length < 2 ? (
				<button className="btn-add-slot" onClick={addNewSlot}>
					<i className="fas fa-plus-circle"></i> Add New Slot
				</button>
			) : null}
		</div>
	);
}

export default DayOfWeek;
