import moment from 'moment';
import React from 'react';

function Payment(props) {
	const { tutorId, studentId, logType, timestamp, details } = props.payment;

	function setType() {
		if (logType === 'FOUR' || logType === 'FIFTEEN' || logType === 'THIRTY') {
			return (
				<span>
					<i className="green fas fa-money-bill-wave-alt"></i>
				</span>
			);
		} else {
			return (
				<span>
					{' '}
					<i className="red fas fa-money-check"></i>
				</span>
			);
		}
	}

	function id() {
		if (logType === 'CASHOUT') {
			return tutorId;
		} else {
			return studentId;
		}
	}

	function setAmount() {
		if (logType === 'CASHOUT') {
			// to show only dollar amount no cents
			return '$' + details / 100;
		} else {
			if (logType === 'FOUR') {
				return '$160';
			} else if (logType === 'FIFTEEN') {
				return '$540';
			} else {
				return '$990';
			}
		}
	}

	return (
		<div className="item-data">
			<div className="row">
				<div className="col-4">
					<p className="explain-text">{id()}</p>
				</div>
				<div className="col-3">{moment(timestamp).format('ll')}</div>
				<div className="col-3">{setAmount()}</div>
				<div className="col-2">{setType()}</div>
			</div>
		</div>
	);
}

export default Payment;
