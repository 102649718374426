import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { sha512 } from 'js-sha512';
import { Failure, Success } from '../alerts/Alerts';
import { useHistory } from 'react-router-dom';
import LoadingButton from '../loading/LoadingButton';
import './auth.css';

function ForgotPassword() {
	const history = useHistory();

	const [email, setEmail] = useState('');
	const [code, setCode] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [formState, setFormState] = useState(0);
	const [formError, setFormError] = useState(null);

	const [loadStateSend, setLoadStateSend] = useState(<div>Send</div>);
	const [loadStateSubmit, setLoadStateSubmit] = useState(<div>Submit</div>);

	const handleNewPassword = (e) => {
		e.preventDefault();
		setLoadStateSubmit(<LoadingButton />);

		if (newPassword !== confirmPassword) {
			setLoadStateSubmit(<div>Submit</div>);
			setFormError({
				title: 'Passwords Do Not Match.',
				message: 'Make sure your password and confirm password match',
			});
		} else if (newPassword.length < 8) {
			setLoadStateSubmit(<div>Submit</div>);
			setFormError({
				title: 'Password Too Short.',
				message: 'Password must be at least 8 characters long',
			});
		} else {
			// Updates the user's password
			Auth.forgotPasswordSubmit(email, code, sha512(newPassword))
				.then(() => {
					setFormError(null);
					history.push('/login');
				})
				.catch((err) => {
					setLoadStateSubmit(<div>Submit</div>);

					if (err.name === 'CodeMismatchException') {
						setFormError({
							title: 'Invalid Code.',
							message: 'Please enter the code sent to your email',
						});
					} else if (err.name === 'LimitExceededException') {
						setFormError({
							title: 'Attempt Limit Exceeded.',
							message: 'Please try again later',
						});
					} else if (err.name === 'ExpiredCodeException') {
						setFormError({
							title: 'Code Expired.',
							message: 'Please try again',
						});
					} else {
						setFormError({
							title: 'Failed to Reset Password.',
							message:
								'An error occured trying to change your password, please try again',
						});
					}
				});
		}
	};

	const handleEmail = (e) => {
		e.preventDefault();
		setLoadStateSend(<LoadingButton />);
		// Get the user's email and send validation code to their email
		Auth.forgotPassword(email)
			.then((user) => {
				setFormState(1);
			})
			.catch((err) => {
				setLoadStateSend(<div>Send</div>);
				if (err.name === 'UserNotFoundException') {
					setFormError({
						title: 'User Not Found.',
						message: 'An account with the given email does not exist',
					});
				}
			});
	};

	return (
		<div className="container">
			{formError ? (
				<Failure title={formError.title} message={formError.message} />
			) : null}

			<div className="auth-container">
				<div className="auth-logo-div">
					<img className="auth-logo" src="/images/Peerlinc.png" alt="" />
				</div>

				<h5 className="card-title text-center">Forgot Password</h5>
				{formState === 0 ? (
					<form onSubmit={handleEmail}>
						<input
							type="email"
							className="auth-input"
							name="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeHolder="Email"
						></input>
						<button type="submit" className="auth-button">
							{loadStateSend}
						</button>
					</form>
				) : (
					<div>
						<Success title="" message="Validation code sent to your email" />
						<form onSubmit={handleNewPassword} autoComplete="off">
							<input
								id="code"
								type="text"
								className="auth-input"
								name="code"
								value={code}
								onChange={(e) => setCode(e.target.value)}
								placeHolder="Validation Code"
								autoComplete="off"
							></input>
							<input
								type="password"
								className="auth-input"
								name="new-password"
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
								placeHolder="New Password"
							></input>
							<input
								type="password"
								className="auth-input"
								name="confirm-password"
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								placeHolder="Confirm Password"
							></input>

							<button type="submit" className="auth-button">
								{loadStateSubmit}
							</button>
						</form>
					</div>
				)}
			</div>
		</div>
	);
}

export default ForgotPassword;
