import React, { useEffect, useState } from 'react';
import Header from '../../components/admin/home/Header';
import Message from '../../components/admin/Message';
import axios from 'axios';
import { Failure } from '../../components/alerts/Alerts';

function AdminMessages() {
	const [alert, setAlert] = useState();

	const [messages, setMessages] = useState([]);

	useEffect(() => {
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/messages/`
			)
			.then((res) => {
				let orderedMessages = res.data.Items.reverse();
				setMessages(orderedMessages);
			})
			.catch((err) =>
				setAlert(<Failure title="Oops!" message={err.message} />)
			);
	}, []);

	return (
		<div>
			<Header />
			<div className="admin-container">
				{alert}
				<div className="all-container">
					<div className="all-labels">
						<div className="row">
							<div className="col-2">
								<i className="fal fa-user"></i> From
							</div>
							<div className="col-2">
								<i className="fal fa-user"></i> To
							</div>
							<div className="col-2">
								<i className="far fa-clock"></i> Date
							</div>
							<div className="col-6">
								<i className="far fa-comments"></i> Message
							</div>
						</div>
					</div>

					{messages.map((message) => (
						<Message message={message} />
					))}
				</div>
			</div>
		</div>
	);
}

export default AdminMessages;
