export const constantKeys = {
	REGISTER_RECOM_SUBJECT: '_am_register_subject_',
	REGISTER_RECOM_SLOT: '_am_register_timeslot_',
	REGISTER_USER_TYPE: '_am_register_usertype_',
	REGISTER_USER_GRADE: '_am_register_grade_',
	REGISTER_USER_SUBJECT: '_am_register_subject_',
	REGISTER_RECOM_TUTOR: '_am_register_free_tutor_id_',
	IS_NEW_USER: '_is_new_user_',
	IS_NEW_TUTOR: '_is_new_tutor_',
};
