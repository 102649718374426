import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Video from './Video';
import './tutor-profile.css';
import { useEffect } from 'react';
import Certified from '../../Icons/Certified.svg';
import Register from '../auth/Register';
import { AuthContext } from '../../contexts/AuthContext';
import { MATHS_COURSES, SCIENCE_COURSES } from '../../config/general';

// Tutors on Search
function Tutor(props) {
	const auth = useContext(AuthContext);
	const [subjects, setSubjects] = useState([]);

	const {
		// eslint-disable-next-line no-unused-vars
		id,
		username,
		name,
		about,
		youtubeLink,
		image,
		specialties,
	} = props.tutor;

	const { readMoreVisible } = props;

	const getDirectLink = () => {
		var host = window.location.host;
		var protocol = window.location.protocol;

		var tutorUrl = `${protocol}//${host}/tutor/${id}`;

		return tutorUrl;
	};

	const copyDirectLink = () => {
		var tutorUrl = getDirectLink();

		navigator.clipboard.writeText(tutorUrl).then(() => {
			alert('Copied to clipboard: ' + tutorUrl);
		});
	};

	const maths = [
		'Basic Math',
		'Algebra',
		'Geometry',
		'Pre-Calc/Trig',
		'Calculus',
		'Statistics',
		'Advanced Math',
	];

	const [readMoreHandler, setReadMoreHandler] = useState(
		readMoreVisible || false
	);

	const readMore = () => {
		if (readMoreHandler === false) {
			return (
				<>
					{about.slice(0, 200) + '...'}{' '}
					<span
						className="read-more-text"
						onClick={() => setReadMoreHandler(true)}
					>
						Read more
					</span>
				</>
			);
		} else {
			return (
				<>
					{about}{' '}
					<span
						className="read-more-text"
						onClick={() => setReadMoreHandler(false)}
					>
						Show less
					</span>
				</>
			);
		}
	};

	const physics = ['Basic Physics', 'AP Physics', 'Advanced Physics'];

	const computerSciences = [
		'Basic Computer Science',
		'AP Computer Science',
		'Advanced Computer Science',
	];

	const biologies = ['Basic Biology', 'AP Biology', 'Advanced Biology'];

	const chemistries = ['Basic Chemistry', 'AP Chemistry', 'Advanced Chemistry'];

	useEffect(() => {
		let subjectsArray = [];
		if (maths.some((math) => specialties.includes(math))) {
			subjectsArray.push('Math');
		}

		if (specialties.includes('SAT/ACT')) {
			subjectsArray.push('SAT/ACT');
		}

		if (physics.some((physic) => specialties.includes(physic))) {
			subjectsArray.push('Physics');
		}

		if (computerSciences.some((cs) => specialties.includes(cs))) {
			subjectsArray.push('Computer Science');
		}

		if (biologies.some((bio) => specialties.includes(bio))) {
			subjectsArray.push('Biology');
		}

		if (chemistries.some((chem) => specialties.includes(chem))) {
			subjectsArray.push('Chemistry');
		}

		if (MATHS_COURSES.some((course) => specialties.includes(course))) {
			subjectsArray.push('Math');
		}

		if (SCIENCE_COURSES.some((course) => specialties.includes(course))) {
			subjectsArray.push('Science');
		}

		// remove duplicates
		subjectsArray = [...new Set(subjectsArray)];

		setSubjects(subjectsArray);
		// eslint-disable-next-line
	}, [specialties]);

	return (
		<div className="a-tutor">
			<div className="row box-tutor-details">
				<div className="col-lg-3 col-md-4 col-sm-12">
					<Video
						youtubeLink={youtubeLink}
						image={image}
						id={username}
						name={name}
					/>
				</div>

				<div className="col-lg-6 col-md-8 col-sm-12 about-tutor">
					<h5>{name}</h5>

					<div className="tutor-statement-div">
						<p className="tutor-statement">{readMore()}</p>
					</div>

					{subjects.map((subject, index) => {
						return (
							<button className="subject" key={index}>
								{subject}
							</button>
						);
					})}
				</div>

				<div className="col-lg-3 col-md-4 col-sm-12">
					<div className="copy-box">
						<p className="txt-title">Direct Profile Access</p>
						<div className="copy-link" onClick={copyDirectLink}>
							<span className="link">{getDirectLink()}</span>
							<span className="icon">
								<i className="fal fa-copy"></i>
							</span>
						</div>
					</div>

					{auth.currentUser === null ? (
						<Register
							fromLogin={false}
							tutorId={id}
							tutorAvailable={props.tutor.availability}
						/>
					) : (
						<Link
							className="link"
							to={{
								pathname: '/student-dashboard',
								state: {
									pickedTutorHash: id,
									action: 'book',
								},
							}}
						>
							<button className="book book-tutor">Book a Session</button>
						</Link>
					)}

					{/* </form> */}
				</div>
				<div className="peerlinc-certificate">
					<img src={Certified} alt="" className="peerlinc-check"></img>
					<span>Certified</span>
				</div>
			</div>
		</div>
	);
}
export default Tutor;
