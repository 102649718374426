import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './index.scss';

function ModalIntroVideo(props) {
	const { show, onClose } = props;

	return (
		<>
			<Modal show={show} className="custom-modal-box modal-intro-video">
				<button className="btn btn-close" onClick={onClose}>
					<img src="/images/highschool/icon-close.svg" alt="close" />
				</button>
				<Modal.Body>
					<div className="intro-video-box">
						<h4>Welcome to Peerlinc!</h4>

						<iframe
							src="https://www.loom.com/embed/7bd921a5211f463d9169b016cc5c4bf5?autoplay=1&hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
							title="home video"
							className="video"
						></iframe>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default ModalIntroVideo;
