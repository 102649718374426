import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';

import 'katex/dist/katex.min.css';

const TextMessage = (props) => {
	const { message, tutor } = props;
	const { sender_name, message_text } = message || {};

	const isSystemMessage = sender_name === 'system';

	return (
		<div className={`${isSystemMessage ? 'ai-response' : 'user-text'} message`}>
			<div className="message__wrapper">
				<ReactMarkdown
					className={`message__markdown`}
					children={message_text}
					remarkPlugins={[
						[
							remarkMath,
							{
								inlineMathDouble: '$$',
							},
						],
						[remarkGfm, { singleTilde: false }],
					]}
					rehypePlugins={[rehypeKatex]}
					components={{
						code({ node, inline, className, children, ...props }) {
							const match = /language-(\w+)/.exec(className || 'language-js');
							return !inline && match ? (
								<SyntaxHighlighter
									children={String(children).replace(/\n$/, '')}
									style={atomDark}
									language={match[1]}
									PreTag="div"
									{...props}
								/>
							) : (
								<code className={className} {...props}>
									{children}
								</code>
							);
						},
					}}
				/>
			</div>

			{/* <div className={`message__createdAt`}>{formatTime(date_created)}</div> */}
			<div className={`message__senderName`}>
				{isSystemMessage ? tutor : 'YOU'}
			</div>
		</div>
	);
};

export default TextMessage;
