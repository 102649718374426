import React from 'react';
import Header from '../../components/admin/home/Header';
import UpcomingSessions from '../../components/admin/home/UpcomingSessions';
import RecentStudents from '../../components/admin/home/RecentStudents';
import Totals from '../../components/admin/data/Totals';

function Admin() {
	return (
		<div>
			<Header />

			<div className="admin-container">
				<Totals />

				<br />
				<br />
				<div className="row">
					<UpcomingSessions />
					<RecentStudents />
				</div>
				<p className="center-it explain-text">
					Data since V2 launch (10/15/2020)
				</p>
			</div>
		</div>
	);
}

export default Admin;
