import React, { useState } from 'react';
import doneImg from '../../Images/Done.svg';
import LoadingButton from '../loading/LoadingButton';

function Done(props) {
	const [loading, setLoading] = useState(false);
	return (
		<div className="done-container">
			<img src={doneImg} alt="" />
			{props.title != null ? <h1>{props.title}</h1> : null}
			{props.paragraph != null ? <h3>{props.paragraph}</h3> : null}
			{props.text != null ? <h6>{props.text}</h6> : null}
			{props.text2 != null ? <h5>{props.text2}</h5> : null}
			{props.btns ? (
				<>
					<button
						onClick={() => window.location.reload()}
						className="button-alt"
					>
						Later
					</button>
					<button
						onClick={() => {
							setLoading();
							props.onBook();
						}}
						className="button-primary"
					>
						{loading ? (
							<LoadingButton />
						) : (
							`Book a Free Session with ${props?.tutorName || 'tutor'}`
						)}
					</button>
				</>
			) : null}
		</div>
	);
}

export default Done;
