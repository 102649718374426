import React from 'react';
import { IoChevronForwardOutline } from 'react-icons/io5';

export default function AskTutorQuestion({
	tutorName,
	userQuestion,
	setUserQuestion,
	handleAskQuestion,
}) {
	if (!tutorName) return null;
	return (
		<div className="home-ask-question">
			<div className="question-box">
				<span className="text-tutor">Ask {tutorName || ''} a question</span>
				<input
					type="text"
					placeholder="Example: how to solve X+3 = 9"
					value={userQuestion}
					onChange={(e) => setUserQuestion(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							handleAskQuestion();
						}
					}}
				/>
			</div>
			<button className="btn-enter" onClick={handleAskQuestion}>
				<span>Submit</span>
			</button>
		</div>
	);
}
