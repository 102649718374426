import React, { useState, useEffect, useContext } from 'react';
import Nav from '../../components/dashboard/Nav';
import { Success, Failure } from '../../components/alerts/Alerts';
import { AuthContext } from '../../contexts/AuthContext';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingButton from '../../components/loading/LoadingButton';
import { constantKeys } from '../../tools/constant';
// import MyGroups from '../../components/dashboard/MyGroups';
// import ModalIntroVideo from '../../components/dashboard/ModalIntroVideo';
//import ModalIntroTrial from '../../components/dashboard/ModalIntroTrial';
import ModalSubscription from '../../components/dashboard/ModalSubscription';
// import MathGPT from '../../components/math-gpt/MathGPT';
// import { ChatContextProvider } from '../../contexts/ChatContext';
import MyTutorVideos from '../../components/dashboard/MyTutorVideos';
import NavLogo from '../../components/NavLogo';
import { toast } from 'react-toastify';
import moment from 'moment';
import AIChatConversations from '../../components/math-gpt/AIChatConversations';
import MyConversationVideos from '../../components/dashboard/MyConversationVideos';
import classnames from 'classnames';

function StudentDashboard() {
	const auth = useContext(AuthContext);
	const studentProfile = auth?.currentStudent;
	// const { name } = studentProfile || {};
	const history = useHistory();
	const location = useLocation();

	const [showSubscriptionPurchase, setShowSubscriptionPurchase] =
		useState(false);

	const [alert, setAlert] = useState();
	const [loading, setLoading] = useState(<NavLogo />);
	const [isTrialExpired, setIsTrialExpired] = useState(false);

	const [activeTab, setActiveTab] = useState('tutoring');

	useEffect(() => {
		const { id } = studentProfile || {};
		if (id) {
			checkStudentSubscription();
		}
		// eslint-disable-next-line
	}, [studentProfile]);

	const checkStudentSubscription = async () => {
		const {
			upcomingSessions,
			pastSessions,
			subscriptionTxnId,
			subscriptionMode = '',
			created,
		} = studentProfile;

		if (studentProfile) {
			if (subscriptionTxnId || subscriptionMode === 'manual') {
			} else {
				const allSessions = [...upcomingSessions, ...pastSessions];
				const filteredSessions = allSessions.filter((session) => session);
				const totalSessionsBooked = filteredSessions?.length || 0;

				const accountCreated = moment(created).format('YYYY-MM-DD');
				const today = moment().format('YYYY-MM-DD');

				// Is 60 days passed?
				const noOfTrialDays = moment(today).diff(accountCreated, 'days');
				const is30DaysPassed = noOfTrialDays > 30;

				if (totalSessionsBooked >= 2 || is30DaysPassed) {
					handleTrialExpired();
				}
			}
		}
	};

	function whichID() {
		if (auth.role === 'student') {
			return auth.userHash;
		} else if (auth.role === 'admin') {
			return location.state.id;
		} else {
			return null;
		}
	}

	const [balance, setBalance] = useState(0);
	const [pickedTutorHash, setPickedTutorHash] = useState('');

	const [successTitle, setSuccessTitle] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [errorTitle, setErrorTitle] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const getStudentProfile = async (studentId) => {
		axios
			.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/checkout/student_profile`,
				{
					student_id: studentId,
				}
			)
			.then((result) => {
				var response = result.data;
				if (response && response.status) {
					auth.setCurrentStudent(response.data);
				}
			})
			.catch(() => {
				setAlert(
					<Failure title="oops!" message="Something went wrong. Contact us." />
				);
			});
	};

	useEffect(() => {
		// Check to make sure user has been authenticated and is a student or admin
		if (
			auth.currentUser !== null &&
			(auth.role === 'student' || auth.role === 'admin')
		) {
			const studentId = whichID();

			getStudentProfile(studentId);

			axios
				.get(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/students`,
					{
						params: {
							studentParam: studentId,
						},
					}
				)
				.then((res) => {
					const studentData = res.data;
					setBalance(studentData.balance);
					auth.setStudentBalance(studentData.balance);
					// if (studentData.balance === 0) {
					// 	setError(
					// 		'You have 0 hours!',
					// 		"To book a session, click on 'Buy Hours' and purchase a package."
					// 	);
					// }

					if (location.state) {
						if (location.state.success) {
							setSuccess(
								location.state.success.title,
								location.state.success.message
							);
						}
						if (location.state.error) {
							setError(
								location.state.error.title,
								location.state.error.message
							);
						}
						if (
							location.state.pickedTutorHash &&
							location.state.pickedTutorHash.length > 0
						) {
							setPickedTutorHash(location.state.pickedTutorHash);

							if (
								!studentData.tutors.includes(location.state.pickedTutorHash)
							) {
								axios
									.post(
										`${process.env.REACT_APP_NEW_API_ENDPOINT}/general/connect-tutor`,
										{
											student_id: auth.userHash,
											tutor_id: location.state.pickedTutorHash,
										}
									)
									.then((result) => {
										history.push({
											pathname: '/student-dashboard',
											state: {},
										});
									});

								axios.post(
									`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/connect`,
									{
										studentParam: auth.userHash,
										tutorParam: location.state.pickedTutorHash,
									}
								);
							}
						}
					}
				})
				.catch(() =>
					setAlert(
						<Failure
							title="Oops!"
							message="Something happened. Try again or contact us."
						/>
					)
				);
		}
		// eslint-disable-next-line
	}, [auth?.userHash, location?.state?.id]);

	const setError = (title, message) => {
		setErrorTitle(title);
		setErrorMessage(message);
	};

	const setSuccess = (title, message) => {
		setSuccessTitle(title);
		setSuccessMessage(message);
	};

	const handleSuccessfulBook = async (sessionTime, tutorHash, helpText) => {
		setLoading(<LoadingButton />);
		try {
			const booking = await axios.put(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/bookings`,
				{
					studentParam: whichID(),
					tutorParam: tutorHash,
					requestedTime: sessionTime,
					helpText: helpText,
				}
			);

			if (booking.data.statusCode === 200) {
				if (booking.data.message === 'Requested') {
					toast.success('Successfully requested booking a tutoring session');
					history.push('/student-dashboard', {
						success: {
							title: 'Requested Booking!',
							message: 'Successfully requested booking a tutoring session',
						},
					});
				} else {
					toast.success('You successfully booked a new tutoring session');
					history.push('/student-dashboard', {
						success: {
							title: 'Successful Book!',
							message: 'You successfully booked a new tutoring session',
						},
					});
				}
				setError('', '');
			} else {
				setLoading(<LoadingButton />);
				const errMessage =
					booking?.data?.message ||
					'Unable to book, please try again or contact us';
				toast.error(errMessage);

				history.push('/student-dashboard', {
					error: {
						title: 'Oops! Something went wrong.',
						message: errMessage,
					},
				});
				setSuccess('', '');
			}
		} catch (err) {
			toast.error('Unable to book, please try again or contact us');
			setError(
				'Oops! Something went wrong.',
				'Unable to book, please try again or contact us'
			);
		}
		setLoading(<NavLogo />);
	};

	const handleTrialExpired = () => {
		setIsTrialExpired(true);
		setShowSubscriptionPurchase(true);
	};

	return (
		<div>
			<Nav
				logo={loading}
				settings="/student-settings"
				isExpired={isTrialExpired}
			/>
			<br />
			{alert}
			<div className="dashboard">
				<Success title={successTitle} message={successMessage} />
				<Failure title={errorTitle} message={errorMessage} />

				<Row>
					<Col lg={8} className={'ipad-sessions-container'}>
						<div className="section-box-container my-tutor-videos overflow-hidden">
							<div className="section-header">
								<span
									className={classnames('tab', {
										active: activeTab === 'tutoring',
									})}
									onClick={() => setActiveTab('tutoring')}
								>
									Tutors
								</span>
								<span
									className={classnames('tab', {
										active: activeTab === 'videos',
									})}
									onClick={() => setActiveTab('videos')}
								>
									Videos
								</span>
							</div>
							{activeTab === 'tutoring' ? (
								<MyTutorVideos
									handleSuccessfulBook={handleSuccessfulBook}
									profile={studentProfile}
									pickedTutorHash={pickedTutorHash}
									studentBalance={balance}
								/>
							) : (
								<MyConversationVideos profile={studentProfile} />
							)}
						</div>
					</Col>
					<Col lg={4} className={'ipad-dashboard'}>
						<AIChatConversations />
					</Col>
				</Row>
			</div>

			{/* {showIntroVideo && (
				<ModalIntroVideo show={true} onClose={() => handleIntroVideo(false)} />
			)} */}

			{/* {showIntroTrial && (
				<ModalIntroTrial show={true} onClose={() => setShowIntroTrial(false)} />
			)} */}

			{showSubscriptionPurchase && (
				<ModalSubscription onClose={() => setShowSubscriptionPurchase(false)} />
			)}
		</div>
	);
}

export default StudentDashboard;
