import React, { useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import DetailedTutor from './DetailedTutor';
import TutorAssign from './TutorAssign';

function Tutor(props) {
	const { tutor } = props;
	const [showEditModal, setShowEditModal] = useState(false);
	const [showAssignModal, setShowAssignModal] = useState(false);
	const { name, email, phoneNumber, balance, ansariCertified, id } = tutor;

	const handleEditModal = () => {
		setShowEditModal(!showEditModal);
	};

	const handleAssignModal = () => {
		setShowAssignModal(!showAssignModal);
	};

	return (
		<>
			{showEditModal && (
				<Modal size="lg" show={true} onHide={handleEditModal}>
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body>
						<DetailedTutor id={id} />
					</Modal.Body>
				</Modal>
			)}

			{showAssignModal && (
				<Modal size="lg" show={true} onHide={handleAssignModal}>
					<Modal.Header title="Assign Highschool" closeButton>
						<Modal.Title>Assign Highschool</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<TutorAssign id={id} />
					</Modal.Body>
				</Modal>
			)}

			<tr>
				<td>{name}</td>
				<td>{email}</td>
				<td>{phoneNumber}</td>
				<td>{balance}</td>
				<td>
					{ansariCertified ? (
						<i className="certified fas fa-check-circle"></i>
					) : (
						<i className="not-certified fas fa-times-circle"></i>
					)}
				</td>
				<td>
					<button className="btn btn-primary btn-sm" onClick={handleEditModal}>
						Edit
					</button>
					<button
						className="btn btn-primary btn-sm"
						onClick={handleAssignModal}
					>
						Assign
					</button>
				</td>
			</tr>
		</>
	);
}

export default Tutor;
