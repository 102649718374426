import React, { useState } from 'react';
import { ultimateConverter } from '../../tools/timeConverter';
import DetailedSession from './DetailedSession';
import Modal from 'react-bootstrap/Modal';

function Session(props) {
	const [show, setShow] = useState(false);

	const {
		studentName,
		tutorName,
		when,
		id,
		cancelled,
		completed,
		studentMiss,
		tutorMiss,
	} = props.session;

	// Converting to human readable time
	var whenFormatted = ultimateConverter(when);

	// Completed?

	function status() {
		if (completed) {
			return <i className="session-done fas fa-check-circle"></i>;
		} else if (cancelled || studentMiss || tutorMiss) {
			return <i className="session-cancelled fas fa-times-circle"></i>;
		} else {
			return <i className="session-pending fas fa-check-circle"></i>;
		}
	}

	function showSession() {
		setShow(true);
	}

	function handleClose() {
		setShow(false);
	}

	return (
		<div>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<DetailedSession id={id} />
				</Modal.Body>
			</Modal>
			<div className="a-data" onClick={showSession}>
				<div className="row">
					<div className="col-3">{whenFormatted}</div>
					<div className="col-3">{studentName}</div>
					<div className="col-3">{tutorName}</div>
					<div className="col-3">{status()}</div>
				</div>
			</div>
		</div>
	);
}

export default Session;
