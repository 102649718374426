import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Failure } from '../../alerts/Alerts';

// TOTAL REVENUE NOT IN USE BECAUSE SKEWED AS OF NOW DUE TO V2 LAUNCH
// function Revenue() {
// 	const [alert, setAlert] = useState();
// 	const [revenue, setRevenue] = useState(0);

// 	// Retrieve payments
// 	useEffect(() => {
// 		axios
// 			.get(
// 				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/logs/`,
// 				{ params: { logType: 'PAYMENTS' } }
// 			)
// 			.then((res) => {
// 				var total = 0;
// 				let payments = res.data.Items;
// 				for (let i = 0; i < payments.length; i++) {
// 					let logType = payments[i].logType;
// 					if (logType === 'FOUR') {
// 						total += 160;
// 					}
// 					if (logType === 'FIFTEEN') {
// 						total += 540;
// 					}
// 					if (logType === 'THIRTY') {
// 						total += 990;
// 					}
// 				}
// 				setRevenue(total);
// 			})
// 			.catch((err) =>
// 				setAlert(<Failure title="Oops!" message={err.message} />)
// 			);
// 	}, []);

// 	return (
// 		<div className="col-3">
// 			<div class="analytic-box">
// 				{alert}
// 				<p>
// 					<i class="green fad fa-sack"></i> Total Revenue
// 				</p>

// 				<hr />
// 				<h4>${revenue}</h4>
// 			</div>
// 		</div>
// 	);
// }

export function Sessions(props) {
	const { col } = props;
	const [alert, setAlert] = useState();

	const [sessionCount, setSessionCount] = useState(0);

	useEffect(() => {
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/sessions/`
			)
			.then((res) => {
				setSessionCount(res.data.Items.length);
			})
			.catch((err) =>
				setAlert(<Failure title="Oops!" message={err.message} />)
			);
	}, []);

	return (
		<div className={col ? `col-${col}` : 'col-3'}>
			<div class="analytic-box">
				{alert}
				<p className="txt-value">
					<span>{sessionCount}</span>
				</p>
				<p className="txt-title"> Total Sessions</p>
			</div>
		</div>
	);
}

export function Students(props) {
	const { col } = props;
	const [alert, setAlert] = useState();

	const [studentsCount, setStudentsCount] = useState([]);

	useEffect(() => {
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/students`
			)
			.then((res) => {
				setStudentsCount(res.data.Items.length);
			})
			.catch((err) =>
				setAlert(<Failure title="Oops!" message={err.message} />)
			);
	}, []);

	return (
		<div className={col ? `col-${col}` : 'col-3'}>
			<div class="analytic-box">
				{alert}
				<p className="txt-value">
					<span>{studentsCount}</span>
				</p>
				<p className="txt-title">Total Students</p>
			</div>
		</div>
	);
}

export function Tutors(props) {
	const { col } = props;
	const [alert, setAlert] = useState();

	const [tutorsCount, setTutorsCount] = useState([]);

	useEffect(() => {
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/tutors`
			)
			.then((res) => {
				var certifiedTutors = res.data.Items.filter(function (tutor) {
					return tutor.ansariCertified === true;
				});
				// make sure tutors are available
				var availableTutors = certifiedTutors.filter(function (tutor) {
					return tutor.availability.length > 1;
				});
				setTutorsCount(availableTutors.length);
			})
			.catch(() =>
				setAlert(<Failure title="oops!" message="Couldn't retrieve tutors" />)
			);
	}, []);

	return (
		<div className={col ? `col-${col}` : 'col-3'}>
			<div class="analytic-box">
				{alert}
				<p className="txt-value">
					<span>{tutorsCount}</span>
				</p>
				<p className="txt-title">Active Tutors</p>
			</div>
		</div>
	);
}

export default function Totals() {
	return (
		<div class="row">
			<Sessions col={4} />
			<Students col={4} />
			<Tutors col={4} />
		</div>
	);
}
