import React from 'react';
import Footer from '../../components/home/Footer';
import Header from '../../components/home/Header';

function NoMatch() {
	return (
		<div>
			<Header />

			<div className="no-match">
				<img
					className="error-image"
					src="/images/holding-books.png"
					alt="error"
				/>
				<h1> Uh Oh!</h1>
				<br />
				<h4> This page doesn't exist </h4>
				<br />
				<a href="/">
					<button className="error-button">Go Back Home</button>
				</a>
			</div>
			<Footer />
		</div>
	);
}

export default NoMatch;
