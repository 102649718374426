/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
// import { Success, Failure } from '../../components/alerts/Alerts';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import { utcStringToLocalDate } from '../../tools/timeConverter';
import { useLocation, useHistory } from 'react-router-dom';
import Nav from '../../components/dashboard/Nav';
import MyStudents from '../../components/dashboard/MyStudents';
import Sessions from '../../components/dashboard/Sessions';
import Cash from '../../components/buy/Cash';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import NavLogo from '../../components/NavLogo';
import ModalIntroVideo from '../../components/dashboard/ModalIntroVideo';
import { constantKeys } from '../../tools/constant';

function TutorDashboard() {
	const history = useHistory();
	const location = useLocation();

	const auth = useContext(AuthContext);

	const [upcomingSessions, setUpcomingSessions] = useState([]);
	const [pastSessions, setPastSessions] = useState([]);
	const [students, setStudents] = useState([]);
	const [balance, setBalance] = useState(0);
	const [currentTutorProfile, setCurrentTutorProfile] = useState(null);

	const [showIntroVideo, setShowIntroVideo] = useState(false);

	const handleIntroVideo = (action) => {
		setShowIntroVideo(action);
		if (action === false) {
			localStorage.removeItem(constantKeys.IS_NEW_TUTOR);
		}
	};

	useEffect(() => {
		const isNewTutor = localStorage.getItem(constantKeys.IS_NEW_TUTOR);
		if (isNewTutor) {
			handleIntroVideo(true);
		}
	}, []);

	useEffect(() => {
		// Check to make sure user has been authenticated and is a tutor
		const { role, userHash } = auth;
		const { tutorId } = location?.state || {};

		var tutorProfileId = null;
		if (role === 'admin') tutorProfileId = tutorId;
		if (role === 'tutor') tutorProfileId = userHash;

		if (tutorProfileId && (role === 'tutor' || role === 'admin')) {
			axios
				.get(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/tutors`,
					{
						params: {
							tutorParam: tutorProfileId,
						},
					}
				)
				.then((res) => {
					const tutorData = res.data;
					setCurrentTutorProfile(tutorData);
					let newUpcomingSessions = [...tutorData.upcomingSessions];
					let newPastSessions = [...tutorData.pastSessions];
					// Check for sessions that have passed and switch them from upcoming to past sessions
					for (let i = 0; i < tutorData.upcomingSessions.length; i++) {
						const sessionId = tutorData.upcomingSessions[i];
						if (sessionId.length > 0) {
							axios
								.get(
									`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/sessions`,
									{
										params: {
											sessionParam: sessionId,
										},
									}
								)
								.then((res) => {
									const sessionDate = utcStringToLocalDate(res.data.when);
									const curDate = new Date();

									if (curDate > sessionDate) {
										const index = newUpcomingSessions.indexOf(sessionId);
										if (index > -1) {
											newUpcomingSessions.splice(index, 1);
											newPastSessions.push(sessionId);
										}
									}
								});
						}
					}

					setUpcomingSessions(newUpcomingSessions);
					setPastSessions(newPastSessions);
					setStudents(tutorData.students);
					setBalance(tutorData.balance);

					const { ansariCertified, availability, tncAgreed } = tutorData;
					if (!ansariCertified) {
						toast.error(
							'Please complete your profile and wait for profile approval!'
						);
						history.push('/tutor-settings');
						return;
					}
					if (availability?.length <= 1) {
						toast.error('Please set your availability to continue');
						history.push('/tutor-availability');
						return;
					}
					if (!tncAgreed) {
						toast.error('Please agree to the terms and conditions to continue');
						history.push('/tutor-tnc');
						return;
					}

					if (location.state) {
						if (location.state.success) {
							const { title, message } = location.state.success;
							toast.success(title + '\n' + message);
						}
						if (location.state.error) {
							const { title, message } = location.state.error;
							toast.error(title + '\n' + message);
						}
						// if (location.state.isLogin) {
						//   window.location.reload()
						// }
					}
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth, location.state]);

	const handleCashout = async () => {
		try {
			const cashout = await axios.post(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/stripe/cashout`,
				{
					tutorParam: auth.userHash,
				}
			);

			if (cashout.data.responseCode === 200) {
				toast.success('Money has been transferred to your account');
				history.push('/tutor-dashboard', {
					success: {
						title: 'Successful cash out!',
						message: 'Money has been transferred to your account',
					},
				});
			} else {
				toast.error('Unable to cash out, please try again or contact us');
				history.push('/tutor-dashboard', {
					error: {
						title: 'Oops! Something went wrong.',
						message: 'Unable to cash out, please try again or contact us',
					},
				});
			}
			window.location.reload();
		} catch (err) {}
	};

	return (
		<div>
			<Nav logo={<NavLogo />} settings="/tutor-settings" />

			<div className="dashboard">
				<Row>
					<Col lg={6}>
						<div className="col-sm-12 col-md-12 col-lg-12">
							<div className="balance-container general-container">
								<h3 className="balance-title">My Balance</h3>
								<h2 className="balance-amount">${balance}</h2>
								<Cash
									profile={currentTutorProfile}
									balance={balance}
									handleCashout={handleCashout}
								/>
							</div>
						</div>

						<MyStudents studentHashes={students} height={'462px'} />
					</Col>

					<Col lg={6}>
						<Sessions
							title="Upcoming Sessions"
							action="Join"
							className="general-container sessions-container"
							sessionHashes={upcomingSessions}
							isPast={false}
						/>

						<Sessions
							title="Past Sessions"
							action="Mark"
							className="general-container past-sessions-container"
							sessionHashes={pastSessions}
							isPast={true}
						/>
					</Col>
				</Row>
			</div>

			{showIntroVideo && (
				<ModalIntroVideo show={true} onClose={() => handleIntroVideo(false)} />
			)}
		</div>
	);
}

export default TutorDashboard;
