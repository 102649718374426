import React from 'react';
import Header from '../../components/home/Header';
import Footer from '../../components/home/Footer';

function Terms() {
	return (
		<div>
			<Header />

			<div className="terms-text">
				<div className="terms-title">
					<h1>Terms and Conditions</h1>
				</div>
				<div className="terms-content">
					<p>
						These terms and conditions ("Agreement") set forth the general terms
						and conditions of your use of the peerlinc.com website ("Website" or
						"Service") and any of its related products and services
						(collectively, "Services"). This Agreement is legally binding
						between you ("User", "you" or "your") and PeerLinc, LLC ("PeerLinc,
						LLC", "we", "us" or "our"). If you are entering into this agreement
						on behalf of a business or other legal entity, you represent that
						you have the authority to bind such an entity to this agreement, in
						which case the terms "User", "you" or "your" shall refer to such
						entity. If you do not have such authority, or if you do not agree
						with the terms of this agreement, you must not accept this agreement
						and may not access and use the Website and Services. By accessing
						and using the Website and Services, you acknowledge that you have
						read, understood, and agree to be bound by the terms of this
						Agreement. You acknowledge that this Agreement is a contract between
						you and PeerLinc, LLC, even though it is electronic and is not
						physically signed by you, and it governs your use of the Website and
						Services.
					</p>

					<h4>Students</h4>
					<p>
						Students on PeerLinc are defined as the students who receive
						tutoring services via the PeerLinc platform. Students agree to
						conduct themselves in a professional manner at all times when
						receiving tutoring services. Any students found to be acting in a
						manner deemed unprofessional will be removed from the PeeLinc
						platform.
					</p>

					<h4>Tutors</h4>
					<p>
						Tutors are independent contractors who provide tutoring support
						through the PeerLinc platform. PeerLinc makes no guarantees about
						the content of the tutoring sessions between tutors and students on
						the platform. Tutors agree to use the highest level of
						professionalism at all times while on the PeerLinc platform.
						Students and parents should bring any issues to the attention of
						PeerLinc, should they arise. Any tutors found to be acting in a
						manner deemed unprofessional will be removed from the PeeLinc
						platform.
					</p>

					<h4>Accounts and Membership</h4>

					<p>
						If you create an account on the Website, you are responsible for
						maintaining the security of your account and you are fully
						responsible for all activities that occur under the account and any
						other actions taken in connection with it. We may, but have no
						obligation to, monitor and review new accounts before you may sign
						in and start using the Services. Providing false contact information
						of any kind may result in the termination of your account. You must
						immediately notify us of any unauthorized uses of your account or
						any other breaches of security. We will not be liable for any acts
						or omissions by you, including any damages of any kind incurred as a
						result of such acts or omissions. We may suspend, disable, or delete
						your account (or any part thereof) if we determine that you have
						violated any provision of this Agreement or that your conduct or
						content would tend to damage our reputation and goodwill. If we
						delete your account for the foregoing reasons, you may not
						re-register for our Services. We may block your email address and
						Internet protocol address to prevent further registration.
					</p>

					<h4>Billing and Payments</h4>

					<p>
						You shall pay all fees or charges to your account in accordance with
						the fees, charges, and billing terms in effect at the time a fee or
						charge is due and payable. Where Services are offered on a free
						trial basis, payment may be required after the free trial period
						ends, and not when you enter your billing details (which may be
						required prior to the commencement of the free trial period). If
						auto-renewal is enabled for the Services you have subscribed for,
						you will be charged automatically in accordance with the term you
						selected. Sensitive and private data exchange happens over a SSL
						secured communication channel and is encrypted and protected with
						digital signatures, and the Website and Services are also in
						compliance with PCI vulnerability standards in order to create as
						secure of an environment as possible for Users. Scans for malware
						are performed on a regular basis for additional security and
						protection. If, in our judgment, your purchase constitutes a
						high-risk transaction, we will require you to provide us with a copy
						of your valid government-issued photo identification, and possibly a
						copy of a recent bank statement for the credit or debit card used
						for the purchase. We reserve the right to change products and
						product pricing at any time. We also reserve the right to refuse any
						order you place with us. We may, in our sole discretion, limit or
						cancel quantities purchased per person, per household or per order.
						These restrictions may include orders placed by or under the same
						customer account, the same credit card, and/or orders that use the
						same billing and/or shipping address. In the event that we make a
						change to or cancel an order, we may attempt to notify you by
						contacting the e-mail and/or billing address/phone number provided
						at the time the order was made.
					</p>

					<h4>Accuracy of Information</h4>

					<p>
						Occasionally there may be information on the Website that contains
						typographical errors, inaccuracies or omissions that may relate to
						product descriptions, pricing, availability, promotions and offers.
						We reserve the right to correct any errors, inaccuracies or
						omissions, and to change or update information or cancel orders if
						any information on the Website or Services is inaccurate at any time
						without prior notice (including after you have submitted your
						order). We undertake no obligation to update, amend or clarify
						information on the Website including, without limitation, pricing
						information, except as required by law. No specified update or
						refresh date applied on the Website should be taken to indicate that
						all information on the Website or Services has been modified or
						updated.
					</p>

					<h4>Links to other resources</h4>

					<p>
						Although the Website and Services may link to other resources (such
						as websites, mobile applications, etc.), we are not, directly or
						indirectly, implying any approval, association, sponsorship,
						endorsement, or affiliation with any linked resource, unless
						specifically stated herein. We are not responsible for examining or
						evaluating, and we do not warrant the offerings of, any businesses
						or individuals or the content of their resources. We do not assume
						any responsibility or liability for the actions, products, services,
						and content of any other third parties. You should carefully review
						the legal statements and other conditions of use of any resource
						which you access through a link on the Website. Your linking to any
						other off-site resources is at your own risk.
					</p>

					<h4>Prohibited Uses</h4>

					<p>
						In addition to other terms as set forth in the Agreement, you are
						prohibited from using the Website and Services or Content: (a) for
						any unlawful purpose; (b) to solicit others to perform or
						participate in any unlawful acts; (c) to violate any international,
						federal, provincial or state regulations, rules, laws, or local
						ordinances; (d) to infringe upon or violate our intellectual
						property rights or the intellectual property rights of others; (e)
						to harass, abuse, insult, harm, defame, slander, disparage,
						intimidate, or discriminate based on gender, sexual orientation,
						religion, ethnicity, race, age, national origin, or disability; (f)
						to submit false or misleading information; (g) to upload or transmit
						viruses or any other type of malicious code that will or may be used
						in any way that will affect the functionality or operation of the
						Website and Services, third party products and services, or the
						Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or
						scrape; (i) for any obscene or immoral purpose; or (j) to interfere
						with or circumvent the security features of the Website and
						Services, third party products and services, or the Internet. We
						reserve the right to terminate your use of the Website and Services
						for violating any of the prohibited uses.
					</p>

					<h4>Intellectual Property Rights</h4>

					<p>
						"Intellectual Property Rights" means all present and future rights
						conferred by statute, common law or equity in or in relation to any
						copyright and related rights, trademarks, designs, patents,
						inventions, goodwill and the right to sue for passing off, rights to
						inventions, rights to use, and all other intellectual property
						rights, in each case whether registered or unregistered and
						including all applications and rights to apply for and be granted,
						rights to claim priority from, such rights and all similar or
						equivalent rights or forms of protection and any other results of
						intellectual activity which subsist or will subsist now or in the
						future in any part of the world. This Agreement does not transfer to
						you any intellectual property owned by PeerLinc, LLC or third
						parties, and all rights, titles, and interests in and to such
						property will remain (as between the parties) solely with PeerLinc,
						LLC. All trademarks, service marks, graphics and logos used in
						connection with the Website and Services, are trademarks or
						registered trademarks of PeerLinc, LLC or its licensors. Other
						trademarks, service marks, graphics and logos used in connection
						with the Website and Services may be the trademarks of other third
						parties. Your use of the Website and Services grants you no right or
						license to reproduce or otherwise use any of PeerLinc, LLC or third
						party trademarks.
					</p>

					<h4>Limitation of Liability</h4>

					<p>
						To the fullest extent permitted by applicable law, in no event will
						PeerLinc, LLC, its affiliates, directors, officers, employees,
						agents, suppliers or licensors be liable to any person for any
						indirect, incidental, special, punitive, cover or consequential
						damages (including, without limitation, damages for lost profits,
						revenue, sales, goodwill, use of content, impact on business,
						business interruption, loss of anticipated savings, loss of business
						opportunity) however caused, under any theory of liability,
						including, without limitation, contract, tort, warranty, breach of
						statutory duty, negligence or otherwise, even if the liable party
						has been advised as to the possibility of such damages or could have
						foreseen such damages. To the maximum extent permitted by applicable
						law, the aggregate liability of PeerLinc, LLC and its affiliates,
						officers, employees, agents, suppliers and licensors relating to the
						services will be limited to an amount no greater than one dollar or
						any amounts actually paid in cash by you to PeerLinc, LLC for the
						prior one month period prior to the first event or occurrence giving
						rise to such liability. The limitations and exclusions also apply if
						this remedy does not fully compensate you for any losses or fails of
						its essential purpose.
					</p>

					<h4>Indemnification</h4>

					<p>
						You agree to indemnify and hold PeerLinc, LLC and its affiliates,
						directors, officers, employees, agents, suppliers and licensors
						harmless from and against any liabilities, losses, damages or costs,
						including reasonable attorneys&rsquo; fees, incurred in connection
						with or arising from any third party allegations, claims, actions,
						disputes, or demands asserted against any of them as a result of or
						relating to your Content, your use of the Website and Services or
						any willful misconduct on your part.
					</p>

					<h4>Severability</h4>

					<p>
						All rights and restrictions contained in this Agreement may be
						exercised and shall be applicable and binding only to the extent
						that they do not violate any applicable laws and are intended to be
						limited to the extent necessary so that they will not render this
						Agreement illegal, invalid or unenforceable. If any provision or
						portion of any provision of this Agreement shall be held to be
						illegal, invalid or unenforceable by a court of competent
						jurisdiction, it is the intention of the parties that the remaining
						provisions or portions thereof shall constitute their agreement with
						respect to the subject matter hereof, and all such remaining
						provisions or portions thereof shall remain in full force and
						effect.
					</p>

					<h4>Dispute Resolution</h4>

					<p>
						The formation, interpretation, and performance of this Agreement and
						any disputes arising out of it shall be governed by the substantive
						and procedural laws of Oregon, United States without regard to its
						rules on conflicts or choice of law and, to the extent applicable,
						the laws of United States. The exclusive jurisdiction and venue for
						actions related to the subject matter hereof shall be the courts
						located in Oregon, United States, and you hereby submit to the
						personal jurisdiction of such courts. You hereby waive any right to
						a jury trial in any proceeding arising out of or related to this
						Agreement. The United Nations Convention on Contracts for the
						International Sale of Goods does not apply to this Agreement.
					</p>

					<h4>Changes and Amendments</h4>

					<p>
						We reserve the right to modify this Agreement or its terms related
						to the Website and Services at any time at our discretion. When we
						do, we will revise the updated date at the bottom of this page. We
						may also provide notice to you in other ways at our discretion, such
						as through the contact information you have provided.
					</p>

					<p>
						An updated version of this Agreement will be effective immediately
						upon the posting of the revised Agreement unless otherwise
						specified. Your continued use of the Website and Services after the
						effective date of the revised Agreement (or such other act specified
						at that time) will constitute your consent to those changes.
					</p>

					<h4>Acceptance of Terms</h4>

					<p>
						You acknowledge that you have read this Agreement and agree to all
						its terms and conditions. By accessing and using the Website and
						Services you agree to be bound by this Agreement. If you do not
						agree to abide by the terms of this Agreement, you are not
						authorized to access or use the Website and Services.
					</p>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default Terms;
