import React, {useContext} from 'react'
import {Route} from 'react-router-dom'
import {AuthContext} from '../contexts/AuthContext'
import Login from '../components/auth/Login'
import {useHistory} from 'react-router-dom'

// Route that only authenticated users can access
function PrivateRoute({component: Component, ...rest}) {

    const history = useHistory()

    const auth = useContext(AuthContext)

    return (
        <Route {...rest} render={props => {
            if (auth.currentUser === null) {
                // Redirect to login page if user is not logged in
                history.push('/login')
                return <Login {...props} />
            } else {
                // Continue to desired page if user is logged in
                return <Component {...props} />
            }
        }} />
    )
}

export default PrivateRoute
