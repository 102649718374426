import React from 'react';
import { ValidationError } from '@statickit/react';
import Header from '../home/Header';
import Footer from '../home/Footer';
import './contact.css';

function ManuallyConfirm() {
	return (
		<div>
			<Header />
			<div className="center-it">
				<div className="contact-bug">
					<h1 className="contact-title">Request a manual confirmation</h1>

					<h4 className="contact-paragraph">
						We'll manually confirm you as fast as we can
					</h4>

					<form
						action="https://formspree.io/p/1579158750088920430/f/contactForm"
						method="POST"
					>
						<div>
							{/* TYPE */}
							<input
								type="text"
								name="type"
								className="contact-type"
								id="type"
								value="confirm"
								required
							/>

							{/* NAME */}

							<div>
								<input
									type="name"
									name="name"
									className="contact-input"
									id="name"
									placeholder="Your Name*"
									required
								/>
							</div>

							<ValidationError prefix="Name" field="name" />

							{/* EMAIL */}
							<div>
								<input
									id="email"
									type="email"
									name="email"
									className="contact-input"
									placeholder="Your Email*"
									required
								/>
							</div>

							<ValidationError prefix="Email" field="email" />

							{/* PHONE */}

							<input
								id="number"
								type="phone"
								name="number"
								className="contact-input"
								placeholder="Your Phone*"
								required
							/>

							<ValidationError prefix="Number" field="number" />
						</div>
						{/* BUTTON */}
						<button type="submit">Request</button>
					</form>
				</div>
			</div>

			<br />

			<Footer />
		</div>
	);
}

export default ManuallyConfirm;
