import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import PlayBtn from '../../Icons/playbtn.svg';
import './tutor-profile.css';

function Video(props) {
	const [viewVideo, setViewVideo] = useState(false);
	return (
		<div>
			<div className="tutor-image-container">
				<img className="tutor-image" src={props.image} alt="video" />
				<div className="tutor-video-play" onClick={() => setViewVideo(true)}>
					<img className="play-button" src={PlayBtn} alt=""></img>
				</div>
			</div>

			{/* Video popup */}

			{viewVideo && (
				<Modal show={true} onHide={() => setViewVideo(false)}>
					<Modal.Header closeButton>
						<h4>{props.name}</h4>
					</Modal.Header>
					<Modal.Body className="modal-body">
						<iframe
							title="vid"
							className="modal-video "
							width="420"
							height="315"
							src={props.youtubeLink}
						></iframe>
					</Modal.Body>
				</Modal>
			)}
		</div>
	);
}

export default Video;
