import React, { useState, useEffect } from 'react';
import { ultimateConverter } from '../../tools/timeConverter';

function Message(props) {
	const [messageFormatted, setMessage] = useState('');

	const { sender, sendee, timestamp, message } = props.message;

	useEffect(() => {
		if (message != null) {
			setMessage(message);
		}
	}, [message]);

	return (
		<div className="a-data">
			<div className="row">
				<div className="col-2">{sender}</div>
				<div className="col-2">{sendee}</div>
				<div className="col-2"> {ultimateConverter(timestamp)}</div>
				<div className="col-6">{messageFormatted}</div>
			</div>
		</div>
	);
}

export default Message;
