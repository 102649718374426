// Converts a string in %Y-%m-%d %H:%M:%S.%f format to local date
export const utcStringToLocalDate = (utcString) => {
	utcString = utcString.replace(' ', 'T');
	utcString += 'Z';
	const localDate = new Date(utcString);

	const timezoneOffset =
		localDate.getTimezoneOffset() -
		new Date(`1970-01-01T00:00:00.00000`).getTimezoneOffset();

    

	const localDateOffset = new Date(
		localDate.getTime() + timezoneOffset * 60000
	);


	return localDateOffset;
};

// Converts local date to %Y-%m-%d %H:%M:%S.%f format
export const localDateToUtcString = (localDate) => {
	// const timezoneOffset = localDate.getTimezoneOffset(); // offset in minutes
	const timezoneOffset = new Date(
		`1970-01-01T00:00:00.00000`
	).getTimezoneOffset();
	const utcDate = new Date(localDate.getTime() + timezoneOffset * 60000);

	const year = utcDate.getFullYear();

	// Add leading 0 and take last two characters, which removes the 0 from two digit numbers
	const month = ('0' + (utcDate.getMonth() + 1)).slice(-2);
	const day = ('0' + utcDate.getDate()).slice(-2);
	const hours = ('0' + utcDate.getHours()).slice(-2);
	const minutes = ('0' + utcDate.getMinutes()).slice(-2);

	const utcString = `${year}-${month}-${day} ${hours}:${minutes}:00.00000`;
	//console.log('DATE TO UTC', utcString);
	return utcString;
};

// Return array of available session dates that are not yet booked
const getAvailableSessionTimes = (availability, upcomingTimes) => {
	let [utcStartTime, numHours] = availability.split('|');
	utcStartTime = utcStartTime.replace(' ', 'T');
	utcStartTime += 'Z';
	const availableStartTime = new Date(utcStartTime);

	const bookStartTime = new Date(new Date().getTime());

	let totalAvailableSessionTimes = [];

	// Loop through the next 8 days that can be booked
	let numDays = 0;
	let d = 0;
	while (numDays < 8) {
		const bookDate = new Date(bookStartTime.getTime() + d * 24 * 3600000);

		if (bookDate.getDay() === availableStartTime.getDay()) {
			let availableSessionTimes = [];

			// Create all possible session times
			for (let t = 0; t < numHours; t++) {
				const sessionDate = new Date(
					bookDate.getFullYear(),
					bookDate.getMonth(),
					bookDate.getDate(),
					availableStartTime.getHours() + t,
					availableStartTime.getMinutes()
				);

				if (sessionDate.getTime() >= bookStartTime.getTime())
					availableSessionTimes.push(sessionDate);
			}

			// Search and remove already booked sessions
			for (let i = 0; i < upcomingTimes.length; i++) {
				const localDate = new Date(utcStringToLocalDate(upcomingTimes[i]));

				for (let j = 0; j < availableSessionTimes.length; j++) {
					if (localDate.getTime() === availableSessionTimes[j].getTime()) {
						availableSessionTimes.splice(j, 1);
						j--;
					}
				}
			}

			// Add available sessions to the total available sessions
			totalAvailableSessionTimes.push(...availableSessionTimes);

			if (availableSessionTimes.length > 0) numDays++;
		}

		d++;
	}

	return totalAvailableSessionTimes;
};

// Get available sessions for next 8 days
export const getAllAvailableSessionTimes = (availableTimes, upcomingTimes) => {
	let totalAvailableSessionTimes = [];

	for (let t = 0; t < availableTimes.length; t++) {
		if (availableTimes[t].length)
			totalAvailableSessionTimes.push(
				...getAvailableSessionTimes(availableTimes[t], upcomingTimes)
			);
	}

	totalAvailableSessionTimes = totalAvailableSessionTimes.sort(
		(aDate, bDate) => aDate - bDate
	);

	let availableSessionTimes = [];
	let numDays = 0;
	let prevDate = 0;
	for (let t = 0; t < totalAvailableSessionTimes.length; t++) {
		if (totalAvailableSessionTimes[t].getDate() !== prevDate) {
			numDays++;
			prevDate = totalAvailableSessionTimes[t].getDate();
		}
		if (numDays > 8) break;
		else availableSessionTimes.push(totalAvailableSessionTimes[t]);
	}

	return availableSessionTimes;
};

// Convert date object to formatted month day
export const formattedSessionDay = (day) => {
	const monthNames = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	return `${monthNames[day.getMonth()]} ${day.getDate()}`;
};

// Convert date object to session time hour-hour
export const formattedSessionTime = (time) => {
	let startHour = time.getHours();
	let startMinute = time.getMinutes();
	let period = startHour >= 12 ? 'pm' : 'am';

	if (startHour > 12) startHour = startHour % 12;
	if (startHour === 0) startHour = 12;

	startMinute = ('0' + startMinute).slice(-2);

	return `${startHour}:${startMinute} ${period}`;
};

function amOrpm(whenHour, whenMinute) {
	if (whenHour < 12) {
		if (whenHour === 0 && whenMinute === 0) {
			return '12:00AM';
		} else if (whenHour === 0 && whenMinute === 30) {
			return '12:30AM';
		} else if (whenMinute === 0) {
			return `${whenHour}:${whenMinute}0AM`;
		}
		return `${whenHour}:${whenMinute}AM`;
	} else {
		if (whenHour === 12 && whenMinute === 0) {
			return '12:00PM';
		} else if (whenHour === 12 && whenMinute === 30) {
			return '12:30PM';
		} else if (whenMinute === 0) {
			return `${whenHour - 12}:${whenMinute}0PM`;
		}
		return `${whenHour - 12}:${whenMinute}PM`;
	}
}

// Ultimate converter from 2020-08-24 07:00:00.00000 to 8/24, 7:00AM
export const ultimateConverter = (when) => {
	let whenObject = utcStringToLocalDate(when);

	let whenMonth = whenObject.getMonth() + 1;
	let whenDay = whenObject.getDate();
	let whenHour = whenObject.getHours();
	let whenMinute = whenObject.getMinutes();

	return `${whenMonth}/${whenDay}, ${amOrpm(whenHour, whenMinute)}`;
};

export const dateToMonthDay = (when) => {
	if (when != null) {
		let whenObject = utcStringToLocalDate(when);

		let whenMonth = whenObject.getMonth() + 1;
		let whenDay = whenObject.getDate();

		return `${whenMonth}/${whenDay}`;
	}
};
