import React, { useState } from 'react';
import Header from '../../components/admin/home/Header';
import AdminSubscriptions from './Subscriptions';
import AdminPayments from './Payments';

function AdminTransactions() {
	const [isSubscription, setSubscription] = useState(true);

	return (
		<div className="page-subscriptions">
			<Header />
			<div className="admin-container">
				<div className="row">
					<div className="col-md-8">
						<h2 className="page-heading-txt">Transactions</h2>
					</div>
					<div className="col-md-4">
						<div className="switch-type">
							<span
								className={isSubscription ? 'active' : ''}
								onClick={() => setSubscription(true)}
							>
								Subscriptions
							</span>
							<span
								className={!isSubscription ? 'active' : ''}
								onClick={() => setSubscription(false)}
							>
								Cashouts
							</span>
						</div>
					</div>
				</div>

				{isSubscription ? <AdminSubscriptions /> : <AdminPayments />}
			</div>
		</div>
	);
}

export default AdminTransactions;
