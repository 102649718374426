import React from 'react';
import Footer from '../home/Footer';
import Header from '../home/Header';

function Success() {
	return (
		<div>
			<Header />

			<div className="success-page">
				<h1 className="success-title"> Good to Go!</h1>

				<p className="success-text"> We'll be in touch soon </p>
			</div>

			<Footer />
		</div>
	);
}

export default Success;
