import React from 'react';
import moment from 'moment';

function Subscriptions(props) {
	const { studentName } = props;
	const { studentId, logType, timestamp } = props.payment;
	//const { tutorId, studentId, logType, timestamp, chargeId, subscriptionId} = props.payment;

	function setType() {
		if (logType.includes('SUBSCRIPTION')) {
			var logInfo = logType?.split('##')?.[0]?.replace(/_/g, ' ') || '';
			logInfo = logInfo.replace(/SUBSCRIPTION/g, '').toLowerCase();
			if (logInfo.includes('credit')) return 'session purchased';
			if (logInfo.includes('trial')) return '-';
			return `${logInfo}`;
		}
	}

	function getClassName(type) {
		if (type.includes('schedule')) {
			return `payment-badge payment-badge-warning`;
		}
		if (type.includes('cancel')) {
			return `payment-badge payment-badge-danger`;
		}
		if (type.includes('renew')) {
			return `payment-badge payment-badge-success-2`;
		}
		if (type.includes('trial') || type.includes('purchased')) {
			return `payment-badge payment-badge-success`;
		}
		return `payment-badge payment-badge-info`;
	}

	function setAmount() {
		if (logType.includes('SUBSCRIPTION')) {
			if (
				logType.includes('SUBSCRIPTION_PURCHASED') ||
				logType.includes('SUBSCRIPTION_CREDIT_PURCHASED') ||
				logType.includes('SUBSCRIPTION_RENEWED')
			) {
				var logInfo = logType?.split('##')?.[1]?.split('_')?.[1] || '';
				if (logInfo > 0) return logInfo ? `$${logInfo}` : '';
				return '';
			}
		}
	}

	// function setExtraInfo() {
	//     if(subscriptionId){
	//         return `Subscription Id: ${subscriptionId}`
	//     }
	//     if(chargeId){
	//         return `Charge Id: ${chargeId}`
	//     }
	// }

	var offset = moment().utcOffset();
	return (
		<div className="item-data">
			<div className="row">
				<div className="col-4">
					{studentName && <p>{studentName}</p>}
					<p className="explain-text">{studentId}</p>
					{/* <p className="explain-text">{setExtraInfo()}</p> */}
				</div>
				<div className="col-3">
					{moment
						.utc(timestamp)
						.utcOffset(offset)
						.format('MMM, DD YYYY | hh:mm A')}
				</div>
				<div className="col-3">{setAmount()}</div>
				<div className={`col-2`}>
					<span className={getClassName(setType())}>{setType()}</span>
				</div>
			</div>
		</div>
	);
}

export default Subscriptions;
