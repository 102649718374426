import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import SaveButton from '../settings/SaveButton';
import Input from '../settings/Input';
import LoadingButton from '../loading/LoadingButton';
import Session from './Session';
import { Failure, Success } from '../alerts/Alerts';
import UserTransactions from './UserTransactions';
import { utcTimeFormat } from '../../tools/utils';
import { Button } from 'react-bootstrap';
import './index.scss';

function DetailedStudent(props) {
	const [alert, setAlert] = useState();
	const [highschools, setHighschools] = useState([]);

	// Load State
	const [loadState, setLoadState] = useState('Update');
	const [isLoading, setLoading] = useState(false);
	const [loadingUpdate, setLoadingUpdate] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);

	// Show and Set User Data
	const [studentInfo, setStudentInfo] = useState(null);
	const [created, setCreated] = useState('');
	const [date, setDate] = useState('');
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [parentEmail, setParentEmail] = useState('');
	const [upcomingSessionsIds, setUpcomingSessionsIds] = useState([]);
	const [pastSessionsIds, setPastSessionsIds] = useState([]);
	const [client, setClient] = useState(false);
	const [studentHighschoolId, setStudentHighschoolId] = useState('');
	const [studentType, setStudentType] = useState('');

	// Access id value
	const history = useHistory();
	const id = props.id;

	// Show Student Data
	useEffect(() => {
		fetchStudents();
		fetchHighSchools();
		// eslint-disable-next-line
	}, [id]);

	const fetchHighSchools = () => {
		axios
			.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_highschool`,
				{
					action: 'list_highschool',
				}
			)
			.then((res) => {
				var list = res?.data?.data;
				setHighschools(list);
			});
	};

	const fetchStudents = async () => {
		setLoading(true);
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/students/`,
				{
					params: {
						studentParam: id,
					},
				}
			)
			.then((res) => {
				const studentData = res.data;

				// Date Created conversion
				let createDate = new Date(studentData.created);
				let createDateYear = createDate.getFullYear();
				let createDateMonth = createDate.getMonth() + 1;
				let createDateDay = createDate.getDate();
				let formattedCreateDate = `${createDateMonth}/${createDateDay}/${createDateYear}`;

				// Last seen date conversion
				moment().format();
				let seenDate = new Date(studentData.lastVisited);
				let formattedSeenDate = moment(seenDate).fromNow();

				// Set the values for user ingo
				setStudentInfo(studentData);
				setCreated(formattedCreateDate);
				setDate(formattedSeenDate);
				setName(studentData.name);
				setPhone(studentData.phoneNumber);
				setEmail(studentData.email);
				setParentEmail(studentData.parentEmail);
				setBalance(studentData.balance);
				setUpcomingSessionsIds(studentData.upcomingSessions);
				setPastSessionsIds(studentData.pastSessions);
				setClient(studentData.firstPackagePurchased);
				setStudentHighschoolId(studentData?.highschoolId || null);
				setStudentType(studentData?.studentType || null);
			})
			.catch((err) => setAlert(<Failure title="oops!" message={err.message} />))
			.finally(() => {
				setLoading(false);
			});
	};

	const updateHighschool = async (e) => {
		try {
			const highschoolId = e.target.value;
			setLoadingUpdate(true);
			await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_highschool_students`,
				{
					action: 'update_highschool_student',
					highschoolId: highschoolId,
					studentId: id,
				}
			);
		} catch (err) {
			console.log(err);
		} finally {
			setLoadingUpdate(false);
		}
	};

	const updateStudentType = async (e) => {
		try {
			const student_type = e.target.value;
			setLoadingUpdate(true);
			await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/update/student`,
				{
					action: 'update_student_type',
					studentId: id,
					student_type: student_type,
				}
			);
		} catch (err) {
			console.log(err);
		} finally {
			setLoadingUpdate(false);
		}
	};

	// To Change the Student's Balance
	const [balance, setBalance] = useState(0);

	function changeBalance(event) {
		setBalance(Number(event.target.value));
	}

	function updateBalance() {
		setLoadState(<LoadingButton />);
		axios
			.post(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/students/`,
				{
					balanceParam: balance,
					studentParam: id,
				}
			)
			.then((res) => {
				setLoadState('Update');
				setAlert(
					<Success title="Here's what happened: " message={res.data.message} />
				);
			})
			.catch((err) =>
				setAlert(<Failure title="oops!" message={err.message} />)
			);
	}

	// Show Upcoming Sessions

	const [upcomingSessions, setUpcomingSessions] = useState([]);

	useEffect(() => {
		for (let s = 1; s < upcomingSessionsIds.length; s++) {
			axios
				.get(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/sessions/`,
					{
						params: {
							sessionParam: upcomingSessionsIds[s],
						},
					}
				)
				.then((res) => {
					setUpcomingSessions((prevSessions) => [res.data, ...prevSessions]);
				})
				.catch((err) =>
					setAlert(<Failure title="oops!" message={err.message} />)
				);
		}
		// eslint-disable-next-line
	}, [upcomingSessionsIds]);

	// Show Past Sessions

	const [pastSessions, setPastSessions] = useState([]);

	useEffect(() => {
		for (let s = 1; s < pastSessionsIds.length; s++) {
			axios
				.get(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/sessions/`,
					{
						params: {
							sessionParam: pastSessionsIds[s],
						},
					}
				)
				.then((res) => {
					setPastSessions((prevSessions) => [res.data, ...prevSessions]);
				})
				.catch((err) =>
					setAlert(<Failure title="oops!" message={err.message} />)
				);
		}
		// eslint-disable-next-line
	}, [pastSessionsIds]);

	// Delete Student
	const deleteStudent = async () => {
		try {
			setLoadingDelete(true);

			await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/delete/student`,
				{
					studentId: id,
				}
			);
			await axios.delete(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/students/`,
				{
					data: {
						studentParam: id,
					},
				}
			);

			history.go(0);
		} catch (err) {
			setAlert(<Failure title="oops!" message={err.message} />);
		} finally {
			setLoadingDelete(false);
		}
	};

	// View as User
	function viewAsUser() {
		history.push('/student-dashboard', { id: id });
	}

	const handleManualSubscription = (action) => {
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/update/subscription`,
				{
					student_id: id,
					action: action,
				}
			)
			.then((res) => {
				setAlert(<Success title="Done!" message={res.data.message} />);
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			})
			.catch((err) =>
				setAlert(<Failure title="oops!" message={err?.data?.message || ''} />)
			)
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div>
			<div className="general-container profile-details">
				{alert}
				<div className="row">
					<div className="col-lg-6 col-md-6 col-sm-12">
						<div className="settings-box">
							<table className="table table-striped">
								<tbody>
									<tr>
										<td>Name:</td>
										<td>{name}</td>
									</tr>
									<tr>
										<td>Phone Number:</td>
										<td>{phone}</td>
									</tr>
									<tr>
										<td>Email:</td>
										<td>{email}</td>
									</tr>
									<tr>
										<td>Parent Email:</td>
										<td>{parentEmail}</td>
									</tr>
									<tr>
										<td>Account Created:</td>
										<td>{created}</td>
									</tr>
									<tr>
										<td>Last seen:</td>
										<td>{date}</td>
									</tr>

									{studentInfo && studentInfo.stripeHash && (
										<tr>
											<td>Stripe ID: </td>
											<td>{studentInfo.stripeHash}</td>
										</tr>
									)}
									{studentInfo && studentInfo.subscriptionMode && (
										<tr>
											<td>Manual Subscription: </td>
											<td>
												{studentInfo.subscriptionMode === 'manual'
													? 'Yes'
													: 'No'}
											</td>
										</tr>
									)}
									{studentInfo && studentInfo.subscriptionTxnId && (
										<>
											<tr>
												<td>Subscription Id: </td>
												<td>{studentInfo.subscriptionTxnId}</td>
											</tr>
											<tr>
												<td>Sub. Start Date:</td>
												<td>
													{utcTimeFormat(studentInfo.subscriptionStartDate)}
												</td>
											</tr>
											<tr>
												<td>Sub. End Date:</td>
												<td>
													{utcTimeFormat(studentInfo.subscriptionEndDate)}
												</td>
											</tr>
										</>
									)}
								</tbody>
							</table>

							<div className="form-group">
								<h3 className="settings-label">Highschool</h3>
								<select className="form-control" onChange={updateHighschool}>
									<option value="" disabled selected={!studentHighschoolId}>
										-- Select Highschool --
									</option>
									<option
										value="00000000-0000-0000-0000-000000000000"
										selected={
											'00000000-0000-0000-0000-000000000000' ===
											studentHighschoolId
										}
									>
										Not in a school
									</option>
									{highschools.map((highschool) => {
										return (
											<option
												value={highschool.id}
												selected={highschool?.id === studentHighschoolId}
											>
												{highschool.name}
											</option>
										);
									})}
								</select>
							</div>

							<div className="form-group">
								<h3 className="settings-label">Student Type</h3>
								<select className="form-control" onChange={updateStudentType}>
									<option value="" disabled>
										-- Select Type --
									</option>

									<option
										value={'standard'}
										selected={'standard' === studentType || !studentType}
									>
										Standard
									</option>
									<option value={'intern'} selected={'intern' === studentType}>
										Intern
									</option>
								</select>
							</div>

							{loadingUpdate && <LoadingButton />}
						</div>
						<br />
					</div>

					<div className="col-lg-6 col-md-6 col-sm-12">
						{studentInfo && studentInfo.subscriptionTxnId ? (
							<p>You can't set manual mode for active Subscription</p>
						) : (
							<div className="manual-subscription">
								{studentInfo &&
								studentInfo.subscriptionMode &&
								studentInfo.subscriptionMode === 'manual' ? (
									<Button
										variant="danger"
										onClick={() => handleManualSubscription('inactive')}
									>
										{isLoading ? <LoadingButton /> : `Stop Subscription`}
									</Button>
								) : (
									<Button
										variant="success"
										onClick={() => handleManualSubscription('active')}
									>
										{' '}
										{isLoading ? <LoadingButton /> : `Start Subscription`}
									</Button>
								)}
							</div>
						)}
						<Input
							label="Change Balance"
							value={balance}
							onChange={changeBalance}
							type="number"
						/>

						<SaveButton onClick={updateBalance} loadState={loadState} />

						<div>
							<button onClick={viewAsUser} className="view-user">
								View as User
							</button>

							<button
								onClick={deleteStudent}
								className="delete-user"
								disabled={loadingDelete}
							>
								{loadingDelete ? <LoadingButton /> : 'Delete User'}
							</button>
						</div>

						<div className="admin-sessions general-container">
							<p>Upcoming Sessions</p>

							{upcomingSessions.map((session) => (
								<Session session={session} />
							))}
						</div>

						<br />
						<div className="admin-sessions general-container">
							<p>Past Sessions</p>
							{pastSessions.map((session) => (
								<Session session={session} />
							))}
						</div>
						{client ? <UserTransactions id={id} /> : null}
					</div>
				</div>
			</div>
			<p className="center-it explain-text">{id}</p>
		</div>
	);
}

export default DetailedStudent;
