import React from 'react';

function Whiteboard() {
	var stored = localStorage.getItem('demoRoom');
	if (!stored || stored.indexOf('room.sh') === -1) {
		stored = null;
	}
	fetch('https://api.thelessonspace.com/v2/demo/', {
		method: 'POST',
		headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
		body: JSON.stringify({ desired_room: stored }),
	}).then(function (response) {
		response.json().then(function (j) {
			localStorage.setItem('demoRoom', j.url);
			document.getElementById('lessonspaceDemoIframe').src = j.url;
		});
	});

	return (
		<div>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220">
				<path
					fill="#fafafa"
					fill-opacity="1"
					d="M0,64L80,58.7C160,53,320,43,480,48C640,53,800,75,960,69.3C1120,64,1280,32,1360,16L1440,0L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
				></path>
			</svg>
			<section className="fifth-container bg-light">
				<h1 class="whiteboard-text"> Made for Teaching</h1>

				<div class="whiteboard-container">
					<iframe
						id="lessonspaceDemoIframe"
						title="whiteboard"
						frameborder="0"
						allow="camera; microphone; display-capture"
						src="about:blank"
						class="whiteboard-frame"
					></iframe>
				</div>

				<a
					target="_blank"
					rel="noopener noreferrer"
					href="https://www.thelessonspace.com/demo"
				>
					{' '}
					<button className=" whiteboard-button">View Full Demo</button>{' '}
				</a>
			</section>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220">
				<path
					fill="#fafafa"
					fill-opacity="1"
					d="M0,64L80,58.7C160,53,320,43,480,48C640,53,800,75,960,69.3C1120,64,1280,32,1360,16L1440,0L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
				></path>
			</svg>
		</div>
	);
}

export default Whiteboard;
