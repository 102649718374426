import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../../components/admin/home/Header';
import LoadingPage from '../../../components/loading/LoadingPage';
import { Failure } from '../../../components/alerts/Alerts';
import { NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import LoadingButton from '../../../components/loading/LoadingButton';
import moment from 'moment';

import './index.scss';

function AdminListTutorVideos() {
	const [alert, setAlert] = useState();
	const [videos, setVideos] = useState([]);
	const [filteredVideos, setFilteredVideos] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getTutorVideos();
		// eslint-disable-next-line
	}, []);

	const getTutorVideos = async () => {
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_tutor_video`,
				{
					action: 'list_tutor_videos',
				}
			)

			.then((res) => {
				const list = res?.data?.data || [];
				setVideos(list);
				setFilteredVideos(list);
			})
			.catch(() =>
				setAlert(
					<Failure
						title="Oops!"
						message="Something happened. Try again or contact us."
					/>
				)
			)
			.finally(() => setLoading(false));
	};

	const DeleteButton = ({ item }) => {
		const { admin_teacher_video_id } = item;
		const [loading, setLoading] = useState(false);

		const handleDelete = async () => {
			try {
				const confirm = window.confirm(
					'Are you sure you want to delete this tutor video?'
				);
				if (!confirm) return;

				setLoading(true);
				await axios.post(
					`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_tutor_video`,
					{
						action: 'delete_tutor_video',
						tutorVideoId: admin_teacher_video_id,
					}
				);
				getTutorVideos();
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		};

		return (
			<Button disabled={loading} className="btn-delete" onClick={handleDelete}>
				{loading ? <LoadingButton /> : <i className="fas fa-trash-alt"></i>}
			</Button>
		);
	};

	const columns = [
		{
			name: 'Date',
			sortable: true,
			selector: 'date_created',
			cell: (item) => (
				<>
					<span>{moment(item?.date_created).format('MMM DD, YYYY')}</span>
				</>
			),
		},
		{
			name: 'Subject',
			sortable: false,
			cell: (item) => <>{item?.subject_name || 'N/A'}</>,
		},
		{
			name: 'Tutor Name',
			sortable: true,
			selector: 'tutor_name',
		},
		{
			name: 'Welcome Video',
			sortable: true,
			selector: 'is_welcome_video',
			cell: (item) => (
				<>
					{item?.is_welcome_video ? (
						<span className="badge badge-success">Yes</span>
					) : (
						'No'
					)}
				</>
			),
		},
		{
			name: 'Video Name',
			sortable: true,
			selector: 'video_name',
		},
		{
			name: 'Video TAGs',
			sortable: false,
			cell: (item) => <>{item?.video_tags?.join(', ') || 'N/A'}</>,
		},
		{
			name: 'Highschool',
			sortable: false,
			cell: (item) => <>{item?.highschool_name || 'N/A'}</>,
		},
		{
			name: 'Video URL',
			sortable: false,
			cell: (item) => (
				<div className="custom-link-container">
					<a
						href={item?.video_url}
						className="custom-link"
						target="_blank"
						rel="noopener noreferrer"
					>
						{item?.video_url}
					</a>
				</div>
			),
		},
		{
			name: 'Action',
			width: '130px',
			cell: (item) => (
				<>
					<NavLink to={`/admin-videos/edit/${item.admin_teacher_video_id}`}>
						<Button className="btn-edit">
							<i className="fas fa-pencil"></i>
						</Button>
					</NavLink>
					<DeleteButton item={item} />
				</>
			),
		},
	];

	const handleSearch = (e) => {
		const value = e.target.value;
		const searchKeyword = value.toLowerCase();
		const filteredItems = videos.filter((item) => {
			return (
				item?.tutor_name?.toLowerCase().includes(searchKeyword) ||
				item?.video_name?.toLowerCase().includes(searchKeyword) ||
				item?.highschool_name?.toLowerCase().includes(searchKeyword) ||
				item?.subject_name?.toLowerCase().includes(searchKeyword)
			);
		});
		setFilteredVideos(filteredItems);

		if (value === '') {
			setFilteredVideos(videos);
		}
	};

	if (loading) {
		return <LoadingPage />;
	}

	return (
		<div className="page-admin-videos">
			<Header />
			{alert}
			<div className="container-fluid">
				<div className="row page-header mt-5 mb-4">
					<h4 className="admin-title">Videos</h4>

					<div className="action-buttons">
						<input
							type="text"
							className="form-control"
							placeholder="Search"
							onChange={handleSearch}
						/>
					</div>
					<div className="">
						<a href="/admin-videos/add" className="btn-custom-primary">
							Add new video
						</a>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						{filteredVideos.length > 0 ? (
							<DataTable
								columns={columns}
								data={filteredVideos}
								pagination
								striped
							/>
						) : (
							<p className="text-center">No videos found</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdminListTutorVideos;
