import React from 'react';
import StudentSettings from '../../components/settings/StudentSettings';
import TutorSettings from '../../components/settings/TutorSettings';
import Nav from '../../components/dashboard/Nav';
import NavLogo from '../../components/NavLogo';

function StudentSettingsPage() {
	return (
		<div>
			<Nav logo={<NavLogo />} />

			<StudentSettings />
		</div>
	);
}

function TutorSettingsPage() {
	return (
		<div>
			<Nav logo={<NavLogo />} />

			<TutorSettings />
		</div>
	);
}

export { StudentSettingsPage, TutorSettingsPage };
