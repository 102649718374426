import React from 'react';

function LoadingButton() {
	return (
		<div className="spinner-border" role="status">
			<span className="sr-only">Loading...</span>
		</div>
	);
}

export default LoadingButton;
