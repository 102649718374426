import React, { useEffect, useState } from 'react';
import Header from '../../components/home/Header';
import { useLocation } from 'react-router-dom';
import { Failure } from '../../components/alerts/Alerts';
import axios from 'axios';
import { shuffle } from '../../tools/arrayTools';
import AMatch from '../../components/funnels/match/AMatch';
import LoadingButton from '../../components/loading/LoadingButton';
function SeeMatches() {
	const location = useLocation();
	const [alert, setAlert] = useState();

	// Get Math and University
	const [math, setMath] = useState();
	const [university, setUniversity] = useState();
	useEffect(() => {
		setMath(location.state.math);
		setUniversity(location.state.university);
	}, [location]);

	// Get all Tutors
	const [tutors, setTutors] = useState([]);
	useEffect(() => {
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/tutors`
			)
			.then((res) => {
				let tutorsData = res.data.Items;
				var certifiedTutors = tutorsData.filter(function (tutor) {
					return tutor.ansariCertified;
				});
				var activeTutors = certifiedTutors.filter(function (tutor) {
					return tutor.availability.length > 1;
				});
				setTutors(activeTutors);
			})
			.catch((err) =>
				setAlert(
					<Failure
						title="Oops!"
						message={err.message + '. Try again or Contact us'}
					/>
				)
			);
	}, []);

	// Set Two Matches
	const [matches, setMatches] = useState([]);
	useEffect(() => {
		setTutors(shuffle(tutors));
		let matchesArray = [];
		for (let i = 0; i < tutors.length; i++) {
			if (matchesArray.length === 2) {
				break;
			}
			if (specialtyIncludes(tutors[i]) && tutors[i].university === university) {
				matchesArray.push(tutors[i]);
			}
		}

		// Not enough matches, add randoms
		if (matchesArray.length < 2) {
			for (let i = 0; i < tutors.length; i++) {
				if (matchesArray.length === 2) {
					break;
				}
				if (!matchesArray.includes(tutors[i])) {
					matchesArray.push(tutors[i]);
				}
			}
		}

		setTimeout(() => {
			setMatches(matchesArray);
		}, 4000);

		// eslint-disable-next-line
	}, [tutors]);

	function specialtyIncludes(tutor) {
		let specialties = tutor.specialties;
		for (let i = 0; i < specialties.length; i++) {
			if (specialties[i].toLowerCase() === math.toLowerCase()) {
				return true;
			}
		}

		return false;
	}

	const [loadState, setLoadState] = useState();
	useEffect(() => {
		if (matches.length > 0) {
			setLoadState(
				<div>
					<div className="row">
						{matches.map((match) => {
							return <AMatch match={match} />;
						})}
					</div>

					<a href="/search">
						<button className="see-more">
							See More{' '}
							<div>
								<i className="see-more-arrow fas fa-sort-down"></i>
							</div>
						</button>
					</a>
				</div>
			);
		} else {
			setLoadState(
				<div className="loading-matches">
					<LoadingButton />
					<p className="explain-text">loading your matches</p>
				</div>
			);
		}
	}, [matches]);

	return (
		<div className="center-it">
			<Header />
			{alert}
			<p className="head-quote">
				94% of students go with their matched tutor and are happy
			</p>

			{loadState}
		</div>
	);
}

export default SeeMatches;
