import React, { useState, useEffect } from 'react';
import { Book } from '../book/Book';
import axios from 'axios';
import LoadingButton from '../loading/LoadingButton';
import { toast } from 'react-toastify';
import Carousel from 'react-bootstrap/Carousel';
import ReactPlayer from 'react-player';
import { useLocation } from 'react-router-dom';
import './index.scss';
const queryString = require('query-string');

function MyTutorVideos(props) {
	const location = useLocation();
	const parsed = queryString.parse(location?.search);
	const { tid: pickedTutorId } = parsed;
	const { profile, handleSuccessfulBook, isNewUser, pickedTutorHash } = props;

	const [tutors, setTutors] = useState([]);
	const [isDataLoading, setDataLoading] = useState(true);
	const [isVideoPlaying, setVideoPlaying] = useState(true);

	const { id: studentId, highschoolId } = profile || {};
	useEffect(() => {
		if (studentId && highschoolId) {
			getMyTutors(studentId, highschoolId);
		}
		setTimeout(() => {
			setDataLoading(false);
		}, 10000);
		// eslint-disable-next-line
	}, [studentId]);

	const getMyTutors = async (studentId, highschoolId) => {
		setDataLoading(true);

		try {
			const results = await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/highschool/teachers`,
				{
					action: 'get_my_teachers',
					studentId: studentId,
					highschoolId: highschoolId,
				}
			);
			const { data } = results;
			const { status, message, data: teachers } = data;
			if (status) {
				const filteredTutors = teachers.filter(
					(tutor) => tutor?.profile?.ansariCertified
				);
				const selectedTutorId = pickedTutorId || pickedTutorHash;
				// sort picked tutor to the top
				if (selectedTutorId) {
					const pickedTutor = filteredTutors.find(
						(tutor) => tutor.teacher_id === selectedTutorId
					);
					const otherTutors = filteredTutors.filter(
						(tutor) => tutor.teacher_id !== selectedTutorId
					);
					const sortedTutors = [pickedTutor, ...otherTutors];

					setTutors(sortedTutors);
				} else {
					setTutors(filteredTutors);
				}
			} else {
				toast.error(message);
			}
		} catch (err) {
			const errorMessage = err.response?.data?.message || err.message;
			toast.error(errorMessage);
		} finally {
			setDataLoading(false);
		}
	};

	const [slideIndex, setSlideIndex] = useState(0);

	const handleSliderSelect = (index, e) => {
		setSlideIndex(index);
	};

	const handleStopVideo = () => {
		setVideoPlaying(false);
		setTimeout(() => {
			setVideoPlaying(true);
		}, 200);
	};

	return (
		<>
			<div className="section-body">
				{isDataLoading ? (
					<div className="box-loader">
						<LoadingButton />
					</div>
				) : tutors.length > 0 ? (
					<Carousel
						interval={null}
						activeIndex={slideIndex}
						indicators={tutors?.length > 1}
						controls={tutors?.length > 1}
						onSelect={handleSliderSelect}
					>
						{tutors.map((tutor, index) => {
							const { profile, video_url } = tutor || {};
							const { youtubeLink, name, ansariCertified, availability } =
								profile || {};
							var renderVideoLink = video_url || youtubeLink || null;

							if (isNewUser) {
								renderVideoLink = youtubeLink;
							}

							const validVideoUrl =
								renderVideoLink && renderVideoLink.startsWith('http');
							const isLoomVideo =
								renderVideoLink && renderVideoLink.includes('loom.com');
							const isVimeoVideo =
								renderVideoLink && renderVideoLink.includes('vimeo.com');

							if (isLoomVideo) {
								renderVideoLink = renderVideoLink.replace('share', 'embed');
							}

							return (
								<Carousel.Item key={index}>
									<div className="tutor-profile">{name || 'Tutor'}</div>
									<div className="tutor-video">
										{slideIndex === index && isVideoPlaying && (
											<>
												{validVideoUrl ? (
													<>
														{isLoomVideo || isVimeoVideo ? (
															<iframe
																src={renderVideoLink}
																title="tutor video"
																className="loom-video"
															></iframe>
														) : (
															<ReactPlayer
																url={renderVideoLink}
																width={'100%'}
																height={'340px'}
															/>
														)}
													</>
												) : (
													<p>No video found</p>
												)}
											</>
										)}
									</div>
									<div className="book-btn">
										{ansariCertified && availability?.length > 1 && (
											<Book
												tutor={profile}
												handleSuccessfulBook={handleSuccessfulBook}
												stopVideo={handleStopVideo}
											/>
										)}
									</div>
								</Carousel.Item>
							);
						})}
					</Carousel>
				) : (
					<div className="no-data-found">
						<img src="../images/women.svg" alt="sad face" />
						<p className="text-hint">No Tutors found</p>
					</div>
				)}
			</div>
		</>
	);
}

export default MyTutorVideos;
