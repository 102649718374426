import React from 'react';
import { useHistory } from 'react-router-dom';

function AMatch(props) {
	const history = useHistory();
	const match = props.match;

	function goToProfile() {
		history.push('/tutor/' + match.username);
	}

	return (
		<div class="col-sm-12 col-md-6 col-lg-6 ">
			<div class="a-match">
				<img class="match-image" src={match.image} alt="match" />
				<p class="match-name">
					{' '}
					<i class="tutor-online fas fa-circle" /> {match.name}
				</p>
				<p>
					<img class="university" src={match.universityImageLink} alt="uni" />{' '}
					{match.university}
				</p>

				<div>
					<button onClick={goToProfile} class="book-session-match">
						View Profile
					</button>
				</div>
			</div>
		</div>
	);
}

export default AMatch;
