import React, { useState, useEffect } from 'react';
import {
	formattedSessionDay,
	formattedSessionTime,
} from '../../tools/timeConverter';
import './book.css';

function BookContentSummary(props) {
	const [formattedDay, setFormattedDay] = useState('');
	const [formattedTime, setFormattedTime] = useState('');
	const request = props.time - Date.now() < 12 * 60 * 60 * 1000;

	useEffect(() => {
		if (props.day) setFormattedDay(formattedSessionDay(props.day));
		if (props.time) setFormattedTime(formattedSessionTime(props.time));
	}, [props.day, props.time]);

	return (
		<div className="col-10">
			{request ? (
				<p class="book-explain explain-text">
					Please note that this is booking is a request since it is not 12 hours
					in advance. The tutor has the option to accept or deny it. You will
					get notified by email.
				</p>
			) : null}
			<>
				<div class="book-content-summary-top">
					<h6>Subject: </h6> <h5>{props.what}</h5> <div class="edit"></div>
				</div>

				<div className="book-content-summary book-content-container">
					<div className="book-content-summary-row row">
						<div className="book-content-summary-header col-4">Date</div>
						<div className="book-content-summary-header col-4">Time</div>
						<div className="book-content-summary-header col-4">Hour</div>
					</div>

					<div className=" book-content-summary-row row">
						<div className="book-content-summary-value col-4">
							{formattedDay}
						</div>
						<div className="book-content-summary-value col-4">
							{formattedTime}
						</div>
						<div className="book-content-summary-value col-4">1 Hour</div>
					</div>
				</div>
				<div class="edit"></div>

				<img className="tutor-pic " src={props.tutorImage} alt="" />
				<div
					class="col-sm-6 col-lg-4 mytutor-details mytutor-details-summary"
					style={{ width: 'auto' }}
				>
					<h6>{props.tutorName}</h6>
				</div>
			</>
		</div>
	);
}

export default BookContentSummary;
