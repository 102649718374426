import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingPage from '../../loading/LoadingPage';
import Student from '../Student';
import { utcStringToLocalDate } from '../../../tools/timeConverter';
import { Failure } from '../../alerts/Alerts';

function RecentStudents() {
	const [alert, setAlert] = useState();

	const [students, setStudents] = useState([]);

	// Retrieve the 10 upcoming sessions
	useEffect(() => {
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/students`
			)

			.then((res) => {
				var filteredStudents = res.data.Items.filter(function (student) {
					return student.email != null;
				});

				// Sort from Newest Reigster on top
				let sortedStudents = filteredStudents.sort(
					(aStudent, bStudent) =>
						utcStringToLocalDate(bStudent.created) -
						utcStringToLocalDate(aStudent.created)
				);
				let slicedSortedStudents = sortedStudents.slice(0, 10);
				setStudents(slicedSortedStudents);
			})
			.catch((err) =>
				setAlert(<Failure title="Oops!" message={err.message} />)
			);
	}, []);

	// Show sessions on the page
	const [showState, setShowState] = useState(<LoadingPage />);

	useEffect(() => {
		if (students.length > 0) {
			setShowState(students.map((student) => <Student student={student} />));
		}
	}, [students]);

	return (
		<div class="col-8">
			<div class="general-container recent-admin">
				{alert}
				<h5>Recent Students</h5>

				<div class="all-labels">
					<div class="row">
						<div class="col-2">
							<i class="fal fa-user"></i> Name
						</div>
						<div class="col-2">
							<i class="far fa-envelope-open"></i> Email
						</div>
						<div class="col-2">
							{' '}
							<i class="far fa-phone-alt"></i> Phone
						</div>
						<div class="col-2">
							<i class="fal fa-user-tie"></i> Parent
						</div>
						<div class="col-2">
							<i class="far fa-moon"></i> Balance
						</div>
						<div class="col-2">
							{' '}
							<i class="far fa-chess-knight"></i> Status
						</div>
					</div>
				</div>

				<hr />

				{showState}
			</div>
		</div>
	);
}

export default RecentStudents;
