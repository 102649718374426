import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Landing Pages
import Home from '../pages/public/Home';
import ParentContactPage from '../pages/public/ParentContactPage';
import BookConsult from './funnels/consult/BookConsult';
import SeeMatches from '../pages/public/SeeMatches';
import Consult from '../pages/public/Consult';

// Public
import Terms from '../pages/public/Terms';
import NoMatch from '../pages/public/NoMatch';
// import Search from '../pages/public/Search';
import TutorProfile from '../pages/public/TutorProfile';
import Pricing from '../pages/public/Pricing';

// Contact
import Contact from './contact/Contact';
import ManuallyConfirm from './contact/ManuallyConfirm';
import Aid from './contact/Aid';
import Bug from './contact/Bug';
import Success from './contact/Success';

// Auth
import Login from './auth/Login';
import Confirm from './auth/Confirm';
import ForgotPassword from './auth/ForgotPassword';

// Private
import StudentDashboard from '../pages/private/StudentDashboard';
import TutorDashboard from '../pages/private/TutorDashboard';
import {
	StudentSettingsPage,
	TutorSettingsPage,
} from '../pages/private/Settings';
import StudentPayment from './dashboard/StudentPayment';
import TutorPayment from './dashboard/TutorPayment';

// Admin
import Admin from '../pages/admin/Admin';
import AdminStudents from '../pages/admin/Students';
import AdminTutors from '../pages/admin/Tutors';
import AdminMessages from '../pages/admin/Messages';
import AdminTransactions from '../pages/admin/Transactions';
import AdminSessions from '../pages/admin/Sessions';
import AdminSettings from '../pages/admin/Settings';
import DetailedStudent from './admin/DetailedStudent';
import DetailedTutor from './admin/DetailedTutor';
import DetailedSession from './admin/DetailedSession';
import AdminData from '../pages/admin/Data';

// Other
import AuthContextProvider from '../contexts/AuthContext';
import PrivateRoute from '../hocs/PrivateRoute';
import UnPrivateRoute from '../hocs/UnPrivateRoute';
import AdminRoute from '../hocs/AdminRoute';
import RegisterStudent from '../pages/public/RegisterStudent';
import AdminListHighschool from '../pages/admin/highschool/ListHighschool';
import AdminAddHighschool from '../pages/admin/highschool/AddHighschool';
import AdminListHighschoolStudents from '../pages/admin/highschool/ListHighschoolStudent';
import StudentSessions from '../pages/private/StudentSessions';
import HomeHighschool from '../pages/public/HomeHighschool';
import HomeInternship from '../pages/public/HomeInternship';
import HomeTutors from '../pages/public/HomeTutors';
import AdminListHighschoolGroups from '../pages/admin/highschool/ListHighschoolGroups';
import AdminAddHighschoolTeacher from '../pages/admin/highschool/AddHighschoolTeacher';
import AdminListHighschoolTeachers from '../pages/admin/highschool/ListHighschoolTeachers';
import AdminEditHighschool from '../pages/admin/highschool/EditHighschool';
import TutorVideos from '../pages/private/TutorVideos';
import TutorAvailability from '../pages/private/TutorAvailability';
import TutorTnC from '../pages/private/TutorTnC';
import PrivacyPolicy from '../pages/public/PrivacyPolicy';
import TutorSchools from '../pages/private/TutorSchools';
import HomeParents from '../pages/public/HomeParents';
import HomeTeamAnya from '../pages/public/HomeTeamAnya';
import ApplyAsTutor from './auth/ApplyAsTutor';
import AdminListTutorVideos from '../pages/admin/videos/AdminListTutorVideos';
import AdminManageTutorVideo from '../pages/admin/videos/AdminManageTutorVideo';
import BookStudentSessions from '../pages/private/BookStudentSessions';

function Main() {
	return (
		<>
			{/* Provide context for current user */}
			<AuthContextProvider>
				<Switch>
					{/* Landing Pages */}
					<Route exact path="/" component={Home} />
					<Route exact path="/hs/:id" component={HomeHighschool} />
					<Route exact path="/highschool/:id" component={HomeHighschool} />
					<Route exact path="/internship" component={HomeInternship} />
					<Route exact path="/tutors" component={HomeTutors} />
					<Route exact path="/parents" component={HomeParents} />
					<Route exact path="/teamanya" component={HomeTeamAnya} />
					<Route
						exact
						path="/register-student/:id"
						component={RegisterStudent}
					/>
					<Route exact path="/matches" component={SeeMatches} />
					<Route exact path="/consult" component={Consult} />
					<Route exact path="/book-consult" component={BookConsult} />
					{/* Public Pages */}
					<Route exact path="/terms" component={Terms} />
					<Route exact path="/privacy-policy" component={PrivacyPolicy} />
					{/* <Route exact path="/search" component={Search} /> */}
					<Route path="/tutor/:username" component={TutorProfile} />
					<Route exact path="/pricing" component={Pricing} />
					{/* Contact */}
					<Route exact path="/contact" component={Contact} />
					<Route exact path="/bug" component={Bug} />
					<Route exact path="/aid" component={Aid} />
					<Route exact path="/parent-contact" component={ParentContactPage} />
					<Route exact path="/success" component={Success} />
					{/* Auth Pages */}
					<UnPrivateRoute exact path="/register" component={Login} />
					<UnPrivateRoute exact path="/login" component={Login} />
					<UnPrivateRoute exact path="/confirm" component={Confirm} />
					<UnPrivateRoute exact path="/apply" component={ApplyAsTutor} />
					<Route exact path="/manually-confirm" component={ManuallyConfirm} />
					<Route exact path="/forgot-password" component={ForgotPassword} />

					<Route exact path="/book-session" component={BookStudentSessions} />

					{/* Private Pages */}
					<PrivateRoute exact path="/student/trial/confirm">
						<Redirect to="/student-dashboard" />
					</PrivateRoute>
					<PrivateRoute
						exact
						path="/student-dashboard"
						component={StudentDashboard}
					/>
					<PrivateRoute exact path="/tutoring" component={StudentSessions} />
					<PrivateRoute
						exact
						path="/tutor-dashboard"
						component={TutorDashboard}
					/>
					<PrivateRoute exact path="/tutor-videos" component={TutorVideos} />
					<PrivateRoute exact path="/tutor-schools" component={TutorSchools} />
					<PrivateRoute exact path="/tutor-tnc" component={TutorTnC} />
					<PrivateRoute
						exact
						path="/tutor-availability"
						component={TutorAvailability}
					/>
					<PrivateRoute
						exact
						path="/student-settings"
						component={StudentSettingsPage}
					/>
					<PrivateRoute
						exact
						path="/tutor-settings"
						component={TutorSettingsPage}
					/>
					<PrivateRoute
						exact
						path="/student-payment"
						component={StudentPayment}
					/>
					<PrivateRoute exact path="/tutor-payment" component={TutorPayment} />

					{/* Admin Pages */}
					<AdminRoute exact path="/admin" component={Admin} />
					<AdminRoute
						exact
						path="/admin-videos"
						component={AdminListTutorVideos}
					/>
					<AdminRoute
						exact
						path="/admin-videos/add"
						component={AdminManageTutorVideo}
					/>
					<AdminRoute
						exact
						path="/admin-videos/edit/:id"
						component={AdminManageTutorVideo}
					/>

					<AdminRoute
						exact
						path="/admin-highschools"
						component={AdminListHighschool}
					/>
					<AdminRoute
						exact
						path="/admin-highschool/add"
						component={AdminAddHighschool}
					/>
					<AdminRoute
						exact
						path="/admin-highschool/edit/:id"
						component={AdminEditHighschool}
					/>
					<AdminRoute
						exact
						path="/admin-highschool-teacher/add"
						component={AdminAddHighschoolTeacher}
					/>
					<AdminRoute
						exact
						path="/admin-highschool-students/:id"
						component={AdminListHighschoolStudents}
					/>
					<AdminRoute
						exact
						path="/admin-highschool-groups/:id"
						component={AdminListHighschoolGroups}
					/>
					<AdminRoute
						exact
						path="/admin-highschool-teachers/:id"
						component={AdminListHighschoolTeachers}
					/>
					<AdminRoute exact path="/admin-students" component={AdminStudents} />
					<AdminRoute exact path="/admin-tutors" component={AdminTutors} />
					<AdminRoute exact path="/admin-messages" component={AdminMessages} />
					<AdminRoute
						exact
						path="/admin-subscriptions"
						component={AdminTransactions}
					/>
					<AdminRoute exact path="/admin-data" component={AdminData} />
					<AdminRoute exact path="/admin-sessions" component={AdminSessions} />
					<AdminRoute exact path="/admin-settings" component={AdminSettings} />
					<AdminRoute exact path="/admin/student" component={DetailedStudent} />
					<AdminRoute exact path="/admin/tutor" component={DetailedTutor} />
					<AdminRoute exact path="/admin/session" component={DetailedSession} />
					<Route component={NoMatch}></Route>
				</Switch>
			</AuthContextProvider>
		</>
	);
}

export default Main;
