import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { utcStringToLocalDate } from '../../tools/timeConverter';
import Header from '../../components/admin/home/Header';
import Search from '../../components/tutors/Search';
import Student from '../../components/admin/Student';
import LoadingPage from '../../components/loading/LoadingPage';
import NothingFound from '../../components/loading/NothingFound';
import { Failure } from '../../components/alerts/Alerts';

function AdminStudents() {
	const [alert, setAlert] = useState();

	// Call API to get Student Information and store them in 'students'
	const [students, setStudents] = useState([]);

	useEffect(() => {
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/students`
			)

			.then((res) => {
				// Filter out deleted students
				var filteredStudents = res.data.Items.filter(function (student) {
					return student.email != null;
				});

				// Sort from Newest Reigster on top
				var sortedStudents = filteredStudents.sort(
					(aStudent, bStudent) =>
						utcStringToLocalDate(bStudent.created) -
						utcStringToLocalDate(aStudent.created)
				);
				setStudents(sortedStudents);
			})
			.catch(() =>
				setAlert(
					<Failure
						title="Oops!"
						message="Something happened. Try again or contact us."
					/>
				)
			);
	}, []);

	// Show students on the page
	const [showState, setShowState] = useState(<LoadingPage />);

	useEffect(() => {
		if (students.length > 0) {
			setShowState(students.map((student) => <Student student={student} />));
		}
	}, [students]);

	// Search Funcationality
	const [searchState, setSearch] = useState('');

	function searchOnChange(event) {
		setSearch(event.target.value);
	}

	function lowBalance(client, balance) {
		if (client && balance < 4) {
			return true;
		} else {
			return false;
		}
	}

	function findStudent(e) {
		e.preventDefault();
		var results = [];

		var search = searchState.toString().toLocaleLowerCase();

		if (searchState === '') {
			setShowState(students.map((student) => <Student student={student} />));
			return;
		}

		if (search.includes('low balance')) {
			for (let t = 0; t < students.length; t++) {
				var status = students[t].firstPackagePurchased;
				var balance = students[t].balance;

				if (lowBalance(status, balance)) {
					results.push(students[t]);
				}
			}
		}
		for (let t = 0; t < students.length; t++) {
			var id = students[t].id.toLowerCase();
			var name = students[t].name.toLowerCase();
			var phone = students[t].phoneNumber.toLowerCase();
			var email = students[t].email.toLowerCase();
			var parentEmail = students[t].parentEmail.toLowerCase();

			if (
				name.includes(search) ||
				phone.includes(search) ||
				email.includes(search) ||
				parentEmail.includes(search) ||
				id.includes(search)
			) {
				results.push(students[t]);
			}
		}
		if (results.length > 0) {
			setShowState(results.map((student) => <Student student={student} />));
			return;
		}

		setShowState(<NothingFound />);
	}

	return (
		<div>
			<Header />
			{alert}
			<div className="admin-container">
				<Search
					searchOnClick={findStudent}
					searchOnChange={searchOnChange}
					inputValue={searchState}
				/>

				<div className="all-students-container">
					<div className="all-labels">
						<div className="row">
							<div className="col-2">
								<i className="fal fa-user"></i> Name
							</div>
							<div className="col-2">
								<i className="far fa-envelope-open"></i> Email
							</div>
							<div className="col-2">
								<i className="far fa-phone-alt"></i> Phone
							</div>
							<div className="col-2">
								<i className="fal fa-user-tie"></i> Parent
							</div>
							<div className="col-2">
								<i className="far fa-moon"></i> Balance
							</div>
							<div className="col-2">
								<i className="far fa-chess-knight"></i> Status
							</div>
						</div>
					</div>

					{showState}
				</div>
			</div>
		</div>
	);
}

export default AdminStudents;
