import React from 'react';

function Header() {
	return (
		<div className="header-custom">
			<nav className="navbar navbar-custom navbar-expand-lg" id="mainNav">
				<div className="container">
					<a className="navbar-brand" href="/">
						<img
							className="header-image"
							src="/images/Peerlinc.png"
							alt="home"
						/>
					</a>

					<a href="/pricing" className="header-item">
						Pricing
					</a>
				</div>
			</nav>
		</div>
	);
}

export default Header;
