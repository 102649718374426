import React, { useState, useEffect } from 'react';
import LoadingButton from '../loading/LoadingButton';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import './buy.css';

function Cash(props) {
	const { profile, balance, handleCashout } = props;
	const history = useHistory();
	// Cash out button if balance is greater than 0, then the click to confirm button, then the cashout is handled

	const [cashState, setCashState] = useState();

	useEffect(() => {
		if (balance > 0) {
			setCashState(
				<button onClick={cashClickedTrue} className="buy-button">
					<span className="plus"></span>
					Cash Out
				</button>
			);
		} else {
			setCashState(
				<button className="buy-button button-disabled">
					<span className="plus"></span>
					Cash Out
				</button>
			);
		}
		// eslint-disable-next-line
	}, [balance, profile]);

	const handleConfirm = () => {
		const { stripeHash, stripeConfirmed } = profile || {};
		if (!stripeHash || !stripeConfirmed) {
			toast.error('Please connect your bank account to cashout money');
			history.push('/tutor-payment');
			return;
		}

		setCashState(
			<button className="buy-button">
				<span className="plus"></span>
				<LoadingButton />
			</button>
		);
		handleCashout();
	};

	const cashClickedTrue = () => {
		setCashState(
			<button className="buy-button">
				<span className="plus"></span>
				<LoadingButton />
			</button>
		);

		setTimeout(() => {
			setCashState(
				<button onClick={handleConfirm} className="buy-button">
					<span className="plus"></span>
					Click to Confirm
				</button>
			);
		}, 1000);
	};

	return <div>{cashState}</div>;
}

export default Cash;
