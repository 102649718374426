import React from 'react';

function PricingCard(props) {
	const { title, price, type, next } = props;
	return (
		<>
			<div className={'card-container'}>
				<div className={'price-info'}>
					<p className="text-title">{title}</p>
					<p className="text-price">${price}</p>
					<p className="text-type">
						per {type === 'monthly' ? 'month' : 'year'}
					</p>
				</div>
				<>{next}</>
			</div>
		</>
	);
}

export default PricingCard;
