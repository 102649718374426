/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Failure } from '../alerts/Alerts';
import axios from 'axios';
import { sha512 } from 'js-sha512';
import phone from 'phone';
import LoadingButton from '../loading/LoadingButton';
import { constantKeys } from '../../tools/constant';
import '../home/Modals/modal.css';
import './auth.css';

function ApplyAsTutor() {
	const history = useHistory();

	// Validation
	const [password, setPassword] = useState(''); // Does this fix exposing password?
	const [formError, setFormError] = useState(null);
	const [loadState, setLoadState] = useState(<div>Apply</div>);

	const generateUsername = (email) => {
		var username = email.split('@')[0];
		// remove all non-alphanumeric characters
		username = username.replace(/[^a-zA-Z0-9]/g, '');

		return username;
	};

	// Register Tutor
	const handleRegister = async (e) => {
		e.preventDefault();
		setLoadState(<LoadingButton />);

		let email = e.target.email.value.toLowerCase();
		let phoneNumber = phone(e.target.phoneNumber.value)[0]; // Convert to E.164 number convention (+18888888888)
		let name = e.target.name.value;
		let userName = generateUsername(email);
		let university = '';
		let tutorStatement = '';

		try {
			// Error handling for phone number, password length, and university
			if (!phoneNumber) {
				setFormError({
					title: 'Invalid Phone Number.',
					message: 'Make sure your phone number is in +1########## format',
				});
			} else if (password.length < 8) {
				setFormError({
					title: 'Invalid Password.',
					message: 'Password must be at least 8 characters long',
				});
			} else {
				// Sign up with necessary information
				const user = await Auth.signUp({
					username: email,
					password: sha512(password),
					attributes: {
						phone_number: phoneNumber,
						name: name,
						'custom:university': university,
						'custom:role': 'tutor',
					},
				});
				setPassword('');
				setFormError('');

				// Put new tutor to backend with it's user sub as the hash
				await axios.put(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/tutors`,
					{
						tutorParam: user.userSub,
						usernameParam: userName,
						nameParam: name,
						universityParam: university,
						emailParam: email,
						imageUrlParam:
							'https://peerlinc-app-assets.s3.us-west-1.amazonaws.com/default_avatar.png',
						phoneNumberParam: phoneNumber,
						youtubeParam: '',
						aboutParam: tutorStatement,
						specialtyParam: [''],
						factsParam: [''],
						availabilityParam: [''],
					}
				);

				localStorage.setItem(constantKeys.IS_NEW_TUTOR, '1');
				// Redirect to confirmation page
				history.push('/confirm', { username: user.userSub, isRegister: true });
			}
		} catch (error) {
			if (error.name === 'UsernameExistsException') {
				setFormError({
					title: 'Invalid Email.',
					message: 'An account with the given email already exists',
				});
			} else {
				setFormError({
					title: 'Failed to Register.',
					message: 'Error occurred when registering. Please try again',
				});
			}
		} finally {
			setLoadState(<div>Apply</div>);
		}
	};

	return (
		<div>
			<div>
				<div className="auth-container">
					<div className="auth-logo-div">
						<img className="auth-logo" src="/images/Peerlinc.png" alt="" />
					</div>

					<h5 className="auth-header">Apply</h5>
					<form className="form-signin" onSubmit={handleRegister}>
						<div>
							<input
								type="text"
								name="name"
								id="inputName"
								className="auth-input"
								placeholder="Full name"
								required
							/>
						</div>

						<div>
							<input
								type="email"
								name="email"
								id="inputEmail"
								className="auth-input"
								placeholder="Email address"
								required
							/>
						</div>

						<div>
							<input
								type="password"
								name="password"
								id="inputPassword"
								className="auth-input"
								placeholder="Password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								required
							/>
						</div>

						<div>
							<input
								type="phone"
								name="phoneNumber"
								id="inputPhone"
								className="auth-input"
								placeholder="(818) 876-2450"
								required
							/>
						</div>

						<button className="auth-button register-button" type="submit">
							{loadState}
						</button>

						{formError ? (
							<Failure title={formError.title} message={formError.message} />
						) : null}

						{/* <div className="custom-control forgot-link">
							<p>
								Have an account? <a href="/sign-in">sign in</a>
							</p>
						</div> */}
					</form>
				</div>
			</div>
		</div>
	);
}

export default ApplyAsTutor;
