import React, { useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import './modal.css';
import { useHistory } from 'react-router-dom';
import { constantKeys } from '../../../tools/constant';

const subjectLists = [
	{ name: 'Math', icon: 'fa-pi' },
	{ name: 'English', icon: 'fa-font-case' },
	{ name: 'Physics', icon: 'fa-paper-plane' },
	{ name: 'Computer Science', icon: 'fa-code' },
	{ name: 'Chemistry', icon: 'fa-atom' },
	{ name: 'Biology', icon: 'fa-kidneys' },
	{ name: 'SAT/ACT', icon: 'fa-sticky-note' },
];

// const timeSlotList = [
// 	{ name: 'Morning before noon' },
// 	{ name: 'Afternoon 12PM-5PM' },
// 	{ name: 'Evening after 5PM' },
// 	{ name: 'Any time' },
// ];

function PerfectTutor(props) {
	const history = useHistory();

	const [show, setShow] = useState(false);

	const handleShow = () => setShow(true);
	const handleClose = () => {
		setStep(0);
		setShow(false);
	};

	const [step, setStep] = useState(0);
	const [selectedSubjects, setSelectedSubject] = useState([]);
	//const [selectedSlots, setSelectedSlots] = useState([]);
	const [userType, setUserType] = useState(null);
	const [selectedGrade, setGrade] = useState(null);

	const selectSubject = (item) => {
		var itemList = [...selectedSubjects];
		if (itemList.includes(item)) {
			const index = itemList.indexOf(item);
			if (index > -1) {
				itemList.splice(index, 1);
			}
		} else {
			itemList.push(item);
		}
		setSelectedSubject(itemList);
	};

	const saveSubject = () => {
		var subjects = selectedSubjects.join(', ');
		localStorage.setItem(`${constantKeys.REGISTER_RECOM_SUBJECT}`, subjects);
		setStep(1);
	};

	// const selectSlot = (item) => {
	// 	var itemList = [...selectedSlots];
	// 	if (itemList.includes(item)) {
	// 		const index = itemList.indexOf(item);
	// 		if (index > -1) {
	// 			itemList.splice(index, 1);
	// 		}
	// 	} else {
	// 		itemList.push(item);
	// 	}
	// 	setSelectedSlots(itemList);
	// };

	// const saveTimeSlot = () => {
	// 	var slots = selectedSlots.join(', ');
	// 	localStorage.setItem(`${constantKeys.REGISTER_RECOM_SLOT}`, slots);
	// 	setStep(2);
	// };

	const handleRecommendations = () => {
		localStorage.setItem(
			`${constantKeys.REGISTER_USER_SUBJECT}`,
			selectedSubjects
		);
		localStorage.setItem(`${constantKeys.REGISTER_RECOM_SLOT}`, 'Any Time');
		localStorage.setItem(`${constantKeys.REGISTER_USER_TYPE}`, userType);
		localStorage.setItem(`${constantKeys.REGISTER_USER_GRADE}`, selectedGrade);

		history.push('/search');
	};

	const goBackStep = () => {
		if (step > 0) {
			setStep(step - 1);
		}
	};

	return (
		<>
			<div onClick={handleShow}>
				{props.children ? (
					props.children
				) : (
					<button class="request-typeform" onClick={handleShow}>
						Request a Tutor <i class="fas fa-arrow-right"></i>
					</button>
				)}
			</div>

			{show && (
				<Modal centered show={true} onHide={handleClose}>
					<Modal.Header closeButton></Modal.Header>

					{step === 0 && (
						<div class="modal-design">
							<Modal.Body>
								<h1>Let's find a perfect tutor for you</h1>
								<h3>
									Answer a few questions to get a few perfect recommendations
								</h3>
								<br />
								<h3>Step {step + 1} of 2</h3>
								<br />
								<h4>Select your subjects</h4>
								<div>
									{subjectLists.map((item, index) => {
										return (
											<button
												key={index}
												className={
													selectedSubjects.includes(item.name)
														? 'a-subject-active a-subject'
														: 'a-subject'
												}
												onClick={() => selectSubject(item.name)}
											>
												<i class={`new-orange fad ${item.icon}`}></i>{' '}
												{item.name}
												<i class="a-subject-check green fas fa-check-circle"></i>
											</button>
										);
									})}
								</div>

								<button
									class="next-button"
									onClick={() => {
										saveSubject();
									}}
								>
									Next
								</button>
							</Modal.Body>
						</div>
					)}
					{/* {step === 1 && (
						<div class="modal-perfect-tutor modal-design ">
							<Modal.Body>
								<h3>Step {step + 1} of 3</h3>
								<br />
								<h5>What time would you prefer for sessions?</h5>

								{timeSlotList.map((item, index) => {
									return (
										<button
											key={index}
											className={
												selectedSlots.includes(item.name)
													? 'time-button-active time-button'
													: 'time-button'
											}
											onClick={() => selectSlot(item.name)}
										>
											{item.name}
										</button>
									);
								})}

								<div className="col-12 recommendation-button-container">

									<button
										class="book-prev"
										onClick={() => {
											goBackStep();
										}}
									>
										Back
									</button>
									<button
										class="book-first-next"
										onClick={() => {
											saveTimeSlot();
										}}
									>
										Next
									</button>

								</div>
							</Modal.Body>
						</div>
					)} */}
					{step === 1 && (
						<div class="modal-design modal-design-radio">
							<Modal.Body>
								<h3>Step {step + 1} of 2</h3>
								<br />
								<h6>I am</h6>
								<br />
								<Row>
									<label class="container">
										Parent
										<input
											type="radio"
											name="radio"
											onClick={() => setUserType('parent')}
										/>
										<span class="checkmark"></span>
									</label>
									<label class="container">
										Student
										<input
											type="radio"
											name="radio"
											onClick={() => setUserType('student')}
										/>
										<span class="checkmark"></span>
									</label>
								</Row>

								<br />

								{userType && (
									<>
										<h6>
											What is your {userType === 'parent' ? "child's" : ''}{' '}
											grade level?
										</h6>
										<br />

										<select
											class="dropdown"
											name="grade"
											id="grade"
											value={selectedGrade}
											onChange={(e) => setGrade(e?.target?.value || 0)}
										>
											<option value="1">Elementary</option>
											<option value="2">Middle School</option>
											<option value="3">High School</option>
											<option value="4">College</option>
											<option value="5">Past College</option>
										</select>

										<br />
									</>
								)}

								<div className="col-12 recommendation-button-container">
									<button
										class="book-prev"
										onClick={() => {
											goBackStep();
										}}
									>
										Back
									</button>
									<button
										class="book-first-next"
										onClick={handleRecommendations}
									>
										Recommendations
									</button>
								</div>
							</Modal.Body>
						</div>
					)}
				</Modal>
			)}
		</>
	);
}

export default PerfectTutor;
