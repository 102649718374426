import React from 'react';
import Input from '../../components/settings/Input';
import Header from '../../components/admin/home/Header';

function AdminSettings() {
	return (
		<div>
			<Header />

			<div className="settings-container general-container">
				<div className="settings-general-container">
					<h4 className="settings-header"> Admin Information </h4>
					<div className="row">
						<div className="col-12">
							<div className="settings-box">
								<Input label="Email" value="ali@Peerlinc.com" type='email'/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdminSettings;
