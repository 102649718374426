import React from 'react';

function LoadingPage() {
	return (
		<div className="loading-div">
			<div className="spinner-border" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);
}

export default LoadingPage;
