import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../../components/admin/home/Header';
import LoadingButton from '../../../components/loading/LoadingButton';
import { useHistory } from 'react-router-dom';

import { getTutors } from '../../../services/api';
import TagsInput from 'react-tagsinput';

import './index.scss';

function AdminManageTutorVideo(props) {
	const history = useHistory();

	const tutorVideoId = props?.match?.params?.id || null;
	const [highschools, setHighschools] = useState([]);
	const [alert, setAlert] = useState(null);
	const [loading, setLoading] = useState(false);
	const [state, setState] = useState({
		highschool_id: '',
	});
	const [videoTags, setVideoTags] = useState([]);

	const [tutors, setTutors] = React.useState([]);

	const fetchTutors = async () => {
		const response = await getTutors();
		setTutors(response);
	};

	useEffect(() => {
		fetchTutors();
		fetchHighSchools();
		if (tutorVideoId) {
			fetchVideoDetails();
		}
		// eslint-disable-next-line
	}, []);

	const fetchHighSchools = () => {
		axios
			.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_highschool`,
				{
					action: 'list_highschool',
				}
			)

			.then((res) => {
				var list = res?.data?.data;
				setHighschools(list);
			});
	};

	const fetchVideoDetails = async () => {
		const res = await axios.post(
			`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_tutor_video`,
			{
				action: 'get_tutor_video',
				tutorVideoId: tutorVideoId,
			}
		);
		const { data: videoData } = res?.data || {};
		const {
			is_welcome_video,
			_highschool_id,
			_teacher_id,
			video_url,
			video_tags,
			video_name,
			subject_name,
		} = videoData || {};
		setState({
			...state,
			video_type: is_welcome_video === 1 ? 'welcome' : '',
			highschool_id: _highschool_id,
			tutor_id: _teacher_id,
			video_url,
			video_name,
			subject_name,
		});
		setVideoTags(video_tags || []);
	};

	const handleTagsChange = (tags) => {
		setVideoTags(tags);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const handleCheckChange = (e) => {
		const { name, checked, value } = e.target;
		setState({ ...state, [name]: checked ? value : '' });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setAlert(null);
		const {
			highschool_id,
			subject_name,
			tutor_id,
			video_name,
			video_type,
			video_url,
		} = state || {};

		try {
			const postData = {
				action: 'add_tutor_video',
				highschool_id: highschool_id || null,
				subject_name: subject_name || null,
				tutor_id: tutor_id || null,
				video_name: video_name || null,
				video_type: video_type || null,
				video_url: video_url || null,
				video_tags: videoTags || [],
			};

			if (tutorVideoId) {
				postData.action = 'edit_tutor_video';
				postData.tutorVideoId = tutorVideoId;
			}

			const saveResponse = await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_tutor_video`,
				postData
			);
			const { data: saveData } = saveResponse;
			if (saveData.status) {
				setAlert({
					type: 'success',
					message: saveData.message,
				});
				setTimeout(() => {
					history.push('/admin-videos');
				}, 3000);
			} else {
				setAlert({
					type: 'danger',
					message: saveData.message,
				});
			}

			setLoading(false);
		} catch (err) {
			console.log(err);
			setAlert({
				type: 'danger',
				message: 'Something went wrong! Please try again later.',
			});
		}
	};

	return (
		<div className="page-admin-add-videos">
			<Header />
			<div className="container-fluid">
				<div className="row mt-5">
					<div className="col-md-4"></div>
					<div className="col-md-4">
						<div className="card">
							<h4>{tutorVideoId ? 'Edit' : 'Add'} tutor video</h4>
							<form onSubmit={handleSubmit}>
								<div className="form-group">
									<label>Tutor Name</label>
									<select
										className="form-control"
										name="tutor_id"
										value={state?.tutor_id || ''}
										onChange={handleInputChange}
										required
									>
										<option value="" disabled selected>
											-- Select Tutor --
										</option>
										{tutors.map((item, idx) => {
											return (
												<option key={idx} value={item.id}>
													{item?.name || ''}
												</option>
											);
										})}
									</select>
								</div>

								<div className="form-group">
									<label>Video Name</label>
									<input
										type="text"
										className="form-control"
										name="video_name"
										placeholder="Enter Video name"
										value={state?.video_name || ''}
										onChange={handleInputChange}
										required
									/>
								</div>

								<div className="form-group">
									<label>Subject Name</label>
									<input
										type="text"
										className="form-control"
										name="subject_name"
										placeholder="Enter Subject name"
										value={state?.subject_name || ''}
										onChange={handleInputChange}
									/>
								</div>

								<div className="form-group">
									<label>High School Name</label>
									<select
										className="form-control"
										name="highschool_id"
										value={state?.highschool_id || ''}
										onChange={handleInputChange}
									>
										<option value="" disabled selected>
											-- Select Highschool --
										</option>
										{highschools.map((highschool, idx) => {
											return (
												<option key={idx} value={highschool.id}>
													{highschool.name}
												</option>
											);
										})}
									</select>
								</div>

								<div className="form-group">
									<label>Video Tags</label>
									<TagsInput
										type="text"
										className="form-control tag-input"
										name="video_tags"
										value={videoTags}
										onChange={handleTagsChange}
									/>
								</div>

								<div className="form-group">
									<label>Video Type: </label>
									<label className="check-inline">
										<input
											type="checkbox"
											name="video_type"
											className="form-check"
											value="welcome"
											checked={state?.video_type === 'welcome'}
											onChange={handleCheckChange}
										/>
										Welcome Video
									</label>
								</div>

								<div className="form-group">
									<label>Video URL</label>
									<textarea
										className="form-control"
										name="video_url"
										placeholder="Enter Video URL"
										value={state?.video_url || ''}
										onChange={handleInputChange}
										required
									></textarea>
								</div>

								<div className="form-group text-right">
									<button
										type="submit"
										className="btn-custom-primary"
										disabled={loading}
									>
										{loading ? <LoadingButton /> : 'Submit'}
									</button>
								</div>

								{alert && (
									<div className="form-group">
										<div className={`alert alert-${alert?.type}`} role="alert">
											{alert?.message}
										</div>
									</div>
								)}
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdminManageTutorVideo;
