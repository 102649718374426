import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Session from '../Session';
import LoadingPage from '../../loading/LoadingPage';
import { Failure } from '../../alerts/Alerts';

function UpcomingSessions() {
	const [alert, setAlert] = useState();

	const [sessions, setSessions] = useState([]);

	// Retrieve the 10 upcoming sessions
	useEffect(() => {
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/sessions/`,
				{ params: { timeframeParam: 'future' } }
			)
			.then((res) => {
				setSessions(res.data.Items.slice(0, 10));
			})
			.catch((err) => setAlert(<Failure title="oops!" message={err.message} />));
	}, []);

	// Show sessions on the page
	const [showState, setShowState] = useState(<LoadingPage />);

	useEffect(() => {
		if (sessions.length > 0) {
			setShowState(sessions.map((session) => <Session session={session} />));
		}
	}, [sessions]);

	return (
		<div class="col-4">
			<div class="general-container recent-admin">
				{alert}
				<h5>Upcoming Sessions</h5>
				<div class="all-labels">
					<div class="row">
						<div class="col-3">
							<i class="fal fa-user"></i> Date
						</div>
						<div class="col-3">
							<i class="fal fa-user"></i> Student
						</div>
						<div class="col-3">
							<i class="far fa-clock"></i> Tutor
						</div>
						<div class="col-3">
							<i class="far fa-comments"></i> Status
						</div>
					</div>
				</div>
				<hr />

				{showState}
			</div>
		</div>
	);
}

export default UpcomingSessions;
