import React, { useState, useContext } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { AuthContext } from '../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import Login from '../auth/Login';
import { Modal } from 'react-bootstrap';

export default (props) => {
	const auth = useContext(AuthContext);
	const history = useHistory();

	const [showPopup, setShowPopup] = useState(false);

	const [menu, setMenu] = useState(false);
	const handleClose = () => setShowPopup(false);
	const handleShow = () => {
		handleOnClose();
		setShowPopup(true);
	};

	const handleDashboard = () => {
		handleOnClose();
		if (auth.role === 'tutor') {
			history.push('/tutor-dashboard');
		} else if (auth.role === 'student') {
			history.push('/student-dashboard');
		} else if (auth.role === 'admin') {
			history.push('/admin');
		}
	};

	const handleOnOpen = () => {
		setMenu(true);
	};
	const handleOnClose = () => {
		setMenu(false);
	};

	return (
		// Pass on our props
		<Menu right onOpen={handleOnOpen} onClose={handleOnClose} isOpen={menu}>
			<a href="/pricing" className="header-item">
				Pricing
			</a>
			{/* <a href="http://help.Peerlinc.com/en/" className="header-item">
				Help
			</a> */}
			<li className="nav-item">
				<div>
					{auth.currentUser !== null ? (
						<button
							onClick={handleDashboard}
							type="button"
							className="godash-button login-button"
						>
							<span className="login-text">
								Dashboard <i className="fad fa-angle-double-right"></i>{' '}
							</span>
						</button>
					) : (
						<button type="button" className="login-button" onClick={handleShow}>
							<span className="login-text">Login</span>
						</button>
					)}
				</div>
			</li>

			<Modal show={showPopup} onHide={handleClose}>
				<Modal.Header className="modal-header" closeButton></Modal.Header>
				<Modal.Body className="modal-body">
					<Login newRegister={true} notFromURL />
				</Modal.Body>
			</Modal>
		</Menu>
	);
};
