import React from 'react';
import { ValidationError } from '@statickit/react';
import Header from '../home/Header';
import Footer from '../home/Footer';
import './contact.css';

function Contact() {
	return (
		<div>
			<Header />

			<div className="center-it">
				<h1 className="contact-title">Report a Bug <i class="fas fa-bug"></i> </h1>

				<h4 className="contact-paragraph">Thanks for helping us out :)</h4>

				<form action="https://formspree.io/f/xdopvoqj" method="POST">
					<div>
						{/* NAME */}

						<div>
							<input
								type="name"
								name="name"
								class="contact-input"
								id="name"
								placeholder="Your Name*"
								required
							/>
						</div>

						<ValidationError prefix="Name" field="name" />

						{/* EMAIL */}
						<div>
							<input
								id="email"
								type="email"
								name="email"
								className="contact-input"
								placeholder="Your Email*"
								required
							/>
						</div>

						<ValidationError prefix="Email" field="email" />

						{/* TEXT */}

						<textarea
							type="text"
							name="text"
							className="contact-textarea"
							placeholder="Please describe the bug in detail and email any picture or videos to ali@Peerlinc.com"
							required
						/>

						<ValidationError prefix="Text" field="text" />
					</div>
					{/* BUTTON */}
					<button type="submit">Submit Bug</button>
				</form>
			</div>

			<br />

			<Footer />
		</div>
	);
}

export default Contact;
