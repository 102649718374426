import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import ChatIcon from '../../Icons/message-text.svg';
import moment from 'moment';

const dateTimeStamp = moment().unix();

function Tutors(props) {
	// const [alert, setAlert] = useState();

	const [students, setStudents] = useState([]);
	const [results, setResults] = useState([]);

	useEffect(() => {
		getStudentsData(props.studentHashes);
		// eslint-disable-next-line
	}, [props.studentHashes]);

	const studentPromise = (studentHash) => {
		return new Promise(async (resolve, reject) => {
			axios
				.get(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/students`,
					{
						params: {
							studentParam: studentHash,
						},
					}
				)
				.then((res) => {
					const studentData = res.data;

					// does student exist
					if (!('name' in studentData)) {
						resolve(null);
					} else {
						// Store necessary information
						const studentItems = {
							hash: studentHash,
							image: studentData.image,
							name: studentData.name,
						};

						resolve(studentItems);
					}
				})
				.catch(() => {
					console.log('one student info was deleted');
					resolve(null);
				});
		});
	};

	const getStudentsData = async (studentHashes) => {
		let studentPromises = [];
		// Get data of each student in tutor's students
		for (let s = 0; s < studentHashes.length; s++) {
			if (studentHashes[s].length) {
				studentPromises.push(studentPromise(studentHashes[s]));
			}
		}

		Promise.all(studentPromises).then((data) => {
			data = data.filter((items) => items !== null);
			setStudents(data);
			setResults(data);
		});
	};

	// const handleMessage = (student) => {
	// 	props.setChatUserTwo({
	// 		id: student.hash,
	// 		role: 'student',
	// 	});
	// };

	// Search Funcationality
	const [searchState, setSearch] = useState('');

	function searchOnChange(event) {
		setSearch(event.target.value);
	}

	function handleSearch(e) {
		e.preventDefault();
		var searchResults = [];

		if (searchState === '') {
			setResults(students);
			return;
		}

		var search = searchState.toString().toLowerCase();

		for (var i = 0; i < students.length; i++) {
			var studentName = students[i].name.toLowerCase();

			if (studentName.includes(search)) {
				searchResults.push(students[i]);
			}
		}

		setResults(searchResults);
	}
	return (
		<div className="col-sm-12 col-md-12 col-lg-12">
			<div
				className="tutors-container general-container"
				style={{ height: props.height }}
			>
				{/* {alert} */}
				<div className="row">
					<h2 className="dash-header dash-header-tutor col-lg-3">
						My Students
					</h2>

					<form
						onSubmit={handleSearch}
						className="col-lg-8 col-sm-12 tutor-dash-search"
					>
						<input
							placeholder="Search for a student..."
							className="search-students"
							type="text"
							name="search"
							id="search"
							onChange={searchOnChange}
							value={searchState}
						/>

						<button className="submit-students" type="submit" name="search">
							<i className="black fas fa-search"></i>
						</button>
					</form>
				</div>

				{results.map((student, index) => {
					return (
						<div className="users-a-tutor row" key={index}>
							<div className="col-9">
								<img
									className="tutor-pic"
									src={
										student.image &&
										student.image.length &&
										!student.image.includes('flaticon')
											? `${student.image}?${dateTimeStamp}`
											: 'https://i.ibb.co/xJyCzrB/user.png'
									}
									alt=""
								/>
								<span className="student-name"> {student.name} </span>
							</div>

							{/* <div className="col-2">
								<button
									className="users-message-button"
									onClick={() => handleMessage(student)}
								>
									<img src={ChatIcon} alt="" />
								</button>
							</div> */}
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default Tutors;
