import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TutorProfileCard from '../../components/tutors/TutorProfileCard';
import Header from '../../components/home/Header';
import { Failure } from '../../components/alerts/Alerts';

function TutorProfile(props) {
	const [alert, setAlert] = useState();

	const [id, setId] = useState('');

	// get the Tutor ID based on Tutor Username
	const username = props.match.params.username;
	useEffect(() => {
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/tutors`,
				{
					params: {
						tutorParam: username,
					},
				}
			)
			.then((res) => {
				const tutorData = res.data;
				setId(tutorData.id);
			})
			.catch((err) =>
				setAlert(<Failure title="Oops!" message={err.message + ". Try again or Contact us"} />)
			);
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			<Header />
			{alert}
			<TutorProfileCard id={id} />
		</div>
	);
}

export default TutorProfile;
