import React, { useState, useEffect } from 'react';
import Tutor from '../../components/tutors/Tutor';
//import { useHistory } from 'react-router-dom';
import LoadingPage from '../../components/loading/LoadingPage';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import { Failure } from '../alerts/Alerts';
import './tutor-profile.css';
import Footer from '../../components/home/Footer';

function TutorProfileCard(props) {
	const [alert, setAlert] = useState();

	// Access id value passed from Tutor Component
	// const history = useHistory();
	const id = props.id;
	const [tutor, setTutor] = useState(null);
	const [name, setName] = useState('');
	const [image, setImage] = useState('');
	const [university, setUniversity] = useState('');


	useEffect(() => {

    // Get tutor data and set state values
    if (id && id.length)
      axios
        .get(
          `https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/tutors`,
          {
            params: {
              tutorParam: id,
            },
          }
        )
        .then((res) => {
          const tutorData = res.data;
		  setTutor(tutorData);
          setName(tutorData.name);
          setImage(tutorData.image);
          setUniversity(tutorData.university);
        })
        .catch((err) => setAlert(<Failure title="Oops!" message={err.message + ". Try again or Contact us"} />));

	}, [id]);

	return (
		<div>
			<MetaTags>
				<title>{name}</title>
				<meta name="description" content={university} />
				<meta property="og:title" content={name} />
				<meta property="og:image" content={image} />
			</MetaTags>

			{alert}

			{/* A Tutor */}
			<div>
				{
					tutor ? (
						<Tutor tutor={tutor} readMoreVisible />	
					):(
						<LoadingPage />
					)
				}
			</div>

			<Footer />
		</div>
	);
}

export default TutorProfileCard;