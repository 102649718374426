import React, { useState, useEffect, useContext } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import Input from './Input';
import LoadingButton from '../loading/LoadingButton';
import { toast } from 'react-toastify';
import { MultiSelect } from 'react-multi-select-component';
import { MATHS_COURSES, SCIENCE_COURSES } from '../../config/general';

import './settings.css';
import './index.scss';
import { useLocation } from 'react-router-dom';

const SPECIALTY = [...MATHS_COURSES, ...SCIENCE_COURSES];

function TutorSettings() {
	const auth = useContext(AuthContext);
	const location = useLocation();
	const { tutorId } = location?.state || {};
	var tutorProfileId = null;
	const { role, userHash } = auth || {};
	if (role === 'tutor' || role === 'admin') {
		tutorProfileId = tutorId || userHash || null;
	}

	const handleChange = (e) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const [state, setState] = useState(null);

	const [loading, setLoading] = useState(false);
	const [userImage, setUserImage] = useState(null);
	const [userImagePreview, setUserImagePreview] = useState(null);
	const [selectedSpecialties, setSelectedSpecialties] = useState([]);

	const specialtyOptions = SPECIALTY.map((specialty) => {
		return {
			label: specialty,
			value: specialty,
		};
	});

	const removeSpecialty = (specialty) => {
		const newSpecialties = selectedSpecialties.filter(
			(s) => s.value !== specialty.value
		);
		setSelectedSpecialties(newSpecialties);
	};

	useEffect(() => {
		// Get tutor and set state values if they are logged in
		axios
			.post(`${process.env.REACT_APP_NEW_API_ENDPOINT}/teacher/profile`, {
				tutorId: tutorProfileId,
				action: 'get_profile',
			})
			.then((result) => {
				const { data: userProfile } = result?.data;
				setState(userProfile);

				var userImageUrl = userProfile?.image;
				if (userImageUrl) {
					if (userImageUrl?.includes('flaticon')) {
						userImageUrl =
							'https://peerlinc-app-assets.s3.us-west-1.amazonaws.com/default_avatar.png';
					}
					setUserImagePreview(userImageUrl);
				}

				const { specialties } = userProfile || {};
				if (specialties) {
					const selectedSpecialties = specialties.map((specialty) => {
						return {
							label: specialty,
							value: specialty,
						};
					});
					setSelectedSpecialties(selectedSpecialties);
				}
			});
	}, [tutorProfileId]);

	// Upload Image
	const handleImageChange = async (e) => {
		e.preventDefault();

		let reader = new FileReader();
		let file = e.target.files[0];

		reader.onloadend = () => {
			setUserImagePreview(reader.result);
		};

		reader.readAsDataURL(file);
		setUserImage(file);
	};

	const uploadImage = async (file) => {
		try {
			if (!file) {
				return;
			}

			const response = await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/general/image/upload`,
				{
					folder: 'teacher-images',
					file_name: file?.name || 'user.png',
					content_type: file?.type || 'image/png',
				}
			);
			const { data } = response;
			const { uploadURL } = data;

			const fileUrl = uploadURL.split('?')[0];

			await axios.put(uploadURL, file, {
				headers: {
					'Content-Type': file?.type || 'image/png',
				},
			});
			return fileUrl;
		} catch (error) {
			console.log(error);
		}
	};

	// Convert Youtube link to embed youtube link
	const handleYoutubeLink = (url) => {
		// Regex stuff to get the id from the video url
		const regExp =
			/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
		const match = url.match(regExp);

		const videoId = match && match[2].length === 11 ? match[2] : null;

		return `https://www.youtube.com/embed/${videoId}`;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);
			const {
				name,
				email,
				image,
				phoneNumber,
				youtubeLink,
				about,
				collegeName,
				collegeGraduation,
				highschoolName,
				highschoolGraduation,
			} = state;

			if (!name || !email || !phoneNumber) {
				toast.error('Please fill in all required fields');
				setLoading(false);
				return;
			}

			// Update attributes of user in authentication user pool
			if (auth.role === 'tutor') {
				await Auth.updateUserAttributes(auth.currentUser, {
					name: name,
					email: email,
					phone_number: phoneNumber,
				});
			}

			var userImageUrl = image;
			if (userImage) {
				const imageUploadUrl = await uploadImage(userImage);
				if (imageUploadUrl) {
					userImageUrl = imageUploadUrl;
				}
			}

			// Update user in database
			const tutorPost = await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/teacher/profile`,
				{
					action: 'update_profile',
					tutorId: tutorProfileId,
					name,
					emailId: email,
					phoneNumber,
					youtubeLink: handleYoutubeLink(youtubeLink),
					about,
					imageUrl: userImageUrl,
					collegeName,
					collegeGraduation,
					highschoolName,
					highschoolGraduation,
					specialties: selectedSpecialties.map((s) => s.value),
				}
			);
			const { data } = tutorPost;
			const { status, message } = data;
			if (status) {
				toast.success(message);
			} else {
				toast.error(message);
			}
		} catch (err) {
			const errMessage = err.response?.data?.message;
			if (errMessage) {
				toast.error(errMessage);
			}
		} finally {
			setLoading(false);
		}
	};

	const getDirectLink = () => {
		const host = window.location.origin;
		const tutorUrl = `${host}/tutor/${tutorProfileId}`;
		return tutorUrl;
	};

	const copyDirectLink = () => {
		const tutorUrl = getDirectLink();

		navigator.clipboard.writeText(tutorUrl).then(() => {
			alert('Copied to clipboard: ' + tutorUrl);
		});
	};

	return (
		<>
			<div className="page-edit-profile">
				<div className="container">
					<form onSubmit={handleSubmit}>
						<div className="page-header">
							<h4>Tutor Profile</h4>

							{state?.id && (
								<button
									disabled={loading}
									className="btn-save btn-custom"
									type="submit"
								>
									{loading ? <LoadingButton /> : 'Save Changes'}
								</button>
							)}
						</div>

						<div className="section-card">
							<div className="section-header">
								<h4>General</h4>
							</div>
							<div className="row">
								<div className="col-md-3 col-sm-12">
									<div className="form-label-group">
										<div className="custom-image-picker">
											<input
												type="file"
												id="file"
												className="inputfile"
												accept="image/*"
												onChange={handleImageChange}
											/>
											{userImagePreview ? (
												<img
													src={userImagePreview}
													alt="User"
													className="user-image-preview"
												/>
											) : (
												<img
													src="/images/highschool/student-image-picker.png"
													alt="User"
													className="user-image-preview"
												/>
											)}
											<span>Change</span>
										</div>
									</div>
								</div>
								<div className="col-md-9 col-sm-12">
									<div className="row">
										<div className="col-md-6 col-sm-12">
											<Input
												label="Full Name"
												type="text"
												name={'name'}
												value={state?.name || ''}
												onChange={handleChange}
												disabled={auth?.role !== 'tutor'}
												required
											/>
											<Input
												label="Phone Number"
												type="text"
												name={'phoneNumber'}
												value={state?.phoneNumber || ''}
												onChange={handleChange}
												disabled={auth?.role !== 'tutor'}
												required
											/>
										</div>
										<div className="col-md-6 col-sm-12">
											<Input
												label="Email"
												type="text"
												name={'email'}
												value={state?.email || ''}
												onChange={handleChange}
												disabled={auth?.role !== 'tutor'}
												required
											/>
											<div className="copy-box">
												<p className="txt-title">Direct Profile Access</p>
												<div className="copy-link" onClick={copyDirectLink}>
													<span className="link">{getDirectLink()}</span>
													<span className="icon">
														<i className="fal fa-copy"></i>
													</span>
												</div>
											</div>
										</div>
									</div>

									<Input
										label="Profile video"
										type="text"
										name={'youtubeLink'}
										value={state?.youtubeLink || ''}
										onChange={handleChange}
										required
									/>

									<h3 className="settings-label">Bio</h3>
									<textarea
										className="settings-input"
										name={'about'}
										value={state?.about || ''}
										onChange={handleChange}
										required
									/>
								</div>
							</div>
						</div>

						<div className="section-card">
							<div className="section-header">
								<h4>School information</h4>
							</div>
							<div className="row">
								<div className="col-md-6 col-sm-12">
									<label className="settings-label">College</label>
									<div className="form-label-group">
										<input
											type="text"
											className="settings-input"
											placeholder="College name"
											name={'collegeName'}
											value={state?.collegeName || ''}
											onChange={handleChange}
										/>
									</div>
									<label className="settings-label">High School</label>
									<div className="form-label-group">
										<input
											type="text"
											className="settings-input"
											placeholder="High school name"
											name={'highschoolName'}
											value={state?.highschoolName || ''}
											onChange={handleChange}
										/>
									</div>
								</div>
								<div className="col-md-6 col-sm-12">
									<label className="settings-label">College Graduation</label>
									<div className="form-label-group">
										<input
											type="text"
											className="settings-input"
											placeholder="e.g. Spring, Summer, Winter + year"
											name={'collegeGraduation'}
											value={state?.collegeGraduation || ''}
											onChange={handleChange}
										/>
									</div>
									<label className="settings-label">
										High School Graduation
									</label>
									<div className="form-label-group">
										<input
											type="text"
											className="settings-input"
											placeholder="e.g. class of 2002"
											name={'highschoolGraduation'}
											value={state?.highschoolGraduation || ''}
											onChange={handleChange}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="section-card">
							<div className="section-header">
								<h4>Specialty</h4>
							</div>
							<div className="row">
								<div className="col-md-12 col-sm-12">
									<div className="form-label-group">
										<MultiSelect
											options={specialtyOptions}
											value={selectedSpecialties}
											onChange={setSelectedSpecialties}
										/>

										<div className="specialty-list">
											{selectedSpecialties.map((specialty, index) => (
												<div className="specialty-item" key={index}>
													<span>{specialty.label}</span>
													<span
														className="icon"
														onClick={() => removeSpecialty(specialty)}
													>
														<i className="fal fa-times"></i>
													</span>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default TutorSettings;
