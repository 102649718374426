import React, { useState, useEffect, useContext } from 'react';
import Content from './Content';
import { Modal } from 'react-bootstrap';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { AuthContext } from './../../contexts/AuthContext';
import LoadingButton from '../loading/LoadingButton';
import axios from 'axios';
import PurchaseSubscription from '../dashboard/Trial/Trial';
import { toast } from 'react-toastify';
import './book.css';
import moment from 'moment';

const PER_SESSION_PRICE = 25;

function Book(props) {
	const auth = useContext(AuthContext);
	const { tutor, stopVideo, open } = props;
	const { currentStudent, studentBalance } = auth;
	const stripe = useStripe();
	const elements = useElements();
	// eslint-disable-next-line
	const [payState, setPayState] = useState(`Pay $${PER_SESSION_PRICE}`);
	const [isPurchase, setPurchase] = useState(false);
	const [reset, setReset] = useState(false);
	const [show, setShow] = useState(false);
	const [bookProcess, setBookProcess] = useState(false);
	const [showSubscriptionPurchase, setShowSubscriptionPurchase] =
		useState(false);

	useEffect(() => {
		if (open) {
			handleBookSession();
		}
		// eslint-disable-next-line
	}, [open]);

	const handleBookSession = () => {
		if (typeof stopVideo === 'function') {
			stopVideo();
		}
		if (currentStudent) {
			const {
				subscriptionTxnId,
				subscriptionMode,
				upcomingSessions,
				pastSessions,
				created,
			} = currentStudent;
			const allSessions = [...upcomingSessions, ...pastSessions];
			const filteredSessions = allSessions.filter((session) => session);
			const totalSessionsBooked = filteredSessions?.length || 0;

			const accountCreated = moment(created).format('YYYY-MM-DD');
			const today = moment().format('YYYY-MM-DD');

			// Is 60 days passed?
			const noOfTrialDays = moment(today).diff(accountCreated, 'days');
			const is30DaysPassed = noOfTrialDays > 30;

			if (subscriptionTxnId || subscriptionMode === 'manual') {
				if (studentBalance && studentBalance > 0) {
					setPurchase(false);
				} else {
					setPurchase(true);
				}
				handleShow();
			} else {
				if (totalSessionsBooked >= 2 || is30DaysPassed) {
					setShowSubscriptionPurchase(true);
				} else {
					setPurchase(true);
					handleShow();
				}
			}
			setBookProcess(true);
		}
	};

	const handleShow = () => {
		setShow(true);
	};

	const handleClose = () => {
		setBookProcess(false);
		setShow(false);
		setReset(true);
	};

	const handlePayment = async (e) => {
		setPayState(<LoadingButton />);
		e.preventDefault();

		if (!stripe || !elements) {
			return;
		}
		try {
			const cardElement = elements.getElement(CardElement);
			// PLEASE REMOVE PAYMENTMETHOD IF NOT NEEDED
			// eslint-disable-next-line
			const { error, token } = await stripe.createToken(cardElement);

			if (error) {
				var errorMessage =
					error?.message ||
					'Failed to process credit card. Please try again or contact us';
				toast.error(errorMessage);
			} else {
				const { id } = token;
				const paymentRes = await axios.post(
					`${process.env.REACT_APP_NEW_API_ENDPOINT}/checkout/purchase_session`,
					{
						student_id: auth.userHash,
						token_id: id,
					}
				);
				if (paymentRes && paymentRes.data && paymentRes.data.status) {
					setPurchase(false);
				} else {
					toast.error(paymentRes?.data?.message || 'Something went wrong!');
				}
			}

			setPayState(`Pay $${PER_SESSION_PRICE}`);
		} catch (err) {
			var errMessage =
				err?.response?.data?.message ||
				'Something went wrong! Please try again or contact us';
			setPayState(`Pay $${PER_SESSION_PRICE}`);
			toast.error(errMessage);
		}
	};

	return (
		<div>
			<button
				type="button"
				className="users-book-button"
				onClick={handleBookSession}
			>
				Book
			</button>
			{bookProcess && (
				<>
					{showSubscriptionPurchase && (
						<PurchaseSubscription
							handleClose={() => setShowSubscriptionPurchase(false)}
							isOpen
						/>
					)}
					{!showSubscriptionPurchase && (
						<Modal
							show={show}
							onHide={handleClose}
							className={'book-modal'}
							backdrop="static"
						>
							<Modal.Header closeButton>
								<span className="session-with">
									{isPurchase && 'Purchase'} Session with{' '}
									<strong>{props.tutor && props.tutor.name}</strong>
								</span>
							</Modal.Header>
							<Modal.Body className="modal-body">
								{isPurchase && (
									<>
										<form onSubmit={handlePayment}>
											<div className="stripe-card">
												<CardElement />
											</div>

											<button
												className={'primary-button'}
												type={'submit'}
												disabled={!stripe || !elements}
											>
												{payState}
											</button>
										</form>
									</>
								)}
								{!isPurchase && tutor && (
									<Content
										tutorName={tutor.name}
										tutorAvailability={tutor.availability}
										tutorHash={tutor.hash}
										handleSuccessfulBook={props.handleSuccessfulBook}
										handleClose={handleClose}
										reset={reset}
									/>
								)}
							</Modal.Body>
						</Modal>
					)}
				</>
			)}
		</div>
	);
}

export { Book };
