import moment from 'moment';
import React from 'react';

function Payment(props) {
	const { logType, timestamp, details } = props.payment;

	function setAmount() {
		if (logType.includes('SUBSCRIPTION')) {
			if (
				logType.includes('SUBSCRIPTION_PURCHASED') ||
				logType.includes('SUBSCRIPTION_CREDIT_PURCHASED')
			) {
				var logInfo = logType?.split('##')?.[1]?.split('_')?.[1] || '';
				return logInfo ? `$${logInfo}` : '';
			}
		} else if (logType === 'CASHOUT') {
			// to show only dollar amount no cents
			return '$' + details / 100;
		} else {
			if (logType === 'FOUR') {
				return '$160';
			} else if (logType === 'FIFTEEN') {
				return '$540';
			} else {
				return '$990';
			}
		}
	}

	function getClassName(type) {
		if (type.includes('schedule')) {
			return `payment-badge payment-badge-warning`;
		}
		if (type.includes('cancel')) {
			return `payment-badge payment-badge-danger`;
		}
		if (type.includes('credit')) {
			return `payment-badge payment-badge-success-2`;
		}
		if (type.includes('purchased') || type.includes('renew')) {
			return `payment-badge payment-badge-success`;
		}
		return `payment-badge payment-badge-info`;
	}

	function setType() {
		if (logType.includes('SUBSCRIPTION')) {
			var logInfo = logType?.split('##')?.[0]?.replace(/_/g, ' ') || '';
			logInfo = logInfo.replace(/SUBSCRIPTION/g, '').toLowerCase();
			return `${logInfo}`;
		} else if (logType === 'CASHOUT') {
			return 'Cashout';
		} else if (logType === 'FOUR') {
			return '4 Hours';
		} else if (logType === 'FIFTEEN') {
			return '15 Hours';
		} else {
			return '30 Hours';
		}
	}
	var offset = moment().utcOffset();
	return (
		<div>
			<div class="a-data">
				<div class="row">
					<div class="col-8 text-left">
						<p>{moment.utc(timestamp).utcOffset(offset).format('lll')}</p>
						<span className={getClassName(setType())}>{setType()}</span>
					</div>
					<div class="col-4">
						<span className="txt-price">{setAmount()}</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Payment;
