/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import Nav from '../../components/dashboard/Nav';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import LoadingButton from '../../components/loading/LoadingButton';
import './index.scss';
import NavLogo from '../../components/NavLogo';

function TutorVideos() {
	const auth = useContext(AuthContext);
	const { userHash: tutorId } = auth;

	const [highschools, setHighschools] = useState([]);
	const [loading, setLoading] = useState(false);
	const [formLoading, setFormLoading] = useState('');

	const initialFormData = {
		highschoolId: '',
		videoUrl: '',
	};

	const [studentVideo, setStudentVideo] = useState(initialFormData);

	const [parentVideo, setParentVideo] = useState(initialFormData);

	const handleParentVideoChange = (e) => {
		setParentVideo({
			...parentVideo,
			[e.target.name]: e.target.value,
		});
	};

	const handleStudentVideoChange = (e) => {
		setStudentVideo({
			...studentVideo,
			[e.target.name]: e.target.value,
		});
	};

	useEffect(() => {
		fetchHighschools();
		// eslint-disable-next-line
	}, [tutorId]);

	const saveVideo = async (e, type) => {
		e.preventDefault();

		try {
			setFormLoading(type);

			const results = await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/teacher/videos`,
				{
					action: 'save_video_link',
					tutorId: tutorId,
					highschoolId:
						type === 'student'
							? studentVideo.highschoolId
							: parentVideo.highschoolId,
					videoUrl:
						type === 'student' ? studentVideo.videoUrl : parentVideo.videoUrl,
					userType: type,
				}
			);
			const { data } = results || {};
			const { status, message } = data || {};
			if (status) {
				toast.success(message);
				setParentVideo(initialFormData);
				setStudentVideo(initialFormData);
			} else {
				toast.error(message);
			}
		} catch (error) {
			const errMessage = error?.response?.data?.message || error.message;
			toast.error(errMessage);
		} finally {
			setFormLoading('');
		}
	};

	const fetchHighschools = async () => {
		try {
			setLoading(true);
			const results = await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/teacher/videos`,
				{
					action: 'get_teacher_highschools',
					tutorId: tutorId,
				}
			);
			const { data } = results?.data || {};
			const { highschools } = data || {};
			if (highschools) {
				setHighschools(highschools);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Nav logo={<NavLogo />} settings="/tutor-settings" />

			<div className="dashboard page-videos">
				<div className="section-header">
					<h3>Videos</h3>
				</div>

				<div className="section-content">
					{loading && (
						<div className="page-loading">
							<LoadingButton />
						</div>
					)}
					{!loading && (
						<Row>
							<Col lg={6} xs={12}>
								<div className="video-box">
									<div className="video-box-header">
										<h4>For Students</h4>
									</div>
									<div className="video-box-body">
										<form onSubmit={(e) => saveVideo(e, 'student')}>
											<div className="form-group">
												<label>High School</label>
												<select
													className="form-control"
													name="highschoolId"
													onChange={handleStudentVideoChange}
													value={studentVideo.highschoolId}
													required
												>
													<option value={''} disabled>
														Select High School
													</option>
													{highschools.map((item, index) => (
														<option key={index} value={item.highschool_id}>
															{item.highschool_name}
														</option>
													))}
												</select>
											</div>

											<div className="form-group">
												<label>Video URL</label>
												<textarea
													type="text"
													className="form-control"
													placeholder="Enter video URL"
													name="videoUrl"
													onChange={handleStudentVideoChange}
													value={studentVideo.videoUrl}
													rows="3"
													required
												/>
											</div>

											<div className="form-group">
												<button
													type="submit"
													className="btn btn-primary"
													disabled={formLoading === 'student'}
												>
													{formLoading === 'student' ? (
														<LoadingButton />
													) : (
														'Save'
													)}
												</button>
											</div>
										</form>
									</div>
								</div>
							</Col>

							<Col lg={6} xs={12}>
								<div className="video-box">
									<div className="video-box-header">
										<h4>For Parents</h4>
									</div>
									<div className="video-box-body">
										<form onSubmit={(e) => saveVideo(e, 'parent')}>
											<div className="form-group">
												<label>High School</label>
												<select
													className="form-control"
													name="highschoolId"
													onChange={handleParentVideoChange}
													value={parentVideo.highschoolId}
													required
												>
													<option value={''} disabled>
														Select High School
													</option>
													{highschools.map((item, index) => (
														<option key={index} value={item.highschool_id}>
															{item.highschool_name}
														</option>
													))}
												</select>
											</div>

											<div className="form-group">
												<label>Video URL</label>
												<textarea
													type="text"
													className="form-control"
													placeholder="Enter video URL"
													name="videoUrl"
													onChange={handleParentVideoChange}
													value={parentVideo.videoUrl}
													rows="3"
													required
												/>
											</div>

											<div className="form-group">
												<button
													type="submit"
													className="btn btn-primary"
													disabled={formLoading === 'parent'}
												>
													{formLoading === 'parent' ? (
														<LoadingButton />
													) : (
														'Save'
													)}
												</button>
											</div>
										</form>
									</div>
								</div>
							</Col>
						</Row>
					)}
				</div>
			</div>
		</>
	);
}

export default TutorVideos;
