import axios from 'axios';

export const getTutors = async () => {
	const response = await axios.get(
		`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/tutors`
	);

	const tutorsData = response?.data?.Items || [];
	// console.log(tutorsData)

	// make sure tutors are certified
	const availableTutors = tutorsData?.filter(function (tutor) {
		return tutor.ansariCertified && tutor.availability.length > 1;
	});

	return availableTutors || [];
};
