import React from 'react';

function ATutor(props) {
	return (
		<div className="col-md-4 col-sm-12 col-lg-4">
			<div class="a-tutor-home">
				<div class='row'>
					<img className="tutor-home-image col-sm-5" src={props.image} alt="" />

					<div>
						<p class = 'tutor-name'>
							{' '}
							{props.name}<i class="green fas fa-badge-check"></i>
						</p>
						{/* <p class = 'tutor-hours'>
							<i class="fad fa-stopwatch"></i> {props.hours} Hours Tutored
						</p> */}
						<h5>
							{' '}
							<img
								className="tutor-university"
								src={props.university}
								alt=""
							/>{' '}
							{props.universitytext}
						</h5>
					</div>
				</div>
				
				<div class='row subject-home-div'>
					<button class="subject">{props.subject1}</button>
					<button class="subject">{props.subject2}</button>
					<button class="subject">{props.subject3}</button>
				</div>
				
				{/* <br />
				<br />
				<br /> */}
				{/* <a href={props.profile}>
				<button
					className="home-view-profile btn-outline-dark"
					type="submit"
					name="button"
				>
					View Profile
				</button>
			</a> */}
			</div>
		</div>
	);
}

export default ATutor;
