import React from 'react';

function Footer(props) {
	return (
		<div
			style={{ backgroundColor: props.bgColor ? props.bgColor : 'transparent' }}
		>
			<svg
				data-name="Layer 1"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 1200 120"
				preserveAspectRatio="none"
				style={{ position: 'relative', top: '2px' }}
			>
				<path
					d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
					className="shape-fill"
				></path>
			</svg>
			<footer className="footer-section">
				<div className="row">
					<div className="col-md-12 col-lg-12 col-sm-12">
						{/* <p className="copyright">Copyright &copy; Peerlinc inc. 2020</p> */}
						<p className="made-with">
							Made with <i className="heart-footer fad fa-heart"></i> in SF
						</p>

						<div>
							<a className="terms-link" href="/contact">
								Contact Us
							</a>
						</div>

						<br />
						<a className="terms-link" href="/terms">
							Terms & Conditions
						</a>
						{' | '}
						<a className="terms-link" href="/privacy-policy">
							Privacy Policy
						</a>
						<p className="copyright">
							Copyright &copy; Peerlinc LLC {new Date().getFullYear()} |
							Developed By{' '}
							<a
								href="https://www.micro1.ai/"
								target="_blank"
								rel="noopener noreferrer"
							>
								micro1.ai
							</a>
						</p>
					</div>
				</div>
				<br />
			</footer>
		</div>
	);
}

export default Footer;
