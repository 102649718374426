import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingPage from '../../components/loading/LoadingPage';
import { Failure } from '../../components/alerts/Alerts';
import Payment from './Payment';

function UserTransactions(props) {
	const [payments, setPayments] = useState([]);
	const [alert, setAlert] = useState();
	const [showState, setShowState] = useState(<LoadingPage />);
	const id = props.id;

	useEffect(() => {
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/logs/`,
				{ params: { logType: 'PAYMENTS' } }
			)
			.then((res) => {
				// get payment data
				var unfilteredPayments = res.data.Items;

				// filter based on student/tutor ID
				var filteredPayments = [];

				// Add if they're a tutor
				for (var i = 0; i < unfilteredPayments.length; i++) {
					if (unfilteredPayments[i].tutorId === id) {
						filteredPayments.push(unfilteredPayments[i]);
					}
				}

				// Add if they're a student
				for (var j = 0; j < unfilteredPayments.length; j++) {
					if (unfilteredPayments[j].studentId === id) {
						filteredPayments.push(unfilteredPayments[j]);
					}
				}

				// Sort by date
				var sortedPayments = filteredPayments.reverse();
				setPayments(sortedPayments);
			})
			.catch((err) =>
				setAlert(<Failure title="Oops!" message={err.message} />)
			);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (payments.length > 0) {
			setShowState(payments.map((payment) => <Payment payment={payment} />));
		}
		if (payments.length === 0) {
			setShowState(<p>No transactions found.</p>);
		}
	}, [payments]);

	return (
		<div>
			<div class="admin-container">
				{alert}
				<h2>Transactions</h2>
				<div class="all-container">
					<div class="all-labels">
						<div class="row">
							<div class="col-4">
								<i class="far fa-clock"></i> Date
							</div>
							<div class="col-4">
								<i class="far fa-money-bill-wave-alt"></i> Amount
							</div>
							<div class="col-4">
								<i class="fas fa-typewriter"></i> Type
							</div>
						</div>
					</div>
					{showState}
				</div>
			</div>
		</div>
	);
}

export default UserTransactions;
