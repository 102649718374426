import React, {useContext} from 'react'
import {Route} from 'react-router-dom'
import {AuthContext} from '../contexts/AuthContext'
import Login from '../components/auth/Login'
import TutorDashboard from '../pages/private/TutorDashboard'
import StudentDashboard from '../pages/private/StudentDashboard'
import {useHistory} from 'react-router-dom'

// Route that only admins can access
function AdminRoute({component: Component, ...rest}) {

    const history = useHistory()

    const auth = useContext(AuthContext)

    return (
        <Route {...rest} render={props => {
            if (auth.currentUser !== null) {
                // Continue to page if admin, else redirect to appropriate dashboard
                if (auth.role === 'admin')
                    return <Component {...props} />
                else if (auth.role === 'tutor') {
                    history.push('/tutor-dashboard')
                    return <TutorDashboard {...props} />
                }
                else if (auth.role === 'student') {
                    history.push('/student-dashboard')
                    return <StudentDashboard {...props} />
                }
            } else {
                // Return to login page if user is not logged in
                history.push('/login')
                return <Login {...props} />
            }
        }} />
    )
}

export default AdminRoute
