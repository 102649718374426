import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import './dashboard.css';
import { Failure } from '../alerts/Alerts';

function Nav(props) {
	const history = useHistory();
	const auth = useContext(AuthContext);
	const location = useLocation();
	const { tutorId } = location?.state || {};

	const { isExpired } = props;

	const [alert, setAlert] = useState();
	const [image, setImage] = useState(null);

	const handleLogout = async () => {
		try {
			auth.logout();
			auth.setCurrentStudent(null);
			history.push('/');
		} catch (error) {
			setAlert(
				<Failure title="oops!" message="Something went wrong. Contact us." />
			);
		}
	};

	useEffect(() => {
		let endpoint = '';
		let param = '';

		var userProfileId = null;
		if (auth.role === 'tutor' || tutorId) {
			endpoint = 'tutors';
			param = 'tutorParam';
			userProfileId = tutorId || auth?.userHash;
		} else if (auth.role === 'student') {
			endpoint = 'students';
			param = 'studentParam';
			userProfileId = auth?.userHash;
		}
		if (endpoint && param) {
			axios
				.get(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/${endpoint}`,
					{
						params: {
							[param]: userProfileId,
						},
					}
				)
				.then((res) => {
					setImage(res.data.image);
				})
				.catch(() => {
					setAlert(
						<Failure
							title="oops!"
							message="Something went wrong. Contact us."
						/>
					);
				});
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (auth.role === 'student') {
			axios
				.post(
					`${process.env.REACT_APP_NEW_API_ENDPOINT}/checkout/student_profile`,
					{
						student_id: auth.userHash,
					}
				)
				.then((result) => {
					var response = result.data;
					if (response && response.status) {
						auth.setCurrentStudent(response.data);
					}
				})
				.catch(() => {
					setAlert(
						<Failure
							title="oops!"
							message="Something went wrong. Contact us."
						/>
					);
				});
		}
		// eslint-disable-next-line
	}, []);

	const getActiveClass = (path) => {
		return history?.location?.pathname?.includes(path)
			? 'dropdown-item selected'
			: 'dropdown-item';
	};

	const MenuItem = (props) => {
		const { link, icon, label } = props;
		return (
			<a className={getActiveClass(link)} href={`/${link}`}>
				<i className={`fal ${icon}`}></i> {label}
			</a>
		);
	};

	const dashboardLink = (type = 'dashboard') => {
		const { role } = auth || {};
		if (!role) return '/login';
		if (role === 'tutor') {
			return `/tutor-${type}`;
		}
		if (role === 'student') {
			return `/student-${type}`;
		}
		return '/login';
	};

	return (
		<div className="dashboard-header">
			{alert}
			<div className="nav-img-div">{props?.logo}</div>

			{auth.role === 'student' ? (
				<div className="referral-container"></div>
			) : null}

			{!tutorId && (
				<div className="dropdown-custom">
					<div className=" dropdown">
						<button
							className="dropdown-button dropdown-toggle"
							type="button"
							id="dropdownMenuLink"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<img
								className="dash-profile"
								src={
									image && !image.includes('flaticon')
										? image
										: 'https://i.ibb.co/xJyCzrB/user.png'
								}
								alt="AA"
							/>
							<i className="profile-dropdown fas fa-caret-down"></i>
						</button>
						<ul
							className="dropdown-menu dropdown-menu-right"
							aria-labelledby="dropdownMenuLink"
						>
							<p className="dropdown-welcome">
								Hi,{' '}
								<span className="dropdown-welcome-name">
									{auth.currentUser.attributes.name}
								</span>
							</p>

							<a className={getActiveClass('dashboard')} href={dashboardLink()}>
								<i className="fal fa-moon"></i> Dashboard
							</a>
							{isExpired ? (
								<>
									<div
										className="dropdown-logout dropdown-item"
										onClick={handleLogout}
									>
										<i className="fal fa-door-open"></i> Logout
									</div>
								</>
							) : (
								<>
									{auth.role === 'tutor' ? (
										<>
											<MenuItem
												link="tutor-videos"
												icon="fa-video"
												label="Videos"
											/>
											<MenuItem
												link="tutor-schools"
												icon="fa-school"
												label="Schools"
											/>
											<MenuItem
												link="tutor-availability"
												icon="fa-calendar"
												label="Availability"
											/>
											<MenuItem link="tutor-tnc" icon="fa-file" label="T&C" />
											<MenuItem
												link="tutor-payment"
												icon="fa-sack-dollar"
												label="Payment"
											/>
										</>
									) : (
										<>
											<MenuItem link="tutoring" icon="fa-tv" label="Tutoring" />
											<MenuItem
												link="student-payment"
												icon="fa-sack-dollar"
												label="Payment"
											/>
										</>
									)}

									{/* If user is logged in, redirect to appropriate page (student or tutor). If not, redirect to login */}
									<a
										className={getActiveClass('settings')}
										href={dashboardLink('settings')}
									>
										<i className="fal fa-cog"></i> Settings
									</a>
									<div
										className="dropdown-logout dropdown-item"
										onClick={handleLogout}
									>
										<i className="fal fa-door-open"></i> Logout
									</div>
								</>
							)}
						</ul>
					</div>
				</div>
			)}
		</div>
	);
}

export default Nav;
