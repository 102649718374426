import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { utcStringToLocalDate } from '../../../tools/timeConverter';
import Header from '../../../components/admin/home/Header';
import LoadingPage from '../../../components/loading/LoadingPage';
import { Failure } from '../../../components/alerts/Alerts';
import moment from 'moment';
import './index.scss';
import { Button } from 'react-bootstrap';
const queryString = require('query-string');

function AdminListHighschoolGroups(props) {
	const { id: highschoolId } = props.match.params;
	const parsed = queryString.parse(props?.location?.search);
	const [alert, setAlert] = useState();
	const [groups, setGroups] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchGroups();
		// eslint-disable-next-line
	}, []);

	const fetchGroups = () => {
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_highschool_groups`,
				{
					action: 'list_highschool_groups',
					id: highschoolId,
				}
			)
			.then((res) => {
				var list = res?.data?.data;
				var sortedList = list.sort(
					(aStudent, bStudent) =>
						utcStringToLocalDate(bStudent?.date_created) -
						utcStringToLocalDate(aStudent?.date_created)
				);
				setGroups(sortedList);
			})
			.catch(() =>
				setAlert(
					<Failure
						title="Oops!"
						message="Something happened. Try again or contact us."
					/>
				)
			)
			.finally(() => setLoading(false));
	};

	const handleDelete = (groupId) => {
		// confirm delete
		const confirmDelete = window.confirm(
			'Are you sure you want to delete this group?'
		);
		if (confirmDelete) {
			setLoading(true);
			axios
				.post(
					`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_highschool_groups`,
					{
						action: 'delete_highschool_group',
						groupId: groupId,
					}
				)
				.then((res) => {
					if (res?.data?.status) {
						// remove from list
						const newGroups = groups.filter(
							(group) => group.group_id !== groupId
						);
						setGroups(newGroups);
					}
				})
				.catch((err) => console.log(err))
				.finally(() => setLoading(false));
		}
	};

	const displayMembers = (members) => {
		if (!members || members.length === 0) return 'No members';
		var membersList = members.map((member) => member?.name);
		return membersList.join(', ');
	};

	const displayLastActivity = (item) => {
		const { last_activity_date, date_modified } = item;
		if (last_activity_date) {
			// display ago
			return moment.utc(last_activity_date).local().fromNow();
		} else if (date_modified) {
			return moment.utc(date_modified).local().fromNow();
		} else {
			return 'N/A';
		}
	};

	if (loading) {
		return <LoadingPage />;
	}

	return (
		<div className="page-admin-highschool">
			<Header />
			{alert}
			<div className="container-fluid">
				<div className="row mt-5 mb-4">
					<div className="col-md-5">
						<h4 className="admin-title">
							{parsed?.name} Groups ({groups?.length})
						</h4>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						{groups.length > 0 ? (
							<table className="table table-hover">
								<thead>
									<tr>
										<th scope="col">Date Created</th>
										<th scope="col">Group Name</th>
										<th scope="col">Group Members</th>
										<th scope="col">Total</th>
										<th scope="col">Last activity</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>
									{groups.map((item, index) => (
										<tr key={index}>
											<td>
												{moment(item?.date_created)
													.local()
													.format('DD MMM, YYYY')}
											</td>
											<td>{item?.group_name}</td>
											<td>{displayMembers(item?.members)}</td>
											<td>{item?.members?.length}</td>
											<td>{displayLastActivity(item)}</td>
											<td>
												<Button
													className="btn-delete"
													onClick={() => handleDelete(item?.group_id)}
												>
													<i className="fas fa-trash-alt"></i>
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							<p className="text-center">No groups found</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdminListHighschoolGroups;
