import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import SaveButton from '../settings/SaveButton';
import Input from '../settings/Input';
import LoadingButton from '../loading/LoadingButton';
import Session from './Session';
import { Failure, Success } from '../alerts/Alerts';
import UserTransactions from './UserTransactions';
import './index.scss';

function DetailedTutor(props) {
	// Alerts
	const [alert, setAlert] = useState();

	// Load State
	const [updateLoadState, setUpdateLoadState] = useState('Update');
	const [certifyLoadState, setCertifyLoadState] = useState('Certify');

	// Access id value
	const history = useHistory();
	const id = props.id;

	const [tutorProfile, setTutorProfile] = useState(null);

	// To show tutor data
	const [created, setCreated] = useState('');
	const [seen, setSeen] = useState('');
	const [certify, setCertify] = useState('');
	const [upcomingSessionsIds, setUpcomingSessionsIds] = useState([]);
	const [pastSessionsIds, setPastSessionsIds] = useState([]);
	const [tncAgreed, setTncAgreed] = useState(false);

	// Retrieve Tutor Data and set values
	useEffect(() => {
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/tutors/`,
				{
					params: {
						tutorParam: id,
					},
				}
			)
			.then((res) => {
				const tutorData = res.data;

				// Date Created conversion
				let createDate = new Date(tutorData.created);
				let createDateYear = createDate.getFullYear();
				let createDateMonth = createDate.getMonth() + 1;
				let createDateDay = createDate.getDate();
				let formattedCreateDate = `${createDateMonth}/${createDateDay}/${createDateYear}`;

				// Last seen date conversion
				moment().format();
				let seenDate = new Date(tutorData.lastVisited);
				let formattedSeenDate = moment(seenDate).fromNow();

				setTutorProfile(tutorData);

				// Set the values for user ingo
				setCreated(formattedCreateDate);
				setSeen(formattedSeenDate);
				setBalance(tutorData.balance);
				setCertify(tutorData.ansariCertified);
				setUpcomingSessionsIds(tutorData.upcomingSessions);
				setPastSessionsIds(tutorData.pastSessions);
				setTncAgreed(tutorData?.tncAgreed || false);
			})
			.catch((err) =>
				setAlert(<Failure title="Oops!" message={err.message} />)
			);
	}, [id]);

	const viewAsTutor = (path) => {
		history.push(`${path}`, { tutorId: id });
	};

	// Change Tutor's Balance
	const [balance, setBalance] = useState('');

	function changeBalance(event) {
		setBalance(Number(event.target.value));
	}

	function updateBalance() {
		setUpdateLoadState(<LoadingButton />);

		axios
			.post(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/tutors/`,
				{
					balanceParam: balance,
					tutorParam: id,
				}
			)
			.then((res) => {
				setUpdateLoadState('Update');
				setAlert(
					<Success title="Here's what happened:" message={res.data.message} />
				);
			})
			.catch((err) =>
				setAlert(<Failure title="Oops!" message={err.message} />)
			);
	}

	// Certify Tutor
	function certifyTutor() {
		setCertifyLoadState(<LoadingButton />);

		if (!certify) {
			axios
				.post(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/tutors/`,
					{
						ansariCertifiedParam: true,
						tutorParam: id,
					}
				)
				.then((res) => {
					setCertify(true);
					setCertifyLoadState('Certified');
					setAlert(
						<Success title="Here's what happened:" message={res.data.message} />
					);
				})
				.catch((err) =>
					setAlert(<Failure title="Oops!" message={err.message} />)
				);
		} else {
			axios
				.post(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/tutors/`,
					{
						ansariCertifiedParam: false,
						tutorParam: id,
					}
				)
				.then((res) => {
					setCertify(false);
					setCertifyLoadState('Certify');
					setAlert(
						<Success title="Here's what happened:" message={res.data.message} />
					);
				})
				.catch((err) => setAlert(<Failure title="oops!" message={err} />));
		}
	}

	// Show Upcoming Sessions
	const [upcomingSessions, setUpcomingSessions] = useState([]);

	useEffect(() => {
		for (let s = 1; s < upcomingSessionsIds.length; s++) {
			axios
				.get(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/sessions/`,
					{
						params: {
							sessionParam: upcomingSessionsIds[s],
						},
					}
				)
				.then((res) => {
					setUpcomingSessions((prevSessions) => [res.data, ...prevSessions]);
				})
				.catch((err) =>
					setAlert(<Failure title="Oops!" message={err.message} />)
				);
		}
	}, [upcomingSessionsIds]);

	// Show Past Sessions
	const [pastSessions, setPastSessions] = useState([]);

	useEffect(() => {
		for (let s = 1; s < pastSessionsIds.length; s++) {
			axios
				.get(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/sessions/`,
					{
						params: {
							sessionParam: pastSessionsIds[s],
						},
					}
				)
				.then((res) => {
					setPastSessions((prevSessions) => [res.data, ...prevSessions]);
				})
				.catch((err) =>
					setAlert(<Failure title="Oops!" message={err.message} />)
				);
		}
		// eslint-disable-next-line
	}, [pastSessionsIds]);

	// Delete Tutor
	function deleteTutor() {
		axios
			.delete(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/tutors/`,
				{
					data: {
						tutorParam: id,
					},
				}
			)
			.then(() => {
				// reload
				history.go(0);
			})
			.catch((err) =>
				setAlert(<Failure title="Oops!" message={err.message} />)
			);
	}

	return (
		<div>
			<div className="general-container profile-details">
				{alert}
				<div className="row">
					<div className="col-lg-6 col-md-6 col-sm-12">
						<div className="settings-box">
							<table className="table table-striped">
								<tbody>
									<tr>
										<td>Username:</td>
										<td>
											<a href={'/tutor/' + id}>{tutorProfile?.username}</a>
										</td>
									</tr>
									<tr>
										<td>Name:</td>
										<td>{tutorProfile?.name}</td>
									</tr>
									<tr>
										<td>Phone Number:</td>
										<td>{tutorProfile?.phoneNumber}</td>
									</tr>
									<tr>
										<td>Email:</td>
										<td>{tutorProfile?.email}</td>
									</tr>
									<tr>
										<td>Specialties:</td>
										<td>{tutorProfile?.specialties?.join(', ')}</td>
									</tr>
									<tr>
										<td>Account made:</td>
										<td>{created}</td>
									</tr>
									<tr>
										<td>Last seen:</td>
										<td>{seen}</td>
									</tr>
									<tr>
										<td>Certified:</td>
										<td>
											{certify ? (
												<span className="badge badge-yes">Yes</span>
											) : (
												<span className="badge badge-no">No</span>
											)}
										</td>
									</tr>
									<tr>
										<td>TnC Agreed:</td>
										<td>
											{tncAgreed ? (
												<span className="badge badge-yes">Yes</span>
											) : (
												<span className="badge badge-no">No</span>
											)}
										</td>
									</tr>
								</tbody>
							</table>

							<div className="row">
								<div className="col-6">
									<button onClick={certifyTutor} className="btn btn-success">
										{certifyLoadState}
									</button>
								</div>
								<div className="col-6">
									<button onClick={deleteTutor} className="btn btn-danger">
										Delete
									</button>
								</div>
							</div>

							<div className="mt-2 btn-groups">
								<button
									onClick={() => viewAsTutor('tutor-settings')}
									className="btn btn-black"
								>
									View settings
								</button>
								<button
									onClick={() => viewAsTutor('tutor-availability')}
									className="btn btn-black"
								>
									View availability
								</button>
							</div>
						</div>
						<br />
					</div>

					<div className="col-lg-6 col-md-6 col-sm-12">
						<div className="box-balance">
							<Input
								label="Change Balance"
								value={balance}
								onChange={changeBalance}
								type="number"
							/>

							<SaveButton onClick={updateBalance} loadState={updateLoadState} />
						</div>
						<div className="admin-sessions general-container">
							<p>Upcoming Sessions</p>

							{upcomingSessions.map((session) => (
								<Session session={session} />
							))}
						</div>

						<br />
						<div className="admin-sessions general-container">
							<p>Past Sessions</p>
							{pastSessions.map((session) => (
								<Session session={session} />
							))}
						</div>

						<UserTransactions id={id} />

						<br />
					</div>
					<div className="row">
						<div className="tutor-bio">
							<h5>Tutor Bio</h5>
							<p>{tutorProfile?.about}</p>
						</div>
					</div>
				</div>
			</div>

			<p className="center-it explain-text">{id}</p>
		</div>
	);
}

export default DetailedTutor;
