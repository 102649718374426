import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Failure } from '../../alerts/Alerts';

function RegisterToFreeSession(props) {
	const [rate, setRate] = useState([]);

	useEffect(() => {
		if (props.freeSessions && props.registers) {
			// Set Rate
			var rate = (props.freeSessions / props.registers).toPrecision(3);

			setRate(rate);
		}
	}, [props.freeSessions, props.registers]);

	return (
		<div class="col-4">
			<div class="analytic-box">
				<p className="txt-title">Register {'>'} Free Session</p>
				<p className="txt-value">
					<span>{rate}</span>
				</p>
			</div>
		</div>
	);
}

function FreeSessionToClient(props) {
	const [rate, setRate] = useState([]);

	useEffect(() => {
		if (props.freeSessions && props.clients) {
			// Set Rate
			var rate = (props.clients / props.freeSessions).toPrecision(3);

			setRate(rate);
		}
	}, [props.freeSessions, props.clients]);

	return (
		<div class="col-4">
			<div class="analytic-box">
				<p className="txt-title">Free Session {'>'} Client</p>
				<p className="txt-value">
					<span>{rate}</span>
				</p>
			</div>
		</div>
	);
}

function RegisterToClient(props) {
	const [rate, setRate] = useState([]);

	useEffect(() => {
		if (props.registers && props.clients) {
			// Set Rate
			var rate = (props.clients / props.registers).toPrecision(3);

			setRate(rate);
		}
	}, [props.registers, props.clients]);

	return (
		<div class="col-4">
			<div class="analytic-box">
				<p className="txt-title">Register {'>'} Client</p>
				<p className="txt-value">
					<span>{rate}</span>
				</p>
			</div>
		</div>
	);
}

function Rates() {
	const [alert, setAlert] = useState();
	const [registers, setRegisters] = useState();
	const [freeSessions, setFreeSessions] = useState();
	const [clients, setClients] = useState();

	// Get all data and pass to components
	useEffect(() => {
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/students`
			)

			.then((res) => {
				var students = res.data.Items;
				var freeSessions = students.filter(function (student) {
					return student.firstSessionBooked === true;
				});
				var clients = students.filter(function (student) {
					return student.firstPackagePurchased === true;
				});

				setRegisters(students.length);
				setFreeSessions(freeSessions.length);
				setClients(clients.length);
			})
			.catch((err) =>
				setAlert(<Failure title="Oops!" message={err.message} />)
			);
	}, []);
	return (
		<div class="row">
			{alert}
			<RegisterToFreeSession
				registers={registers}
				freeSessions={freeSessions}
			/>
			<FreeSessionToClient freeSessions={freeSessions} clients={clients} />
			<RegisterToClient registers={registers} clients={clients} />
		</div>
	);
}

export default Rates;
