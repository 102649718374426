import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingButton from '../../loading/LoadingButton';
import { Sessions, Students, Tutors } from './Totals';
import { numberWithCommas } from '../../../tools/utils';
// import moment from 'moment';

function SubscriptionReports() {
	const [loading, setLoading] = useState(true);
	const [transactions, setTransactions] = useState([]);
	const [payments, setPayments] = useState([]);
	const [total, setTotal] = useState();
	const [totalRevenue, setTotalRevenue] = useState();
	const [students, setStudents] = useState([]);
	const [manualSubscription, setManualSubscription] = useState(0);
	const [monthlySubscriptions, setMonthlySubscriptions] = useState(0);
	const [monthlySubscriptionsAmount, setMonthlySubscriptionsAmount] =
		useState(0);
	const [yearlySubscriptions, setYearlySubscriptions] = useState(0);
	const [yearlySubscriptionsAmount, setYearlySubscriptionsAmount] = useState(0);

	// Retrieve payments
	useEffect(() => {
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/logs/`,
				{ params: { logType: 'PAYMENTS' } }
			)
			.then((res) => {
				let sortedPayments = res.data.Items.reverse();
				setPayments(sortedPayments);
			});
	}, []);

	useEffect(() => {
		var foursAmount = 0;
		var fifteensAmount = 0;
		var thirtiesAmount = 0;

		for (let i = 0; i < payments.length; i++) {
			if (payments[i].logType === 'FOUR') {
				foursAmount += 1;
			}
			if (payments[i].logType === 'FIFTEEN') {
				fifteensAmount += 1;
			}
			if (payments[i].logType === 'THIRTY') {
				thirtiesAmount += 1;
			}
		}

		var subTotal =
			foursAmount * 160 + fifteensAmount * 540 + thirtiesAmount * 990;
		setTotal(subTotal);
	}, [payments]);

	// Retrieve Students
	useEffect(() => {
		axios
			.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/list/subscription`,
				{}
			)
			.then((res) => {
				var rowItems = res?.data?.data || [];
				setTransactions(rowItems);
			})
			.finally(() => {
				setLoading(false);
			});
		axios
			.post(`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/list/students`, {})
			.then((res) => {
				var rowItems = res?.data?.data || [];
				setStudents(rowItems);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		var totalMonthlySubscriptionItems = students.filter(
			(item) =>
				item.subscriptionTxnId &&
				item.subscriptionTxnId.includes('sub_') &&
				item.subscriptionFrequency === 'month' &&
				item.subscriptionMode === 'automatic'
		);
		var totalMonthlySubscription = totalMonthlySubscriptionItems?.length || 0;
		setMonthlySubscriptions(totalMonthlySubscription);

		/* Monthy Subscription Revenue */
		// var totalMonthRevenueItems = transactions.filter(
		// 	(item) =>
		// 	item.logType.includes('SUBSCRIPTION_PURCHASED##month')||
		// 	item.logType.includes('SUBSCRIPTION_RENEWED##one_47')
		// );
		// var totalMonthRevenue = totalMonthRevenueItems?.length || 0;
		setMonthlySubscriptionsAmount(totalMonthlySubscription * 47);

		/* Active Yearly Subscriptions */

		var totalYearlySubscriptionItems = students.filter(
			(item) =>
				item.subscriptionTxnId &&
				item.subscriptionTxnId.includes('sub_') &&
				item.subscriptionFrequency === 'year' &&
				item.subscriptionMode === 'automatic'
		);
		var totalYearlySubscription = totalYearlySubscriptionItems?.length || 0;
		setYearlySubscriptions(totalYearlySubscription);
		setYearlySubscriptionsAmount(totalYearlySubscription * 276);

		var totalManualSubscriptionItems = students.filter(
			(item) => item.subscriptionMode === 'manual'
		);
		var totalManualSubscription = totalManualSubscriptionItems?.length || 0;
		setManualSubscription(totalManualSubscription);
	}, [students]);

	useEffect(() => {
		var subTotal = 0;
		for (let i = 0; i < transactions.length; i++) {
			var logType = transactions[i]['logType'] || '';
			if (logType.includes('SUBSCRIPTION')) {
				if (
					logType.includes('SUBSCRIPTION_PURCHASED') ||
					logType.includes('SUBSCRIPTION_CREDIT_PURCHASED') ||
					logType.includes('SUBSCRIPTION_RENEWED')
				) {
					var amount = logType?.split('##')?.[1]?.split('_')?.[1] || '0';
					if (amount > 0) subTotal += parseFloat(amount);
				}
			}
		}
		var totalRevenue = total + subTotal + 45000;
		if(totalRevenue > 0) totalRevenue = numberWithCommas(totalRevenue);
		setTotalRevenue(totalRevenue);
	}, [total, transactions]);

	return (
		<div>
			{loading ? (
				<LoadingButton />
			) : (
				<>
					<h2 class="data-title">Totals</h2>

					<div class="row">
						<Sessions />
						<Students />
						<Tutors />
						<div class="col-3">
							<div class="analytic-box">
								<p className="txt-value">
									<span>${totalRevenue}</span>
								</p>
								<p className="txt-title">Total Revenue</p>
							</div>
						</div>
					</div>

					<br />
					<h2 class="data-title">Subscriptions</h2>
					<div class="row">
						<div class="col-3">
							<div class="analytic-box">
								<p className="txt-title">Monthly subscriptions</p>
								<p className="txt-value">
									<span>{monthlySubscriptions}</span> ($
									{monthlySubscriptionsAmount})
								</p>
							</div>
						</div>
						<div class="col-3">
							<div class="analytic-box">
								<p className="txt-title">Yearly subscriptions</p>
								<p className="txt-value">
									<span>{yearlySubscriptions}</span> ($
									{yearlySubscriptionsAmount})
								</p>
							</div>
						</div>
						<div class="col-3">
							<div class="analytic-box">
								<p className="txt-title">Manual Subscriptions</p>
								<p className="txt-value">
									<span>{manualSubscription}</span>
								</p>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default SubscriptionReports;
