import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import { utcStringToLocalDate } from '../../tools/timeConverter';
import Availability from '../../components/book/Availability';
import LoadingButton from '../../components/loading/LoadingButton';
import { toast } from 'react-toastify';
import Nav from '../../components/dashboard/Nav';

import './index.scss';
import { useLocation } from 'react-router-dom';
import NavLogo from '../../components/NavLogo';

function TutorAvailability() {
	const auth = useContext(AuthContext);
	const location = useLocation();
	const { tutorId } = location?.state || {};
	var tutorProfileId = null;

	const { role, userHash } = auth || {};
	if (role === 'tutor' || role === 'admin') {
		tutorProfileId = tutorId || userHash || null;
	}

	const [availability, setAvailability] = useState([]);

	const [newAvailableSessions, setNewAvailableSessions] = useState([]);

	const [loading, setLoading] = useState(false);
	const [dataLoading, setDataLoading] = useState(true);

	useEffect(() => {
		// Get tutor and set state values if they are logged in
		if (!tutorProfileId) return;

		setDataLoading(true);
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/tutors`,
				{
					params: {
						tutorParam: tutorProfileId,
					},
				}
			)
			.then((res) => {
				const tutorData = res.data;
				setAvailability(tutorData.availability);

				const curAvailability = tutorData.availability;
				let newAvailableSessionTime = [];

				// Prefill available times with previous settings
				for (let i = 0; i < curAvailability.length; i++) {
					if (curAvailability[i].length) {
						const [availableTime, numHours] = curAvailability[i].split('|');

						const availableDate = utcStringToLocalDate(availableTime);

						let startHour = ('0' + availableDate.getHours()).slice(-2);
						let startMinute = ('0' + availableDate.getMinutes()).slice(-2);
						let endHour = (
							'0' +
							(parseInt(startHour) + parseInt(numHours))
						).slice(-2);
						let endMinute = startMinute;

						// TEMPORARY FIX, CHANGE TO HANDLE TIMEZONE
						if (parseInt(endHour) > 23) endHour = '23';

						const newSlot = {
							start: `${startHour}:${startMinute}`,
							end: `${endHour}:${endMinute}`,
						};

						if (newAvailableSessionTime[availableDate.getDay()]) {
							let combined = false;
							for (
								let i = 0;
								i < newAvailableSessionTime[availableDate.getDay()].length;
								i++
							) {
								if (
									newAvailableSessionTime[availableDate.getDay()][i].end ===
									newSlot.start
								) {
									newAvailableSessionTime[availableDate.getDay()][i].end =
										newSlot.end;
									combined = true;
									break;
								} else if (
									newAvailableSessionTime[availableDate.getDay()][i].start ===
									newSlot.end
								) {
									newAvailableSessionTime[availableDate.getDay()][i].start =
										newSlot.start;
									combined = true;
									break;
								}
							}
							if (!combined)
								newAvailableSessionTime[availableDate.getDay()][1] = newSlot;
						} else {
							newAvailableSessionTime[availableDate.getDay()] = [];
							newAvailableSessionTime[availableDate.getDay()][0] = newSlot;
						}
					}
				}

				setNewAvailableSessions(newAvailableSessionTime);
				setDataLoading(false);
			})
			.finally(() => {
				setDataLoading(false);
			});
	}, [tutorProfileId]);

	const handleSubmit = async (e) => {
		try {
			setLoading(true);

			let newAvailability = [''];
			let availabilityError = '';

			// Set tutor's available sessions
			for (let i = 0; i < newAvailableSessions.length; i++) {
				let dayOfWeek = newAvailableSessions[i];

				// Check if tutor is available on a certain day of week
				if (dayOfWeek) {
					// Error if availability times on the same day overlap
					if (dayOfWeek.length > 1) {
						let time1 = dayOfWeek[0];
						let time2 = dayOfWeek[1];

						if (time1 && time2) {
							let start1 = parseInt(time1.start);
							let end1 = parseInt(time1.end);
							let start2 = parseInt(time2.start);
							let end2 = parseInt(time2.end);

							if (
								(start1 < end2 && start1 >= start2) ||
								(end1 <= end2 && end1 > start2) ||
								(start1 <= start2 && end1 >= end2)
							) {
								availabilityError =
									'Make sure availability times on the same day do not overlap';
								break;
							}
							if (
								(start2 < end1 && start2 >= start1) ||
								(end2 <= end1 && end2 > start1) ||
								(start2 <= start1 && end2 >= end1)
							) {
								availabilityError =
									'Make sure availability times on the same day do not overlap';
								break;
							}
						}
					}

					// Get period of times the tutor is available on that day of week
					for (let j = 0; j < dayOfWeek.length; j++) {
						let time = dayOfWeek[j];

						if (time) {
							let [startHour, startMinute] = time.start.split(':');
							let [endHour, endMinute] = time.end.split(':');
							let start = parseInt(startHour) + parseInt(startMinute) / 60;
							let end = parseInt(endHour) + parseInt(endMinute) / 60;
							let numHours = end - start;
							let day = i;

							// Remove last 30 min if doesn't fit in one hour interval
							if (numHours % 1 !== 0) {
								end -= 0.5;
								numHours = end - start;
							}

							// Error if end time is before start time
							if (end <= start) {
								availabilityError = 'Make sure start time is before end time';
								break;
							}

							const timezoneOffset =
								new Date(`1970-01-01T00:00:00.00000`).getTimezoneOffset() / 60;

							start += timezoneOffset;

							if (start >= 24) {
								day++;
								start = start % 24;
								day = day % 7;
							} else if (start < 0) {
								day--;
								start = ((start % 24) + 24) % 24;
								day = day % 7;
							}
							day += 4;

							if (day === 3) day = 10;

							// Add leading 0 digit
							let startHourStr = ('0' + parseInt(start)).slice(-2);
							let startMinuteStr = ('0' + (start % 1) * 60).slice(-2);
							let dayStr = ('0' + day).slice(-2);

							if (numHours > 0) {
								if (day === 10 && start + numHours > 24) {
									let numHoursCur = 24 - start;
									let numHoursOver = numHours - numHoursCur;

									numHoursCur = parseInt(numHoursCur);
									numHoursOver = parseInt(numHoursOver);

									let dateStringCur = `1970-01-10 ${startHourStr}:${startMinuteStr}:00.00000|${numHoursCur}`;
									let dateStringOver = `1970-01-04 00:00:00.00000|${numHoursOver}`;

									if (numHoursCur > 0) newAvailability.push(dateStringCur);
									if (numHoursOver > 0) newAvailability.push(dateStringOver);
								} else {
									// Format: "%Y-%m-%d %H:%M:%S.%f"
									// Day 4 = sunday, Day 10 = saturday
									let dateString = `1970-01-${dayStr} ${startHourStr}:${startMinuteStr}:00.00000|${numHours}`;
									newAvailability.push(dateString);
								}
							}
						}
					}
				}
			}

			if (availabilityError.length) {
				toast.error(
					'Oops! Invalid availability times.\n\n' + availabilityError
				);
				return null;
			}

			// Update user in database
			const tutorPost = await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/teacher/profile`,
				{
					action: 'update_availability',
					tutorId: tutorProfileId,
					availability: newAvailability,
				}
			);
			const { data } = tutorPost;
			const { status, message } = data;
			if (status) {
				toast.success(message);
			} else {
				toast.error(message);
			}
		} catch (err) {
			const errMessage = err.response?.data?.message;
			if (errMessage) {
				toast.error(errMessage);
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Nav logo={<NavLogo />} />

			<div className="dashboard page-availability">
				<div className="section-header">
					<h3>Availability</h3>
				</div>
				<div className="section-content">
					{dataLoading ? (
						<div className="page-loading">
							<LoadingButton />
						</div>
					) : (
						<>
							<Availability
								availability={availability}
								newAvailableSessions={newAvailableSessions}
								setNewAvailableSessions={setNewAvailableSessions}
							/>

							<div className="action-footer">
								<button
									className="btn btn-primary"
									onClick={handleSubmit}
									disabled={loading}
								>
									{loading ? <LoadingButton /> : 'Save Changes'}
								</button>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}

export default TutorAvailability;
