import React from 'react';
import './subscription.scss';

function SubscriptionPackages() {
	return (
		<section className="page-subscription">
			<div class="container">
				<div class="row">
					<div class="pricing-header">
						<h2>Pricing</h2>
					</div>
				</div>

				<div class="row justify-content-center">
					<div class="price-section">
						<div className="pricing-box">
							<div className="box-header">
								<p className="txt-title">Monthly Plan</p>
								<p className="txt-price">$10</p>
								<p className="txt-per-type">per month</p>
							</div>
						</div>
						<div className="pricing-box">
							<div className="box-header">
								<p className="txt-title">Yearly Plan</p>
								<p className="txt-price">$99</p>
								<p className="txt-per-type">per year</p>
							</div>
						</div>
					</div>
				</div>

				<div class="row justify-content-center feature-section">
					<div className="box-body">
						<ul>
							<li>
								<i className="fa fa-check-circle"></i>
								<span>
									<strong>Office Hours:</strong> Every week throughout the
									school year, Anya hosts live virtual "Office Hours". Ask
									questions and get answers to your most pressing math homework
									questions. Educational and Fun!
								</span>
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								<span>
									<strong>STEM Videos:</strong> Homework videos, math
									foundations, study strategies and more, #TeamAnya posts videos
									on a weekly basis.
								</span>
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								<span>
									<strong>Math Chat:</strong> Need help in a hurry? For any math
									questions you have, #TeamAnya has answers.
								</span>
							</li>
						</ul>
					</div>
					<div className="box-footer">
						<a href="/register" className="btn btn-purchase">
							Get started
						</a>
					</div>
				</div>
			</div>
		</section>
	);
}

export default SubscriptionPackages;
