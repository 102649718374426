/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { sha512 } from 'js-sha512';
import LoadingButton from '../../components/loading/LoadingButton';
import { Failure } from '../../components/alerts/Alerts';
import Header from '../../components/home/Header';
import Footer from '../../components/home/Footer';
import { constantKeys } from '../../tools/constant';
import phone from 'phone';
import { GRADES } from '../../config/general';
import OtpInput from 'react-otp-input';
import './index.scss';
const queryString = require('query-string');

function RegisterStudent(props) {
	const { id: highschoolId } = props.match.params;
	const parsed = queryString.parse(props.location.search);

	const history = useHistory();
	const [userOtpCode, setUserOtpCode] = useState('');
	const [step, setStep] = useState(0);
	const [awsUsername, setAwsUsername] = useState('');

	const [password, setPassword] = useState(''); // Does this fix exposing password?
	const [formError, setFormError] = useState(null);
	const [loadState, setLoadState] = useState(<div>Create account</div>);
	const [loadConfirmState, setLoadConfirmState] = useState(<div>Confirm</div>);

	const nextStep = () => {
		setStep(step + 1);
		// scroll to top
		window.scrollTo(0, 0);
	};

	const handleChange = (otp) => {
		setUserOtpCode(otp);
	};

	const handleConfirmation = async (e) => {
		e.preventDefault();
		setLoadConfirmState(<LoadingButton />);

		try {
			// Confirm sign up with confirmation code
			// eslint-disable-next-line
			await Auth.confirmSignUp(awsUsername, userOtpCode);

			history.push('/login');
		} catch (error) {
			setLoadConfirmState(<div>Confirm</div>);
			setFormError({
				title: 'Invalid Verification Code.',
				message: 'Please enter the code sent to your email',
			});
		}
	};

	const handleRegister = async (e) => {
		e.preventDefault();
		setLoadState(<LoadingButton />);

		const email = e?.target?.email?.value?.toLowerCase() || '';
		const phoneNumber =
			phone(e?.target?.phoneNumber?.value, 'USA')?.[0] || null;
		const name = e?.target?.name?.value || '';
		const school_name = e?.target?.school_name?.value || '';
		const grade_number = e?.target?.grade_number?.value || '';
		const parentEmail = e?.target?.parentEmail?.value || '';
		let where = '';
		let referralCode = null;

		const mathTeacherName = '';
		const scienceTeacherName = '';
		const mathTopics = '';
		const scienceTopics = '';

		try {
			var userImageUrl =
				'https://peerlinc-app-assets.s3.us-west-1.amazonaws.com/default_avatar.png';
			// Error handling for phone number and password length
			if (!phoneNumber) {
				setFormError({
					title: 'Invalid Phone Number.',
					message: 'Make sure your phone number is valid US number',
				});
				setLoadState(<div>Create account</div>);
				return;
			}
			if (password.length < 8) {
				setFormError({
					title: 'Invalid Password.',
					message: 'Password must be at least 8 characters long',
				});
				setLoadState(<div>Create account</div>);
				return;
			}
			// Sign up with necessary information and attributes
			const user = await Auth.signUp({
				username: email,
				password: sha512(password),
				attributes: {
					phone_number: phoneNumber,
					name: name,
					'custom:parent_email': parentEmail,
					'custom:role': 'student',
				},
			});
			setPassword('');
			setFormError(null);

			// Put new student to backend with it's user sub as the hash
			await axios.put(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/students`,
				{
					studentParam: user.userSub,
					nameParam: name,
					emailParam: email,
					phoneNumberParam: phoneNumber,
					imageUrlParam: userImageUrl,
					mathParam: '',
					parentEmailParam: parentEmail,
					whereParam: where,
					referralCodeParam: referralCode,
					schoolNameParam: school_name,
					gradeNumberParam: grade_number,
					highschoolIdParam: highschoolId,
					mathTopicsParam: mathTopics,
					scienceTopicsParam: scienceTopics,
					mathTeacherNameParam: mathTeacherName,
					scienceTeacherNameParam: scienceTeacherName,
				}
			);
			const { userSub } = user;
			setAwsUsername(userSub);
			localStorage.setItem(constantKeys.IS_NEW_USER, '1');
			nextStep();
		} catch (error) {
			setLoadState(<div>Create account</div>);
			if (error.name === 'UsernameExistsException') {
				setFormError({
					title: 'Invalid Email.',
					message: 'An account with the given email already exists',
				});
			} else {
				setFormError({
					title: 'Unable to Register.',
					message: 'Error occurred when registering, please try again',
				});
			}
		}
	};

	const Steps = () => {
		if (step === 0) {
			return (
				<>
					<button className="btn-back" onClick={() => history.goBack()}>
						<i className="fa fa-long-arrow-left" aria-hidden="true"></i> Back to
						home
					</button>
					<div className="box-form">
						<form onSubmit={handleRegister}>
							<h3>Create Your Account</h3>

							<div className="form-label-group">
								<label>Team Name</label>
								<input type="hidden" name="school_id" value={highschoolId} />
								<input
									type="text"
									name="school_name"
									id="inputName"
									className="auth-input"
									placeholder="Enter Team Name"
									value={parsed?.name || ''}
									required
									readOnly
								/>
							</div>

							<div className="form-label-group">
								<label>Grade number</label>
								<select name="grade_number" className="auth-input" required>
									<option value="" disabled selected>
										Select your Grade
									</option>
									{GRADES.map((grade, index) => {
										return (
											<option key={index} value={grade}>
												{grade}
											</option>
										);
									})}
								</select>
							</div>

							<div className="form-label-group">
								<label>Your full name</label>
								<input
									type="text"
									name="name"
									id="inputName"
									className="auth-input"
									placeholder="Enter your full name"
									required
								/>
							</div>

							<div className="form-label-group">
								<label>Email address</label>
								<input
									type="email"
									name="email"
									id="inputStudentEmail"
									className="auth-input"
									placeholder="Enter your email address"
									required
								/>
							</div>

							<div className="form-label-group">
								<label>Parent Email address</label>
								<input
									type="email"
									name="parentEmail"
									id="inputParentEmail"
									className="auth-input"
									placeholder="Parent Email address"
									required
								/>
							</div>

							<div className="form-label-group">
								<label>Phone number</label>
								<input
									type="phone"
									name="phoneNumber"
									id="inputPhone"
									className="auth-input"
									placeholder="Enter your phone number"
									maxLength={10}
									required
								/>
							</div>

							<div className="form-label-group">
								<label>Password</label>
								<input
									type="password"
									name="password"
									id="inputPassword"
									className="auth-input"
									placeholder="Create a password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									required
								/>
								<span className="text-helper">Note: minimum 8 character</span>
							</div>

							{formError ? (
								<Failure title={formError.title} message={formError.message} />
							) : null}

							<div className="text-right">
								<button className="btn-custom" type="submit">
									{loadState}
								</button>
							</div>

							{/* Confirm code design */}
						</form>
					</div>
				</>
			);
		} else if (step === 1) {
			return (
				<div className="box-confirm box-form">
					<h1>Confirmation code</h1>
					<h3>
						Copy the code sent to your email and paste it below. If it doesn't
						show up in your inbox, please check your spam folder
					</h3>

					<OtpInput
						value={userOtpCode}
						onChange={handleChange}
						numInputs={6}
						inputStyle={'otp-input-box'}
						containerStyle={'otp-verification-box'}
					/>

					<div>
						<label>
							Didn't received a code?{' '}
							<a href="/manually-confirm" className="new-orange">
								Request a manual confirmation
							</a>
						</label>
					</div>

					<div className="text-right">
						<button
							className="auth-button"
							type="button"
							onClick={handleConfirmation}
						>
							{loadConfirmState}
						</button>
					</div>

					{formError ? (
						<Failure title={formError.title} message={formError.message} />
					) : null}
				</div>
			);
		} else {
			return null;
		}
	};

	return (
		<div className="page-register">
			<Header request={true} />

			<div className="container">
				<div className="row">
					<div className="col-md-3"></div>
					<div className="col-md-6">{Steps()}</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default RegisterStudent;
