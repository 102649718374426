import React, { useState, useEffect, createContext } from 'react';
import { Auth } from 'aws-amplify';
import LoadingPage from '../components/loading/LoadingPage';
import axios from 'axios';

export const AuthContext = createContext();

function AuthContextProvider(props) {
	const [currentUser, setCurrentUser] = useState(null);
	const [currentStudent, setCurrentStudent] = useState(null);
	const [studentBalance, setStudentBalance] = useState(0);
	const [userHash, setUserHash] = useState('');
	const [role, setRole] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getCurrentUser();
	}, []);

	useEffect(() => {
		if (userHash && role === 'student') {
			getStudentProfile();
		}
		// eslint-disable-next-line
	}, [userHash]);

	const getStudentProfile = async () => {
		axios
			.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/checkout/student_profile`,
				{
					student_id: userHash,
				}
			)
			.then((result) => {
				var response = result.data;
				if (response && response.status) {
					setCurrentStudent(response.data);
				}
			});
	};

	const getCurrentUser = () => {
		setIsLoading(true);
		Auth.currentAuthenticatedUser()
			.then((user) => {
				setRole(user.attributes['custom:role']);
				setUserHash(user.attributes.sub);
				setCurrentUser(user);
				setIsLoading(false);
			})
			.catch((err) => {
				setRole('');
				setUserHash('');
				setCurrentUser(null);
				setIsLoading(false);
			});
	};

	const login = async (email, password) => {
		const user = await Auth.signIn(email, password);
		getCurrentUser();
		return user;
	};

	const logout = async () => {
		await Auth.signOut();
		getCurrentUser();
	};

	return (
		<div>
			{/* Display loading screen while it gets the current user */}
			{isLoading ? (
				<LoadingPage />
			) : (
				<AuthContext.Provider
					value={{
						currentUser,
						currentStudent,
						userHash,
						role,
						getStudentProfile,
						setCurrentStudent,
						getCurrentUser,
						login,
						logout,
						studentBalance,
						setStudentBalance,
						setUserHash,
						setRole,
					}}
				>
					{props.children}
				</AuthContext.Provider>
			)}
		</div>
	);
}

export default AuthContextProvider;
