import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import What from './What';
import Day from './Day';
import Time from './Time';
import Summary from './Summary';
import './book.css';
import { getAllAvailableSessionTimes } from '../../tools/timeConverter';
import { Failure } from '../alerts/Alerts';
import Done from './Done';
import { constantKeys } from '../../tools/constant';

// var i = 1;
var ContentTitles = [
	'What day works best?',
	'What time works best?',
	'Does everything look right?',
];
var ContentTitle = ContentTitles[0];

function Content(props) {
	// Alerts for errors
	const [alert, setAlert] = useState();

	const [availableSessions, setAvailableSessions] = useState([]);
	const [chosenSession, setChosenSession] = useState('');
	const [day, setDay] = useState(null);
	const [time, setTime] = useState(null);

	var selectedSubject = localStorage.getItem(
		constantKeys.REGISTER_RECOM_SUBJECT
	);
	const [what, setWhat] = useState(selectedSubject);

	const [contentState, setContentState] = useState(0);
	const [selectedTutor, setSelectedTutor] = useState(null);

	useEffect(() => {
		getAvailableSessions();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (props.reset) {
			handleReset();
		}
		// eslint-disable-next-line
	}, [props.reset]);

	const handleReset = () => {
		ContentTitle = ContentTitles[0];
		setContentState(0);
		setAvailableSessions([]);
		setChosenSession('');
		setDay(null);
		setTime(null);
		setWhat('');
	};

	const sessionPromise = (sessionHash) => {
		return new Promise(async (resolve, reject) => {
			try {
				const session = await axios.get(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/sessions`,
					{
						params: {
							sessionParam: sessionHash,
						},
					}
				);
				resolve(session.data.when);
			} catch (err) {
				setAlert(
					<Failure
						title="Oops!"
						message="Unable to retrieve an available session"
					/>
				);
				resolve(null);
			}
		});
	};

	const getAvailableSessions = async () => {
		try {
			const tutor = await axios.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/tutors`,
				{
					params: {
						tutorParam: props.tutorHash,
					},
				}
			);
			const tutorInfo = tutor.data;
			setSelectedTutor(tutorInfo);
			const tutorUpcomingSessions = tutor.data.upcomingSessions;
			let upcomingTimes = [];

			for (let i = 0; i < tutorUpcomingSessions.length; i++) {
				if (tutorUpcomingSessions[i].length > 0) {
					upcomingTimes.push(sessionPromise(tutorUpcomingSessions[i]));
				}
			}

			Promise.all(upcomingTimes).then((data) => {
				data = data.filter((items) => items !== null);
				if (props.tutorAvailability.length) {
					setAvailableSessions(
						getAllAvailableSessionTimes(props.tutorAvailability, data)
					);
				}
			});
		} catch (err) {
			setAlert(<Failure title="Oops!" message={err.message} />);
		}
	};

	const completeBook = () => {
		//NextBookContent();
		props.handleClose();
		props.handleSuccessfulBook(chosenSession, props.tutorHash, what);
		//setTimeout(() => {
		//}, 3000);
		//return () => clearTimeout();
	};

	function NextBookContent() {
		let state = contentState;

		if (state > 4) {
			setContentState(4);
		} else {
			setContentState(state + 1);
		}

		ContentTitle = ContentTitles[state + 1];
	}

	function PrevBookContent() {
		let state = contentState;

		if (state > 0) {
			state--;
			setContentState(state);
			ContentTitle = ContentTitles[state];

			if (state === 0) {
				setDay(null);
			} else if (state === 1) {
				setTime(null);
				setChosenSession(null);
			}
		}
	}

	function BookContentButton() {
		if (contentState < 2) {
			if (
				(contentState === 0 && day === null) ||
				(contentState === 1 && time === null)
			) {
				return (
					<div className="col-12 book-button-container">
						{contentState > 0 && contentState < 2 ? (
							<button onClick={PrevBookContent} className="book-prev">
								Back
							</button>
						) : null}
						<button className="button-disabled book-first-next">
							Continue
						</button>
					</div>
				);
			} else {
				return (
					<div className="col-12 book-button-container">
						{contentState > 0 && contentState < 2 ? (
							<button onClick={PrevBookContent} className="book-prev">
								Back
							</button>
						) : null}
						<button onClick={NextBookContent} className="book-first-next">
							Continue
						</button>
					</div>
				);
			}
		} else if (contentState === 2) {
			return (
				<div className="col-12 book-button-container">
					<button onClick={PrevBookContent} className="book-prev">
						Back
					</button>
					<button
						onClick={completeBook}
						type="button"
						class="book-first-next"
						data-dismiss="modal"
						aria-label="Close"
					>
						Confirm
					</button>
				</div>
			);
		} else {
			return null;
		}
	}

	return (
		<div class="book-content">
			{alert}
			<h4 class="book-header">{ContentTitle}</h4>
			<div className="row">
				{/* <Content state={contentState} /> */}
				{/* {contentState === 0 ? <What what={what} setWhat={setWhat} /> : null} */}
				{contentState === 0 ? (
					<Day setDay={setDay} availableSessions={availableSessions} />
				) : null}
				{contentState === 1 ? (
					<Time
						setTime={setTime}
						day={day}
						availableSessions={availableSessions}
						setChosenSession={setChosenSession}
					/>
				) : null}
				{contentState === 2 ? (
					<Summary
						day={day}
						time={time}
						what={what}
						tutorName={props.tutorName}
						tutorHash={props.tutorHash}
						tutorImage={selectedTutor.image}
						tutorLocation={selectedTutor.university}
						tutorLocationImage={selectedTutor.universityImageLink}
					/>
				) : null}
				{contentState === 3 ? (
					<Done text="Your session has been successfully booked" />
				) : null}

				<BookContentButton />
			</div>
		</div>
	);
}

export default Content;
