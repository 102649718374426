import React from 'react';
import PerfectTutor from './Modals/PerfectTutor';

function PageTop(props) {
	return (
		<div>
			<header className="main-container">
				<div className="container text-left">
					<div className="row">
						<div className="main-writing col-sm-12 col-md-12 col-lg-6">
							<h1 className="main-text">{props.text}</h1>
							<p className="main-paragraph">{props.paragraph}</p>
							<PerfectTutor />

							{/* {props.action} */}

							{/* <div class="berkeley">
								<p>
									{props.smallTag}
									<span class={props.showBerkeley}>
										<img
											class="berkeley-made"
											src="../images/ucb.png"
											alt="berk"
										/>
									</span>
								</p>
							</div> */}
						</div>

						<div className="col-sm-12 col-md-12 col-lg-6">
							<div class="main">
								{/* <div class="orange-box"></div> */}
								<div class="main-video-div">
									{/* <iframe
										src="https://www.loom.com/embed/0c1b01cbde2c48d1a19aaa9f605b568d"
										title="home video"
										class="main-video"
									></iframe> */}
									<img
										src={'/images/home-top-image.svg'}
										className="home-banner-image"
										alt="Peerlic"
									/>
								</div>
								{/* <img
									className={props.mainImageClass}
									src={props.MainImage}
									alt="main"
								/>

								<i
									data-toggle="modal"
									data-target="#homeModal"
									class={props.playClass}
								></i> */}
							</div>
						</div>

					</div>
				</div>
			</header>

			{/* Video popup */}

			<div
				class="modal fade"
				id="homeModal"
				tabindex="-1"
				role="dialog"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<button
								type="button"
								class="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<iframe
								title="home"
								class="modal-video"
								width="420"
								height="315"
								src={props.video}
							></iframe>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PageTop;
