import React, { useState, useEffect } from 'react';
import LoadingButton from '../loading/LoadingButton';
import './book.css';

function BookContentOneDay(props) {
	const monthNames = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Th', 'Fri', 'Sat'];

	return (
		<div class="col-lg-3 col-md-3 col-sm-6" onClick={() => props.setDay(props.day)}>
			<div class="dates">
				<button className="book-date-day" tabindex="1">
					<span class="book-a-date">
						{dayNames[props.day.getDay()]}, {monthNames[props.day.getMonth()]}{' '}
						{props.day.getDate()}
					</span>
				</button>
			</div>
		</div>
	);
}

function BookContentDay(props) {
	const [availableDays, setAvailableDays] = useState([]);
	const [days, setDays] = useState(
		<div class="loading-days">
			<LoadingButton />
		</div>
	);
	const [tooLong, setTooLong] = useState(false);

	// To show days
	useEffect(() => {
		let newAvailableDays = [];

		for (let i = 0; i < props.availableSessions.length; i++) {
			let isIncluded = false;
			for (let j = 0; j < newAvailableDays.length; j++) {
				if (
					newAvailableDays[j].getDate() === props.availableSessions[i].getDate()
				) {
					isIncluded = true;
					break;
				}
			}

			if (!isIncluded) newAvailableDays.push(props.availableSessions[i]);
		}

		newAvailableDays.sort((aDate, bDate) => aDate - bDate);

		setAvailableDays(newAvailableDays);
	}, [props.availableSessions]);

	// Loading effect
	useEffect(() => {
		if (availableDays.length > 0) {
			setDays(
				availableDays.map((day) => {
					return <BookContentOneDay setDay={props.setDay} day={day} />;
				})
			);
		}
		if (availableDays.length === 0 && tooLong) {
			setDays(
				<div class="explain-text tutor-inactive">
					This tutor is not active. To add another tutor, click "+" under "My
					Tutors"
				</div>
			);
		}
		// eslint-disable-next-line
	}, [availableDays, tooLong]);

	setTimeout(() => {
		setTooLong(true);
	}, 8000);

	return (
		<div class='day-container'>
			<div class="row">{days}</div>
		</div>
	);
}

export default BookContentDay;
