import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingButton from '../loading/LoadingButton';
import { toast } from 'react-toastify';
import { FaPlay } from 'react-icons/fa';
import ReactPlayer from 'react-player';
import classnames from 'classnames';
import './index.scss';

function MyConversationVideos(props) {
	const { profile } = props;

	const [videos, setVideos] = useState([]);
	const [activeVideo, setActiveVideo] = useState(null);
	const [isDataLoading, setDataLoading] = useState(true);

	const { id: studentId } = profile || {};
	useEffect(() => {
		if (studentId) {
			getMyTutors(studentId);
		}
		// eslint-disable-next-line
	}, [studentId]);

	const getMyTutors = async (studentId) => {
		setDataLoading(true);

		try {
			const results = await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/chat/videos`,
				{
					user_id: studentId,
				}
			);
			const { data } = results;
			const { status, message, data: videos } = data;
			if (status) {
				setVideos(videos);
				if (videos.length > 0) {
					setActiveVideo(videos[0]);
				}
			} else {
				toast.error(message);
			}
		} catch (err) {
			const errorMessage = err.response?.data?.message || err.message;
			toast.error(errorMessage);
		} finally {
			setDataLoading(false);
		}
	};

	var { video_url: renderVideoLink } = activeVideo || {};

	const validVideoUrl = renderVideoLink && renderVideoLink.startsWith('http');
	const isLoomVideo = renderVideoLink && renderVideoLink.includes('loom.com');
	const isVimeoVideo = renderVideoLink && renderVideoLink.includes('vimeo.com');

	if (isLoomVideo) {
		renderVideoLink = renderVideoLink.replace('share', 'embed');
	}

	return (
		<>
			<div className="section-body">
				<div className="video-container">
					{activeVideo && validVideoUrl && (
						<>
							{isLoomVideo || isVimeoVideo ? (
								<iframe
									src={renderVideoLink}
									title="tutor video"
									className="loom-video"
								></iframe>
							) : (
								<ReactPlayer
									url={renderVideoLink}
									width={'100%'}
									height={'100%'}
								/>
							)}
						</>
					)}
				</div>

				{isDataLoading ? (
					<div className="box-loader">
						<LoadingButton />
					</div>
				) : videos.length > 0 ? (
					<div className="conversation-videos">
						{videos.map((video, index) => {
							const { video_id, video_name } = video || {};

							return (
								<div
									key={index}
									className={classnames('video-item', {
										active: activeVideo?.video_id === video_id,
									})}
									onClick={() => setActiveVideo(video)}
								>
									<FaPlay /> <span>{video_name || 'Tutor'}</span>
								</div>
							);
						})}
					</div>
				) : (
					<div className="no-data-found">
						<img src="../images/women.svg" alt="sad face" />
						<p className="text-hint">No Videos found</p>
					</div>
				)}
			</div>
		</>
	);
}

export default MyConversationVideos;
