import React, { useState, useEffect, useContext } from 'react';
import { Failure } from '../alerts/Alerts';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import Input from './Input';
import LoadingButton from '../loading/LoadingButton';
import { GRADES, MATHS_COURSES, SCIENCE_COURSES } from '../../config/general';
import './settings.css';
import './index.scss';

function StudentSettings() {
	const history = useHistory();
	const auth = useContext(AuthContext);

	const [state, setState] = useState(null);

	const [errorTitle, setErrorTitle] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const [loading, setLoading] = useState(false);
	const [userImage, setUserImage] = useState(null);
	const [userImagePreview, setUserImagePreview] = useState(null);

	useEffect(() => {
		// Get student and set state values if they are logged in
		if (auth.currentUser !== null) {
			const userHash = auth.userHash;

			axios
				.post(
					`${process.env.REACT_APP_NEW_API_ENDPOINT}/checkout/student_profile`,
					{
						student_id: userHash,
					}
				)
				.then((result) => {
					var response = result.data;
					if (response && response.status) {
						const userProfile = response.data;
						setState(userProfile);

						if (userProfile?.highschoolId) {
							getHighschool(userProfile?.highschoolId);
						}

						var userImageUrl = userProfile?.image;
						if (userImageUrl) {
							if (userImageUrl?.includes('flaticon')) {
								userImageUrl =
									'https://peerlinc-app-assets.s3.us-west-1.amazonaws.com/default_avatar.png';
							}
							setUserImagePreview(userImageUrl);
						}
					}
				});
		}
		// eslint-disable-next-line
	}, [auth?.userHash]);

	const getHighschool = async (id) => {
		axios
			.post(`${process.env.REACT_APP_NEW_API_ENDPOINT}/highschool`, {
				action: 'check_highschool',
				slug: id,
			})
			.then((result) => {
				var response = result.data;
				if (response.status) {
					const highschool = response.data;
					setState((prevState) => ({
						...prevState,
						schoolName: highschool.name,
					}));
				}
			});
	};

	// Upload Image

	const handleImageChange = async (e) => {
		e.preventDefault();

		let reader = new FileReader();
		let file = e.target.files[0];

		reader.onloadend = () => {
			setUserImagePreview(reader.result);
		};

		reader.readAsDataURL(file);
		setUserImage(file);
	};

	const uploadImage = async (file) => {
		try {
			if (!file) {
				return;
			}

			const response = await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/general/image/upload`,
				{
					folder: 'user-images',
					file_name: file?.name || 'user.png',
					content_type: file?.type || 'image/png',
				}
			);
			const { data } = response;
			const { uploadURL } = data;

			const fileUrl = uploadURL.split('?')[0];

			await axios.put(uploadURL, file, {
				headers: {
					'Content-Type': file?.type || 'image/png',
				},
			});
			return fileUrl;
		} catch (error) {
			console.log(error);
		}
	};

	const setError = (title, message) => {
		setErrorTitle(title);
		setErrorMessage(message);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);
			const {
				name,
				email,
				image,
				phoneNumber,
				parentEmail,
				gradeNumber,
				mathTopics,
				scienceTopics,
			} = state;

			var userImageUrl = image;
			if (userImage) {
				const imageUploadUrl = await uploadImage(userImage);
				if (imageUploadUrl) {
					userImageUrl = imageUploadUrl;
				}
			}

			// Update attributes of user in authentication user pool
			await Auth.updateUserAttributes(auth.currentUser, {
				name: name,
				email: email,
				phone_number: phoneNumber,
				'custom:parent_email': parentEmail,
			});

			// Update user in database
			const studentPost = await axios.post(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/students`,
				{
					studentParam: auth.userHash,
					nameParam: name,
					emailParam: email,
					imageUrlParam: userImageUrl,
					parentEmailParam: parentEmail,
					phoneNumberParam: phoneNumber,
					gradeNumberParam: gradeNumber,
					mathTopicsParam: mathTopics,
					scienceTopicsParam: scienceTopics,
				}
			);

			if (!studentPost.data.length) {
				history.push('/student-dashboard', {
					success: {
						title: 'Updated Settings!',
						message: 'Successfully updated your settings',
					},
				});
				window.location.reload();
			} else {
				setLoading(false);
				setError(
					'Oops! Something went wrong',
					'Failed to update setting. Please try again or contact us'
				);
			}
		} catch (err) {
			setLoading(false);
			console.log(err);
			setError(
				'Oops! Something went wrong',
				'Failed to update setting. Please try again or contact us'
			);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	return (
		<div className="page-edit-profile">
			<div className="container">
				<form onSubmit={handleSubmit}>
					<div className="page-header">
						<h4>Edit Profile</h4>

						{state?.id && (
							<button
								disabled={loading}
								className="btn-save btn-custom"
								type="submit"
							>
								{loading ? <LoadingButton /> : 'Save Changes'}
							</button>
						)}
					</div>

					<div className="section-card">
						<div className="row">
							<div className="col-md-4 col-sm-12">
								<div className="form-label-group">
									<h4>Profile Picture</h4>

									<div className="custom-image-picker">
										<input
											type="file"
											id="file"
											className="inputfile"
											accept="image/*"
											onChange={handleImageChange}
										/>
										{userImagePreview ? (
											<img
												src={userImagePreview}
												alt="User"
												className="user-image-preview"
											/>
										) : (
											<img
												src="/images/highschool/student-image-picker.png"
												alt="User"
												className="user-image-preview"
											/>
										)}
									</div>
								</div>
							</div>
							<div className="col-md-8 col-sm-12">
								<div className="settings-box">
									<Input
										label="Your full name"
										type="text"
										name={'name'}
										value={state?.name || ''}
										onChange={handleChange}
									/>

									<Input
										label="Email"
										type="text"
										name={'email'}
										value={state?.email || ''}
										onChange={handleChange}
									/>

									<Input
										label="Parent email (Optional)"
										type="text"
										name={'parentEmail'}
										value={state?.parentEmail || ''}
										onChange={handleChange}
									/>

									<Input
										label="Phone Number"
										type="text"
										name={'phoneNumber'}
										value={state?.phoneNumber || ''}
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>
						{errorTitle.length > 0 ? (
							<Failure title={errorTitle} message={errorMessage} />
						) : null}
					</div>

					<div className="section-card">
						<div className="row">
							<div className="col-md-6 col-sm-12">
								<div className="form-label-group">
									<label className="settings-label">Grade number</label>
									<select
										className="settings-input"
										name="gradeNumber"
										value={state?.gradeNumber}
										onChange={handleChange}
										required
									>
										<option value="">Select your Grade</option>
										{GRADES.map((grade, index) => {
											return (
												<option key={index} value={grade}>
													{grade}
												</option>
											);
										})}
									</select>
								</div>
								<div className="form-label-group">
									<label className="settings-label">Math Class</label>
									<select
										className="settings-input"
										name="mathTopics"
										value={state?.mathTopics}
										onChange={handleChange}
										required
									>
										{MATHS_COURSES.map((item, index) => {
											return (
												<option key={index} value={item}>
													{item}
												</option>
											);
										})}
									</select>
								</div>
							</div>
							<div className="col-md-6 col-sm-12">
								<label className="settings-label">High School</label>
								<div className="form-label-group">
									<input
										type="text"
										className="settings-input"
										value={state?.schoolName || ''}
										readOnly
									/>
								</div>

								<div className="form-label-group">
									<label className="settings-label">Science Class</label>
									<select
										className="settings-input"
										name="scienceTopics"
										value={state?.scienceTopics}
										onChange={handleChange}
										required
									>
										{SCIENCE_COURSES.map((item, index) => {
											return (
												<option key={index} value={item}>
													{item}
												</option>
											);
										})}
									</select>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}

export default StudentSettings;
