import React, { useState, useContext } from 'react';
import '../admin.css';
import { AuthContext } from '../../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { Failure } from '../../alerts/Alerts';

function Header() {
	const [alert, setAlert] = useState();

	const history = useHistory();
	const auth = useContext(AuthContext);

	const handleLogout = async () => {
		try {
			auth.logout();
			history.push('/');
		} catch (error) {
			setAlert(<Failure title="Oops!" message={error} />);
		}
	};

	return (
		<div>
			{alert}
			<nav className="navbar navbar-expand-lg navbar-light">
				<a className="navbar-brand" href="/admin">
					<img
						className="admin-nav-image"
						src="/images/Peerlinc.png"
						alt="admin"
					/>
				</a>
				<button
					className="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarText"
					aria-controls="navbarText"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse admin-navbar" id="navbarText">
					<ul className="navbar-nav mr-auto">
						<li className="nav-item">
							<a className="nav-link" href="/admin-students">
								Students
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="/admin-highschools">
								High Schools
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="/admin-videos">
								Videos
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="/admin-messages">
								Messages
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="/admin-tutors">
								Tutors
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="/admin-sessions">
								Sessions
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="/admin-subscriptions">
								Subscriptions
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="/admin-data">
								Data
							</a>
						</li>
					</ul>
					<div className="dropdown">
						<div
							className="admin-dropdown dropdown-toggle"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							Account <i className="profile-dropdown fas fa-caret-down"></i>
						</div>
						<div className="dropdown-menu dropdown-menu-right">
							<a className="dropdown-item" href="/admin-settings">
								Settings
							</a>
							<div className="dropdown-item" onClick={handleLogout}>
								Logout
							</div>
						</div>
					</div>
				</div>
			</nav>
		</div>
	);
}

export default Header;
