import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../../components/admin/home/Header';
import LoadingPage from '../../../components/loading/LoadingPage';
import { Failure } from '../../../components/alerts/Alerts';
import moment from 'moment';
import './index.scss';
import { Button } from 'react-bootstrap';
const queryString = require('query-string');

function AdminListHighschoolTeachers(props) {
	const { id: highschoolId } = props.match.params;
	const parsed = queryString.parse(props?.location?.search);
	const [alert, setAlert] = useState();
	const [teachers, setTeachers] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchGroups();
		// eslint-disable-next-line
	}, []);

	const fetchGroups = () => {
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_highschool_teachers`,
				{
					action: 'list_teacher',
					highschoolId: highschoolId,
				}
			)
			.then((res) => {
				var list = res?.data?.data;
				setTeachers(list);
			})
			.catch(() =>
				setAlert(
					<Failure
						title="Oops!"
						message="Something happened. Try again or contact us."
					/>
				)
			)
			.finally(() => setLoading(false));
	};

	const handleDelete = (teacherId) => {
		// confirm delete
		const confirmDelete = window.confirm(
			'Are you sure you want to delete this teacher?'
		);
		if (confirmDelete) {
			setLoading(true);
			axios
				.post(
					`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_highschool_teachers`,
					{
						action: 'delete_teacher',
						teacherId: teacherId,
					}
				)
				.then((res) => {
					if (res?.data?.status) {
						// remove from list
						const filteredItems = teachers.filter(
							(item) => item.teacher_id !== teacherId
						);
						setTeachers(filteredItems);
					}
				})
				.catch((err) => console.log(err))
				.finally(() => setLoading(false));
		}
	};

	if (loading) {
		return <LoadingPage />;
	}

	return (
		<div className="page-admin-highschool">
			<Header />
			{alert}
			<div className="container-fluid">
				<div className="row mt-5 mb-4">
					<div className="col-md-5">
						<h4 className="admin-title">
							{parsed?.name} Teachers ({teachers?.length})
						</h4>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						{teachers.length > 0 ? (
							<table className="table table-hover">
								<thead>
									<tr>
										<th scope="col">Date Created</th>
										<th scope="col">Name</th>
										<th scope="col">Type</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>
									{teachers.map((item, index) => (
										<tr key={index}>
											<td>
												{moment(item?.date_created)
													.local()
													.format('DD MMM, YYYY')}
											</td>
											<td>{item?.teacher_name}</td>
											<td>{item?.teacher_type}</td>
											<td>
												<Button
													className="btn-delete"
													onClick={() => handleDelete(item?.teacher_id)}
												>
													<i className="fas fa-trash-alt"></i>
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							<p className="text-center">No teachers found</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdminListHighschoolTeachers;
