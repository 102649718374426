import React from 'react';

function Calendly() {
	return (
		<div style={{ height: '1100px' }}>
			<iframe
				src="https://calendly.com/Peerlinc/15min?primary_color=f25e5e"
				width="100%"
				height="100%"
				frameborder="0"
				title="calendly"
			></iframe>
		</div>
	);
}


export default Calendly
