import React from 'react';
import DayOfWeek from './DayOfWeek';
import './availability.scss';

// Up to two slots per day

function Availability(props) {
	return (
		<div className="availability-list">
			<DayOfWeek
				day="Monday"
				dayIndex="1"
				availability={props.availability}
				newAvailableSessions={props.newAvailableSessions}
				setNewAvailableSessions={props.setNewAvailableSessions}
			/>

			<DayOfWeek
				day="Tuesday"
				dayIndex="2"
				availability={props.availability}
				newAvailableSessions={props.newAvailableSessions}
				setNewAvailableSessions={props.setNewAvailableSessions}
			/>

			<DayOfWeek
				day="Wednesday"
				dayIndex="3"
				availability={props.availability}
				newAvailableSessions={props.newAvailableSessions}
				setNewAvailableSessions={props.setNewAvailableSessions}
			/>

			<DayOfWeek
				day="Thursday"
				dayIndex="4"
				availability={props.availability}
				newAvailableSessions={props.newAvailableSessions}
				setNewAvailableSessions={props.setNewAvailableSessions}
			/>

			<DayOfWeek
				day="Friday"
				dayIndex="5"
				availability={props.availability}
				newAvailableSessions={props.newAvailableSessions}
				setNewAvailableSessions={props.setNewAvailableSessions}
			/>

			<DayOfWeek
				day="Saturday"
				dayIndex="6"
				availability={props.availability}
				newAvailableSessions={props.newAvailableSessions}
				setNewAvailableSessions={props.setNewAvailableSessions}
			/>

			<DayOfWeek
				day="Sunday"
				dayIndex="0"
				availability={props.availability}
				newAvailableSessions={props.newAvailableSessions}
				setNewAvailableSessions={props.setNewAvailableSessions}
			/>
		</div>
	);
}

export default Availability;
