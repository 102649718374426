import React from 'react';
import { TypeAnimation } from 'react-type-animation';

const AnimatedText = () => {
	return (
		<TypeAnimation
			sequence={['Homework', 1500, 'Test Prep', 1500, 'Tutor', 1500]}
			wrapper="span"
			className="animated-text"
			cursor={true}
			repeat={Infinity}
		/>
	);
};

export default AnimatedText;
