import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { sha512 } from 'js-sha512';
import { AuthContext } from '../../contexts/AuthContext';
import { Failure } from '../alerts/Alerts';
import LoadingButton from '../loading/LoadingButton';
import Register from './Register';
import axios from 'axios';
import './auth.css';

function Login(props) {
	const history = useHistory();
	const location = useLocation();
	const auth = useContext(AuthContext);

	const [formError, setFormError] = useState(null);

	const [loadState, setLoadState] = useState(<div>Login</div>);

	const handleLogin = async (e) => {
		e.preventDefault();

		setLoadState(<LoadingButton />);

		const username = e.target.email.value.toLowerCase();

		try {
			// Call the login method in authContext to sign in with email and password
			const user = await auth.login(username, sha512(e.target.password.value));

			// Redirect to appropriate dashboard depending on the user's role
			if (user.attributes['custom:role'] === 'tutor') {
				axios.patch(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/tutors`,
					{
						tutorParam: user.attributes.sub,
					}
				);

				let paramState = {};
				paramState['isLogin'] = true;
				// Pass a location state value to tell if user just registered
				if (location.state)
					paramState['isRegister'] = location.state.isRegister;

				history.push('/tutor-dashboard', paramState);
			} else if (user.attributes['custom:role'] === 'student') {
				axios.patch(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/students`,
					{
						studentParam: user.attributes.sub,
					}
				);

				let paramState = {};

				paramState['isLogin'] = true;
				// Pass a location state value to tell if user just registered
				if (location.state)
					paramState['isRegister'] =
						location.state.isRegister || props.isRegister;

				// Pass location state values if user came from tutor book or chat
				if (props.pickedTutorHash) {
					paramState['pickedTutorHash'] = props.pickedTutorHash;
					paramState['action'] = props.action;
				}

				if (props.newRegister) {
					paramState['newRegister'] = true;
				}

				history.push({
					pathname: '/student-dashboard',
					state: paramState,
				});
			} else if (user.attributes['custom:role'] === 'admin') {
				history.push('/admin');
			}
		} catch (error) {
			setLoadState(<div>Login</div>);
			if (error.name === 'UserNotFoundException') {
				setFormError({
					title: 'User Not Found.',
					message: 'An account with the given email does not exist',
				});
			} else if (error.name === 'NotAuthorizedException') {
				setFormError({
					title: 'Unable to Login.',
					message: 'Incorrect email or password',
				});
			} else if (error.name === 'UserNotConfirmedException') {
				history.push({
					pathname: '/confirm',
					state: {
						username: username,
						resendConfirm: true,
					},
				});
			} else {
				setFormError({
					title: 'Failed to Login.',
					message: 'Error occured when logging in. Please try again',
				});
			}
		}
	};

	return (
		<div>
			<div className="container">
				<div className="auth-container">
					{!props.newRegister && (
						<div class="auth-logo-div">
							<img className="auth-logo" src="/images/Peerlinc.png" alt="" />
						</div>
					)}
					<h4 className="auth-header">Login</h4>

					{formError ? (
						<Failure title={formError.title} message={formError.message} />
					) : null}

					<form className="form-signin" onSubmit={handleLogin}>
						{/* email */}
						<label>Enter Email</label>
						<div className="">
							<input
								type="email"
								name="email"
								id="inputEmail"
								className="auth-input"
								placeholder="Email"
								required
							/>
						</div>

						{/* password */}
						<label>Enter Password</label>
						<div className="">
							<input
								type="password"
								name="password"
								id="inputPassword"
								className="auth-input"
								placeholder="Password"
								required
							/>
						</div>

						<button
							className={
								!props.notFromURL
									? 'auth-button'
									: 'auth-button auth-button-url'
							}
							type="submit"
						>
							{loadState}
						</button>

						{props.newRegister && (
							<div className=" forgot-link">
								<a className="forgot-password" href="/forgot-password">
									Forgot password?
								</a>
							</div>
						)}
					</form>

					{!props.newRegister && (
						<>
							<div>
								<a href="/forgot-password">Forgot password?</a>
							</div>

							<hr />
							<p>
								Don't have an account? <Register fromLogin={true} />{' '}
							</p>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default Login;
