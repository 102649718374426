import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingPage from '../../components/loading/LoadingPage';
import Payment from '../../components/admin/Payment';
import { Failure } from '../../components/alerts/Alerts';

function AdminPayments() {
	const [alert, setAlert] = useState();
	const [payments, setPayments] = useState([]);

	// Retrieve payments
	useEffect(() => {
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/logs/`,
				{ params: { logType: 'PAYMENTS' } }
			)
			.then((res) => {
				let sortedPayments = res.data.Items.reverse();
				setPayments(sortedPayments);
			})
			.catch((err) =>
				setAlert(<Failure title="Oops!" message={err.message} />)
			);
	}, []);

	// Loading
	const [showState, setShowState] = useState(<LoadingPage />);

	useEffect(() => {
		if (payments.length > 0) {
			setShowState(
				payments.map((payment, index) => {
					return <Payment key={index} payment={payment} />;
				})
			);
		}
	}, [payments]);

	return (
		<div className="all-container">
			{alert}
			<div className="all-labels">
				<div className="row">
					<div className="col-4 txt-heading">User</div>
					<div className="col-3 txt-heading">Date</div>
					<div className="col-3 txt-heading">Amount</div>
					<div className="col-2 txt-heading">Type</div>
				</div>
			</div>
			{showState}
		</div>
	);
}

export default AdminPayments;
