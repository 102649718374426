import React from 'react';
function HowItWorks(props) {
	return (
		<div className="col-sm-12 col-md-4 col-lg-4">
			<div className="numbering-steps new-orange">{props.step}</div>
			<div className="how-it-works">
				{<img className="second-images" src={props.img} alt="" />}
				<h2 className="sc-text">{props.text}</h2>
				<p className="sc-paragraph">{props.paragraph}</p>
			</div>
		</div>
	);
}

export default HowItWorks;
