import moment from 'moment';

export const utcTimeFormat = (tmp) => {
	tmp = tmp * 1000;
	return moment(tmp).format('DD MMM, YYYY');
};

export const numberWithCommas = (x) => {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
