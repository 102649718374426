import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ultimateConverter } from '../../tools/timeConverter';
import axios from 'axios';
import Input from '../settings/Input';
import { Failure } from '../alerts/Alerts';

function DetailedSession(props) {
	const [alert, setAlert] = useState();

	// Show and Set Session Data
	const [when, setWhen] = useState('');
	const [student, setStudent] = useState('');
	const [tutor, setTutor] = useState('');
	const [help, setHelp] = useState('');
	const [link, setLink] = useState('');
	const [sessionStatus, setSessionStatus] = useState('');

	// Access id value
	const history = useHistory();
	const id = props.id;

	// Show and Set Session Data
	useEffect(() => {
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/sessions/`,
				{
					params: {
						sessionParam: id,
					},
				}
			)
			.then((res) => {
				setWhen(ultimateConverter(res.data.when));
				setStudent(res.data.studentName);
				setTutor(res.data.tutorName);
				setHelp(res.data.helpText);
				setLink(res.data.tutorUrl);
				setStatus(res.data.completed, res.data.tutorMiss, res.data.studentMiss);
			})
			.catch((err) =>
				setAlert(<Failure title="Oops!" message={err.message} />)
			);
	}, [id]);

	function cancelSession() {
		axios
			.delete(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/sessions/`,
				{
					data: {
						sessionParam: id,
					},
				}
			)
			.then(() => {
				// reload
				history.go(0);
			})
			.catch((err) =>
				setAlert(<Failure title="oops!" message={err.message} />)
			);
	}

	function setStatus(completed, tutorMissed, studentMissed) {
		if (tutorMissed) {
			setSessionStatus('Tutor Missed');
		} else if (studentMissed) {
			setSessionStatus('Student Missed');
		} else if (completed) {
			setSessionStatus('Completed');
		} else {
			setSessionStatus('Not Marked');
		}
	}

	return (
		<div>
			<div classNameName=" general-container">
				{alert}

				<h4>{when}</h4>
				<br />

				<Input label="Student" value={student} type="text" />

				<Input label="Tutor" value={tutor} type="text" />

				<Input label="Help on" value={help} type="text" />

				<Input label="Session Status" value={sessionStatus} type="text" />

				<div className="row">
					<div className="col-6">
						<a href={link} target="_blank" rel="noopener noreferrer">
							<button>Join</button>
						</a>
					</div>
					<div className="col-6">
						<button onClick={cancelSession} className="delete-user">
							Cancel
						</button>
					</div>
				</div>

				<br />
				<p className="explain-text">{id}</p>
			</div>
		</div>
	);
}

export default DetailedSession;
