import React, { useState, useContext, useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import PricingCard from './PricingCard';
import Done from '../../book/Done';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { AuthContext } from '../../../contexts/AuthContext';
import LoadingButton from '../../loading/LoadingButton';
import { Failure } from '../../alerts/Alerts';
import axios from 'axios';
import { constantKeys } from '../../../tools/constant';
import { useHistory } from 'react-router-dom';
//import { Redirect } from 'react-router-dom';
import './../index.scss';
import './card.scss';

const packageType = {
	month: { subtotal: 10, total: 10 },
	year: { subtotal: 8.25, total: 99 },
};

function PurchaseSubscription(props) {
	const history = useHistory();
	const auth = useContext(AuthContext);
	const { currentStudent } = auth;
	const stripe = useStripe();
	const elements = useElements();
	const [show, setShow] = useState(false);
	const [payState, setPayState] = useState('Pay $0');
	const [errorMessage, setErrorMessage] = useState('');

	const [step, setStep] = useState(0);
	const [subscriptionMode, setSubscriptionMode] = useState(null);

	const handleModalClose = () => {
		setStep(1);
		if (props?.handleClose) {
			props.handleClose(false);
		}
	};

	const handleClose = () => {
		setShow(false);
		setStep(0);
	};

	const purchaseTrial = () => {
		if (currentStudent) {
			purchaseSubscription();
		}
	};

	const purchaseSubscription = () => {
		setStep(1);
		setShow(true);
	};

	useEffect(() => {
		if (props.isOpen) {
			purchaseTrial();
		}
		// eslint-disable-next-line
	}, []);

	const handleBookSession = () => {
		handleClose();
		var selectedTutorId = localStorage.getItem(
			constantKeys.REGISTER_RECOM_TUTOR
		);

		axios
			.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/checkout/student_profile`,
				{
					student_id: auth.userHash,
				}
			)
			.then((result) => {
				var response = result.data;
				if (response && response.status) {
					auth.setCurrentStudent(response.data);
					history.push('/student-dashboard', {
						pickedTutorHash: selectedTutorId,
						action: 'book',
					});
				}
			});
	};

	const handleSubscription = (mode) => {
		setSubscriptionMode(mode);
		setStep(2);

		var payAmount = packageType[mode]['total'];
		setPayState(`Pay $${payAmount}`);
	};

	const handlePayment = async (e) => {
		setPayState(<LoadingButton />);
		e.preventDefault();

		var payAmount = packageType[subscriptionMode]['total'];

		if (!stripe || !elements) {
			return;
		}
		try {
			const cardElement = elements.getElement(CardElement);
			// PLEASE REMOVE PAYMENTMETHOD IF NOT NEEDED
			// eslint-disable-next-line
			const { error, token } = await stripe.createToken(cardElement);

			if (error) {
				var errorMessage =
					error?.message ||
					'Failed to process credit card. Please try again or contact us';
				setErrorMessage(errorMessage);
			} else {
				const { id } = token;
				const subscriptionRes = await axios.post(
					`${process.env.REACT_APP_NEW_API_ENDPOINT}/checkout/payment_process`,
					{
						student_id: auth.userHash,
						token_id: id,
						subscription_duration: subscriptionMode,
					}
				);
				setErrorMessage('');
				if (
					subscriptionRes &&
					subscriptionRes.data &&
					subscriptionRes.data.status
				) {
					//alert(subscriptionRes.data.message);
					window.location.reload();
				} else {
					setErrorMessage(
						subscriptionRes?.data?.message || 'Something went wrong!'
					);
				}
			}
			setPayState(`Pay $${payAmount}`);
		} catch (err) {
			var errMessage =
				err?.response?.data?.message ||
				'Something went wrong! Please try again or contact us';

			setPayState(`Pay $${payAmount}`);

			setErrorMessage(errMessage);
		}
	};

	const Steps = () => {
		if (step === 1) {
			return (
				<>
					<h1>Choose a Plan</h1>
					<Row>
						<Col sm={12} md={6}>
							<PricingCard
								title={'Monthly subscription'}
								recommended={false}
								next={
									<button
										className={'primary-button alt-button'}
										onClick={() => handleSubscription('month')}
									>
										Purchase
									</button>
								}
								type={'monthly'}
								price={packageType['month']['total']}
							/>
						</Col>
						<Col sm={12} md={6}>
							<PricingCard
								title={'Yearly subscription'}
								next={
									<button
										className={'primary-button'}
										onClick={() => handleSubscription('year')}
									>
										Purchase
									</button>
								}
								type={'yearly'}
								price={packageType['year']['total']}
							/>
						</Col>
					</Row>

					<div className="feature-list">
						<ul>
							<li>
								<i className="fa fa-check-circle"></i>
								<span>
									<strong>Office Hours:</strong> Every week throughout the
									school year, Anya hosts live virtual "Office Hours". Ask
									questions and get answers to your most pressing math homework
									questions. Educational and Fun!
								</span>
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								<span>
									<strong>STEM Videos:</strong> Homework videos, math
									foundations, study strategies and more, #TeamAnya posts videos
									on a weekly basis.
								</span>
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								<span>
									<strong>Math Chat:</strong> Need help in a hurry? For any math
									questions you have, #TeamAnya has answers.
								</span>
							</li>
						</ul>
					</div>
				</>
			);
		} else if (step === 2 && subscriptionMode) {
			return (
				<div className="align-left">
					<h1>Confirm Purchase</h1>
					<div className="confirm-box">
						<h3>{subscriptionMode}ly subscription</h3>
						<div className={'price-tag'}>
							<h2>
								${packageType[subscriptionMode]['total']}/{subscriptionMode}
							</h2>
						</div>
					</div>
					<div className={'total-row'}>
						<h2>Total:</h2>
						<h3>${packageType[subscriptionMode]['total']}</h3>
					</div>

					<form onSubmit={handlePayment}>
						<div className="stripe-card">
							<CardElement />
						</div>

						<button
							className={'primary-button'}
							type={'submit'}
							disabled={!stripe || !elements}
						>
							{payState}
						</button>

						<div style={{ marginTop: '10px' }}>
							{errorMessage.length > 0 ? (
								<Failure title={'Error!'} message={errorMessage} />
							) : null}
						</div>
					</form>
				</div>
			);
		} else {
			return (
				<>
					<Done
						title={'You are all set'}
						paragraph={`Your subscription has started, you will be charged $${packageType?.[subscriptionMode]?.['total']}. You can cancel anytime by going to 'payments' under your account.`}
						text2={''}
						btns={true}
						tutorName={selectedTutor?.name || null}
						onBook={handleBookSession}
					/>
				</>
			);
		}
	};

	const [selectedTutor, setSelectedTutors] = useState(null);

	useEffect(() => {
		var selectedTutorId = localStorage.getItem(
			constantKeys.REGISTER_RECOM_TUTOR
		);
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/tutors`
			)
			.then((res) => {
				let tutorsData = res.data?.Items || [];
				var tutor = tutorsData.find((item) => item.id === selectedTutorId);
				if (tutor) setSelectedTutors(tutor);
			});

		// eslint-disable-next-line
	}, []);

	if (props.isOpen) {
		return (
			<Modal
				centered
				show={true}
				onHide={handleModalClose}
				className={'free-trial-modal'}
				dialogClassName="free-trial-modal-dialog"
			>
				{!!!props?.nonClosable && <Modal.Header closeButton></Modal.Header>}

				<Modal.Body>{Steps()}</Modal.Body>
			</Modal>
		);
	}

	return (
		<>
			{/* <button className="referral-button" onClick={purchaseSubscription}>
				<span>Buy Subscription</span>
			</button> */}

			<Modal
				centered
				show={show}
				onHide={handleClose}
				className={'free-trial-modal'}
				dialogClassName="free-trial-modal-dialog"
			>
				<Modal.Header closeButton></Modal.Header>

				<Modal.Body>{Steps()}</Modal.Body>
			</Modal>
		</>
	);
}

export default PurchaseSubscription;
