import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

function Mark(props) {
	const { sessionId, isMarked, markSession } = props;

	const [status, setStatus] = useState('');
	const [reportBox, setReportBox] = useState();
	const [report, setReport] = useState();

	const [completeClass, setCompleteClass] = useState(
		'complete-pick modal-pick'
	);

	const [show, setShow] = useState(false);

	const handleConfirm = () => {
		markSession(sessionId, status, report);
	};

	function showMark() {
		setShow(true);
	}

	function handleClose() {
		setShow(false);
	}

	useEffect(() => {
		if (status === 'completed') {
			setCompleteClass('complete-pick modal-pick picked');
			setReportBox(
				<div>
					<p class="send-report-text">Send Report</p>
					<textarea
						value={report}
						onChange={(e) => setReport(e.target.value)}
						type="text"
						name="report"
						id="report"
						class="auth-input report-message"
						placeholder="[4/12] We focused on graphing lines today. We also moved on to slope intercept form and understood the meaning behind x and y intercepts. Student A did very well and I look forward to our next session. For homework, please make sure to complete the homework packet."
						required
					></textarea>
				</div>
			);
		} else {
			setCompleteClass('complete-pick modal-pick');
			setReportBox();
		}
	}, [status, report]);

	return (
		<div>
			{/* Check to see if the session has been marked */}
			{isMarked ? (
				<button class="completed-session">
					<i class="fas fa-check"></i>
				</button>
			) : (
				<button class="mark-button" data-toggle="modal" onClick={showMark}>
					Mark
				</button>
			)}

			{/* Mark Modal */}

			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div class="center-it">
						<h4>Did the session happen?</h4>
						<br />
						<form onSubmit={handleConfirm}>
							<div class="pick-div row">
								<span
									className={completeClass}
									tabindex="1"
									onClick={() => setStatus('completed')}
								>
									{' '}
									Complete 
								</span>

								<span
									className="complete-pick modal-pick"
									tabindex="1"
									onClick={() => setStatus('student-missed')}
								>
									{' '}
									Student Missed 
								</span>

								<span
									className="complete-pick modal-pick"
									tabindex="1"
									onClick={() => setStatus('tutor-missed')}
								>
									{' '}
									Tutor Missed
								</span>
							</div>
							{reportBox}

							<div>
								{status.length? (
									<button class="confirm-mark-button" type="submit">
										{' '}
										Confirm{' '}
									</button>
								) : null}
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default Mark;
