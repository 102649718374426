/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Failure } from '../alerts/Alerts';
import axios from 'axios';
import { sha512 } from 'js-sha512';
import LoadingButton from '../loading/LoadingButton';
import { Modal } from 'react-bootstrap';
import { constantKeys } from '../../tools/constant';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import '../home/Modals/modal.css';
import './auth.css';
import './index.scss';

// import RegisterConfirm from './RegisterConfirm';

function Register(props) {
	// eslint-disable-next-line no-unused-vars
	const history = useHistory();
	const { location } = history || {};
	const { pathname } = location || {};
	const [phoneNumber, setPhoneNumber] = useState('');
	const [loading, setLoading] = useState(false);
	const {
		fromHome,
		fromLogin,
		tutorAvailable,
		userQuestion,
		showModal,
		onClose,
		selectedTutor,
		highschoolId,
	} = props || {};

	const isRegisterPage = pathname && pathname === '/register' ? true : false;
	const [show, setShow] = useState(isRegisterPage);

	useEffect(() => {
		if (showModal) {
			setShow(true);
		}
	}, [showModal]);

	const handleShow = () => {
		setShow(true);
	};

	const handleClose = () => {
		setShow(false);
		setStep(0);
		onClose && onClose();
	};

	const [step, setStep] = useState(0);

	const [password, setPassword] = useState(''); // Does this fix exposing password?
	const [formError, setFormError] = useState(null);

	const nextStep = () => {
		setStep(step + 1);
	};

	const Steps = () => {
		if (step === 0) {
			return (
				<Modal.Body>
					<div className="container">
						{/* <div className="auth-logo-div">
						<img className="auth-logo" src="/images/Peerlinc.png" alt="" />
					</div> */}

						<form onSubmit={handleRegister}>
							{/* Name */}
							<label>Full name</label>
							<div>
								<input
									type="text"
									name="name"
									id="inputName"
									className="auth-input"
									placeholder="Enter your full name"
									required
								/>
							</div>

							{/* Student email */}
							<label>Email address</label>
							<div>
								<input
									type="email"
									name="email"
									id="inputStudentEmail"
									className="auth-input"
									placeholder="Enter your email address"
									required
								/>
							</div>

							{/* Phone number */}
							<label>Phone number</label>
							<div className="form-label-group">
								<PhoneInput
									defaultCountry="US"
									addInternationalOption={false}
									countries={['US']}
									withCountryCallingCode={true}
									placeholder="Enter for text message Math Homework Help"
									value={phoneNumber}
									onChange={setPhoneNumber}
									className="custom-phone-input"
								/>
							</div>

							{/* password */}
							<label>Password</label>
							<div className="form-label-group">
								<input
									type="password"
									name="password"
									id="inputPassword"
									className="auth-input"
									placeholder="Create a password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									required
								/>
							</div>

							<div>
								<label>
									<input
										type="checkbox"
										required
										style={{
											width: '20px',
											marginTop: '4px',
										}}
									/>{' '}
									I hereby provide my consent to receive Text SMS from peerlinc.
								</label>
							</div>

							{formError ? (
								<Failure title={formError.title} message={formError.message} />
							) : null}

							<button
								className="auth-button register-button"
								type="submit"
								disabled={loading}
							>
								{loading ? <LoadingButton /> : 'Create account'}
							</button>

							{/* Confirm code design */}
						</form>
					</div>
				</Modal.Body>
			);
		} else {
			return (
				<Modal.Body className="custom-modal">
					{/* <Login newRegister notFromURL pickedTutorHash={recommendTutor} /> */}
					<p>
						We'll send you a text message with an answer to your question. You
						can follow up with the tutor if you have any further questions from
						text message.
					</p>
				</Modal.Body>
			);
		}
	};

	const handleRegister = async (e) => {
		e.preventDefault();

		const email = e.target.email.value.toLowerCase();
		const name = e.target.name.value;
		const grade_number = '';
		const parentEmail = '';
		const where = '';
		const referralCode = null;
		const isValidPhone = isValidPhoneNumber(phoneNumber, 'US');

		try {
			setLoading(true);
			// Error handling for phone number and password length
			if (!isValidPhone) {
				setFormError({
					title: 'Invalid Phone Number.',
					message: 'Make sure your phone number is valid US phone number',
				});
			} else if (password.length < 8) {
				setFormError({
					title: 'Invalid Password.',
					message: 'Password must be at least 8 characters long',
				});
			} else {
				const resCheckMobile = await axios.post(
					`${process.env.REACT_APP_NEW_API_ENDPOINT}/auth/check/mobile`,
					{
						phone_number: phoneNumber,
					}
				);
				const { status, message } = resCheckMobile?.data || {};
				if (!status) {
					setFormError({
						title: 'Unable to Register',
						message: message,
					});
					return;
				}

				// Sign up with necessary information and attributes
				const user = await Auth.signUp({
					username: email,
					password: sha512(password),
					attributes: {
						phone_number: phoneNumber,
						name: name,
						'custom:parent_email': parentEmail,
						'custom:role': 'student',
					},
				});
				setPassword('');
				setFormError(null);

				// Put new student to backend with it's user sub as the hash
				await axios.put(
					`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/students`,
					{
						studentParam: user.userSub,
						nameParam: name,
						emailParam: email,
						phoneNumberParam: phoneNumber,
						imageUrlParam:
							'https://peerlinc-app-assets.s3.us-west-1.amazonaws.com/default_avatar.png',
						mathParam: '',
						parentEmailParam: parentEmail,
						whereParam: where,
						referralCodeParam: referralCode,
						gradeNumberParam: grade_number,
						highschoolIdParam: '1d29ee20-1628-4cad-9504-287adaeafd43',
						schoolNameParam: 'PeerLinc High School',
					}
				);

				const resRegister = await axios.post(
					`${process.env.REACT_APP_NEW_API_ENDPOINT}/auth/register`,
					{
						user_id: user.userSub,
						name: name,
						phone_number: phoneNumber,
						user_email_id: email,
						user_question: userQuestion,
						tutor_id: selectedTutor?.id,
						tutor_name: selectedTutor?.name,
						highschool_id: highschoolId || null,
					}
				);
				const { status: registrationStatus, message: registrationMessage } =
					resRegister?.data || {};
				if (!registrationStatus) {
					setFormError({
						title: 'Unable to Register.',
						message: registrationMessage,
					});
					return;
				}

				localStorage.setItem(constantKeys.IS_NEW_USER, '1');

				// Redirect to confirmation page
				nextStep();
			}
		} catch (error) {
			if (error.name === 'UsernameExistsException') {
				setFormError({
					title: 'Invalid Email.',
					message: 'An account with the given email already exists',
				});
			} else {
				setFormError({
					title: 'Unable to Register.',
					message: 'Error occurred when registering, please try again',
				});
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			{fromHome ? null : fromLogin ? (
				<div onClick={handleShow} className="register-here">
					Register here
				</div>
			) : tutorAvailable?.length > 1 ? (
				<button onClick={handleShow} className="book book-tutor">
					Book a Session
				</button>
			) : (
				<button disabled={true} className="book book-tutor">
					Not Available
				</button>
			)}

			<Modal
				centered
				show={show}
				className="register-modal"
				onHide={handleClose}
				backdrop={isRegisterPage ? 'static' : true}
			>
				{!isRegisterPage && (
					<Modal.Header closeButton>
						<h1>We'll text you the answer</h1>
					</Modal.Header>
				)}
				<div className="modal-design">{Steps()}</div>
			</Modal>
		</div>
	);
}

export default Register;
