import React from 'react';
//import Modal from 'react-bootstrap/Modal';
import './index.scss';
import PurchaseSubscription from './Trial/Trial';

function ModalSubscription(props) {
	const { onClose } = props;
	return (
		<>
			<PurchaseSubscription isOpen handleClose={onClose} />
		</>
	);
}

export default ModalSubscription;
