import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ValidationError } from '@statickit/react';
import Header from '../home/Header';
import Footer from '../home/Footer';
import './contact.css';

function Contact() {
	// Help Types
	const helpTypes = ['General', 'Bug', 'Cancel Request', 'Feedback'];

	const [text, setText] = useState('How can we help?*');

	const history = useHistory();

	function changeHelpType(event) {
		let textValue = event.target.value;
		if (textValue === 'Bug') {
			history.push("/bug")
		}
		if (textValue === 'Cancel Request') {
			setText(
				'Please note that we can not accomdate all requests. Please state which session this is for and explain in detail why you need to cancel this session last minute.'
			);
		} 

		if (textValue === 'Feedback') {
			setText(
				'We appreciate your feedback. Please describe your feedback here.'
			);
		}
	}

	return (
		<div>
			<Header />

			<div className="center-it">
				<h1 className="contact-title">Contact Us</h1>

				<h4 className="contact-paragraph">We will be with you shortly :)</h4>

				<form
					action="https://formspree.io/p/1579158750088920430/f/contactForm"
					method="POST"
				>
					<div>
						{/* TYPE */}
						<select
							type="text"
							name="type"
							class="contact-input"
							id="type"
							onChange={changeHelpType}
							required
						>
							<option disabled selected value="">
								How can we help?
							</option>
							{helpTypes.map((type) => {
								return <option value={type}>{type}</option>;
							})}
						</select>

						{/* NAME */}

						<div>
							<input
								type="name"
								name="name"
								class="contact-input"
								id="name"
								placeholder="Your Name*"
								required
							/>
						</div>

						<ValidationError prefix="Name" field="name" />

						{/* EMAIL */}
						<div>
							<input
								id="email"
								type="email"
								name="email"
								className="contact-input"
								placeholder="Your Email*"
								required
							/>
						</div>

						<ValidationError prefix="Email" field="email" />

						{/* TEXT */}

						<textarea
							type="text"
							name="text"
							className="contact-textarea"
							placeholder={text}
							required
						/>

						<ValidationError prefix="Text" field="text" />
					</div>
					{/* BUTTON */}
					<button type="submit">Reach Us</button>
				</form>
			</div>

			<br />

			<Footer />
		</div>
	);
}

export default Contact;
