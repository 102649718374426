import React from 'react';
import Header from '../../components/funnels/consult/Header';
import Footer from '../../components/home/Footer';
import MeetTutors from '../../components/home/MeetTutors';
import Whiteboard from '../../components/home/Whiteboard';
import HowConsult from '../../components/funnels/consult/HowConsult';
import PageTop from '../../components/home/PageTop';
import Cta from '../../components/home/Cta';

function Consult() {
	return (
		<div>
			<Header />

			<PageTop
				text="Peer to Peer STEM Tutoring"
				paragraph="By students from top universities such as Harvard, UC Berkeley, and UCLA. Book a Free 15 minute consultation and discuss your goals with an advisor."
				MainImage="/images/kid-learning.png"
				mainImageClass="consult-main-image"
				action={
					<a href="/book-consult">
						<button className="consult-action action-button">
							Free 15 Minute Consultation
						</button>
					</a>
				}
				SmallImage="/images/berkeleymade.png"
				playClass="play play-consult fad fa-play-circle"
				video="https://www.youtube.com/embed/uoyDSzZPLVs"
			/>

			<HowConsult />

			<MeetTutors title="Certified Tutors from Top Universities" />

			<Whiteboard />

			<Cta button="Free 15 Minute Consultation" link="/book-consult" />

			<Footer />
		</div>
	);
}

export default Consult;
