/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import Nav from '../../components/dashboard/Nav';
import { Row, Col } from 'react-bootstrap';
import LoadingButton from '../../components/loading/LoadingButton';
import './index.scss';
import NavLogo from '../../components/NavLogo';

function TutorSchools() {
	const auth = useContext(AuthContext);
	const { userHash: tutorId } = auth;

	const [highschools, setHighschools] = useState([]);
	const [loading, setLoading] = useState(false);
	const [formLoading, setFormLoading] = useState('');

	const initialFormData = {
		highschoolId: '',
		videoUrl: '',
	};

	const [studentVideo, setStudentVideo] = useState(initialFormData);

	const [parentVideo, setParentVideo] = useState(initialFormData);

	const handleParentVideoChange = (e) => {
		setParentVideo({
			...parentVideo,
			[e.target.name]: e.target.value,
		});
	};

	const handleStudentVideoChange = (e) => {
		setStudentVideo({
			...studentVideo,
			[e.target.name]: e.target.value,
		});
	};

	useEffect(() => {
		fetchHighschools();
		// eslint-disable-next-line
	}, [tutorId]);

	const fetchHighschools = async () => {
		try {
			setLoading(true);
			const results = await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/teacher/profile`,
				{
					action: 'get_assign_highschools',
					tutorId: tutorId,
				}
			);
			const { data } = results?.data || {};
			if (data) {
				setHighschools(data);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const getHighSchoolUrl = (slug) => {
		const url = `${window?.location?.origin}/hs/${slug}`;
		return (
			<a
				href={url}
				target="_blank"
				rel="noopener noreferrer"
				className="copy-clipboard"
			>
				/{slug}
			</a>
		);
	};

	return (
		<>
			<Nav logo={<NavLogo />} settings="/tutor-settings" />

			<div className="dashboard page-schools">
				<div className="section-header">
					<h3>High Schools</h3>
				</div>

				<div className="section-content">
					{loading && (
						<div className="page-loading">
							<LoadingButton />
						</div>
					)}
					{!loading && (
						<Row>
							<Col lg={12} xs={12}>
								<table className="table table-hover highschool-list">
									<thead>
										<tr>
											<th scope="col">Name</th>
											<th scope="col"></th>
											<th scope="col">Address</th>
											<th scope="col">Total Students</th>
											<th scope="col">HS Page Url</th>
										</tr>
									</thead>
									<tbody>
										{highschools?.length === 0 && (
											<tr>
												<td colSpan="4" className="text-center">
													No High School Found
												</td>
											</tr>
										)}

										{highschools?.map((item, index) => (
											<tr key={index}>
												<td>
													<img
														src={item.logo_image}
														alt=""
														className="logo-img"
													/>
												</td>
												<td>{item?.name}</td>
												<td>
													{item?.street_address}, {item?.city}, {item?.state}
												</td>
												<td>{item?.total_student}</td>
												<td>{getHighSchoolUrl(item?.slug)}</td>
											</tr>
										))}
									</tbody>
								</table>
							</Col>
						</Row>
					)}
				</div>
			</div>
		</>
	);
}

export default TutorSchools;
