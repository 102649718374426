import React from 'react';
import Footer from '../../components/home/Footer';
import ModalApplyInternship from '../../components/home/Modals/ModalApplyInternship';
import './index.scss';

function HomeInternship() {
	const [showModal, setShowModal] = React.useState(false);
	return (
		<div className="home-container page-highschool page-tutors">
			<div className="header-custom">
				<nav className="navbar navbar-custom navbar-expand-lg" id="mainNav">
					<div className="container">
						<a className="navbar-brand" href="/">
							<img
								className="header-image"
								src="/images/Peerlinc.png"
								alt="home"
							/>
						</a>

						<ul className="navbar-nav ml-auto">
							<li>
								<button
									className="btn-custom"
									onClick={() => setShowModal(true)}
								>
									Apply
								</button>
							</li>
						</ul>
					</div>
				</nav>
			</div>

			<div className="container home-banner">
				<div className="row hero-banner">
					<div className="main-writing col-sm-12 col-md-12 col-lg-6">
						<h1 className="text-title">
							<span>Social Media Internship</span>
						</h1>
						<p className="text-description">
							At PeerLinc our goal is to bring students together in a spirit of{' '}
							<strong>learning</strong> and <strong>collaboration</strong>.
							<br />
							<br />
							Help us achieve this mission, while gaining{' '}
							<strong>hands on experience</strong> with this tech internship
							role!
						</p>
						<button className="btn-custom" onClick={() => setShowModal(true)}>
							Apply
						</button>
					</div>

					<div className="col-sm-12 col-md-12 col-lg-6">
						<div className="main">
							<div className="orange-box"></div>
							<div className="orange-box bg-black"></div>
							<div className="main-video-div">
								<iframe
									src="https://www.loom.com/embed/a1c17c70b9924e72acc4a885a9c314ba?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
									title="home video"
									className="main-video"
								></iframe>
							</div>
						</div>
					</div>
				</div>
				<div className="row section-branding">
					<div className="direction-arrow"></div>
					<div className="col-sm-12 col-md-10">
						<div className="box-branding">
							<img
								src="/images/Peerlinc.png"
								alt="branding"
								className="img-logo"
							/>
							<img
								src="/images/icons/icon-equal.svg"
								alt="branding"
								className="img-icon"
							/>
							<span>Math-Chat</span>
							<img
								src="/images/icons/icon-plus.svg"
								alt="branding"
								className="img-icon"
							/>
							<span>Top College Tutors</span>
						</div>
					</div>
				</div>
			</div>

			<section className="section-feature mt-10 mb-10">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<img
								src="/images/features/Math-Chat.gif"
								alt="feature"
								className="img-feature"
							/>
						</div>
						<div className="col-md-6 description-box">
							<h4>Math-Chat</h4>
							<ul>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										For any math questions you have, <strong>Math-Chat</strong>{' '}
										has answers!
									</span>
								</li>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										Learn <strong>key concepts</strong> and get real time{' '}
										<strong>homework</strong> help. More efficiently prepare for{' '}
										<strong>quizzes</strong> and <strong>tests</strong>
									</span>
								</li>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<strong>Note:</strong> Math-Chat is designed as an aide to
										support learning efforts and not designed to do homework for
										students
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<section className="section-feature mb-10">
				<div className="container">
					<div className="row">
						<div className="col-md-6 xs-visible">
							<img
								src="/images/features/Top-College-Tutors.gif"
								alt="feature"
								className="img-feature"
							/>
						</div>
						<div className="col-md-6 description-box">
							<h4>Tutors from Top Universities</h4>
							<ul>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<strong>Top College Tutors</strong>: Get focused STEM help
										in 1-on-1 or group tutor settings.
									</span>
								</li>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<strong>Homework and Test Prep</strong>: Receive top-tier
										tutoring support for all your math and science needs.
									</span>
								</li>
								<li>
									<i className="fa fa-check-circle"></i>
									<span>
										<strong>Math-Chat</strong>: Review homework and Math-Chat
										chats with your tutor. Review the material with your tutor
										for a deeper understanding of key concepts.
									</span>
								</li>
							</ul>
						</div>
						<div className="col-md-6 xs-hidden">
							<img
								src="/images/features/Top-College-Tutors.gif"
								alt="feature"
								className="img-feature"
							/>
						</div>
					</div>
				</div>
			</section>

			<div className="cover-footer">
				<section className="cta-section">
					<div className="container text-center">
						<div className="row">
							<div className="col-sm-12 col-md-12 col-lg-12 cta-div">
								<div className="cta-img-div row">
									<img
										className="cta-img"
										src="/images/online-teacher.png"
										alt="cta"
									/>
									<div className="col-lg-6 col-md-6 text-align-left">
										<h1 className="meet-tutors-title">Ready to get started?</h1>
										<button
											className="btn-custom"
											onClick={() => setShowModal(true)}
										>
											<span className="login-text">Apply for Internship</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<Footer />

			{showModal && (
				<ModalApplyInternship show={true} onClose={() => setShowModal(false)} />
			)}
		</div>
	);
}

export default HomeInternship;
