import React from 'react';
import { ValidationError } from '@statickit/react';
import Header from '../home/Header';
import Footer from '../home/Footer';
import './contact.css';

function ParentContact(props) {
	return (
		<div>
			<Header request = {false}/>
			<div className="contact-container container">
				<div className="row">
					<div className="col-sm-9 col-md-9 col-lg-9 form-group">
						<div className="contact">
							<h1 className="contact-title">{props.title}</h1>

							<h4 className="contact-paragraph">{props.text}</h4>

							<form
								action="https://formspree.io/p/1579158750088920430/f/contactForm"
								method="POST"
							>
								<div>
									{/* TYPE */}
									<input
										type="text"
										name="type"
										class="contact-type"
										id="type"
										value={props.type}
										required
									/>

									{/* NAME */}
									<div className="contact-field">
										<div className="contact-label">
											<label htmlFor="name">What's your Name?*</label>
										</div>
										<div>
											<input
												type="name"
												name="name"
												class="contact-input"
												id="name"
												required
											/>
										</div>
									</div>
									<ValidationError prefix="Name" field="name" />

									{/* EMAIL */}
									<div className="contact-label">
										<label htmlFor="email">What's your Email?*</label>
									</div>

									<input
										id="email"
										type="email"
										name="email"
										className="contact-input"
										required
									/>

									<ValidationError prefix="Email" field="email" />

									{/* PHONE */}
									<div className="contact-label">
										<label htmlFor="number">What's your Phone Number?*</label>
									</div>

									<input
										id="number"
										type="phone"
										name="number"
										className="contact-input"
										required
									/>

									<ValidationError prefix="Number" field="number" />

									{/* TEXT */}
									<div className="contact-label">
										<label htmlFor="text">{props.input}</label>
									</div>

									<input
										type="text"
										name="text"
										className="contact-input"
										required
									/>

									<ValidationError prefix="Text" field="text" />

									{/* TEXT */}
									<div className="contact-label">
										<label htmlFor="where">Where did you find us?</label>
									</div>

									<input
										type="where"
										name="where"
										className="contact-input"
										required
									/>

									<ValidationError prefix="Where" field="where" />
								</div>
								{/* BUTTON */}
								<button className="contact-button" type="submit">
									Submit
								</button>
							</form>
						</div>
					</div>

					<div className="col-md-3 col-sm-3 col-lg-3">
						<img
							className="contact-image"
							src="../images/online-teacher.png"
							alt="contact"
						/>
					</div>
				</div>
			</div>
			<br />
			<br />
			<Footer />
		</div>
	);
}

export default ParentContact;
