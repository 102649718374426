import React, { useState } from 'react';
import DetailedStudent from './DetailedStudent';
import Modal from 'react-bootstrap/esm/Modal';

function Student(props) {
	const [show, setShow] = useState(false);
	const {
		name,
		email,
		phoneNumber,
		parentEmail,
		balance,
		id,
		firstSessionBooked,
		firstPackagePurchased,
	} = props.student;

	// Registered, booked, or client
	function status() {
		if (firstPackagePurchased) {
			return (
				<p>
					<i className="green fas fa-check"></i> Client
				</p>
			);
		} else if (firstSessionBooked) {
			return <p>Booked</p>;
		} else {
			return <p>Registered</p>;
		}
	}

	function showStudent() {
		setShow(true);
	}

	function handleClose() {
		setShow(false);
	}

	return (
		<div>
			<Modal size="lg" show={show} onHide={handleClose}>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<DetailedStudent id={id} />
				</Modal.Body>
			</Modal>
			<div onClick={showStudent}>
				<div className="a-data">
					<div className="row">
						<div className="col-2 text-data">{name}</div>
						<div className="col-2 text-data">{email}</div>
						<div className="col-2 text-data">{phoneNumber}</div>
						<div className="col-2 text-data">{parentEmail}</div>
						<div className="col-2 text-data">{balance}</div>
						<div className="col-2 text-data">{status()}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Student;
