import React from 'react';
import './settings.css';

function SaveButton(props) {
	return (
		<button className="settings-save" onClick={props.onClick}>
			{props.loadState}
		</button>
	);
}

export default SaveButton;
