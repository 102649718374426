import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../../components/admin/home/Header';

import './index.scss';
import LoadingButton from '../../../components/loading/LoadingButton';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';

function AdminEditHighschool(props) {
	const history = useHistory();
	const highschoolId = props?.match?.params?.id || null;
	const [alert, setAlert] = useState(null);
	const [activeFilter, setActiveFilter] = useState('public');
	const [slug, setSlug] = useState('');
	const [imageURL, setImageURL] = useState(null);
	const [image, setImage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [state, setState] = useState({
		name: '',
		street_address: '',
		city: '',
		state: '',
		zipcode: '',
		country: '',
		district: '',
		total_student: '',
		date_created: '',
	});

	useEffect(() => {
		if (highschoolId) {
			getHighSchool();
		}
		// eslint-disable-next-line
	}, [highschoolId]);

	const getHighSchool = () => {
		setLoading(true);
		Axios.post(`${process.env.REACT_APP_NEW_API_ENDPOINT}/highschool`, {
			action: 'check_highschool',
			slug: highschoolId,
		}).then((result) => {
			var response = result.data;
			if (response.status) {
				const highschoolInfo = response?.data || {};
				setState({
					name: highschoolInfo?.name,
					street_address: highschoolInfo?.street_address,
					city: highschoolInfo?.city,
					state: highschoolInfo?.state,
					zipcode: highschoolInfo?.zipcode,
					country: highschoolInfo?.country,
					district: highschoolInfo?.district,
					total_student: highschoolInfo?.total_student,
					date_created: highschoolInfo?.date_created,
				});
				setActiveFilter(highschoolInfo?.type);
				setImageURL(highschoolInfo?.logo_image);
				setSlug(highschoolInfo?.slug);
			}
			setLoading(false);
		});
	};

	const handleSlugChange = (val) => {
		// allow only lowercase letters, numbers, and dashes
		const regex = /^[a-z0-9-]*$/;
		if (regex.test(val)) {
			setSlug(val);
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		setImage(file);
		const reader = new FileReader();
		reader.onloadend = () => {
			setImageURL(reader.result);
		};
		reader.readAsDataURL(file);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setAlert(null);

		try {
			var fileUrl = imageURL;
			if (image) {
				const response = await axios.post(
					`${process.env.REACT_APP_NEW_API_ENDPOINT}/general/image/upload`,
					{
						folder: 'highschools',
						file_name: image?.name || 'highschools.png',
						content_type: image?.type || 'image/png',
					}
				);
				const { data } = response;
				const { uploadURL } = data;

				fileUrl = uploadURL.split('?')[0];

				await axios.put(uploadURL, image, {
					headers: {
						'Content-Type': image?.type || 'image/png',
					},
				});
			}
			const postData = {
				action: 'edit_highschool',
				id: highschoolId,
				logo_image: fileUrl,
				name: state?.name || null,
				street_address: state?.street_address || '',
				city: state?.city || '',
				state: state?.state || '',
				zipcode: state?.zipcode || '',
				country: state?.country || '',
				district: state?.district || '',
				total_student: state?.total_student || '',
				date_created: state?.date_created || '',
				type: activeFilter,
				slug: slug,
			};

			const saveResponse = await axios.post(
				`${process.env.REACT_APP_NEW_API_ENDPOINT}/admin/manage_highschool`,
				postData
			);
			const { data: saveData } = saveResponse;
			if (saveData.status) {
				setAlert({
					type: 'success',
					message: 'Highschool updated successfully',
				});
				setTimeout(() => {
					history.push('/admin-highschools');
				}, 3000);
			} else {
				setAlert({
					type: 'danger',
					message: saveData.message,
				});
			}

			setLoading(false);
		} catch (err) {
			console.log(err);
			setAlert({
				type: 'danger',
				message: 'Something went wrong! Please try again later.',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="page-admin-add-highschool">
			<Header />
			<div className="container-fluid">
				<div className="row mt-5">
					<div className="col-md-2"></div>
					<div className="col-md-8">
						<div className="card">
							<h4>Edit high school {loading ? <LoadingButton /> : null}</h4>
							<form onSubmit={handleSubmit}>
								<div className="form-group">
									<div className="custom-file-input-box">
										{imageURL ? (
											<div className="input-preview">
												<img src={imageURL} alt="preview" />
												<i className="fas fa-pencil edit-icon"></i>
											</div>
										) : (
											<div className="input-badge">
												<i className="fa fa-upload" aria-hidden="true"></i>
												<span>HS Logo</span>
											</div>
										)}
										<input
											type="file"
											className="file-input"
											onChange={handleImageChange}
										/>
									</div>
								</div>
								<div className="form-group">
									<label>High School Name</label>
									<input
										type="text"
										className="form-control"
										name="name"
										placeholder="Enter High School Name"
										value={state?.name || ''}
										onChange={handleInputChange}
										required
									/>
								</div>
								<div className="form-group">
									<label>Street address</label>
									<input
										type="text"
										className="form-control"
										name="street_address"
										placeholder="Enter Street address"
										value={state?.street_address || ''}
										onChange={handleInputChange}
										required
									/>
								</div>
								<div className="row">
									<div className="col-md-4">
										<div className="form-group">
											<label>City</label>
											<input
												type="text"
												className="form-control"
												name="city"
												placeholder="Enter City"
												value={state?.city || ''}
												onChange={handleInputChange}
											/>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label>State</label>
											<input
												type="text"
												className="form-control"
												name="state"
												placeholder="Enter State"
												value={state?.state || ''}
												onChange={handleInputChange}
											/>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label>Zip code</label>
											<input
												type="text"
												className="form-control"
												name="zipcode"
												placeholder="Enter Zip code"
												value={state?.zipcode || ''}
												onChange={handleInputChange}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<label>District</label>
											<input
												type="text"
												className="form-control"
												name="district"
												placeholder="Enter District"
												value={state?.district || ''}
												onChange={handleInputChange}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<label>County</label>
											<input
												type="text"
												className="form-control"
												name="country"
												placeholder="Enter County"
											/>
										</div>
									</div>
								</div>
								<div className="form-group">
									<label>Type</label>
									<div className="type-selection">
										<span
											className={activeFilter === 'public' ? 'active' : ''}
											onClick={() => setActiveFilter('public')}
										>
											Public
										</span>
										<span
											className={activeFilter === 'private' ? 'active' : ''}
											onClick={() => setActiveFilter('private')}
										>
											Private
										</span>
									</div>
								</div>
								<div className="form-group">
									<label>Total # of student @ HS</label>
									<input
										type="text"
										className="form-control"
										name="total_student"
										placeholder="Enter total number"
										value={state?.total_student || ''}
										onChange={handleInputChange}
									/>
								</div>
								<div className="form-group">
									<label>URL</label>
									<input
										type="text"
										className="form-control"
										name="slug"
										placeholder="Enter URL of website"
										value={slug}
										onChange={(e) => handleSlugChange(e.target.value)}
										required
									/>
									<p className="text-helper">
										Note: allow only lowercase letters, number & dashes
									</p>
								</div>
								<div className="form-group text-right">
									<button
										type="submit"
										className="btn-custom-primary"
										disabled={loading}
									>
										{loading ? <LoadingButton /> : 'Submit'}
									</button>
								</div>

								{alert && (
									<div className="form-group">
										<div className={`alert alert-${alert?.type}`} role="alert">
											{alert?.message}
										</div>
									</div>
								)}
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdminEditHighschool;
