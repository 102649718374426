import Axios from 'axios';
import React from 'react';
import AnimatedText from '../../components/home/AnimatedText';
import Footer from '../../components/home/Footer';
import LoadingPage from '../../components/loading/LoadingPage';
import { useEffect } from 'react';
import { getTutors } from '../../services/api';
import { Carousel } from 'react-bootstrap';
import Register from '../../components/auth/Register';
import './index.scss';
import AskTutorQuestion from '../../components/home/AskTutorQuestion';

function HomeHighschool(props) {
	const { id } = props.match.params;
	const [highschool, setHighschool] = React.useState(null);
	const [loading, setLoading] = React.useState(true);

	const [slideIndex, setSlideIndex] = React.useState(0);
	const [tutors, setTutors] = React.useState([]);
	const [showRegister, setShowRegister] = React.useState(false);
	const [userQuestion, setUserQuestion] = React.useState('');

	const fetchData = async () => {
		const response = await getTutors();
		setTutors(response);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleAskQuestion = () => {
		if (!userQuestion) return;
		setShowRegister(true);
	};

	React.useEffect(() => {
		const getHighschool = async () => {
			setLoading(true);
			Axios.post(`${process.env.REACT_APP_NEW_API_ENDPOINT}/highschool`, {
				action: 'check_highschool',
				slug: id,
			}).then((result) => {
				var response = result.data;
				if (response.status) {
					setHighschool(response.data);
				}
				setLoading(false);
			});
		};

		getHighschool();
	}, [id]);

	if (loading) {
		return (
			<div className="highschool-home page-highschool">
				<LoadingPage />
				<div className="text-helper-info">Loading...</div>
			</div>
		);
	}

	if (!highschool) {
		return (
			<div className="highschool-home page-highschool">
				<div className="text-helper-info">No Highschool found</div>
				<a href="/" className="btn-custom">
					Go to Home
				</a>
			</div>
		);
	}

	return (
		<>
			<div className="home-container page-highschool">
				<div className="header-custom">
					<nav className="navbar navbar-custom navbar-expand-lg" id="mainNav">
						<div className="container">
							<a className="navbar-brand" href="/">
								<img
									className="header-image"
									src="/images/Peerlinc.png"
									alt="home"
								/>
							</a>

							<ul className="navbar-nav ml-auto">
								<li>
									<a type="button" className="btn-custom" href={`/login`}>
										Login
									</a>
								</li>
							</ul>
						</div>
					</nav>
				</div>

				<div className="container home-banner">
					<div className="row hero-banner">
						<div className="main-writing col-sm-12 col-md-12 col-lg-6">
							<h1 className="text-title">
								{!!highschool?.logo_image && (
									<img
										src={highschool?.logo_image}
										className="logo-school"
										alt={highschool?.name}
									/>
								)}
								<span className="text-school-name">{highschool?.name}</span>
							</h1>
							<div className="home-description">
								<span>Need Math</span>
								<AnimatedText />
								<span>Help?</span>
							</div>

							<div className="xs-hidden w-full">
								<AskTutorQuestion
									tutorName={
										tutors?.length > 0 ? tutors[slideIndex]?.name : null
									}
									userQuestion={userQuestion}
									setUserQuestion={setUserQuestion}
									handleAskQuestion={handleAskQuestion}
								/>
							</div>
						</div>

						<div className="col-sm-12 col-md-12 col-lg-6">
							<div className="home-tutor-card">
								<div className="blank-tutor-card dir1"></div>
								<div className="blank-tutor-card dir2"></div>

								{tutors?.length > 0 && (
									<>
										<img
											src="/images/icons/top-badge.svg"
											alt="tutor"
											className="top-badge"
										/>
										<Carousel
											interval={null}
											activeIndex={slideIndex}
											onSelect={(index) => setSlideIndex(index)}
											onSlide={(index) => setSlideIndex(index)}
											autoPlay={false}
											indicators={false}
											// controls={false}
										>
											{tutors?.map((tutor, index) => {
												return (
													<Carousel.Item key={index}>
														<div className="tutor-card">
															<div
																className="tutor-profile-image"
																style={{
																	backgroundImage: `url(${tutor?.image || ''})`,
																}}
															></div>

															<div className="tutor-info-description">
																<div className="tutor-name">
																	{tutor?.name || ''}
																</div>
																<div className="tutor-school">
																	<img
																		src={tutor?.universityImageLink}
																		alt="school"
																	/>
																	<span>{tutor?.university || ''}</span>
																</div>
															</div>
														</div>
													</Carousel.Item>
												);
											})}
										</Carousel>
									</>
								)}
							</div>

							<div className="xs-visible mt-5">
								<AskTutorQuestion
									tutorName={
										tutors?.length > 0 ? tutors[slideIndex]?.name : null
									}
									userQuestion={userQuestion}
									setUserQuestion={setUserQuestion}
									handleAskQuestion={handleAskQuestion}
								/>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="home-peerlinc-branding">
							<div className="box-border-top"></div>
							<div className="branding-box">
								<div className="branding-card">
									<img
										src="/images/Peerlinc.png"
										alt="branding"
										className="img-logo"
									/>
									<span>math copilot</span>
								</div>
								<img
									src="/images/icons/icon-equal.svg"
									alt="branding"
									className="img-icon"
								/>
								<div className="branding-card">
									<span className="brand-text">Top 1% Tutors + AI</span>
									<span>
										Your{' '}
										<u>
											<strong>Best</strong>
										</u>{' '}
										Math Grade Yet!
									</span>
								</div>
							</div>

							<div className="box-border-top"></div>
						</div>
					</div>
				</div>

				<div className="footer-top">
					<Footer />
				</div>
			</div>

			{showRegister && (
				<Register
					fromHome={true}
					showModal={showRegister}
					onClose={() => setShowRegister(false)}
					userQuestion={userQuestion}
					selectedTutor={tutors[slideIndex]}
					highschoolId={highschool?.id}
				/>
			)}
		</>
	);
}

export default HomeHighschool;
