export const GRADES = ['9th', '10th', '11th', '12th'];
export const MATHS_COURSES = [
	'Algebra 1',
	'Algebra 2',
	'Geometry',
	'Trigonometry',
	'Pre-Calculus',
	'Calculus',
];
export const SCIENCE_COURSES = ['Biology', 'Chemistry', 'Physics'];
