import React from 'react';
import Main from './Main';
import { FormspreeProvider } from '@formspree/react';
import axios from 'axios';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import MetaTags from 'react-meta-tags';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import './master.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import 'react-tagsinput/react-tagsinput.css';
import 'react-phone-number-input/style.css';

// Change API key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function App() {
	// to show whether test or live site API is getting called
	// console.log('you are calling ' + process.env.REACT_APP_STAGE + ' API');

	axios.defaults.headers.common = {
		'Content-Type': 'application/json',
		'x-api-key': process.env.REACT_APP_AWS_API_KEY,
	};

	return (
		<FormspreeProvider project="1579158750088920430">
			<MetaTags>
				<title>Peerlinc</title>
				<meta
					name="description"
					content="Peer Tutoring by Students from Top Universities"
				/>
				<meta
					name="image"
					content="https://www.peerlinc.com/images/Peerlinc.png"
				/>
				<meta property="og:title" content="Peerlinc" />
				<meta
					property="og:image"
					content="https://www.peerlinc.com/images/Peerlinc.png"
				/>
			</MetaTags>
			<Elements stripe={stripePromise}>
				<Main />
			</Elements>
			<ToastContainer />
		</FormspreeProvider>
	);
}

export default App;
