import React, { useEffect, useState } from 'react';
import { utcStringToLocalDate } from '../../tools/timeConverter';
import Header from '../../components/admin/home/Header';
import Search from '../../components/tutors/Search';
import Tutor from '../../components/admin/Tutor';
import axios from 'axios';
import NothingFound from '../../components/loading/NothingFound';
import LoadingPage from '../../components/loading/LoadingPage';
import { Failure } from '../../components/alerts/Alerts';

function AdminTutors() {
	const [alert, setAlert] = useState();

	// Which tutors?
	const [whichTutors, setWhichTutors] = useState('certified');

	// Call API, wait for all Tutors
	const [tutors, setTutors] = useState([]);

	useEffect(() => {
		setShowState(<LoadingPage />);
		axios
			.get(
				`https://x2kepay5wg.execute-api.us-west-1.amazonaws.com/${process.env.REACT_APP_STAGE}/admin/tutors`
			)
			.then((res) => {
				// Filter out deleted tutors
				var filteredTutors = res.data.Items.filter(function (tutor) {
					return tutor.email != null;
				});

				var pickedTutors = [];

				if (whichTutors === 'certified') {
					// Filter out and return certified and active tutors
					pickedTutors = filteredTutors.filter(function (tutor) {
						return (
							tutor.ansariCertified === true && tutor.availability.length > 1
						);
					});
				}
				if (whichTutors === 'applied') {
					// Filter out and return applied tutors
					pickedTutors = filteredTutors.filter(function (tutor) {
						return tutor.ansariCertified === false;
					});
				}
				if (whichTutors === 'inactive') {
					// Filter out and return tutors with no availability
					pickedTutors = filteredTutors.filter(function (tutor) {
						return (
							tutor.availability.length <= 1 && tutor.ansariCertified === true
						);
					});
				}
				var sortedTutors = pickedTutors.sort(
					(aTutor, bTutor) =>
						utcStringToLocalDate(bTutor.created) -
						utcStringToLocalDate(aTutor.created)
				);
				setTutors(sortedTutors);
			})
			.catch(() =>
				setAlert(
					<Failure
						title="Oops!"
						message="Something happened. Try again or contact us."
					/>
				)
			);
	}, [whichTutors]);

	// Set type of tutor
	const changeStatusFilter = (filter) => {
		setWhichTutors(filter);
	};

	// Show Tutors after API answer
	const [showState, setShowState] = useState(
		<tr>
			<td>
				<LoadingPage />
			</td>
		</tr>
	);

	useEffect(() => {
		if (tutors.length > 0) {
			setShowState(
				tutors.map((tutor, index) => <Tutor key={index} tutor={tutor} />)
			);
		}
	}, [tutors]);

	// Search Functionality
	const [searchState, setSearch] = useState('');

	function searchOnChange(event) {
		setSearch(event.target.value);
	}

	function findTutor(e) {
		e.preventDefault();

		var results = [];

		var search = searchState.toString().toLocaleLowerCase();

		if (searchState === '') {
			setShowState(tutors.map((tutor) => <Tutor tutor={tutor} />));
			return;
		}
		for (let t = 0; t < tutors.length; t++) {
			var id = tutors[t].id.toLowerCase();
			var name = tutors[t].name.toLowerCase();
			var phone = tutors[t].phoneNumber.toLowerCase();
			var email = tutors[t].email.toLowerCase();

			if (
				name.includes(search) ||
				phone.includes(search) ||
				email.includes(search) ||
				id.includes(search)
			) {
				results.push(tutors[t]);
			}
		}
		if (results.length > 0) {
			setShowState(results.map((tutor) => <Tutor tutor={tutor} />));
			return;
		}

		setShowState(<NothingFound />);
	}

	return (
		<div>
			<Header />
			<div className="admin-container">
				{alert}

				<Search
					searchOnClick={findTutor}
					searchOnChange={searchOnChange}
					inputValue={searchState}
				/>

				<div className="sessions-when">
					<div className="row">
						<div className="col-4">
							<button
								className={
									whichTutors === 'certified' ? 'admin-picked' : 'admin-pick'
								}
								onClick={() => changeStatusFilter('certified')}
							>
								Certified
							</button>
						</div>
						<div className="col-4">
							<button
								className={
									whichTutors === 'applied' ? 'admin-picked' : 'admin-pick'
								}
								onClick={() => changeStatusFilter('applied')}
							>
								Applied
							</button>
						</div>
						<div className="col-4">
							<button
								className={
									whichTutors === 'inactive' ? 'admin-picked' : 'admin-pick'
								}
								onClick={() => changeStatusFilter('inactive')}
							>
								Inactive
							</button>
						</div>
					</div>
				</div>

				<div className="all-students-container">
					<div className="all-labels">
						<table className="table table-hover data-table">
							<thead>
								<tr>
									<th scope="col">
										<i className="fal fa-user"></i> Name
									</th>
									<th scope="col">
										<i className="far fa-envelope-open"></i> Email
									</th>
									<th scope="col">
										<i className="far fa-phone-alt"></i> Phone
									</th>
									<th scope="col">Balance</th>
									<th scope="col">Status</th>
									<th scope="col"></th>
								</tr>
							</thead>
							<tbody>{showState}</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdminTutors;
