import React from 'react';


function HowConsult() {
    function HowItWorks(props) {
        return (
            <div className="col-sm-12 col-md-4 col-lg-4">
                <img className="second-images" src={props.img} alt="" />
                <h2 className="consult-heading">{props.text}</h2>
                <p className="consult-paragraph">{props.paragraph}</p>
            </div>
        );
    }
	return (
		<div>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 120">
				<path
					fill="#fafafa"
					fill-opacity="1"
					d="M0,64L80,58.7C160,53,320,43,480,48C640,53,800,75,960,69.3C1120,64,1280,32,1360,16L1440,0L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
				></path>
			</svg>
			<div className="second-container bg-light" id="">
				<div className="second-section container">
					<div className="row">
						<HowItWorks
							img="/images/match.png"
							text="Free Consultation"
							paragraph="Book a Free 15 minute consultation to discuss your goals "
						/>
						<HowItWorks
							img="/images/book.png"
							text="1 Free Hour"
							paragraph="After the consultation, you get 1 free hour of tutoring with your matched tutor"
						/>
						<br />
						<HowItWorks
							img="/images/learn.png"
							text="Buy a Package"
							paragraph="If you've enjoyed the free session, you can choose to move forward with a package of hours"
						/>
					</div>
				</div>
			</div>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220">
				<path
					fill="#fafafa"
					fill-opacity="1"
					d="M0,64L80,58.7C160,53,320,43,480,48C640,53,800,75,960,69.3C1120,64,1280,32,1360,16L1440,0L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
				></path>
			</svg>
		</div>
	);
}

export default HowConsult;
