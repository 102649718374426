import React, {useContext} from 'react'
import {Route} from 'react-router-dom'
import {AuthContext} from '../contexts/AuthContext'
import TutorDashboard from '../pages/private/TutorDashboard'
import StudentDashboard from '../pages/private/StudentDashboard'
import Admin from '../pages/admin/Admin'
import {useHistory} from 'react-router-dom' 

// Route that only non-authenticated users can access
function UnPrivateRoute({component: Component, ...rest}) {

    const history = useHistory()

    const auth = useContext(AuthContext)

    return (
        <Route {...rest} render={props => {
            if (auth.currentUser !== null) {
                // Redirect to appropriate dashboard if user is logged in
                if (auth.role === 'tutor') {
                    history.push('/tutor-dashboard')
                    return <TutorDashboard {...props} />
                } else if (auth.role === 'student') {
                    history.push('/student-dashboard')
                    return <StudentDashboard {...props} />
                } else if (auth.role === 'admin') {
                    history.push('/admin')
                    return <Admin {...props} />
                }
            } else {
                // Continue to desired page if user is not logged in
                return <Component {...props} />
            }
        }} />
    )
}

export default UnPrivateRoute
