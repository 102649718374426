import React from 'react';
import Header from '../../components/admin/home/Header';
import SubscriptionReports from '../../components/admin/data/SubscriptionReports';
import Rates from '../../components/admin/data/Rates';

function AdminData() {
	return (
		<div>
			<Header />
			<div className="admin-container page-analytic">
				<SubscriptionReports />

				<br />

				<h2 className="data-title">Rates</h2>
				<Rates />
			</div>
		</div>
	);
}

export default AdminData;
