import React, { useState, useEffect } from 'react';
import {
	localDateToUtcString,
	formattedSessionTime,
} from '../../tools/timeConverter';
import './book.css';

function BookContentOneTime(props) {
	const [formattedTime, setFormattedTime] = useState('');
	const request = props.time - Date.now() < 12 * 60 * 60 * 1000;

	useEffect(() => {
		//console.log(props.time)
		setFormattedTime(formattedSessionTime(props.time));
	}, [props.time]);

	const setSessionTime = () => {
		props.setTime(props.time);
		props.setChosenSession(localDateToUtcString(new Date(props.time)));
	};

	return (
		<div class="col-3" onClick={setSessionTime}>
			<div class="dates">
				<button class="book-date-day" tabindex="1">
					<span class="book-a-date">
						{formattedTime}
						{request ? '*' : ''}
					</span>
				</button>
			</div>
		</div>
	);
}

function BookContentTime(props) {
	const [availableTimes, setAvailableTimes] = useState([]);
	const [requestExplain, setRequestExplain] = useState();

	// add available times
	useEffect(() => {
		let newAvailableTimes = [];

		for (let i = 0; i < props.availableSessions.length; i++) {
			if (props.availableSessions[i].getDate() === props.day.getDate()) {
				newAvailableTimes.push(props.availableSessions[i]);
			}
		}

		newAvailableTimes.sort((aTime, bTime) => aTime - bTime);

		setAvailableTimes(newAvailableTimes);
	}, [props.availableSessions, props.day]);

	// check if there is a time that requires a request
	useEffect(() => {
		for (let i = 0; i < availableTimes.length; i++) {
			if (availableTimes[i] - Date.now() < 12 * 60 * 60 * 1000) {
				setRequestExplain(<p class="explain-text">
				*Bookings that are not 12 hours in advance require tutor approval.
			</p>)
			}
		}
	}, [availableTimes]);

	return (
		<div className="col-12">
			<p class="book-explain explain-text">All times are in your timezone.</p>
			<div className="book-content-container">
				<div className="row">
					{availableTimes.map((time) => {
						return (
							<BookContentOneTime
								time={time}
								setTime={props.setTime}
								setChosenSession={props.setChosenSession}
							/>
						);
					})}
				</div>
				<br />
				{requestExplain}
			</div>
		</div>
	);
}

export default BookContentTime;
