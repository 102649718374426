import React, { useState, useEffect } from 'react';
import './alerts.css';

function Success(props) {
	const [alertStyle, setAlertStyle] = useState({ display: 'none' });

	const closeAlert = () => {
		setAlertStyle({ display: 'none' });
	};

	useEffect(() => {
		if (props.title.length > 0) setAlertStyle({ display: 'block' });
		else setAlertStyle({ display: 'none' });
	}, [props.title]);

	return (
		<div
			style={alertStyle}
			className="alert alert-success alert-dismissible fade show"
			role="alert"
		>
			<strong>{props.title}</strong> {props.message}
			<button
				type="button"
				className="close-alert close"
				// data-dismiss="alert"
				// aria-label="Close"
				onClick={() => setAlertStyle(closeAlert)}
			>
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
	);
}

function Failure(props) {
	const [alertStyle, setAlertStyle] = useState({ display: 'none' });

	const closeAlert = () => {
		setAlertStyle({ display: 'none' });
	};

	useEffect(() => {
		if (props.title.length > 0) setAlertStyle({ display: 'block' });
		else setAlertStyle({ display: 'none' });
	}, [props.title]);

	return (
		<div
			style={alertStyle}
			className="alert alert-danger alert-dismissible fade show"
			role="alert"
		>
			<strong>{props.title}</strong>
			<div>{props.message}</div>
			<button
				type="button"
				className="close-alert close"
				// data-dismiss="alert"
				// aria-label="Close"
				onClick={closeAlert}
			>
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
	);
}

export { Success, Failure };
